import React, { Component } from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import {notify, baseurl} from './utility/utility.js';
import {sendToLoginIfNoAccess, loggeduser} from './utility/security';

import { observer } from "mobx-react";
import AppStore from './store/AppStore';


const GenarateProducts = observer(class GenarateProducts extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggeduser:loggeduser(),
			productType: '',
			title:'',
			description:'',
			price:'',
			modelgroup: '',
			modelquantity:0,
			modelName:'Select Model Name',
			_id:'',
			addProducts:[],
			models:[],
			quantity:0,
			selectModel:[],
			allselectedModel:[],
			allselectedcategory:[],
			allselectedmaterials:[],
			images:[],
			allmodels:[],
			selectedModel:'Select Model',
			selectedmaterials:'Select Materials',
			allcategories:[],
			allmaterials:[],
			selectedcategory:'Select Category',
			modelImagePath:'',
			enabled: true,
		};
	}



	componentWillMount(){
		sendToLoginIfNoAccess("products","add", this, "/GenarateProducts");
		axios.get(baseurl()+'/models', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({allmodels : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	    axios.get(baseurl()+'/materials', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({allmaterials : response.data.result});
			}
	    })

	    axios.get(baseurl()+'/categories', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({allcategories : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	    this.getImage(this.state.productType);
	}

	getImage(productType){
		if(productType == ''){
			this.setState({images: []});
		}else{
			axios.get(baseurl()+'/images/'+productType, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
				console.log(response);
				this.setState({images: response.data.files});
	    })
		}

	}

	changeInput(e){
		var input=e.target;
		if(input.name === "title"){
			this.setState({title : input.value});
		}
		else if(input.name === "description"){
			this.setState({description : input.value});
		}
		else if(input.name === "price"){
			this.setState({price : input.value});
		}
		else if(input.name === "models"){
			this.setState({models : input.value});
		}else if(input.name === "modelgroup"){
			this.setState({modelgroup : input.value});
		}else if(input.name === "productType"){
			this.setState({productType : input.value});
			this.getImage(input.value);
		}
	}

	onChangeFile(e) {
		const url = baseurl()+'/images';
		const data = new FormData();
		for(var i=0;i<e.target.files.length;i++){
			//images.push('/uploads/productimages/cc'+e.target.files[i].name);
			data.append('file',e.target.files[i]);
		}
		data.append('folder',this.state.productType);
		// const config = {
		//     headers: {
		//         'content-type': 'multipart/formdata'
		//     }
		// }
		axios.post(url,data, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
			this.getImage(this.state.productType);
			notify("Image Uploaded", "#28a745");
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	saveData(){
		console.log(this.state);

		if(this.state.modelgroup && this.state.productType && this.state.title && this.state.price && this.state.description && this.state.productImagesFolder && this.state.modelImagePath){

			axios.post(baseurl()+"/products/generate", {
				"productImagesFolder":this.state.productImagesFolder,
				"modelImagePath":this.state.modelImagePath,
				"productType":this.state.productType,
				"materials":this.state.allselectedmaterials,
				"title":this.state.title,
				"price":this.state.price,
				"modelGroup":this.state.modelgroup,
				"models":this.state.allselectedModel,
				"description":this.state.description,
				"categories":this.state.allselectedcategory
			}, { headers: { authorization: this.state.loggeduser.token } })
			.then((res) => {

				console.log(res)

				if(res.data.success === true){
					console.log(res);
					this.setState({addProducts : res.data.result});
					notify("Product Generated", "#28a745");
				}

			})
			.catch((err) => {
				console.log(err)
			})

			// axios.post(baseurl()+'/products/'+this.state.productType, {
			//   	title:this.state.title,
			// 	price:this.state.price,
			// 	modelgroup: this.state.modelgroup,
			// 	producttype: this.state.productType,
			// 	quantity:this.state.quantity,
			// 	description:this.state.description,
			// 	models: this.state.allselectedModel,
			// 	categories: this.state.allselectedcategory,
			// 	materials:this.state.allselectedmaterials,
			// 	enabled:this.state.enabled,
			//   } , { headers: { authorization: this.state.loggeduser.token } })
			//   .then((response)=> {
			// 	   if(response.data.success === true){
			// 	   	console.log(response);
			//   		this.setState({addProducts : response.data.result});
			//   		notify("Product Generated", "#28a745");
			//       }
			//   })
			//   .catch(function (error) {
			//     console.log(error);
			// 	});

		}else{
			notify("Select Product Model Group And Type", "#28a745");
		}
	}

	selectModel(name){
		this.setState({selectedModel : name});
	}

	selectcategory(name){
		this.setState({selectedcategory : name});
	}

	selectmaterials(name){
		this.setState({selectedmaterials : name});
	}

	addModel(){
		var models = {};
		models.model = this.state.selectedModel;
		models.quantity = this.state.modelquantity;
		console.log(models);
		var allselectedModel = JSON.parse(JSON.stringify(this.state.allselectedModel));
		allselectedModel.push(models);
		//console.log(allselectedModel);
		this.setState({allselectedModel : allselectedModel});
		this.calculateQuantity(allselectedModel);
	}

	addcategory(){
		var category = this.state.selectedcategory;
		console.log(category);
		var allselectedcategory = JSON.parse(JSON.stringify(this.state.allselectedcategory));
		allselectedcategory.push(category);
		//console.log(allselectedcategory);
		this.setState({allselectedcategory : allselectedcategory});
	}

	addmaterials(){
		var materials = this.state.selectedmaterials;
		console.log(materials);
		var allselectedmaterials = JSON.parse(JSON.stringify(this.state.allselectedmaterials));
		allselectedmaterials.push(materials);
		//console.log(allselectedmaterials);
		this.setState({allselectedmaterials : allselectedmaterials});
	}

	removematerials(index){
		var allselectedmaterials = this.state.allselectedmaterials;
		allselectedmaterials.splice(index, 1);
		this.setState({ allselectedmaterials: allselectedmaterials });
	}

	removeModel(index){
		var allselectedModel = this.state.allselectedModel;
		allselectedModel.splice(index, 1);
		this.setState({ allselectedModel: allselectedModel });
		this.calculateQuantity(allselectedModel);
	}

	removecategory(index){
		var allselectedcategory = this.state.allselectedcategory;
		allselectedcategory.splice(index, 1);
		this.setState({ allselectedcategory: allselectedcategory });
	}

	calculateQuantity(allselectedModel){
		var totalQuantiy = 0;
		for(var i =0; i < allselectedModel.length;i++){
			var quantity = parseFloat(allselectedModel[i].quantity);
			totalQuantiy += quantity;
		}
		this.setState({ quantity: totalQuantiy });
	}

	delete(data){
		axios.delete(baseurl()+'/images/', {
			data:{
				images: [this.state.productType+"/" + data],
			}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response.data);
		    this.getImage(this.state.productType);
			notify("Image Removed", "#dc3545");
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	selectproductType(type){
		this.setState({productType: type});
		this.getImage(type);
	}

  render(){

		const productTypes = JSON.parse(JSON.stringify(AppStore.productTypes)).map((data, index)=>{
				return (
					<option key={index} value={data.name} >{data.name}</option>
				);
		});

		const modelgroups = AppStore.modelgroups.map((data, index)=>{
			return (
					<option key={index} value={data.name} >{data.name}</option>
			);
		});


  	const allmodels = this.state.allmodels.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectModel.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

	const allcategories = this.state.allcategories.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectcategory.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

	const allmaterials = this.state.allmaterials.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectmaterials.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

  	const addedModel = this.state.allselectedModel.map(function(data, index){
		console.log(data);
		return(
			<tr key={index} className="selected-model">
				<td >
					{data.model}
				</td>
				<td >
					<span onClick={this.removeModel.bind(this, index)}><i className="fa fa-times"></i></span>
				</td>
			</tr>
		)
	}, this);

	const addedcategory = this.state.allselectedcategory.map(function(data, index){
		console.log(data);
		return(
			<tr key={index} className="selected-model">
				<td >
					{data}
				</td>
				<td >
					<span onClick={this.removecategory.bind(this, index)}><i className="fa fa-times"></i></span>
				</td>
			</tr>
		)
	}, this);

	const addedmaterials = this.state.allselectedmaterials.map(function(data, index){
		console.log(data);
		return(
			<tr key={index} className="selected-model">
				<td >
					{data}
				</td>
				<td >
					<span onClick={this.removematerials.bind(this, index)}><i className="fa fa-times"></i></span>
				</td>
			</tr>
		)
	}, this);

	const uploadedImage = this.state.images.map(function(data, index){
			var url = baseurl()+'/uploads/productimages/'+this.state.productType+'/'+data;
			return(
				<div key={index} className="col-3">
					<div className="profile-author">
	                    <img src={url.toLowerCase()} alt="Uploaded product"/>
	                    <div className="profile-icon">
	                        <i className="fa fa-times" onClick={this.delete.bind(this, data)}></i>
	                    </div>
	                </div>
				</div>
		    )
	}, this);

    return (
        <div className="addProducts">
        	<Sidebar GenarateProducts="active" productshow="show" />
	        <div className="page">
	        	<Header />
	        	<div className="container">
	        		<div className="row spd-mt-30">
	        			<div className="col-12">
									<div className="form-group">
										<label>Select Product Type</label>
										<select name="productType"  className="form-control" onChange={this.changeInput.bind(this)}>
											<option value=''>Select Product Type</option>
											{productTypes}
										</select>
									</div>
	        			</div>
	        		</div>
					<div className="row">
						<div className="col-12 add-product">
							<form>
								<div className="form-group">
									<label htmlFor="title">Title</label>
									<input type="text" className="form-control" id="title" name="title" value={this.state.title} onChange={this.changeInput.bind(this)}/>
								</div>
								<div className="form-group">
									<label htmlFor="description">Description</label>
									<textarea rows="5" type="text" name="description" id="description"  className="form-control" value={this.state.description} onChange={this.changeInput.bind(this)}> </textarea>
								</div>
								<div className="form-group">
									<label htmlFor="price">Price</label>
									<input type="number" name="price" id="price"  className="form-control" value={this.state.price} onChange={this.changeInput.bind(this)}/>
								</div>

								<div className="form-group">
									<label htmlFor="productImageFolder">Product Image Folder</label>
									<input type="text" name="productImageFolder" id="productImageFolder"  className="form-control" value={this.state.productImagesFolder} onChange={e => this.setState({productImagesFolder:e.target.value})}/>
								</div>

								<div className="form-group">
									<label htmlFor="modelImagePath">Model Image Path</label>
									<input type="text" name="modelImagePath" id="modelImagePath"  className="form-control" value={this.state.modelImagePath} onChange={e => this.setState({modelImagePath:e.target.value})} />
								</div>

								<div className="form-group">
									<label>Select Model Group</label>
									<select name="modelgroup"  className="form-control" onChange={this.changeInput.bind(this)}>
										<option value="">Select Model Group</option>
										{modelgroups}
									</select>
								</div>

								<div className="select-model">
				        			<div className="dropdown">
										<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								    		{this.state.selectedModel}
								 		</button>
							  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							    			{allmodels}
							  			</div>
									</div>
									<span onClick={this.addModel.bind(this)} className="btn btn-primary">Add</span>
					        	</div>
					        	<div  className="row added-Model">
					        		<div className="col-12">
					        		<table className="table table-responsive">
					        		 {addedModel}
					        		</table>
					        		</div>
					        	</div>
					        	<div className="select-model">
				        			<div className="dropdown">
										<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								    		{this.state.selectedcategory}
								 		</button>
							  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							    			{allcategories}
							  			</div>
									</div>

									<span onClick={this.addcategory.bind(this)} className="btn btn-primary">Add</span>
					        	</div>
					        	<div  className="row added-Model">
					        		<div className="col-12">
					        		<table className="table table-responsive">
					        		 {addedcategory}
					        		</table>
					        		</div>
					        	</div>
					        	<div className="select-model">
				        			<div className="dropdown">
										<button className="btn btn-default dropdown-toggle" type="button" id="addmaterials" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								    		{this.state.selectedmaterials}
								 		</button>
							  			<div className="dropdown-menu" aria-labelledby="addmaterials">
							    			{allmaterials}
							  			</div>
									</div>

									<span onClick={this.addmaterials.bind(this)} className="btn btn-primary">Add</span>
					        	</div>
					        	<div  className="row added-Model">
					        		<div className="col-12">
					        		<table className="table table-responsive">
					        		 {addedmaterials}
					        		</table>
					        		</div>
					        	</div>
					        </form>
						      <div className="modal-footer">
						        <button type="button" className="btn btn-primary" onClick={this.saveData.bind(this)}>Generate Data</button>
						      </div>
						</div>
			        </div>
			        <div className="row">
						<div className="col-12">
							<form>
								<div className="form-group">
									<label htmlFor="imageupload">Image Upload</label>
									<input className="form-control" type="file" onChange={this.onChangeFile.bind(this)} multiple />
								</div>
							</form>
						</div>
			        </div>
			         <div className="row">
						<div className="col-12">
							<h2>IMAGE LIST</h2>
						</div>
					</div>
					<div className="row">
						{uploadedImage}
					</div>
	        	</div>
	        </div><ToastContainer />
        </div>
    );
  }
})

export default GenarateProducts;
