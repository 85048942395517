import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";
import AppStore from './store/AppStore'

const ModelGroup = observer(class ModelGroup extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			update:false,
			id: '',
			name:'',
			models:[],
			ModelGroup:[],
		};
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/ModelGroup" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/modelgroups', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({ModelGroup : response.data.result});
				AppStore.modelgroups = response.data.result;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}else if(input.name === "models"){
			this.setState({models : input.value});
		}
	}

	loadaddData(){
		this.setState({_id : '', name:'', models:[], update:false});
	}

	loadeditData(data){
		this.setState({_id : data._id, name:data.name, models:data.models, update:true});
	}

	addData(){
		axios.post(baseurl()+'/modelgroups', {
		    name: this.state.name,
		    models: this.state.models,
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({ModelGroup : response.data.result});
				AppStore.modelgroups = response.data.result;
				notify("Model Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/modelgroups/'+this.state._id, {
		    name: this.state.name,
		    models: this.state.models,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({ModelGroup : response.data.result});
				AppStore.modelgroups = response.data.result;
				notify("Model Updated", "#0275d8");
				}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/modelgroups/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({ModelGroup : response.data.result});
				AppStore.modelgroups = response.data.result;
				notify('Model Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	cngModel(name){
		var grpModel = this.state.models;
		var chkInModel = this.chkInModel(name);
		if(chkInModel.exist){
			grpModel.splice(chkInModel.index,1);
		}else{
			grpModel.push(name);
		}
		this.setState({models:grpModel});
	}

	chkInModel(name){
		var grpModel = this.state.models;
		var exist = false;
		var index = null;
		for(var i = 0; grpModel.length > i; i++){
			if(name == grpModel[i]){
				exist = true;
				index = i;
				break;
			}
		}
		return {exist:exist, index:index};
	}

	selectAll(){
		var AllModels = JSON.parse(JSON.stringify(AppStore.models));
		var grpModel = [];
		for(var i = 0; AllModels.length > i; i++){
			grpModel.push(AllModels[i].name);
		}
		console.log(grpModel)
		this.setState({models: grpModel});
	}

	selectAllIphone(){
		var AllModels = JSON.parse(JSON.stringify(AppStore.models));
		var grpModel = [];
		for(var i = 0; AllModels.length > i; i++){
			if((AllModels[i].name.toLowerCase()).indexOf('iphone') !== -1 ){
				grpModel.push(AllModels[i].name);
			}
		}
		this.setState({models: grpModel})
	}

	selectByPhone(e){
		var brand = e.target.value
		var AllModels = JSON.parse(JSON.stringify(AppStore.models));
		var grpModel = [];
		for(var i = 0; AllModels.length > i; i++){
			if((AllModels[i].name.toLowerCase()).indexOf(brand) !== -1 ){
				grpModel.push(AllModels[i].name);
			}
		}
		this.setState({models: grpModel})
	}

  render() {

		const allModels = AppStore.models.map((data, index)=>{
			return (
				<label key={index} class="checkbox-inline mr-3">
					<input onChange={() => this.cngModel(data.name)} checked={this.chkInModel(data.name).exist} type="checkbox" value={data.name} /> {data.name}
				</label>
			);
		})

  	const jsonitems=JSON.parse(JSON.stringify(this.state.ModelGroup)).map(function(data){
			return(
				<tr key={data._id}>
			      <td>{data.name}</td>
			      <td>{data.models.map((model, modelIndex) => {return (<span key={modelIndex}>{model} , </span>);})}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
		}, this);



    return (
        <div >
        	<Sidebar ModelGroup = "active" productshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container ModelGroup">
	        	<div className="row">
	        		<div className="col-12">
	        			<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add</button>
								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog modal-lg" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">{this.state.update ? "Update" : "Add"}</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
													<div className="form-group">
														<label htmlFor="Name">Name</label>
														<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
													</div>
													<div className="form-group">
														<label htmlFor="Name">Models</label>

														<button type="button" className="btn btn-success pull-right" onClick={this.selectAll.bind(this)}>Select All</button>
														<select onChange={this.selectByPhone.bind(this)} className="form-control pull-right" style={{width:200}}>
															<option>Select By Brand</option>
															<option value='iphone'>Iphone</option>
															<option value='samsung'>Samsung</option>
															<option value='huawei'>Huawei</option>
															<option value='oppo'>Oppo</option>
															<option value='redmi'>redmi</option>
														</select>
														<button type="button" className="btn btn-danger pull-right" onClick={()=> this.setState({models:[]})}>Un Select All</button>





													</div>
													{allModels}
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.state.update ? this.editData.bind(this) : this.addData.bind(this)} >{this.state.update ? "Update" : "Add"}</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Name</th>
							      <th>Models</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div>
						<ToastContainer />
	        </div>
        </div>
    );
  }
})

export default ModelGroup;
