import React, { Component } from 'react';
import axios from 'axios';
import {baseurl} from './utility/utility.js';
import { observer } from "mobx-react";
import {loggeduser} from './utility/security';
import AppStore from './store/AppStore';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './toastr.min.css';
import 'antd/dist/antd.css';

import {BrowserRouter,Route} from 'react-router-dom';

import Dashboard from './Dashboard';
import Products from './products';
import invoice from './invoice';
import AlbumLinks from './AlbumLinks';
import addProducts from './addproducts';
import Login from './login';
import Orders from './orders';
import PendingOrders from './PendingOrders';
import AppNWebOrders from './AppNWebOrders';
import OrderTasks from './OrderTasks';
import addOrder from './addOrder';
import Customer from './customer';
import customerareas from './customerareas';
import deliveryPerson from './deliveryPerson';
import Productlist from './productlist';
import categories from './categories';
import materials from './materials';
import models from './models';
import ModelGroup from './ModelGroup';
import ProductTypes from './ProductTypes';
import Profile from './profile';
import viewproduct from './viewproduct';
import GenarateProducts from './GenarateProducts';
import distribution from './distribution';
import PrintDistributionList from './PrintDistributionList';
import myorders from './myorders';
import wishlist from './wishlist';
import myorderDetails from './myorderDetails';
import recoverpassword from './recoverpassword';
import resetpassword from './resetpassword';
import coupons from './coupon';
import Logs from './logs';
import Locations from './locations';
import Inventory from './Inventory';
import tasks from './tasks';
import CompleteTasks from './CompleteTasks';
import blacklist from './blacklist';
import UploadImages from './UploadImages';
import courier from './courier';
import courierDistribution from './courierDistribution';
import Accounts from './Accounts';
import BlankCovers from './blankcovers';
import PrintBlankCase from './PrintBlankCase';
import EntryCategories from './EntryCategories';
import CustomizerFonts from './CustomizerFonts';
import Customizer from './Customizer';
import Templates from './Templates';
import FBChats from './FBChats';
import Offers from './Offers';
import OrderEntries from './OrderEntries';
import ModelRequest from './ModelRequest';
import SaleOffer from './SaleOffer';
import PopularDesign from './PopularDesign';
import FileManager from './FileManager';



import toastr from 'toastr';
import PopularModel from './PopularModel.js';
import Waste from './Waste.js';
import InventoryReport from './InventoryReport.js';
import TrackModifications from './TrackModifications.js';
import Approvals from './approvals.js';
import FunctionalPage from './FunctionalPage';


toastr.options = {
	"closeButton": false,
	"debug": false,
	"newestOnTop": false,
	"progressBar": false,
	"positionClass": "toast-bottom-right",
	"preventDuplicates": false,
	"onclick": null,
	"showDuration": "300",
	"hideDuration": "1000",
	"timeOut": "5000",
	"extendedTimeOut": "1000",
	"showEasing": "swing",
	"hideEasing": "linear",
	"showMethod": "fadeIn",
	"hideMethod": "fadeOut"
  }

const App = observer(class App extends Component {

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
		};
		//AppStore.loggeduser = loggeduser();
	}

	componentWillMount(){

		this.loadData('customerareas');
		this.loadData('models');
		this.loadData('blacklist');
		this.loadData('courier');
		this.loadData('productTypes');
		this.loadData('tasks');
		this.loadData('modelgroups');
		this.loadData('approvals')
	}

	loadData(field){
		axios.get(baseurl()+'/'+field , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
			if(response.data.success){
				AppStore[field] = response.data.result;
			}else{
				alert("Could Not Load "+field);
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}


	render() {

		return (
      <BrowserRouter>
        <div>
          <Route exact path="/" component={Productlist} />
          <Route path="/addProducts" component={addProducts} />
          <Route path="/editProducts/:id" component={addProducts} />
          <Route path="/products" component={Products} />
          <Route path="/album-links" component={AlbumLinks} />
          <Route path="/login" component={Login} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/pending-orders" component={PendingOrders} />
          <Route exact path="/app-web-orders" component={AppNWebOrders} />
          <Route path="/order-tasks" component={OrderTasks} />
          <Route path="/blankcovers" component={BlankCovers} />
          <Route path="/blacklist" component={blacklist} />
          <Route path="/invoice/:id" component={invoice} />
          <Route path="/addOrder" component={addOrder} />
          <Route path="/editOrder/:id" component={addOrder} />
          <Route path="/myorderDetails/:id" component={myorderDetails} />
          <Route path="/customer" component={Customer} />
          <Route path="/customerareas" component={customerareas} />
          <Route path="/deliveryPerson" component={deliveryPerson} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/models" component={models} />
          <Route path="/offers" component={Offers} />
          <Route path="/ModelGroup" component={ModelGroup} />
          <Route path="/product-types" component={ProductTypes} />
          <Route path="/categories" component={categories} />
          <Route path="/materials" component={materials} />
          <Route path="/viewproduct/:id" component={viewproduct} />
          <Route path="/profile" component={Profile} />
          <Route path="/UploadImages" component={UploadImages} />
          <Route path="/GenarateProducts" component={GenarateProducts} />
          <Route path="/reports/distribution" component={distribution} />
          <Route
            path="/reports/courier-distribution"
            component={courierDistribution}
          />
          <Route
            path="/reports/printdistributionlist"
            component={PrintDistributionList}
          />
          <Route path="/myorders" component={myorders} />
          <Route path="/wishlist" component={wishlist} />
          <Route path="/recoverpassword" component={recoverpassword} />
          <Route path="/resetpassword/:id" component={resetpassword} />
          <Route path="/logs" component={Logs} />
          <Route path="/coupon" component={coupons} />
          <Route path="/locations" component={Locations} />
          <Route path="/inventory" component={Inventory} />
          <Route path="/waste" component={Waste} />
          <Route path="/inventory-report" component={InventoryReport} />
          <Route path="/tasks" exact component={tasks} />
          <Route path="/tasks/complete" exact component={CompleteTasks} />
          <Route path="/courier" exact component={courier} />
          <Route path="/accounts" exact component={Accounts} />
          <Route path="/printblankcase" exact component={PrintBlankCase} />
          <Route path="/entrycategories" exact component={EntryCategories} />
          <Route path="/file-manager" exact component={FileManager} />
          <Route path="/functionalcomponent" exact component={FunctionalPage} />

          <Route path="/customizer-fonts" exact component={CustomizerFonts} />
          <Route path="/customizer" exact component={Customizer} />
          <Route path="/customizer/:updateId" exact component={Customizer} />
          <Route path="/templates" exact component={Templates} />
          <Route path="/fb-chats" exact component={FBChats} />
          <Route path="/order-entries" exact component={OrderEntries} />
          <Route path="/model-request" exact component={ModelRequest} />
          <Route path="/sale-offer" exact component={SaleOffer} />
          <Route path="/popular-design" exact component={PopularDesign} />
          <Route path="/popular-model" exact component={PopularModel} />
          <Route
            path="/track-modifications/:item/:id"
            exact
            component={TrackModifications}
          />
          <Route path="/approvals" exact component={Approvals} />
        </div>
      </BrowserRouter>
    );
	}
})

export default App;
