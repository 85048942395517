import React, {Component} from 'react';
import {loggeduser} from './utility/security';
import {calculate, numberOfproduct} from './utility/utility.js';

class PrintBlankCase extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggeduser:loggeduser(),
		};
	}

	chkCourier(data){
		return data.filter((ele) => { return ele != 'Select Delivery Person' })
	}

  render() {

	console.log(this.props.location.state)

	const {list} = this.props.location.state;

    return (
        <div className="container PrintBlankCase">

            <h1 className='text-center m-2'>Distribution List For Delivery Persons</h1>

			<div className="row">
				<div className="col-md-12">
					<table className="table table-striped" style={{fontSize:12}}>
						<thead>
							<tr>
								<th className="min-width-80">Name</th>
								<th className="min-width-80">Area </th>
								<th>Total</th>
								<th>Due</th>
								<th>Con:</th>
								<th>Products</th>
								<th>Receivable</th>
								<th>Received</th>
							</tr>
						</thead>
						<tbody>
							{this.chkCourier(Object.keys(list)).map((data, index) =>
								<>
									{/* <tr key={index} style={{backgroundColor:'green', color:'#fff', paddingLeft:10}}>
										<td style={{paddingLeft:10}}>
											{data}
										</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr> */}
									<h3 className='m-0 mt-2 py-2 text-center' >{data}</h3>
									{list[data].map((singleEle, singleIndex) =>
										<tr key={singleIndex} style={{color:singleEle.courierCompany ? "#0000FF" : ''}}>
											<td>
												<b>{singleEle.customerName} {singleEle.courierCompany ? " ("+singleEle.courierCompany+")" : ''}</b>
											</td>
											<td>{singleEle.customerArea}</td>
											<td>{singleEle.total}</td>
											<td>{singleEle.due}</td>
											<td>{singleEle.conveyance}</td>
											<td>{numberOfproduct(singleEle.products)}</td>
											<td>{singleEle.due - singleEle.conveyance}</td>
											<td style={{border:'1px solid #555'}}></td>
										</tr>
									)}
									<tr>
										<td></td>
										<td>Total</td>
										<td>{calculate(list[data],"total")}</td>
										<td>{calculate(list[data],"due")}</td>
										<td>{calculate(list[data],"conveyance")}</td>
										<td></td>
										<td>{calculate(list[data],"receivable")}</td>
										<td style={{border:'1px solid #555'}}></td>
									</tr>
								</>
                            )}
						</tbody>
					</table>
				</div>
			</div>
		</div>
    );
  }
}

export default PrintBlankCase;

