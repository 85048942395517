import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import moment from 'moment';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser} from './utility/security';
import OrderTask from './components/OrderTask';
import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';
import {Link} from 'react-router-dom';

const tasks = observer(class tasks extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			orders:[]
		};
		//this.Data.bind(this)

	}

	componentDidMount(){
		this.filterByDate()
	}

	filterByDate = () => {

		var startDate = moment().subtract(2, 'days').format("YYYY/MM/DD");
		var endDate = moment().add(2, 'days').format("YYYY/MM/DD");

		axios.get(baseurl() + '/orders?startDate='+startDate+'&endDate='+endDate , { headers: { authorization: this.state.loggeduser.token } })
		.then( (response)=> {
			if(response.data.success === true){
				this.setState({orders:response.data.result})
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	UpdateTask = (orderData, tasks) => {
		orderData.tasks = tasks;
		axios.put(baseurl()+'/orders/'+orderData._id, orderData , { headers: { authorization: this.state.loggeduser.token } })
		.then((res)=>{
			if(res.data.success){
				this.filterByDate();
				notify("Tasks Updated", "#0275d8");
			}
		})
		.catch((err)=>{
			notify('Could Not Update Order!','#F44336');
		})
	}



  render() {

	let {orders} = this.state;

    return (
        <div >
        	<Sidebar ordertasks = "active"  />
	        <div className="page">
	        	<Header  />
	        	<div className="container tasks">
					<div className="row">
						<div className="col-12">
							<h1 className='mt-4 text-center' >Task List</h1>
							{orders.map((data, index) => {
								var incomplated = data.tasks.filter((ele) => {return ele.complete ==  false})
								console.log(incomplated)
								if(incomplated.length > 0){
									return (
										<div style={{border:'1px solid #ddd', marginTop:20, padding:20}}>
											<div className='p-1'>
												<div className='text-right'>
													<Link target="_blank" title="Order Details" onClick={()=> AppStore.fromDistribution = false} className="btn btn-primary btn-sm" to={ "editOrder/" + data._id} >
														<i className="fa fa-eye"></i> Order Details
													</Link>
												</div>
												<b>Order Date: {moment(data.orderDate).format('DD/MM/YYYY')}</b> <br/>
												<b>Order Date: {data.customerName}</b>

											</div>
											<OrderTask key={index} tasks={data.tasks} UpdateTaskData={(tasks) => this.UpdateTask(data, tasks) } />
										</div>
									);
								}
							})}
						</div>
					</div>
	        	</div>
				<ToastContainer />
	        </div>
        </div>
    );
  }
})

export default tasks;
