import React, {Component} from 'react';
import {Link} from 'react-router-dom';


class Footer extends Component {


	render() {
		return (
			<footer className="footer-distributed">
				<div className="container">
					<div className="row">
						<div className="footer-left col-md-3">

							<img src="img/footerlogo.png" alt="image missing"/>

							<p className="footer-links">

								<Link to="/contact">Contact Us</Link>
							</p>

							<p className="footer-company-name">FabgearBd &copy; 2017-2018</p>
						</div>

						<div className="footer-center col-md-5">
							<div>
								<i className="fa fa-phone"></i>
								<p>018-3254-6244</p>
							</div>

							<div>
								<i className="fa fa-envelope"></i>
								<p><a href="mailto:fabgearbd@gmail.com">fabgearbd@gmail.com</a></p>
							</div>

						</div>

						<div className="footer-right col-md-4">

							<p className="footer-company-about">
								<span>About Fabgearbd</span>
								Founded in 2017, Fabgear is a pioneer in Unique Mobile Phone accessories and
								case/covers.
							</p>

							<div className="footer-icons">

								<Link to="https://www.facebook.com/fabgearbd" target="_blank"><i
									className="fa fa-facebook"></i></Link>

							</div>

						</div>
					</div>
				</div>
			</footer>
		);
	}
}


export default Footer;
