import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import moment from 'moment';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';

const tasks = observer(class tasks extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			update:false,
			id: '',
			name:'',
			complete: false,
			tasks:[],
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	confirmDone = (data) => {
		confirmAlert({
		  title: 'Confirm Completed',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.completeTask(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/tasks" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/tasks', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({tasks : response.data.result});
				AppStore.tasks = response.data.result;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "description"){
			this.setState({description : input.value});
		}
	}

	loadaddData(){
		this.setState({description : ''});
		this.setState({update : false});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({description : data.name});
		this.setState({update : true});
	}

	addData(){
		axios.post(baseurl()+'/tasks', {
		    description: this.state.description,
		    complete: this.state.complete,
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
					this.setState({tasks : response.data.result});
					AppStore.tasks = response.data.result;
		    	notify("Model Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/tasks/'+this.state._id, {
            description: this.state.description,
            complete: this.state.complete,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
					this.setState({tasks : response.data.result});
					AppStore.tasks = response.data.result;
				notify("Model Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/tasks/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
					this.setState({tasks : response.data.result});
					AppStore.tasks = response.data.result;
		    	notify('Model Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
    }

    completeTask(data){
        this.setState({_id : data._id, description:data.description, complete: true}, ()=> this.editData())
    }

  render() {
  	const jsonitems=this.state.tasks.map(function(data){
          if(!data.complete){
			return(
				<tr key={data._id}>
						<td>
							<button className="btn btn-primary" onClick={this.confirmDone.bind(this, data)} ><i className="fa fa-check" aria-hidden="true"></i></button>
						</td>
						<td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
			      <td>{data.description}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
            );
        }
	}, this);
    return (
        <div >
        	<Sidebar tasks = "active" taskshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container tasks">
	        	<div className="row">
	        		<div className="col-12">
	        					<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add tasks</button>

								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog modal-lg" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">{this.state.update ? "Update" : "Add"}</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
												<div className="form-group">
														<label>Description</label>
														<textarea rows={10} type="text" className="form-control" name="description" value={this.state.description} onChange={this.changeInput.bind(this)}>
																{this.state.description}
														</textarea>
												</div>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.state.update ? this.editData.bind(this) : this.addData.bind(this)} >{this.state.update ? "Update" : "Add"} tasks</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Mark As Done</th>
							      <th>Date</th>
							      <th>Name</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default tasks;
