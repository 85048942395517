import { notification } from "antd";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Header from "./header";
import Sidebar from "./sidebar";
import { loggeduser } from "./utility/security";
import { baseurl, GetImagePathNew } from "./utility/utility";

export default function PendingOrders() {
  const [orders, SetOrders] = useState([]);
  const [auth, Setauth] = useState(loggeduser());

  useEffect(() => LoadOrders(), []);

  const LoadOrders = () => {
    Axios.get(baseurl() + "/customerorders")
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          SetOrders(res.data.result);
        }
      })
      .catch((e) => console.log(e));
  };

  const AddInOrders = (data) => {
    Axios.post(baseurl() + "/orders", data, {
      headers: { authorization: loggeduser().token },
    })
      .then((response) => {
        if (response.data.success === true) {
          Axios.get(baseurl() + "/customerorders/AddInOrders/" + data._id, {
            headers: { authorization: loggeduser().token },
          })
            .then((newRes) => {
              console.log(newRes);
              LoadOrders();
              if (newRes.data.success) {
                notification.success({
                  message: "Added",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              console.log(error.response);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  };

  const jsonitems = orders.map((data, index) => (
    <tr key={data._id}>
      <td>
        <p>Customer Name: {data.customerName}</p>
        <p>Customer Phone: {data.customerPhone}</p>
        <p>Customer address: {data.customerAddress}</p>
      </td>
      <td>
        {data.products.map((productData, productDataIndex) => (
          <div key={productDataIndex}>
            <a
              href={
                baseurl() +
                "/uploads/productimages/row/" +
                (productData.productType == "CC"
                  ? "covers"
                  : productData.productType) +
                "/" +
                productData.code.trim() +
                ".jpg"
              }
              target="_blank"
            >
              <img
                key={index}
                className="full-width"
                src={GetImagePathNew(productData).toLowerCase()}
              />
              <p
                className="mt-3 text-center"
                style={{ fontSize: 16, fontWeight: "bold", color: "#000" }}
              >
                {productData.productType.trim()}-{productData.code.trim()}{" "}
                {productData.model}{" "}
                <span className="text-danger">({productData.quantity}) </span>
              </p>
            </a>
          </div>
        ))}
      </td>
      <td>
        <Button onClick={() => AddInOrders(data)}>Add In Orders</Button>
      </td>
    </tr>
  ));

  return (
    <div className="products">
      <Sidebar orders="active" ordershow="show" />
      <div className="page">
        <Header />
        <div className="container-fluid">
          <div className="row products">
            <div className="col-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th style={{ width: 300 }}>Customer</th>
                    <th style={{ width: 300 }}></th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{jsonitems}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
