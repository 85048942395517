import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {baseurl, notify} from './utility/utility.js';
import AppStore from './store/AppStore.js';

const { compose, withProps, withState, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");


class ModelRequest extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			locationData:[],
			entries:[],

			activeLabelData:{
				_id:'',
				labels:[],
				orders:[],
			},
			addingLabel:'',

			loadedAll:false,

		};
	}

	componentDidMount(){
		sendToLoginIfNoAccess("orders","edit", this, "/ModelRequest");
		this.loadModelRequest()
	}

	loadModelRequest(){
		this.setState({loadedAll:false})

		axios.post(baseurl()+'/fbchats/latest', {
			"query":  {newModelRequest: true}
		} , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (res) => {
			 console.log(res);
			 if(res.data.success){
				this.setState({entries:res.data.result})
			 }
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	Save = (savingLabelData) => {

		savingLabelData.newModelRequest = '';

		var extraUrl = savingLabelData._id ? '/'+savingLabelData._id : '';

		axios.put(baseurl() + '/fbchats' + extraUrl, savingLabelData, {
			headers: { authorization: this.state.loggeduser.token }
		} )
		  .then((res)=> {

		  console.log(res);
		  if(res.data.success){

			this.loadModelRequest()
			notify('Updated!','green');
		  }

		})
		.catch((error) => {
		  this.setState({saving:false})
		  console.log(error);
		});
	  }

	loadeditData = (data) => {
		this.setState({activeLabelData:JSON.parse(JSON.stringify(data))})
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	delete(data){
		axios.delete(baseurl()+'/fbchats/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    if(response.data.success === true){

				this.loadModelRequest();
				notify('Entry Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


  render() {

	const jsonitems=(this.state.entries).map(function(data){
		return(
			<tr key={data._id}>
			  <td>{data.name}</td>
			  <td>
				   <b>{data.newModelRequest}</b>
			  </td>
			  <td>
				  <button title="Clear" className="btn btn-primary btn-sm" onClick={() => this.Save(data)} ><i className="fa fa-check" aria-hidden="true"></i></button>

				  <a target="_blank" href={AppStore.fbbusinessHost + data.fbChatLink}>
				  	<button className="btn btn-default btn-sm" onClick={this.loadeditData.bind(this, data)}><i className="fa fa-comment" aria-hidden="true"></i></button>
				  </a>
				  {/* <button hidden={!hasAccess("orders", "delete")} className="btn btn-danger btn-sm" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button> */}
			  </td>
			</tr>
		);
	}, this);

    return (
        <div >
        	<Sidebar ModelRequest = "active" ordershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Log spd-mt-30">
					<div className="row">
						<div className="col-12 text-center" >
							<h1>Pending Order Entries</h1>
						</div>
						<div className="col-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>Name</th>
										<th>Requested Model</th>
									</tr>
								</thead>
								<tbody>
									{jsonitems}
								</tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default ModelRequest;
