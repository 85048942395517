import toastr from 'toastr';
import _ from 'lodash';
import levenshteinDistance from './levenshteinDistance';


export function SearchProductByKey(searchWord, AppStore, resFunction){

    var pro = [];

    var allPro = JSON.parse(JSON.stringify(AppStore.products));

    for(var i = 0; i < allPro.length; i++){
      var searchPriority = ChkSearchCategories(allPro[i], searchWord, AppStore.categories);
      if(searchPriority > 0){
        allPro[i].searchPriority = searchPriority;
        pro.push(allPro[i]);
      }
    }

    pro = _.orderBy(pro, ['searchPriority'],['desc']);

    resFunction(pro);

  }

export function ChkSearchCategories(productData, searchWord, categories){

    var searArray =  searchWord.trim().split(' ');
    
    var searchableCats = [];

    for(var n = 0; n < searArray.length; n++){

      var pushableCats = null;
      for(var i = 0; i < categories.length; i++){
        var diff = levenshteinDistance(categories[i].name, searArray[n]);
        if(pushableCats){
          if(pushableCats.diff > diff){
            pushableCats = {
              index: i,
              diff: diff
            }
          }
        }else{
          pushableCats = {
            index: i,
            diff: diff
          }
        }
      }
      searchableCats.push(categories[pushableCats.index].name);
    }

    console.log(searchableCats);
    var proCats = productData.categories;    
    var searchPriority = 0;

    for(var i = 0; i < proCats.length; i++){
      for(var n = 0; n < searchableCats.length; n++){

        if(proCats[i].toLowerCase().indexOf(searchableCats[n].toLowerCase()) !== -1){
          searchPriority = searchPriority + 1;
        }
      }
    }
    
    return searchPriority;
  } 