import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

class Templates extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			id: '',
			name:'',
			url:'',
			previewImage:'',
			Templates:[],
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/Templates");
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/customizertemplates',{ headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({Templates : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }

	delete(data){
		axios.delete(baseurl()+'/customizertemplates/'+data._id , {
		   data: {}
		},{ headers: { authorization: this.state.loggeduser.token } })
		.then((response) => {
			//console.log(response);
			console.log(response.data);
			if(response.data.success === true){
				this.setState({Templates : response.data.result});
				notify('Material Deleted!','#F44336');
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

  render() {

  	const jsonitems = this.state.Templates.map(function(data){
		  console.log(data)
		return(
			<tr key={data._id}>
				<td>
					{(data.image) ?
						<img style={{width:100}} src={(baseurl()+'/uploads/productimages/customizer/'+data.image).toLowerCase()} />
						:
						<h5>Preview Not Avaliable</h5>
					}
				</td>
				<td>{data.name}</td>
				<td>
				<button hidden={!hasAccess("products", "delete")}  className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
				</td>
			</tr>
		);
	}, this);

    return (
        <div>
        	<Sidebar Templates = "active"  Customizershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Templates">

					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
								<thead>
								<tr>
									<th>Preview</th>
									<th>Name</th>
									<th>Action</th>
								</tr>
								</thead>
								<tbody>
								{jsonitems}
								</tbody>
							</table>
						</div>
					</div>
	        	</div>
				<ToastContainer />
	        </div>
        </div>
    );
  }
}

export default Templates;
