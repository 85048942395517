import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {ToastContainer} from 'react-toastify';
import {baseurl, LoadEntryCat, notify} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {observer} from 'mobx-react';
import AppStore from './store/AppStore';

const Accounts= observer(class Accounts extends Component {
	constructor(props){
		super(props);
		this.state={
            loggeduser:loggeduser(),

			id: '',
            description:'',
			entryType:'Debit',
			entryCategory:'',
            amount:0,

			Accounts:[],
            sortOrder:"asc",

            mode:'Add',

            startDate: new moment().startOf('month'),
			endDate: new moment(),
            datequery:'This Month',
            grandtotal:0,
		};
		//this.Data.bind(this)


	}

	componentWillMount(){
	  // Make a request for a user with a given ID
	    sendToLoginIfNoAccess("customers","edit", this, "/accounts");
		this.filterByDate(this.state.startDate, this.state.endDate)

		LoadEntryCat(AppStore, this.state.loggeduser.token);
     }

    filterByDate(startedDate, endedDate){
        var startDate = moment(startedDate).format("YYYY/MM/DD");
		var endDate = moment(endedDate).format("YYYY/MM/DD");
        axios.get(baseurl()+'/accounts?startDate='+startDate+'&endDate='+endDate, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
                this.setState({Accounts : response.data.result, grandtotal:response.data.grandtotal});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }



	changeInput(e){
		var input=e.target;
		if(input.name === "description"){
			this.setState({description : input.value});
		}else if(input.name === "entryType"){
			this.setState({entryType : input.value});
		}else if(input.name === "amount"){
			this.setState({amount : input.value});
		}else if(input.name === "entryCategory"){
			this.setState({entryCategory : input.value});
		}
	}

	loadaddData(){
		this.setState({description :'', entryType:'Debit', entryCategory:'',  amount:0, mode: 'Add'});
	}

	addData(){
		if(!this.state.amount || !this.state.entryType || !this.state.entryCategory ){
			notify("Fill Empty", "red");
			return null;
		}
		axios.post(baseurl()+'/accounts', {
		    description: this.state.description,
		    entryType: this.state.entryType,
		    entryCategory: this.state.entryCategory,
		    amount: this.state.amount,
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
                this.filterByDate(this.state.startDate, this.state.endDate);
		    	notify("Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	sortBy(col,e){
		  var Accounts = this.state.Accounts;
			Accounts = _.orderBy(Accounts,[col],[this.state.sortOrder]);
			this.setState({Accounts: Accounts});

			if(this.state.sortOrder === 'asc'){
				this.setState({sortOrder : 'desc'})
			}else{
				this.setState({sortOrder : 'asc'})
			}
     }

    calculateByField(field){
        var Accounts = this.state.Accounts;
        var fieldTotal = 0;
        for(var i = 0; Accounts.length > i; i++){
            if(Accounts[i].entryType ==  field){
                fieldTotal += Accounts[i].amount;
            }
        }

        return fieldTotal;
    }

    changeStartDate =  startDate => this.setState({ startDate });

	changeEndDate = endDate => this.setState({ endDate });

    selecttoday(){
		var today = moment();
		this.filterByDate(today, today);
		this.setState({startDate  : today});
		this.setState({endDate  : today});
		this.setState({datequery  : "Today"});
	}

	selectyesterady(){
		var yesterday = moment().subtract(1, 'days');
		this.filterByDate(yesterday, yesterday);
		this.setState({startDate  : yesterday});
		this.setState({endDate  : yesterday});
		this.setState({datequery  : "Yesterday"});
	}

	selectweek(){
		var end = moment();
		var start = moment().subtract(1, 'week');
		this.filterByDate(start, end);
		this.setState({startDate  : start});
		this.setState({endDate  : end});
		this.setState({datequery  : "This Week"});
	}

	selectmonth(){
		var end = moment();
		var start  = new moment().startOf('month');
		this.filterByDate(start, end);
		this.setState({startDate  : start});
		this.setState({endDate  : end});
		this.setState({datequery  : "This Month"});
	}

	selectTomorrowady(){
		var Tomorrow = moment().add(1, 'days');
		this.filterByDate(Tomorrow, Tomorrow);
		this.setState({startDate  : Tomorrow});
		this.setState({endDate  : Tomorrow});
		this.setState({datequery  : "Tomorrow"});
    }

    selectLastmonth(){
        var start = moment().subtract(1, 'months').startOf('month');
        var end = moment().subtract(1, 'months').endOf('month');
        this.filterByDate(start, end);
		this.setState({startDate  : start});
		this.setState({endDate  : end});
		this.setState({datequery  : "Last Month"});
    }



  render() {
  	const jsonitems = this.state.Accounts.map(function(data){
			return(
				<tr key={data._id}>
			      <td>{moment(data.createdAt).format('DD/MM/YYYY')}</td>
			      <td>{data.entryType}</td>
			      <td>{data.entryCategory}</td>
			      <td>{data.description}</td>
			      <td>{data.entryType == 'Debit' ? data.amount : '--'  }</td>
			      <td>{data.entryType == 'Credit' ? data.amount : '--'  }</td>
			      {/* <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button  hidden={!hasAccess("customers", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td> */}
			    </tr>
			);
		}, this);

    return (
        <div >
        	<Sidebar Accounts="active" accountShow='show' />
	        <div className="page">
	        	<Header  />
	        	<div className="container-fluid Accounts">
                    <div className="row mt-5 mb-2">
                        <div className="col-12">
                            <h1>Grand Total Balance: ৳ {parseFloat(this.state.grandtotal)}</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Transactions</button>

                            {/* Popup Modal Code */}
                            <div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                    <h5 className="modal-title" id="portfolioModalLabel">{this.state.mode}</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    </div>
                                    <div className="modal-body">
									<div className="form-group">
                                        <label>Select Category</label>
										<select value={this.state.entryCategory} className='form-control' onChange={(e) => this.setState({entryCategory:e.target.value})}>
											<option  value=''>Select Entry Category</option>
											{AppStore.EntryCategories.map((data, index)=>(
												<option  value={data.name}>{data.name}</option>
											))}
										</select>
                                    </div>
                                    <div className="form-group">
                                        <label>Description</label>
                                        <textarea type="text" className="form-control"  name="description" value={this.state.description} rows={5} onChange={this.changeInput.bind(this)}></textarea>
                                    </div>

                                    <div className="form-group">
                                        <label>Entry Type</label>
                                        <select type="text" className="form-control"  name="entryType" value={this.state.entryType} rows={5} onChange={this.changeInput.bind(this)}>
                                            <option value="Debit">Debit</option>
                                            <option value="Credit">Credit</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <input type="number" className="form-control"  name="amount" value={this.state.amount} rows={5} onChange={this.changeInput.bind(this)} />
                                    </div>
                                    </div>
                                    <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addData.bind(this)} >{this.state.mode} Transactions</button>
                                    </div>
                                </div>
                                </div>
                            </div>
                                {/* Popup Modal Code End */}
                        </div>
                    </div>
					<div className="row mt-3 mb-3">
                        <div className="col-3">
                            <DatePicker
                                selected={moment(this.state.startDate)}
                                onChange={this.changeStartDate.bind(this)}
                                dateFormat="DD/MM/YYYY"
                            />
                        </div>
                        <div className="col-2">
                            <label className='mt-2'>To</label>
                        </div>
                        <div className="col-3">
                            <DatePicker
                                selected={moment(this.state.endDate)}
                                onChange={this.changeEndDate.bind(this)}
                                dateFormat="DD/MM/YYYY"
                            />
                        </div>
                        <div className="col-2">
                            <button onClick={() => this.filterByDate.bind(this, this.state.startDate, this.state.endDate)} className='btn btn-success'><i className='fa fa-search'></i></button>
                        </div>
                        <div className='col-2 text-right'>
                            <div className="dropdown">
								<button className="btn btn-default dropdown-toggle pull-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										{this.state.datequery}
								</button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<option onClick={this.selecttoday.bind(this)} className="dropdown-item btn btn-default" href="#">Today
									</option>
									<option onClick={this.selectyesterady.bind(this)} className="dropdown-item btn btn-default" href="#">Yesterday</option>
									<option onClick={this.selectTomorrowady.bind(this)} className="dropdown-item btn btn-default" href="#">Tomorrow</option>
									<option onClick={this.selectweek.bind(this)} className="dropdown-item btn btn-default" href="#">This Week</option>
									<option onClick={this.selectmonth.bind(this)} className="dropdown-item btn btn-default" href="#">This month</option>
                                    <option onClick={this.selectLastmonth.bind(this)} className="dropdown-item btn btn-default" href="#">Last month</option>
								</div>
                            </div>
                        </div>
                    </div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th onClick={this.sortBy.bind(this,'createdAt')}>Date</th>
							      <th onClick={this.sortBy.bind(this,'entryType')}>Entry Type</th>
							      <th onClick={this.sortBy.bind(this,'entryType')}>Entry Category</th>
							      <th onClick={this.sortBy.bind(this,'description')} style={{minWidth:400}}>Description</th>
							      <th onClick={this.sortBy.bind(this,'amount')}>Debit</th>
							      <th onClick={this.sortBy.bind(this,'amount')}>Credit</th>
							    </tr>
							  </thead>
							  <tbody>
                                {jsonitems}
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>Total</th>
                                    <th>{this.calculateByField('Debit')}</th>
                                    <th>{this.calculateByField('Credit')}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th>
                                        {"Credit Balance: "+(this.calculateByField('Credit') - this.calculateByField('Debit'))}
                                    </th>
                                </tr>
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default Accounts;
