import axios from 'axios';
import _ from 'lodash'
import { baseurl } from './utility';

export function hasAccess(section, permission){
    if(localStorage.getItem('account') !== null && localStorage.getItem('account') !== 'null'){
        var account = JSON.parse(localStorage.getItem('account'));
        var userPermissions = account.access;
        if(userPermissions !== undefined){
            if(_.includes(userPermissions[section],permission)){
                return true;
            }else if(_.includes(userPermissions[section],"*")){
                return true;
            }
        }
    }
    return false;
}

export function sendToLoginIfNoAccess(section, permission,component,returnURL){
    if(!hasAccess(section,permission)){
       //Add logout axios call
        axios.post( baseurl() + '/logout',{ })
        .then((response) => {
        //console.log(response);
            localStorage.setItem( "account" , null);
        })
        .catch(function (error) {
        console.log(error);
        });
       component.props.history.push('/login?return=' + returnURL)
    }
}

export function loggeduser(returnData){
    var loggeduser = [];
    if(localStorage.getItem('account') !== null && localStorage.getItem('account') !== 'null'){
        loggeduser = JSON.parse(localStorage.getItem('account'));
    }
    return loggeduser;
}

export function loginchk(){
    if(localStorage.getItem('account') === null || localStorage.getItem('account') === 'null' ){
        return true;
    }
    return false;
}
