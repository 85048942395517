import React, {Component} from 'react';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, getLocalItem, notify, setLocalItem} from './utility/utility.js';
import Cart from './cart.js';

class viewproduct extends Component {

	constructor(props){
		super(props);
		this.state = {
			title:'',
			allmodels:[],
			selectedModel:'Select Model',
			price:'',
			originalPrice:'',
			quantity:1,
			description:'',
			images:[],
			materials:[],
			selectedImage:'',
			_id:'',
			product:[],
			cart:[],
			allwishlistProduct:[],
			allcartProduct : [],
			cartQty:0,
			imageFolder:'',
		};
	}

	componentWillMount(){
		var productId = this.props.match.params.id;
		if(productId !== undefined){
			this.loadproductData(productId);
			this.setState({allcartProduct : getLocalItem('cart')});
			this.setState({cartQty : getLocalItem('cart').length});
			this.setState({allwishlistProduct : getLocalItem('wishlist')});
		}
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "quantity"){
			this.setState({quantity : input.value});
		}
		else if(input.name === "customerName"){
			this.setState({customerName : input.value});
		}
	}

	upQty(){
		var qty = parseFloat(this.state.quantity);
		this.setState({quantity : qty+1});
		//console.log(this.state.quantity);
	}

	lessQty(){
		var qty = parseFloat(this.state.quantity);
		if(qty >1){
			this.setState({quantity : qty-1});
		}
	}

	loadproductData(id){
		//console.log(id);
		axios.get(baseurl()+'/products/'+id)
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				//this.setState({alldeliveryperson : response.data.result});
				//console.log(response.data.result);
				//console.log(response.data.result.productsDate);
				this.setState({product:response.data.result})
				this.setState({_id : response.data.result._id});
				this.setState({title : response.data.result.title});
				this.setState({allmodels : response.data.result.models});
				this.setState({price : response.data.result.price});
				this.setState({originalPrice : response.data.result.originalPrice});
				this.setState({description : response.data.result.description});
				this.setState({materials : response.data.result.materials});
				this.setState({images : response.data.result.images});
				this.setState({selectedImage : response.data.result.images[0]});
				this.setState({imageFolder : response.data.result.imageFolder});
			}
	    })
	    .catch(function (error) {
	      //console.log(error);
	    });
	}

	addCart(){
		////console.log(this.state.product);
		var allcartProduct = getLocalItem('cart');
		var product = this.state.product;

	//	if(allcartProduct.map(function(e) { return e._id; }).indexOf(product._id) === -1){
			product.selectedModel = this.state.selectedModel;
			product.quantity = this.state.quantity;
			product.discountTk = 0;
			product.discountPct = 0;
			product.total = product.price*product.quantity;
			allcartProduct.push(product);
			setLocalItem( "cart" , allcartProduct);
			this.setState({allcartProduct : allcartProduct});
			this.setState({cartQty : allcartProduct.length});
			notify("Added To Cart", "#28a745");
		// }else{
		// 	notify("Already Added", "#0275d8");
		// }
	}

	addwishlist(){
		var allwishlistProduct = getLocalItem('wishlist');
		if(allwishlistProduct.map(function(e) { return e._id; }).indexOf(this.state.product._id) === -1){
			allwishlistProduct.push(this.state.product);
			//console.log(allwishlistProduct);
			setLocalItem( "wishlist" , allwishlistProduct);
			notify("Added To Wishlist", "#0275d8");
		}else{
			notify("Already Added", "#0275d8");
		}
	}

	selectModel(selectedModel){
		this.setState({selectedModel : selectedModel});
	}

	selectImage(imagePath){
		this.setState({selectedImage : imagePath});
	}

	render() {
		const singleProModel = this.state.allmodels.map(function(data){
			return(
				<span key={data.model} onClick={this.selectModel.bind(this , data.model)}  className="dropdown-item btn btn-default" href="#">{data.model}</span>
			)
		}, this);

		const singleProMaterials = this.state.materials.map(function(data){
			return(
				<span key={data}  className="all-material" >{data}</span>
			)
		}, this);

		const allimage = this.state.images.map(function(data){
			return(
		        <img className="bottom-image" onClick={this.selectImage.bind(this, data)} src={(baseurl()+"/uploads/productimages/"+this.state.imageFolder+data).toLowerCase()} alt="Uploaded file" />
			)
		}, this);



		return (
		  	<div className="viewproduct">
		        <div>
		        	<Header cartQty={this.state.cartQty} />
		        	<Cart cartProduct={this.state.allcartProduct}  />
		        	<div className="container-fluid viewproduct spd-min-height">
		        		<div className="row spd-mt-30">
		        			<div className="col-md-5 col-12 image-section">
		        				<img className="main-image" src={(baseurl()+"/uploads/productimages/"+this.state.imageFolder+this.state.selectedImage).toLowerCase()} alt="Main file" />

		        				<div className="slide-small">
		        					{allimage}
		        				</div>

		        			</div>
		        			<div className="col-md-7 col-12 details-section">
		        				<h1 className="title">{this.state.title}</h1>
		        				<p className="money">
		        					<span className="span-price">Price:</span> ৳ {this.state.price}
		        					{(this.state.originalPrice !== null && this.state.originalPrice !== undefined) &&
		        					<span className="cross-tk">৳ {this.state.originalPrice}</span>
		        					}
		        				</p>
		        				<p className="money"><span className="span-price">Material:</span>{singleProMaterials}</p>
		        				<p className="money"><span className="span-price">Description:</span>{this.state.description}</p>
		        				<div className="models row ">
		        					<div className="col-12">
			        					<div className="dropdown">
											<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									    		{this.state.selectedModel}
									 		</button>
								  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
								    			{singleProModel}
								  			</div>
										</div>
									</div>
		        				</div>
		        				<p className="spd-mt-10"><span className="span-quantity">Quantity</span>
		        					<span className="quantity-wapper">
		        						<i onClick={this.upQty.bind(this)} className="fa fa-plus btn btn-plus"></i>
		        						<input name='quantity' className="orderQuantity" onChange={this.changeInput.bind(this)} value={this.state.quantity} type="text"  />
		        						<i onClick={this.lessQty.bind(this)} className="fa fa-minus btn btn-minus"></i>
		        					</span>
		        				</p>
		        				<div className="row spd-mt-30">
			        				<div className="col-12">
			        					<button onClick={this.addCart.bind(this)} className="btn btn-add-cart spd-mr-10"><span className="cart-icon"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span>Add to Cart</button>
			        					<button onClick={this.addwishlist.bind(this)} className="btn btn-add-wsihlist">Add To Wishlist</button>
			        				</div>
		        				</div>
		        			</div>
		        		</div>
		        	</div>
		        	<ToastContainer />
		        </div>
		        <Footer />
		  	</div>
		);
	}
}

export default viewproduct;
