import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import _ from 'lodash';
import history from './history';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify, setlog} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

class customer extends Component {
	constructor(props){
		super(props);
		this.state={
			_id:'',
			name:'',
			phone:'',
			phone2:'',
			address:'',
			customers:[],
			sortOrder:"asc",
			loggeduser: loggeduser(),
			allarea:[],
			selectedModel:'Select Area',
			searchcustomer:'',
			start:0,
			perpage:50,
			totalpage:0,
			activepage:1,
		};
		//this.Data.bind(this)
	}


	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}
		else if(input.name === "phone"){
			this.setState({phone : input.value});
		}
		else if(input.name === "phone2"){
			this.setState({phone2 : input.value});
		}
		else if(input.name === "address"){
			this.setState({address : input.value});
		}
		else if(input.name === "searchcustomer"){
			this.setState({searchcustomer : input.value});
			this.searchcustomer(input.value, 0, this.state.perpage);
		}
	}

	componentWillMount(){

		sendToLoginIfNoAccess("customers","view", this, "/customer");
		var searchCustomer = "";
		if(history.location.state !== undefined  ){
			//this.setState({searchcustomer: history.location.state.searchcustomer})
			searchCustomer = history.location.state.searchcustomer;
		}
	  // Make a request for a user with a given ID
	  this.searchcustomer(searchCustomer, 0, this.state.perpage);
	  this.setState({searchcustomer : searchCustomer});
	  axios.get(baseurl()+'/customerareas' ,  { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({allarea : response.data.result});
			}
		  })
	}

	loadaddData(){
		this.setState({name : ''});
		this.setState({phone : ''});
		this.setState({phone2 : ''});
		this.setState({selectedModel : 'Select Area'});
		this.setState({address : ''});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({name : data.name});
		this.setState({phone : data.phone});
		this.setState({phone2 : data.phone});
		this.setState({selectedModel : data.area});

		this.setState({address : data.address});
	}

	addData(){
		axios.post(baseurl()+'/customers', {
		    name: this.state.name,
		    phone: this.state.phone,
		    phone2: this.state.phone2,
		    address: this.state.address,
		    area: this.state.selectedModel,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.searchcustomer(this.state.searchcustomer, this.state.start, this.state.perpage);
		    	notify("Customer Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	editData(){
		axios.put(baseurl()+'/customers/'+this.state._id, {
		    name: this.state.name,
		    phone: this.state.phone,
		    phone2: this.state.phone2,
		    area: this.state.selectedModel,
		    address: this.state.address,
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	setlog("Customer Updated id: "+this.state._id+" History Name: "+this.state.name+" Phone: "+this.state.phone ,"customer update");
		    	this.searchcustomer(this.state.searchcustomer, this.state.start, this.state.perpage);
				notify("Customer Edited", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete( baseurl() + "/customers/"+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.searchcustomer(this.state.searchcustomer, this.state.start, this.state.perpage);
		    	notify('Customer Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

		setlog("Customer deleted Name: "+data.name+" Phone: "+data.phone ,"customer delete");
	}

	sortBy(col,e){
		  var customers = this.state.customers;
			customers = _.orderBy(customers,[col],[this.state.sortOrder]);
			this.setState({customers: customers});

			if(this.state.sortOrder === 'asc'){
				this.setState({sortOrder : 'desc'})
			}else{
				this.setState({sortOrder : 'asc'})
			}
	}

	selectModel(name){
		this.setState({selectedModel : name});
	}

	searchcustomer(name, start, perpage){

		var url = baseurl()+'/customers?start='+start+'&numRecords='+perpage;
		if(name !== "" || name !== undefined){
			url = url+"&name="+encodeURI(name);
		}

		console.log(url);
	  	axios.get(url,  { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({customers : response.data.result});
				this.setState({totalpage : Math.ceil(response.data.count/this.state.perpage)});
			}
	    })
	    .catch(function (error){
	      console.log(error);
		});

		if(name !== ""){
			var queryurl = "/customer?name=" + name;
			history.push(queryurl,{searchcustomer: name})
		}
	 }

	activepage(index){
		if(this.state.activepage === index+1){
			return "page-active";
		}
		console.log(index);
	}

	pagination(pageNo){
			var start = pageNo*this.state.perpage;
			this.searchcustomer(this.state.searchcustomer, start, this.state.perpage);
			this.setState({activepage  : pageNo+1});
			this.setState({start  : start});
			console.log(pageNo);
	}

  render() {
  	const allarea = this.state.allarea.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectModel.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

  	const jsonitems=this.state.customers.map(function(data){
		return(
			<tr key={data._id}>
		      <td>{data.name}</td>
		      <td>{data.phone}</td>
		      <td>{data.phone2}</td>
		      <td>{data.address}</td>
		      <td>{data.area}</td>
		    {hasAccess("customers", "edit")  &&
		      <td>
		      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#editModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
		      	<button  hidden={!hasAccess("customers", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)}  ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
		      </td>
		  	}
		    </tr>
		);
	}, this);

	const pageItem = _.times(this.state.totalpage, i =>
			<span className="page-no" id={this.activepage(i)} onClick={this.pagination.bind(this, i)} key={i}>{i + 1}</span>
	);

    return (
        <div >
        	<Sidebar customers="active" customershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container customer">
	        	<div className="row" >
	        		<div className="col-12">
	        					{hasAccess("customers", "add")  &&
	        					<button type="button" onClick={this.loadaddData.bind(this)} className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap">Add Customer</button>
	        					}
								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" onClick={this.addData.bind()} id="portfolioModalLabel">ADD CUSTOMER</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
								        <form>

											<div className="form-group">
												<label htmlFor="Name">Customer Name</label>
												<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="phone">Contact phone</label>
												<input type="text" name="phone" id="phone"  className="form-control" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="phone2">Contact phone2</label>
												<input type="text" name="phone2" id="phone2"  className="form-control" value={this.state.phone2} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="address">Address</label>
												<input type="text" name="address" id="address"  className="form-control" value={this.state.address} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="select-model">
							        			<div className="dropdown">
													<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											    		{this.state.selectedModel}
											 		</button>
										  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										    			{allarea}
										  			</div>
												</div>
											</div>
								        </form>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" onClick={this.addData.bind(this)} className="btn btn-primary" data-dismiss="modal">Save Data</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
							{/* Popup Modal  Code */}
								<div className="modal fade" id="editModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel"> Customer Information</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
								       <form>
											<div className="form-group">
												<label htmlFor="name">Name</label>
												<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="phone">Contact phone</label>
												<input type="text" className="form-control" id="phone" name="phone" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="phone2">Contact phone2</label>
												<input type="text" className="form-control" id="phone2" name="phone2" value={this.state.phone2} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="address" >Address</label>
												<input type="text" className="form-control" name="address" id="address" value={this.state.address} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="select-model">
							        			<div className="dropdown">
													<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											    		{this.state.selectedModel}
											 		</button>
										  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
										    			{allarea}
										  			</div>
												</div>
											</div>
								        </form>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" onClick={this.editData.bind(this)} data-dismiss="modal" className="btn btn-primary">Update</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<input type="text" value={this.state.searchcustomer} name="searchcustomer" className="form-control searchtext" placeholder="Search By Customer Name" onChange={this.changeInput.bind(this)}  />
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th onClick={this.sortBy.bind(this,'name')}>Customer Name</th>
							      <th onClick={this.sortBy.bind(this,'phone')}>Phone</th>
							      <th onClick={this.sortBy.bind(this,'phone2')}>Phone2</th>
							      <th onClick={this.sortBy.bind(this,'address')}>Address</th>
							      <th onClick={this.sortBy.bind(this,'area')}>Area</th>
							      {hasAccess("customers", "edit")  &&
							      	<th>Action</th>
							      }
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
						<div className="col-12">
							{pageItem}
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default customer;
