import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {baseurl} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";

const TrackModifications = observer(class TrackModifications extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),

			data:{
				modifications:[]
			}
		};
		//this.Data.bind(this)
	}

	componentDidMount(){
		sendToLoginIfNoAccess("reports","edit", this, "/orders" , { headers: { authorization: this.state.loggeduser.token } });

		this.LoadData()

	 }

	LoadData = () => {
		axios.get(baseurl() + `/${this.props.match.params.item}/${this.props.match.params.id}`)
		.then((res) => {
			console.log(res)
			if(res.data.success){
				let result = res.data.result;
				result.modifications = result.modifications.filter(ele => ele)
				this.setState({data:result})
			}
		})
		.catch(err => {

			console.log(err)

		})
	}

	GetModifiedData = (data, modiData) => {

		let newData = {...data, ...modiData}
		delete newData.modifications
		return newData;
	}

	GetColor = (string, modiData) => {

		console.log(modiData)

		Object.keys(modiData).forEach((key,index) => {

			string = string.replace(key, `<b style="color:red;" >${key}</b>`)

		});

		return string

	}

	render() {

		return (
			<div >
				<Sidebar TrackModifications = "active" TrackModificationshow="show" />
				<div className="page">
					<Header  />
					<div className="container-fluid TrackModifications">
						<div className="row">
							<div className="col-12"  style={{display:'flex', flexDirection:'row', overflowY:'auto', maxHeight:'90vh'}} >
								{/* <table>
										{Object.keys(this.state.data).map((key) => {
											return (
												<tr>
													<td value={key}>{key}</td>
													{Object.keys(this.state.data).map((innerKey, innerKeyIndex) => {
														return (
															<ReactJson
																key={innerKeyIndex}
																src={this.GetModifiedData( this.state.data, this.state.data.modifications[innerKeyIndex])[innerKey]}
															/>
														);
													})}
												</tr>
											)
										})}
								</table> */}


								{this.state.data.modifications.map((modiData, index) => {
									if(modiData && modiData.modifications){
										return (
											<div style={{marginTop:20}} >
												<p><b>{index > 0 ? <>Modified By: {this.state.data.modifications[index - 1].user} {"--->"}</> : "Initial Data"}</b></p>
												{/* <ReactJson key={index} src={this.GetModifiedData( this.state.data, modiData.modifications)} /> */}
												{/* <pre>{JSON.stringify(this.GetModifiedData( this.state.data, modiData.modifications), null, 2)}</pre> */}
												<div
													dangerouslySetInnerHTML={{__html:`<pre>${this.GetColor(JSON.stringify(this.GetModifiedData( this.state.data, modiData.modifications), null, 2), modiData.modifications)}</pre>`}}
												>

												</div>
											</div>
										)
									}
								})}

								<div style={{marginTop:20}} >
									<p><b>{this.state.data.modifications.length > 0 ? <>Modified By: {this.state.data.modifications[this.state.data.modifications.length - 1].user} {"--->"}</> : "Initial Data"}</b></p>
									{/* <ReactJson key={index} src={this.GetModifiedData( this.state.data, modiData.modifications)} /> */}
									{/* <ReactJson src={this.state.data} /> */}
									{/* <pre>{this.GetColor(JSON.stringify(this.state.data, null, 2), this.state.data.modifications.length > 0 ? this.state.data.modifications[this.state.data.modifications.length - 1] : {})}</pre> */}
									<div
										dangerouslySetInnerHTML={{__html:`<pre>${this.GetColor(JSON.stringify(this.GetModifiedData( this.state.data, this.state.data.modifications.length > 0 ? this.state.data.modifications[this.state.data.modifications.length - 1] : {}), null, 2), this.state.data.modifications.length > 0 ? this.state.data.modifications[this.state.data.modifications.length - 1] : {})}</pre>`}}
									>

									</div>

								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		);
  	}
})

export default TrackModifications;
