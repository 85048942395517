import React, { Component } from 'react';
import { loggeduser } from './utility/security';
import _ from 'lodash';

class PrintBlankCase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loggeduser: loggeduser(),
			orderDate: new Date(),
			orderNumber: '',
			customerName: '',
			customerAddress: '',
			customerArea: '',
			customerPhone: '',
			alldeliveryperson: [],
			deliveryPerson: 'Select Delivery Person',
			notes: '',
			total: 0,
			payments: [],
			allproducts: [],
			products: [],
			discountTk: 0,
			discountPct: 0,
			deliveryFee: 50,
			status: 'incomplete',
			paymenttype: 'Select Type',
			paymentAmount: '',
			due: 0,
			paid: 'PrintBlankCaseDue',
		};
	}

	render() {
		return (
			<div className="container PrintBlankCase">
				<h1 className='text-center m-2'>All Model List With Quantity</h1>
				<div className="row">
					<div className="col-md-12">
						<table className="table table-striped">
							<thead>
							<tr>
								<th className="min-width-80">Type </th>
								<th className="min-width-80">Model & Qty </th>
							</tr>
							</thead>
							<tbody>
							{_.orderBy(this.props.location.state.modelList, [
								modelList => modelList.productType.toLowerCase(),
								modelList => modelList.name.toLowerCase()
							], ['desc', 'asc']).map((data, index) =>
								<tr key={index}>
									<td style={{ fontWeight: data.productType === "GC" ? 'bold' : 'normal', color: data.productType === "GC" ? 'red' : 'inherit' }}>
										{data.productType}
									</td>
									<td style={{ color: data.productType === "GC" ? 'red' : 'inherit' }}>
										{data.name}
										<b className='text-danger ml-2'>({data.quantity})</b>
									</td>
								</tr>
							)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}

export default PrintBlankCase;
