import React, { Component } from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import Axios from 'axios';
import { ToastContainer } from 'react-toastify';
import {notify, baseurl, setLocalItem, getLocalItem,  GetImagePath, NewGuid, randomString, replaceAll, AddFontLink} from './utility/utility.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, sendToLoginIfNoAccess, loggeduser} from './utility/security';
import {Row, Container, Col, Form, Button, Modal, InputGroup, FormControl, Spinner} from 'react-bootstrap';
import { observer } from 'mobx-react';
import AppStore from './store/AppStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faItalic ,faCarAlt,faSearch, faUser ,faHeart, faCartPlus, faArrowsAlt, faEdit, faMobileAlt,faImages, faDrawPolygon, faFont, faPaintBrush, faEye, faIcons, faTrashAlt, faDownload, faSave} from '@fortawesome/free-solid-svg-icons';
import * as htmlToImage from 'html-to-image';
import DraggableComponent from './components/DraggableComponent';
import SearchDesignModal from './components/SearchDesignModal';
import SearchModel from './components/SearchModel';
import toastr from 'toastr';

const Customizer = observer (class Customizer extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),

			templateName:'',
			model:'iPhone X',
			design:'CC-36.jpg',
			showModelSelect:false,
			showDesignSelect:false,

			showAddNameModal:false,

			searchModel:'',
			searchDesign:'',

			draggableData:[],
			preview:false,

			showImageList:false,

			uploadedImage:[],
			updateImageMode:'add',
			updatingImageIndex:'',

			loading:false,
			addingCart:false
		};
	}

	componentDidMount(){
		if(this.props.location.state && this.props.location.state.design){
		  this.SelectDesign(this.props.location.state.design);
		  if(this.props.location.state.model){
			this.SelectModel(this.props.location.state.model)
		  }
		  this.setState({draggableData:[]});
		  setLocalItem('draggableData', []);
		}else{
		  this.ChkForLocal();
		}
	  }

	  ChkForLocal(){
		setTimeout(() => {
		  console.log("hello", AppStore.CustomizerFonts)
		  if(AppStore.CustomizerFonts.length > 0){
			var CustomizingModel = getLocalItem('CustomizingModel');
			if(CustomizingModel && CustomizingModel !== null && CustomizingModel.length > 0){
			  this.setState({model:CustomizingModel})
			}
			var CustomizingDesign = getLocalItem('CustomizingDesign');
			if(CustomizingDesign && CustomizingDesign !== null && CustomizingDesign.length > 0){
			  this.setState({design:CustomizingDesign})
			}

			var draggableData = getLocalItem('draggableData');
			if(draggableData && draggableData !== null){
			  this.setState({draggableData:draggableData})
			  this.LoadAddedFont(AppStore, draggableData)
			}

			var uploadedImage = getLocalItem('uploadedImage');
			if(uploadedImage && uploadedImage !== null){
			  this.setState({uploadedImage:uploadedImage})
			}
		  }else{
			this.ChkForLocal();
		  }
		}, 100)

	  }

	  LoadAddedFont = (AppStore, draggableData) => {
		for(var i = 0; i < draggableData.length; i++){
		  var name = draggableData[i].fontFamily;
		  var index = AppStore.loadedFonts.findIndex((ele) => {return ele.name == name});
		  if(index == -1){
			var font = AppStore.CustomizerFonts.find((ele) => {return ele.name == name});
			if(font){
			  AddFontLink(AppStore, font);
			}
		  }
		}
	  }

	  // ChkModel(){
	  //   return AppStore.models.filter((ele) => {return (ele.name.toLowerCase()).indexOf(this.state.searchModel.toLowerCase()) !==  -1 });
	  // }

	  SelectModel = (model) => {
		this.setState({
		  model:model,
		  showModelSelect:false
		})
		setLocalItem('CustomizingModel', model);
	  }

	  SelectDesign = (design) => {
		this.setState({
		  design:design,
		  showDesignSelect:false
		})
		setLocalItem('CustomizingDesign', design);
	  }

	  AddElement(type, src = ''){
		this.setState({preview:false});
		var draggableData = this.state.draggableData;
		draggableData.push({
		  type:type,
		  src:src,
		  content:'text here...',
		  color:'red',
		  backgroundColor:'transparent',
		  fontFamily:'Ibarra Real Nova',
		  size:36,
		  bold:true,
		  italic:true,
		  underline:false,
		  textAlign:'center',
		  rotate:0,
		  position:{
			x:0,
			y:250
		  },
		  dimension:{
			height: type == 'image' ? 200 : 150,
			width: type == 'image' ? 200 : 150
		  }
		})
		this.setState({draggableData:draggableData})
		setLocalItem('draggableData', draggableData);
	  }

	  Update = (index, data) => {
		this.setState({preview:false});
		var draggableData = this.state.draggableData;
		draggableData[index] = data;
		this.setState({draggableData:draggableData});

		setLocalItem('draggableData', draggableData);

	  }

	  Delete = (index) => {
		this.setState({preview:false});
		var draggableData = this.state.draggableData;
		draggableData.splice(index, 1);
		this.setState({draggableData:draggableData});

		setLocalItem('draggableData', draggableData);

		this.setState({loading:true});
		setTimeout(() =>{
		  this.setState({loading:false});
		}, 500)

	  }

	  Sort = (index, direction) => {
		this.setState({preview:false});
		var draggableData = JSON.parse(JSON.stringify(this.state.draggableData));
		var data1 = draggableData[index];
		if(direction == 'up'){
		  var data2Index = index+1;
		}else{
		  var data2Index = index-1;
		}
		var data2 = draggableData[data2Index];

		draggableData[index] = data2;
		draggableData[data2Index] = data1;

		this.setState({draggableData:draggableData});

		setLocalItem('draggableData', draggableData);

		this.setState({loading:true});
		setTimeout(() =>{
		  this.setState({loading:false});
		}, 500)
	  }

	  UploadImage(e) {
		this.setState({preview:false});
			const url = AppStore.url+'/images';
		const data = new FormData();
		var uploadedImage = JSON.parse(JSON.stringify(this.state.uploadedImage));
			for(var i=0;i<e.target.files.length;i++){
		  var originalnameArray = (e.target.files[i].name).split(".");
		  var name = NewGuid();
		  var ext = originalnameArray[originalnameArray.length - 1];
		  uploadedImage.push("CI-"+name+'.'+ ext)
		  data.append('file',e.target.files[i], "CI-"+name + "." + ext);
			}
			data.append('folder','customizer');
			Axios.post(url,data)
		.then( (response)=> {
		  this.setState({uploadedImage:uploadedImage});
		  setLocalItem('uploadedImage', uploadedImage)
		})
		.catch((error) => {
		  console.log(error);
		});
	  }

	  RemoveUploadedImage = (index) => {
		var uploadedImage = this.state.uploadedImage;
		uploadedImage.splice(index, 1);
		this.setState({uploadedImage:uploadedImage});
		setLocalItem('uploadedImage', uploadedImage);
	  }

	  UpdateImage = (index) => {
		this.setState({
		  preview:false,
		  updatingImageIndex:index,
		  updateImageMode:'update',
		  showImageList:true
		})
	  }

	  CngImage = (image) => {
		this.setState({preview:false});
		var draggableData = this.state.draggableData;
		draggableData[this.state.updatingImageIndex].src = image;
		this.setState({draggableData:draggableData});

		setLocalItem('draggableData', draggableData);

	  }

	  Save = () => {
		this.setState({
		  preview:false,
		  addingCart:true
		});
		var node = document.getElementById('customizer');

		//this.setState({loading:true});
		htmlToImage.toBlob(node)
		.then((previewImage) => {
		  this.RenderSecondImage(node, previewImage);
		})
		.catch((error) => {
		  console.error('oops, something went wrong!', error);
		});
	  }

	  RenderSecondImage = (node, previewImage) => {
		var preservedSrc = node.childNodes[0].src;
		var preservedBackImage = node.childNodes[0].backgroundImage;
		node.childNodes[0].style.width = '340px';
		node.childNodes[0].style.height = '600px';
		var backgroundImage = node.childNodes[0].style.backgroundImage.replace('url("', '');
		backgroundImage = backgroundImage.replace('")', '');

		node.childNodes[0].src = backgroundImage.replace('row/covers', 'raw');

		// this is for dwonload image
		htmlToImage.toBlob(node)
		.then((rawImage) => {

		  node.childNodes[0].src = preservedSrc;
		  node.childNodes[0].backgroundImage = preservedBackImage;
		  node.childNodes[0].style.width = '100%';
		  node.childNodes[0].style.height = 'auto';

		  var name = randomString(6);
		  this.SaveCustomizeImage(previewImage, name+'.jpg', () => {
			this.SaveCustomizeImage(rawImage, name+'.png', () => {
				//functionality after save image
				this.StoreTemplate("customizer-"+name+'.jpg')
			})
		  })
		})
		.catch((error) => {
		  console.error('oops, something went wrong!', error);
		  this.setState({addingCart:false});
		});
	  }



	  SaveCustomizeImage = (image, name, returnFunction) => {
		const url = AppStore.url+'/images';
		const data = new FormData();
		data.append('file',image, "customizer-"+name);
		data.append('folder','customizer');
		Axios.post(url,data)
		.then( (response)=> {
		  if(response.data.success){
			returnFunction();
		  }
		})
		.catch((error) => {
		  console.log(error);
		});
	  }

	  StoreTemplate = (name) => {

		Axios.post(AppStore.url+'/customizertemplates',{
			name:this.state.templateName,
			image:name,
			template:{
				model:this.state.model,
				design:this.state.design,
				draggableData:this.state.draggableData
			}
		})
		.then( (response)=> {
		  if(response.data.success){
			toastr.success("Template Saved");
			this.setState({
				addingCart:false,
				showAddNameModal:false
			});
		  }else{
			toastr.error(response.data.msg);
			this.setState({addingCart:false});
		  }
		})
		.catch((error) => {
			toastr.error("Server Error Please Try Later");
			this.setState({addingCart:false});
		});
	  }


  render() {

    let {preview} = this.state;


    return (
        <div>
        	<Sidebar Customizer = "active"  Customizershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Customizer">
				<Row>
					<Col className="mt-5" style={{ justifyContent:'center', display:'flex',}}>
						<div style={{display: 'flex', flexDirection: 'row', overflow: 'hidden'}}>
						{(this.state.model && this.state.design && !this.state.loading) ?
						<div style={{position:'relative', justifyContent:'center', display:'flex', flex:1}} id="customizer">


							<img style={{backgroundImage:'url('+ GetImagePath(this.state.design)+')', width:'100%'}} className='mock-img' src={(AppStore.url+'/uploads/productimages/models/'+replaceAll(this.state.model)+'.png').toLowerCase()} />

							{this.state.draggableData.map((data, index) =>
							<DraggableComponent draggableData={this.state.draggableData} data={data} index={index} Delete={this.Delete} Update={this.Update} UpdateImage={this.UpdateImage} Sort={this.Sort} />
							)}

							{preview &&
							<img style={{position:'absolute', width:'100%'}} className='mock-img' src={(AppStore.url+'/uploads/productimages/models/'+replaceAll(this.state.model)+'.png').toLowerCase()} />
							}


						</div>
						:
						<div style={{position:'relative', justifyContent:'center', alignItems:'center', display:'flex', flex:1, width:200, height:400}}>
							<Spinner animation="grow" variant="primary" />
						</div>

						}

						<div className='draggableContentController'>
						<button title="Change Model or Design" className='roundBtn' onClick={() => this.setState({showModelSelect:true})}>
							<FontAwesomeIcon icon={faMobileAlt} />
						</button>
						<button title="Change Model or Design" className='roundBtn' onClick={() => this.setState({showDesignSelect:true})}>
							<FontAwesomeIcon icon={faImages} />
						</button>
						<button title="Add Text" className='roundBtn' onClick={() => this.AddElement('text', '')}>
							<FontAwesomeIcon icon={faFont} />
						</button>
						<button title="Add Images" className='roundBtn' onClick={() => this.setState({updateImageMode:'add', updatingImageIndex:'', showImageList:true})}>
							<FontAwesomeIcon icon={faIcons} />
						</button>
						<button title="Preview" className='roundBtn' style={{backgroundColor:preview?'#eee':'#fff', color:preview?'red':'#000'}} onClick={() => this.setState({preview:!preview})}>
							<FontAwesomeIcon icon={faEye} />
						</button>
						<button title="Save" className='roundBtn' style={{marginTop:100}} onClick={() => this.setState({showAddNameModal:true})} >
							{this.state.addingCart ? <Spinner animation="grow" size="sm" /> : <FontAwesomeIcon icon={faSave} />}
						</button>
						</div>
						</div>
					</Col>
					</Row>
					<Row>
					<Modal
						show={this.state.showImageList}
						onHide={() => this.setState({showImageList:false})}
						size="lg"
						aria-labelledby="example-custom-design-styling-title"
					>
						<Modal.Header closeButton>
						<Modal.Title id="example-custom-design-styling-title">
							ADD IMAGES
						</Modal.Title>
						</Modal.Header>
						<Modal.Body>
						<Row>
							<Col>
							<div style={{alignItems:'center', justifyContent:'center', display:'flex', flexDirection:'row'}}>
								<span style={{width:200}}>UPLOAD NEW IMAGE</span><Form.Control multiple onChange={(e) => this.UploadImage(e)} size="sm" type="file" placeholder="Small text" />
							</div>
							</Col>
						</Row>
						<Row>
							{this.state.uploadedImage.length == 0 &&
							<Col sm={12}>
							<div style={{justifyContent:'center', display:'flex', marginTop:20}}>
								<img className='res-img' src={require('./images/noimage.png')} style={{width:120, height:120, alignSelf:'center'}}/>
							</div>
							</Col>
							}
							{this.state.uploadedImage.map((uploadedImage, index) =>
							<Col md={2} sm={3} xs={4} style={{position:'relative'}} >
								<img
								className='res-img mt-2'
								style={{borderRadius:5, cursor:'pointer'}}
								src={(AppStore.url+'/uploads/productimages/customizer/'+uploadedImage).toLowerCase()}
								onClick={
									() => this.state.updateImageMode == 'update' ?
									this.CngImage( AppStore.url+'/uploads/productimages/customizer/'+uploadedImage)
									:
									this.AddElement('image', AppStore.url+'/uploads/productimages/customizer/'+uploadedImage)
								}
								/>
								<button onClick={() => this.RemoveUploadedImage(index)} className="roundBtn" style={{position:'absolute', top:15, right:20, width:20, height:20, fontSize:10}}><FontAwesomeIcon icon={faTrashAlt} /></button>
							</Col>
							)}
						</Row>
						</Modal.Body>
					</Modal>
					</Row>
					<Row>
						<Modal
							show={this.state.showDesignSelect}
							onHide={() => this.setState({showDesignSelect:false})}
							size="lg"
							aria-labelledby="example-custom-design-styling-title"
						>
							<Modal.Header closeButton>
							<Modal.Title id="example-custom-design-styling-title">
								Select Design
							</Modal.Title>
							</Modal.Header>
							<SearchDesignModal SelectDesign={this.SelectDesign} />
						</Modal>
					</Row>
					<Row>
						<Modal
							show={this.state.showAddNameModal}
							onHide={() => this.setState({showAddNameModal:false})}
							size="sm"
							aria-labelledby="example-custom-design-styling-title"
						>
							<Modal.Header closeButton>
							<Modal.Title id="example-custom-design-styling-title">
								Add Template Name
							</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<Row>
									<Col>
										<div className="form-group">
											<label htmlFor="Name">Template Name</label>
											<input type="text" className="form-control" id="templateName" name="templateName" value={this.state.templateName} onChange={(e) => this.setState({templateName:e.target.value})}/>
										</div>
									</Col>
								</Row>
							</Modal.Body>
							<Modal.Footer>
								<Button variant="secondary" onClick={() => this.setState({showAddNameModal:false})}>Close</Button>
								<Button variant="primary" onClick={() => this.state.templateName ? this.Save() : toastr.error("Template Name Empty")} >
									{this.state.addingCart ? <Spinner animation="grow" size="sm" /> : "Save changes"}
								</Button>
							</Modal.Footer>
						</Modal>
					</Row>
					<Row>
					<SearchModel showModelSelect={this.state.showModelSelect} setshowModelSelect={(res) => this.setState({showModelSelect:res})} OnChangeModel={this.SelectModel} />
				</Row>
				</div>
	        </div>
        </div>
    );
  }
})

export default Customizer;
