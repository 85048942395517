import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";
import AppStore from './store/AppStore';


const UploadImages = observer(class UploadImages extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggeduser:loggeduser(),
			productType: '',
			images:[],
            enabled: true,
            Loading: false,
		};
	}



	componentWillMount(){
		sendToLoginIfNoAccess("products","add", this, "/UploadImages");
	    this.getImage(this.state.productType);
	}

	getImage(productType){
		if(productType == ''){
			this.setState({images: []});
		}else{
			axios.get(baseurl()+'/images/'+productType, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
				console.log(response);
				this.setState({images: response.data.files});
	    })
		}

	}

	changeInput(e){
		var input=e.target;
		if(input.name === "title"){
			this.setState({title : input.value});
		}
		else if(input.name === "description"){
			this.setState({description : input.value});
		}
		else if(input.name === "price"){
			this.setState({price : input.value});
		}
		else if(input.name === "models"){
			this.setState({models : input.value});
		}else if(input.name === "modelgroup"){
			this.setState({modelgroup : input.value});
		}else if(input.name === "productType"){
			this.setState({productType : input.value});
			this.getImage(input.value);
		}
	}

	onChangeFile(e) {
        if(this.state.productType){
            this.setState({Loading:true})
            const url = baseurl()+'/images';
            const data = new FormData();
            for(var i=0;i<e.target.files.length;i++){
                //images.push('/uploads/productimages/cc'+e.target.files[i].name);
                data.append('file',e.target.files[i]);
            }
            data.append('folder',this.state.productType);
            // const config = {
            //     headers: {
            //         'content-type': 'multipart/formdata'
            //     }
            // }
            axios.post(url,data, { headers: { authorization: this.state.loggeduser.token } })
            .then( (response)=> {
                this.getImage(this.state.productType);
                this.setState({Loading:false})
                notify("Image Uploaded", "#28a745");
            })
            .catch((error)=> {
                console.log(error);
                notify("File Didn't Uploaded!",'#F44336');
                this.setState({Loading:false})
            });
        }else{
            notify('Please Select A Product Type!','#F44336');
        }
	}

	delete(data){
		axios.delete(baseurl()+'/images/', {
			data:{
				images: [this.state.productType+"/" + data],
			}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response.data);
		    this.getImage(this.state.productType);
			notify("Image Removed", "#dc3545");
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	selectproductType(type){
		this.setState({productType: type});
		this.getImage(type);
	}

  render(){

    const productTypes = AppStore.productTypes.map((data, index)=>{
        return (
            <option key={index} value={data.name} >{data.name}</option>
        );
    });

	const uploadedImage = this.state.images.map(function(data, index){
			var url = (baseurl()+'/uploads/productimages/'+this.state.productType+'/'+data).toLowerCase();
			return(
				<div key={index} className="col-3">
					{/* <div className="profile-author">
	                    <img src={url} alt="Uploaded product"/>
	                    <div className="profile-icon">
	                        <i className="fa fa-times" onClick={this.delete.bind(this, data)}></i>
	                    </div>
                    </div> */}
                    <div className="imagename-list">
                        <span>{data}</span>
                        <div className="dlt-btn" onClick={this.delete.bind(this, data)}>
	                        <i className="fa fa-times" ></i>
	                    </div>
                    </div>
				</div>
		    )
	}, this);

    return (
        <div className="addProducts">
        	<Sidebar UploadImages="active" productshow="show" />
	        <div className="page">
	        	<Header />
	        	<div className="container">
	        		<div className="row spd-mt-30">
	        			<div className="col-12">
                            <div className="form-group">
                                <label>Select Product Type</label>
                                <select name="productType"  className="form-control" onChange={this.changeInput.bind(this)}>
                                    <option value=''>Select Product Type</option>
                                    {productTypes}
                                </select>
                            </div>
	        			</div>
	        		</div>
			        <div className="row">
						<div className="col-12">
							<form>
								<div className="form-group">
									<label htmlFor="imageupload">Image Upload (image name format should be like CC-Custom-1201-D)</label>
									<input className="form-control" type="file" onChange={this.onChangeFile.bind(this)} multiple />
								</div>
							</form>
						</div>
			        </div>
			         <div className="row">
                         {this.state.Loading &&
                            <div className="col-12 mt-3 mb-3">
                                <div class="d-flex justify-content-center align-center">
                                    <h1 className="m-0 mr-2">UPLOADING</h1>
                                    <div class="spinner-border" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        }
						<div className="col-12">
							<h2>IMAGE LIST</h2>
						</div>
					</div>
					<div className="row">
						{uploadedImage}
					</div>
	        	</div>
	        </div><ToastContainer />
        </div>
    );
  }
})

export default UploadImages;
