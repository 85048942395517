import React, { useState } from 'react'
import IndicatorDots from './indicator-dots'
import Buttons from './buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee ,faCarAlt,faSearch, faUser ,faHeart,faChevronDown, faChevronUp, faCartPlus, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Row, Container, Col, Form, Button, Modal, InputGroup, FormControl, Spinner, ListGroup} from 'react-bootstrap';
import { observer } from 'mobx-react';
import AppStore from '../store/AppStore';
import {replaceAll} from '../utility/utility'

const SearchModel = observer(function SearchModel (props){

    const [searchModel, setsearchModel] = useState('iPhone');

    const ChkModel = () => {
        return AppStore.models.filter((ele) => {return (ele.name.toLowerCase()).indexOf(searchModel.toLowerCase()) !==  -1 })
    }

    var ModelFroRender = ChkModel();

  return (
    <Modal
        show={props.showModelSelect}
        onHide={() => props.setshowModelSelect(false)}
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
    >
        <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
                Select Phone Model
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col>
                <Form.Group size="sm" className="mb-3" controlId="formGridState" onChange={(e) => setsearchModel(e.target.value)}>
                    <Form.Control as="select">
                        <option value="">Select Brand</option>
                        <option value="iPhone">iPhone</option>
                        <option value="samsung">Samsung</option>
                        <option value="oppo">Oppo</option>
                        <option value="huawei">Huawei</option>
                        <option value="xiaomi">Xiaomi</option>
                        <option value="OnePlus">OnePlus</option>
                        <option value="vivo">Vivo</option>
                        <option value="nokia">Nokia</option>
                        <option value="google">Google</option>
                    </Form.Control>
                </Form.Group>
                {/* <InputGroup size="sm" className="mb-3">
                    <FormControl
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        value={searchModel}
                        onChange={(e) => setsearchModel(e.target.value)}
                        placeholder="Search Model"
                    />
                    </InputGroup> */}

            </Col>
            </Row>
            <Row>
            {ModelFroRender.map((data, index) =>
                <Col lg={2} md={3} sm={4} xs={4} key={index} onClick={() => props.OnChangeModel(replaceAll(data.name))} style={{cursor:'pointer'}}>
                    <img className='res-img' src={(AppStore.url+'/uploads/productimages/models/'+data.coverImage).toLowerCase()} />
                    <h6 className='m-0 text-center'>{data.name}</h6>
                </Col>
            )}
            {(ModelFroRender.length == 0) &&
                <Col>
                <h4 className="text-center mt-3" >MODEL NOT AVALIABLE</h4>
                </Col>
            }
            </Row>
        </Modal.Body>
    </Modal>
  );
})

export default SearchModel;
