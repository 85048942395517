import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {Button, Form, Modal, Table} from 'react-bootstrap'
import {observer} from "mobx-react";
import AppStore from './store/AppStore'

const Waste = observer(class Waste extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			type:'',
			model:'',
			code:'',
			quantity:'',
            waste:[],

            filterModel:'',
            filterCode:'',
            filterType:'',

            showBlukAdd:false,

            models:[],
            modelSearch:''
		};
	}

	componentDidMount(){
		sendToLoginIfNoAccess("products","edit", this, "/waste" , { headers: { authorization: this.state.loggeduser.token } });
        this.getData();
        this.loadModels()
    }

	changeInput(e){
        var input=e.target;
		if(input.name === "type"){
			this.setState({type : input.value});
		}else if(input.name === "model"){
			this.setState({model : input.value});
		}else if(input.name === "code"){
			this.setState({code : input.value});
		}else if(input.name === "quantity"){
			this.setState({quantity : input.value});
		}else if(input.name === "filterType"){
			this.setState({filterType : input.value});
		}else if(input.name === "filterModel"){
			this.setState({filterModel : input.value});
		}else if(input.name === "filterCode"){
			this.setState({filterCode : input.value});
		}
    }

    loadModels = () => {
        axios.get(baseurl()+'/models')
        .then(res => {
            if(res.data.success){
                this.setState({models:res.data.result})
            }
        })
    }

    getData(){
        // console.log(this.state);
        // var extraUrl = '';
        // if(this.state.filterCode !== ''){
        //     extraUrl = '?code='+this.state.filterCode;
        // }else if(this.state.filterType !== '' && this.state.filterModel !== ''){
        //     extraUrl = '?type='+this.state.filterType+'&model='+this.state.filterModel;
        // }
        axios.get(baseurl()+'/waste/report', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({waste : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }

    addData(){
        if(this.state.model !== '' && this.state.code && this.state.type !== '' && this.state.quantity){
            axios.post(baseurl()+'/waste', {
                type: this.state.type,
                code: this.state.code,
                quantity: this.state.quantity,
                model: this.state.model,
            } , { headers: { authorization: this.state.loggeduser.token } })
            .then((response) => {
                console.log(response);
                if(response.data.success === true){
                    this.setState({waste : response.data.result});
                    notify("Model Added", "#28a745");
                }else{
                    notify("Could Not Save", "#F44336");
                }
            })
            .catch(function (error) {
                notify("Could Not Save", "#F44336");
            });
        }else{
            notify("Fill Empty", "#F44336");
        }

    }

    addBulk = () => {

        var formattedData = (this.state.models.filter(ele => ele.quantity && ele.name)).map(ele => {
            //if(ele.quantity && ele.name){
                return { model: ele.name, type: 'CC', quantity: ele.quantity}
            //}
        })

        // console.log(formattedData)
        // return null;

        axios.post(baseurl() + '/waste/multiple/add', {
            data:formattedData
        })
        .then(res => {
            this.setState({showBlukAdd:false})
            if(res.data.success){
                this.getData()
            }
            notify("Success", "#28a745");

        })
        .catch(e => {
            this.setState({showBlukAdd:false})
            notify("Could Not Save", "#F44336");
        })
    }

    Getquantity = (name) => {

        var models = JSON.parse(JSON.stringify(this.state.models))

        var index = models.findIndex(ele => ele.name === name);

        if(index > -1){

            return models[index].quantity

        }

        return '';

    }

    Updatequantity = (value, name) => {

        console.log("eddd");

        var models = JSON.parse(JSON.stringify(this.state.models))

        var index = models.findIndex(ele => ele.name === name);

        if(index > -1){

            models[index].quantity = value

            console.log(models)
            this.setState({models:models})

        }


    }

    refreshSearch(){
        this.setState({filterCode:'', filterModel:'', filterType:''}, () => {this.getData()});
    }

    ChkModelisChildren = (modelName) => {
        var yesOrNo = false
        for (let index = 0; index < this.state.models.length; index++) {

            if(this.state.models[index].similarModels.indexOf(modelName) > -1){
                yesOrNo = true
                break;
            }

        }
        return yesOrNo
    }


  render() {
  	const jsonitems=this.state.waste.map(function(data){

        const modelInfo = this.state.models.find(ele => ele.name == data._id)

        if(!this.ChkModelisChildren(data._id)){

			return(
				<tr key={data._id}>
			      {/* <td>{data.type}</td> */}
			      {/* <td>{data.code}</td> */}
			      <td>
                      {data._id}
                      {(modelInfo && modelInfo.similarModels && modelInfo.similarModels.length) > 0 ?
                        <>
                            <h5 className="mt-2" >Similar Models:</h5>

                            {modelInfo.similarModels.map((simiData, simiIndex) =>
                                <h6 key={simiIndex} >{simiData}</h6>
                            )}
                        </>
                    : null}
                  </td>
			      <td>{data.total}</td>
			    </tr>
			);
        }
    }, this);

    const productTypes = AppStore.productTypes.map((data, index)=>{
        return (
            <option key={index} value={data.name} >{data.name}</option>
        );
    });

    const productModel = AppStore.models.map((data, index)=>{
        return (
            <option key={index} value={data.name} >{data.name}</option>
        );
    });

    return (
        <div >
        	<Sidebar waste = "active" inventoryshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container waste">
                    <div className="row">
                        <div className="col-12">
                            {/* <button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" >Add waste</button> */}
                            <button
                                type="button"
                                className="btn btn-primary spd-mt-30 spd-mb-10"
                                onClick={() => this.setState({showBlukAdd:true})}
                             >
                                 Add Bulk waste
                            </button>

                            <Modal
                                show={this.state.showBlukAdd}
                                onHide={() => this.setState({showBlukAdd:false})}
                                // size='lg'
                            >
                                <Modal.Header closeButton>
                                <Modal.Title>Add Bulk waste</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{height:400, overflow:'auto'}}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Model"
                                        className='mb-2'
                                        value={this.state.modelSearch}
                                        onChange={e => this.setState({modelSearch:e.target.value})}
                                    />
                                    <Table striped bordered hover  >
                                        <thead>
                                            <tr>
                                            <th>Model Name</th>
                                            <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.models.filter(ele => ele.name.toLowerCase().indexOf(this.state.modelSearch.toLowerCase()) > -1 ).map((modelData, index) =>
                                            <tr key={index} >
                                                <td>{modelData.name}</td>
                                                <td>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder="Quantity"
                                                        size='sm'
                                                        value={this.Getquantity(modelData.name)}
                                                        onChange={(e) => this.Updatequantity(e.target.value, modelData.name)}
                                                    />
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                        </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({showBlukAdd:false})}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={this.addBulk}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-5">
                                    <select className="form-control" name="filterType" onChange={this.changeInput.bind(this)}>
                                        <option value="" >All Product Type</option>
                                        {productTypes}
                                    </select>
                                </div>
                                <div className="col-4">
                                    <select className="form-control" name="filterModel" onChange={this.changeInput.bind(this)}>
                                        <option value="" >All Model</option>
                                        {productModel}
                                    </select>
                                </div>
                                <div className="col-2">
                                    <button className="btn btn-primary" onClick={this.getData.bind(this)}>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <input type="text" placeholder="Search By Code" className="form-control" value={this.state.filterCode} name="filterCode" onChange={this.changeInput.bind(this)}/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-primary" onClick={this.getData.bind(this)}>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <button className="btn btn-primary" onClick={this.refreshSearch.bind(this)}>Refresh</button>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    {/* <th>Type</th> */}
                                    <th>Models</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {jsonitems}
                                </tbody>
                            </table>
                        </div>
                    </div>
	        	</div>
                <ToastContainer />
	        </div>
        </div>
    );
  }
})

export default Waste;
