import React, {Component} from 'react';
import axios from 'axios';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {baseurl} from './utility/utility.js';

class invoice extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggeduser:loggeduser(),
			orderDate: new Date(),
			orderNumber:'',
			customerName:'',
			customerAddress:'',
			customerArea:'',
			customerPhone:'',
			alldeliveryperson:[],
			deliveryPerson:'Select Delivery Person',
			notes:'',
			total:0,
			payments:[],
			allproducts:[],
			products : [],
			discountTk:0,
			discountPct:0,
			deliveryFee:50,
			status: 'incomplete',
			paymenttype:'Select Type',
			paymentAmount:'',
			due:0,
			paid:'invoiceDue',
		};
	}

	componentWillMount(){
		sendToLoginIfNoAccess("orders","view", this, "/dashboard");
		var productId = this.props.match.params.id;
		this.loadproductsData(productId);
	}

	 loadproductsData(id){
		axios.get(baseurl()+'/orders/'+id, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
				if(response.data.success === true){
					this.setState({orderDate : response.data.result.orderDate});
					this.setState({orderNumber : response.data.result.orderNumber});
					this.setState({customerId : response.data.result.customerId});
					this.setState({customerName : response.data.result.customerName});
					this.setState({customerAddress : response.data.result.customerAddress});
					this.setState({customerPhone : response.data.result.customerPhone});
					this.setState({customerArea : response.data.result.customerArea});
					this.setState({deliveryPerson : response.data.result.deliveryPerson});
					this.setState({notes : response.data.result.notes});
					this.setState({payments : response.data.result.payments});
					this.setState({products : response.data.result.products});
					this.setState({discountTk : response.data.result.discountTk});
					this.setState({discountPct : response.data.result.discountPct});
					this.setState({deliveryFee : response.data.result.deliveryFee});
					this.setState({status : response.data.result.status});
					this.setState({total : response.data.result.total});
					this.setState({due : response.data.result.due});
					if (this.state.due < 1  ){
						this.setState({paid : 'invoicePaid'});
					}else{
						this.setState({paid : 'invoiceDue'});
					}
				}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

  render() {
  	const jsonitems = this.state.products.map(function(data,index){
			return(
				<tr key={index}>
					<td>{data.productType}-{data.code} {data.model}</td>
					<td>{data.note}</td>
					<td>{this.state.products[index].quantity}</td>
					<td>৳ {this.state.products[index].price}</td>
					<td>
						৳ {this.state.products[index].discountTk}
					</td>
					<td>
						{this.state.products[index].discountPct} %
					</td>
					<td>৳ {this.state.products[index].total}</td>
				</tr>
			)
	},this);

    return (
        <div className="container invoice ">
        	{/* <img style={{width:100}} src="/img/paid.png" className={this.state.paid} alt="paid" /> */}
			<div className="row">
				<div className="col-3">
					<img className="logo" src="/img/fabgearlogo.png" alt="logo missing"  />
				</div>
				<div className="col-9 ">
					<div className="fab-addres">
						<address>
							604 Concord Tower<br/>
							113 Kazi Nazrul Islam avenue<br/>
							Dhaka 1000<br/>
							Phone : 01892555866<br/>
							Website : www.fabgearshop.com
						</address>
						<p className="right-invoice">
							<span className="invoice">ORDER</span><br/>
							no.{this.state.orderNumber}<br/>
							<DatePicker className="innvoice-date"
								selected={moment(this.state.orderDate)}
								dateFormat="DD/MM/YYYY"
								/>
							<span className="taka">Amount Due: ৳ {this.state.due}</span>
						</p>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-12">
					<div className="personal-detail">
						<span className="addres">Address</span><br/>
						<span className="name">{this.state.customerName}</span><br/>
						{this.state.customerAddress}<br/>
						Phone : {this.state.customerPhone}
					</div>
					<table className="table table-striped">
						<thead>
							<tr>
								<th className="min-width-80">Product</th>
								<th className="min-width-80">Note</th>
								<th className="min-width-80">Qty</th>
								<th className="min-width-80">Price</th>
								<th className="min-width-80">DISC(TK)</th>
								<th className="min-width-80">DISC(%)</th>
								<th className="sm-th">Total</th>
							</tr>
						</thead>
						<tbody>
							{jsonitems}
						</tbody>
					</table>
					<div className="row">
						<div className="col-7 invoice-notes">
							<h3>Notes</h3>
							<h3><b>{this.state.notes}</b></h3>
						</div>
						<div className="col-5">
							<table className="table">
								<tbody>
									<tr className="tr-fnt">
										<td>Delivery Charge</td>
										<td>৳ {this.state.deliveryFee}</td>
									</tr>
									<tr className="tr-fnt">
										<td>Discount (TK)</td>
										<td>৳ {this.state.discountTk}</td>
									</tr>
									{/*<tr className="tr-fnt">*/}
									{/*	<td>Discount (%)</td>*/}
									{/*	<td>{this.state.discountPct} %</td>*/}
									{/*</tr>*/}

									<tr className="bg-tr">
										<td>Total</td>
										<td>৳ {this.state.total}</td>
									</tr>
									<tr className="tr-fnt">
										<td>Paid</td>
										<td>৳ {this.state.total - this.state.due}</td>
									</tr>
									<tr className="bg-tr">
										<td>Due</td>
										<td>৳ {this.state.due}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
    );
  }
}

export default invoice;

