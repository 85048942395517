import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify, setlog} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

//hello
class coupons extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			id: '',
			couponcode:'',
			discountTk:'',
			discountPct:'',
			minOrder:'',
			startDate:new Date(),
			expiryDate:new Date(),
			coupons:[],
		};
		//this.Data.bind(this)
	}


	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/coupons" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/coupons', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({coupons : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "couponcode"){
			this.setState({couponcode : input.value});
		}
		else if(input.name === "discountTk"){
			this.setState({discountTk : input.value});
		}
		else if(input.name === "discountPct"){
			this.setState({discountPct : input.value});
		}
		else if(input.name === "minOrder"){
			this.setState({minOrder : input.value});
		}

	}

	loadaddData(){
		this.setState({couponcode : ''});
		this.setState({discountTk : ''});
		this.setState({discountPct : ''});
		this.setState({minOrder : ''});
		this.setState({startDate : new Date()});
		this.setState({expiryDate : new Date()});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({couponcode : data.code});
		this.setState({discountTk : data.discountTk});
		this.setState({discountPct : data.discountPct});
		this.setState({minOrder : data.minOrder});
		this.setState({startDate : data.startDate});
		this.setState({expiryDate : data.expiryDate});
	}

	addData(){
		axios.post(baseurl()+'/coupons', {
		    code: this.state.couponcode,
		    discountTk: this.state.discountTk,
		    discountPct: this.state.discountPct,
		    minOrder: this.state.minOrder,
		    startDate: this.state.startDate,
		    expiryDate: this.state.expiryDate
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({coupons : response.data.result});
		    	notify("Coupon Added", "#28a745");
		    	setlog('Coupon Added ' , 'add coupon');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/coupons/'+this.state._id, {
		    code: this.state.couponcode,
		    discountTk: this.state.discountTk,
		    discountPct: this.state.discountPct,
		    minOrder: this.state.minOrder,
		    startDate: this.state.startDate,
		    expiryDate: this.state.expiryDate
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({coupons : response.data.result});
				notify("Coupon Updated", "#0275d8");
				setlog('Coupon Updated id: '+this.state._id , 'update coupon');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/coupons/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({coupons : response.data.result});
		    	notify('Coupon Deleted!','#F44336');
		    	setlog('Coupon Deleted id: '+data._id , 'delete coupon');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}
	changeStartDate =  startDate => this.setState({ startDate });

	changeEndDate = expiryDate => this.setState({ expiryDate });

  render() {
  	const jsonitems=this.state.coupons.map(function(data){
			return(
				<tr key={data._id}>
			      <td>{data.code}</td>
			      <td>{data.discountTk}</td>
			      <td>{data.discountPct}</td>
			      <td>{data.minOrder}</td>
			      <td>{moment(data.startDate).format("DD/MM/YYYY")}</td>
			      <td>{moment(data.expiryDate).format("DD/MM/YYYY")}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#editModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
	}, this);
    return (
        <div >
        	<Sidebar coupons = "active" productshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container models">
	        	<div className="row">
	        		<div className="col-12">
	        					<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#couponModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add coupons</button>

								{/* Popup Modal Code */}
								<div className="modal fade" id="couponModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">Add Coupon</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
								        <form>
											<div className="form-group">
												<label>Coupon Code</label>
												<input type="text" className="form-control" id="couponcode" name="couponcode" value={this.state.couponcode} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>Discount Taka</label>
												<input type="number" className="form-control" id="discountTk" name="discountTk" value={this.state.discountTk} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>DiscountPct</label>
												<input type="number" className="form-control" id="discountPct" name="discountPct" value={this.state.discountPct} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>Min Order</label>
												<input type="number" className="form-control" id="minOrder" name="minOrder" value={this.state.minOrder} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>Start Date</label>
												<DatePicker
													selected={moment(this.state.startDate)}
													onChange={this.changeStartDate.bind(this)}
													dateFormat="DD/MM/YYYY"
	   											/>
												</div>
											<div className="form-group">
												<label>Expiry Date</label>
												<DatePicker
													selected={moment(this.state.expiryDate)}
													onChange={this.changeEndDate.bind(this)}
													dateFormat="DD/MM/YYYY"
	   											/>
												</div>
								        </form>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addData.bind(this)} >Add Coupon</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
							{/* Popup Modal  Code */}
								<div className="modal fade" id="editModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel"> Update Coupon Information</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
								       <form>
											<div className="form-group">
												<label>Coupon Code</label>
												<input type="text" className="form-control" id="couponcode" name="couponcode" value={this.state.couponcode} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>Discount Taka</label>
												<input type="number" className="form-control" id="discountTk" name="discountTk" value={this.state.discountTk} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>DiscountPct</label>
												<input type="number" className="form-control" id="discountPct" name="discountPct" value={this.state.discountPct} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>Min Order</label>
												<input type="number" className="form-control" id="minOrder" name="minOrder" value={this.state.minOrder} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label>Start Date</label>
												<DatePicker
													selected={moment(this.state.startDate)}
													onChange={this.changeStartDate.bind(this)}
													dateFormat="DD/MM/YYYY"
	   											/>
												</div>
											<div className="form-group">
												<label>Expiry Date</label>
												<DatePicker
													selected={moment(this.state.expiryDate)}
													onChange={this.changeEndDate.bind(this)}
													dateFormat="DD/MM/YYYY"
	   											/>
												</div>
								        </form>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.editData.bind(this)} >Update</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Coupon Code</th>
							      <th>Discount Taka</th>
							      <th>Discount Pct</th>
							      <th>MinOrder</th>
							      <th>Start Date</th>
							      <th>Expire Date</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default coupons;
