import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import {ToastContainer} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {baseurl} from './utility/utility.js';

class Logs extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			date: '',
			message:'',
			logType:'',
			user:'',
			logs:[],
			start:0,
			numRecords:10,
			totalpage:0,
			activepage:1,
		};
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};


	componentWillMount(){
		sendToLoginIfNoAccess("orders","edit", this, "/Logs");
		this.loadlogs(this.state.start, this.state.numRecords);
	}


	loadlogs(start, numRecords){
		axios.post(baseurl()+'/logs', {
		  	start: start,
		  	numRecords: numRecords,

		} , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({logs : response.data.result});
				this.setState({totalpage : Math.ceil(response.data.count/this.state.perpage)});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	sortBy(col,e){
		  var persons = this.state.persons;
			persons = _.orderBy(persons,[col],[this.state.sortOrder]);
			this.setState({persons: persons});

			if(this.state.sortOrder === 'asc'){
				this.setState({sortOrder : 'desc'})
			}else{
				this.setState({sortOrder : 'asc'})
			}
	 }

	activepage(index){
		if(this.state.activepage === index+1){
			return "page-active";
		}
		console.log(index);
	}

	pagination(pageNo){
			var start = pageNo*this.state.numRecords;
			this.loadlogs(start, this.state.numRecords);
			this.setState({activepage  : pageNo+1});
			this.setState({start  : start});
			console.log(pageNo);
	}


  render() {

		console.log(this.state.logs)
  	const jsonitems=this.state.logs.map(function(data, index){
			return(
				<tr key={index}>
			      <td>{moment(data.date).format("DD/MM/YY")}</td>
			      <td>{data.message}</td>
			      <td>{data.logType}</td>
			      <td>{data.user}</td>
			    </tr>
			);
	}, this);

	const pageItem = _.times(this.state.totalpage, i =>
			<span className="page-no" id={this.activepage(i)} onClick={this.pagination.bind(this, i)} key={i}>{i + 1}</span>
	);

    return (
        <div >
        	<Sidebar Logs = "active" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Log spd-mt-30">
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th onClick={this.sortBy.bind(this,'name')}>Date</th>
							      <th onClick={this.sortBy.bind(this,'phone')}>Message</th>
							      <th onClick={this.sortBy.bind(this,'logType')}>Log Type</th>
							      <th onClick={this.sortBy.bind(this,'user')}>User</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
						<div className="col-12">
							{pageItem}
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default Logs;
