import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);
    this.state = {
      showMultiSelect:false,
    };
  }

  chkSelected = (value) => {
    if(this.props.selected.indexOf(value) == -1){
      return false;
    }else{
      return true;
    }
  }

  ChkAllSelected = () => {
    var yesOrNo = true;
    if(this.props.data.length == 0) {
      return false
    }
    for(var i =0; i < this.props.data.length; i++){
      // console.log(this.props.selected, this.props.data[i])
      if(this.props.selected.indexOf(this.props.data[i]) == -1){

        yesOrNo = false;
        break;
      }
    }
    console.log(yesOrNo)
    return yesOrNo;
  }

  componentWillReceiveProps(){
    this.render()
  }

  render() {

    let {title, data, selected} = this.props;

    return (
      <div style={{position:'relative', }}>

        <button onClick={()=>this.setState({showMultiSelect:!this.state.showMultiSelect})} className='btn btn-default'>All {title} <i className='fa fa-caret-down'></i></button>

        {this.state.showMultiSelect &&

          <div className='' style={{position:'absolute',marginTop:2, zIndex:999,padding:'10px 20px',minWidth:300,border:'1px solid #ddd',  maxHeight:200, overflowY:'scroll', backgroundColor:'#fff'}}>
            <span onClick={() => this.props.HandleSelect('all')} className='multiselect-item'>
              <input checked={this.ChkAllSelected()}  type='checkbox' className='mr-3' /> All {title}
            </span>
            {data.map((singleData, index)=>(
              <span key={index} onClick={() => this.props.HandleSelect(singleData)}  className='multiselect-item'>
                <input checked={this.chkSelected(singleData)} type='checkbox' className='mr-3' />{singleData}
              </span>
            ))}
            
          </div>

        }

      </div>
    );
  }
}

export default Autocomplete;