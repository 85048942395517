import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import _ from 'lodash';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {observer} from 'mobx-react';
import AppStore from './store/AppStore';

const blacklist= observer(class blacklist extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			id: '',
			name:'',
			phone:'',
			reason:'',
			blacklist:[],
			sortOrder:"asc",
		};
		//this.Data.bind(this)
	}

	componentWillMount(){
	  // Make a request for a user with a given ID
	  sendToLoginIfNoAccess("customers","edit", this, "/blacklist");
	  axios.get(baseurl()+'/blacklist', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({blacklist : response.data.result});
				AppStore.blacklist = response.data.result;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}else if(input.name === "phone"){
			this.setState({phone : input.value});
		}else if(input.name === "reason"){
			this.setState({reason : input.value});
		}
	}

	loadaddData(){
		this.setState({name : '', phone: '', reason: ''});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({name : data.name});
		this.setState({phone : data.phone});
		this.setState({reason : data.reason});
	}

	addData(){
        console.log(this.state)
        if(this.state.name && this.state.phone && this.state.reason){
            axios.post(baseurl()+'/blacklist', {
                name: this.state.name,
                phone: this.state.phone,
                reason: this.state.reason,
            } , { headers: { authorization: this.state.loggeduser.token } })
            .then((response) => {
                console.log(response);
                if(response.data.success === true){
                    this.setState({blacklist : response.data.result});
                    AppStore.blacklist = response.data.result;
                    notify("Blacklist Added", "#28a745");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }else{
            notify('Field Empty','#F44336');
        }
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	editData(){
        if(this.state.name && this.state.phone && this.state.reason){
            axios.put(baseurl()+'/blacklist/'+this.state._id, {
                name: this.state.name,
                phone: this.state.phone,
                reason: this.state.reason,
            }, { headers: { authorization: this.state.loggeduser.token } })
            .then( (response) => {
                console.log(response);
                if(response.data.success === true){
                    this.setState({blacklist : response.data.result});
                        AppStore.blacklist = response.data.result;
                        notify("Blacklist Updated", "#0275d8");
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }else{
            notify('Field Empty','#F44336');
        }
	}

	delete(data){
		axios.delete(baseurl()+'/blacklist/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({blacklist : response.data.result});
					AppStore.blacklist = response.data.result;
		    	notify('Blacklist Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	sortBy(col,e){
		  var blacklist = this.state.blacklist;
			blacklist = _.orderBy(blacklist,[col],[this.state.sortOrder]);
			this.setState({blacklist: blacklist});

			if(this.state.sortOrder === 'asc'){
				this.setState({sortOrder : 'desc'})
			}else{
				this.setState({sortOrder : 'asc'})
			}
	 }



  render() {
  	const jsonitems = this.state.blacklist.map(function(data){
			return(
				<tr key={data._id}>
			      <td>{data.name}</td>
			      <td>{data.phone}</td>
			      <td>{data.reason}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#editModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button  hidden={!hasAccess("customers", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
		}, this);

    return (
        <div >
        	<Sidebar blacklist="active" ordershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container blacklist">
	        	<div className="row">
	        		<div className="col-12">
	        					<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Black List</button>

								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">Add blacklist</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="Name">Name</label>
                                            <input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label >Phone</label>
                                            <input type="text" className="form-control"  name="phone" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Reason</label>
                                            <textarea rows={5} className="form-control"  name="reason" value={this.state.reason} onChange={this.changeInput.bind(this)}></textarea>
                                        </div>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addData.bind(this)} >Add blacklist</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
							{/* Popup Modal  Code */}
								<div className="modal fade" id="editModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel"> blacklist Information</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
                                        <div className="form-group">
                                            <label htmlFor="name">Name</label>
                                            <input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label >Phone</label>
                                            <input type="text" className="form-control"  name="phone" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
                                        </div>
                                        <div className="form-group">
                                            <label >Reason</label>
                                            <textarea rows={5}  className="form-control"  name="reason" value={this.state.reason} onChange={this.changeInput.bind(this)}></textarea>
                                        </div>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.editData.bind(this)} >Update</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th onClick={this.sortBy.bind(this,'name')}>Name</th>
							      <th onClick={this.sortBy.bind(this,'phone')}>Phone</th>
							      <th>Reason</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default blacklist;
