import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {Checkbox, Modal} from 'antd';

class categories extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			_id: '',
			name:'',
			parent: '',
			featured:false,
			serial:'',
			colorCode:'',
			categories:[],

			showModal: false,
			mode:'add'
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentDidMount(){
		sendToLoginIfNoAccess("products","edit", this, "/categories");
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/categories' , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({categories : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }

	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}
	}

	selectCat(data){
		console.log(data);
		this.setState({parent : data});
	}

	addData = () => {

		var extraUrl = this.state.mode == 'add' ? '' : '/'+this.state._id;

		axios[this.state.mode == 'add' ? 'post' : 'put'](baseurl()+'/categories'+extraUrl, this.state , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
				this.setState({categories : response.data.result, showModal: false});

		    	notify("Category "+(this.state.mode == 'add' ? 'Added' : 'Updated'), "#28a745");
			}
		  })
		  .catch(function (error) {

			console.log(error);
			this.setState({showModal: false});

		  });
	}

	LoadAddData = () => {

		this.setState({

			_id: '',
			name:'',
			parent: '',
			featured:false,
			serial:'',
			colorCode:'',
			mode:'add',
			showModal:true

		})

	}

	LoadEditData = (data) => {

		this.setState({

			_id: data._id,
			name:data.name,
			parent: data.parent,
			featured: data.featured,
			serial: data.serial,
			colorCode: data.colorCode,
			mode:'update',
			showModal:true


		})

	}


	delete(data){
		axios.delete(baseurl()+"/categories/"+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({categories : response.data.result});
		    	notify('Category Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

  render() {

  	const jsonitems = this.state.categories.map(function(data){
		return(
			<tr key={data._id}>
				<td>{data.parent}</td>
				<td>{data.name}</td>
				<td>{data.featured ? 'yes' : 'no'}</td>
				<td>{data.serial}</td>
				<td>{data.colorCode}</td>
				<td>
					<button hidden={!hasAccess("products", "edit")}  className="btn btn-primary" onClick={() => this.LoadEditData(data)} ><i className="fa fa-edit" ></i>
					</button>

					<button hidden={!hasAccess("products", "delete")}  className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i>
					</button>
				</td>
			</tr>
		);
	}, this);

	const catDropdown = this.state.categories.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectCat.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		);
	}, this);

    return (
        <div >
        	<Sidebar categories = "active" productshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container-fluid categories">
					<div className="row my-4">
						<div className='col-8'>
							<h1>Categories</h1>
						</div>
						<div className='col-4'>
							<button type="button" className="btn btn-primary"  onClick={this.LoadAddData} >Add Models</button>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Parent</th>
							      <th>Name</th>
							      <th>Featured</th>
							      <th>serial</th>
							      <th>color Code</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div>
				<ToastContainer />
	        </div>
			<Modal
				title="Basic Modal"
				visible={this.state.showModal}
				onOk={this.addData}
				onCancel={() => this.setState({showModal:false})}
			>
				<div className="row">
	        		<div className="col-12 spd-mt-30">
	        			<div className="dropdown">
	        				Parent Category
							<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					    		{this.state.parent}
					 		</button>
				  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    			 {catDropdown}
				  			</div>
						</div>
					</div>
					<div className="col-12 spd-mt-30">
						<div className="form-group">
							<input type="text" className="form-control" value={this.state.name} onChange={e => this.setState({name:e.target.value})} placeholder="Name" />
						</div>
					</div>
					<div className="col-12 spd-mt-30">
						<div className="form-group">
							<input type="number" className="form-control" value={this.state.serial} onChange={e => this.setState({serial:e.target.value})} placeholder="Serial" />
						</div>
					</div>
					<div className="col-12 spd-mt-30">
						<div className="form-group">
							<input type="text" className="form-control" value={this.state.colorCode} onChange={e => this.setState({colorCode:e.target.value})} placeholder="Color Code" />
						</div>
					</div>
					<div className="col-12 spd-mt-30" style={{marginTop:40}}>
						<div className="form-group">
							<Checkbox onChange={e => this.setState({featured:e.target.checked})} checked={this.state.featured}>Feature</Checkbox>
						</div>
					</div>
	        	</div>
			</Modal>
        </div>
    );
  }
}

export default categories;
