import axios, { all } from "axios";
import _ from "lodash";
import { observer } from "mobx-react";
import moment from "moment";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "react-confirm-alert/src/react-confirm-alert.css";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddOrder from "./addOrder";
import AppStore from "./store/AppStore";
import MultiSelect from "./components/MultiSelect";
import Header from "./header.js";
import OrderImage from "./OrderImage";
import Sidebar from "./sidebar.js";
import {
  hasAccess,
  loggeduser,
  sendToLoginIfNoAccess,
} from "./utility/security";
import {
  BandageToStatus2,
  baseurl,
  GetImagePathNew,
  GetRowImage,
  GetStatusClr,
  notify,
  renderWeekDay,
} from "./utility/utility.js";

const Orders = observer(
  class Orders extends Component {
    //test
    PathaoCSVData = [
      [
        "ItemType",
        "StoreName",
        "MerchantOrderId",
        "RecipientName(*)",
        "RecipientPhone(*)",
        "RecipientCity(*)",
        "RecipientZone(*)",
        "RecipientArea",
        "RecipientAddress(*)",
        "AmountToCollect(*)",
        "ItemQuantity",
        "ItemWeight",
        "ItemDesc",
        "SpecialInstruction",
      ],
    ];

    eCourierCSVData = [
      [
        "Receiver Name",
        "Receiver mobile",
        "Receiver Address",
        "Receiver area",
        "Receiver City",
        "Receiver Landmark",
        "Product Type",
        "Package Code",
        "Delivery Time",
        "Payment Method",
        "Product Price",
        "Product Id",
        "Requested Delivery Date",
        "Comment",
        "Number of Item",
        "Actual Product Price",
      ],
    ];

    redxCSVData = [
      [
        "Invoice",
        "Customer Name",
        "Contact No.",
        "Customer Address",
        "District",
        "Area",
        "Area ID",
        "Division",
        "Price",
        "Instruction",
        "Product Selling Price",
        "Seller Name",
        "Seller Phone",
      ],
    ];

    constructor(props) {
      super(props);
      this.state = {
        loggeduser: loggeduser(),
        orderdata: [],
        sortOrder: "desc",
        defaultSort: "orderDate",
        startDate: AppStore.orderStartDate,
        endDate: AppStore.orderEndDate,
        alldeliveryperson: [],
        allModels: [],
        start: AppStore.orderStart,
        perpage: AppStore.orderNumRecordes,
        totalpage: 0,
        activePage: 1,
        orderByphone: [],
        datequery: "Select Date",

        totalOrder: 0,

        activeOrderImage: [],
        searchWord: "",

        updatingPerson: false,
        updatingCourier: false,
        updatingStatus: false,
        updatingStatus2: false,
        updatingId: "",

        filterStatus: "",
        filterCourier: "",

        StatusList: [
          "Incomplete",
          "Printed",
          "Case Collected",
          "Case & Print Done",
          "Sublimated",
          "Bag Ready",
          "Waiting for Customer",
          "Do not send today",
          "Parcelled",
          "Cancelled",
        ],
        SelectedStatusList: [
          "Incomplete",
          "Printed",
          "Case Collected",
          "Case & Print Done",
          "Sublimated",
          "Bag Ready",
          "Waiting for Customer",
          "Do not send today",
          "Parcelled",
          "Cancelled",
        ],

        StatusList2: ["Case Collected", "Case Not Collected"],

        SelectedStatusList2: ["Case Collected", "Case Not Collected"],

        SelectedCourierList: [],

        searched: false,
        searching: false,

        accordionid: "",

        actionEle: [],
        allActionEleSeleted: false,
        actionGoingOn: false,

        //for order details model popup
        UpdateModelOpen: false,
        UpdatingOrderId: "",
      };

      console.log(loggeduser());
    }

    ToggleAccordion(id) {
      if (this.state.accordionid == id) {
        this.setState({ accordionid: "" });
      } else {
        this.setState({ accordionid: id });
      }
    }

    componentWillMount() {
      sendToLoginIfNoAccess("orders", "view", this, "/reports/distribution");

      axios
        .get(baseurl() + "/models", {
          headers: { authorization: this.state.loggeduser.token },
        })
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.setState({ allModels: response.data.result });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

      axios
        .get(baseurl() + "/deliverypersons", {
          headers: { authorization: this.state.loggeduser.token },
        })
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.setState({ alldeliveryperson: response.data.result });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    componentDidMount() {
      this.LoadCourierList();
    }

    LoadCourierList() {
      setTimeout(() => {
        if (AppStore.courier.length == 0) {
          this.LoadCourierList();
        } else {
          var CourierList = this.GetCourierList(AppStore.courier);
          CourierList.push("Non Courier");
          this.setState({ SelectedCourierList: CourierList });
        }
      }, 1000);
    }

    clearLastOrderDate() {
      AppStore.lastAddedOrderDate = "";
      AppStore.loadActiveDate();
    }

    bulkOrderDateChange = (date) => {
      this.ToggleAction(this.state.actionEle, "orderDate", date);
    };

    changeStartDate = (startDate) => (AppStore.orderStartDate = startDate);

    changeEndDate = (endDate) => (AppStore.orderEndDate = endDate);

    sortBy(col, allOrder) {
      var newOrder = this.state.sortOrder === "asc" ? "desc" : "asc";
      if (this.state.sortOrder === "asc") {
        this.setState({ sortOrder: newOrder });
      } else {
        this.setState({ sortOrder: newOrder });
      }

      this.setState({ defaultSort: col });
    }

    StaticSortBy(col, allOrder) {
      this.setState({ defaultSort: col });
      var orderdata = _.orderBy(allOrder, [col], [this.state.sortOrder]);
      AppStore.orders = orderdata;
    }

    searchOrder() {
      if (AppStore.searchWord) {
        if (isNaN(AppStore.searchWord)) {
          var url = baseurl() + "/orders?customerName=" + AppStore.searchWord;
        } else {
          var url = baseurl() + "/orders?customerPhone=" + AppStore.searchWord;
        }
        axios
          .get(url, { headers: { authorization: this.state.loggeduser.token } })
          .then((response) => {
            console.log(response);
            if (response.data.success === true) {
              this.setState({ searched: true });
              var bandageOrder = BandageToStatus2(response.data.result);
              this.setState({ orderdata: bandageOrder });
            } else {
              notify("Could Not Search!", "#F44336");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        notify("Enter Customer Name or Phone Number!", "#F44336");
      }
    }

    numberOfproductByphone(products) {
      var totalProductByphone = 0;
      for (var i = 0; i < products.length; i++) {
        totalProductByphone =
          totalProductByphone + parseFloat(products[i].quantity);
      }
      return totalProductByphone;
    }

    initUpdateModel = (data) => {
      this.setState({
        UpdatingOrderId: data._id,
        UpdateModelOpen: true,
      });
    };

    InUpdateReturnFunction = () => {
      this.setState({ UpdateModelOpen: false, UpdatingOrderId: "" });
      AppStore.filterByDate(
        this.state.start,
        this.state.perpage,
        AppStore.orderStartDate,
        AppStore.orderEndDate
      );
    };

    UpdateOrder(singleOrder, resFun) {
      axios
        .put(
          baseurl() + "/orders/" + singleOrder._id,
          {
            orderDate: singleOrder.orderDate,
            customerName: singleOrder.customerName,
            customerAddress: singleOrder.customerAddress,
            customerArea: singleOrder.customerArea,
            customerPhone: singleOrder.customerPhone,
            deliveryPerson: singleOrder.deliveryPerson,
            deliveryPersonEmail: singleOrder.deliveryPersonEmail,
            deliveryPersonPhone: singleOrder.deliveryPersonPhone,
            courierCompany: singleOrder.courierCompany,
            products: singleOrder.products,
            discountTk: singleOrder.discountTk,
            discountPct: singleOrder.discountPct,
            payments: singleOrder.payments,
            notes: singleOrder.notes,
            status: singleOrder.status,
            status2: singleOrder.status2,
            total: singleOrder.total,
            due: singleOrder.due,
            conveyance: singleOrder.conveyance,
          },
          { headers: { authorization: this.state.loggeduser.token } }
        )
        .then((res) => {
          notify("Order Updated", "#0275d8");
          resFun(res);
        })
        .catch((err) => {
          notify("Could Not Update Order!", "#F44336");
          resFun(err);
        });
    }

    selectPerson(orderdata, personData) {
      this.setState({ updatingPerson: true });
      this.setState({ updatingId: orderdata._id });
      orderdata.deliveryPerson = personData.name;
      orderdata.deliveryPersonEmail = personData.email;
      orderdata.deliveryPersonPhone = personData.phone;
      this.UpdateOrder(orderdata, () => {
        AppStore.filterByDate(
          this.state.start,
          this.state.perpage,
          AppStore.orderStartDate,
          AppStore.orderEndDate
        );
        this.setState({ updatingPerson: false });
        this.setState({ updatingId: "" });
      });
    }

    selectCourier(orderdata, courierData) {
      this.setState({ updatingCourier: true });
      this.setState({ updatingId: orderdata._id });
      orderdata.courierCompany = courierData;
      if (courierData == "Pathao") {
        console.log(courierData);
        orderdata.conveyance = 0;
      }
      console.log(orderdata);
      this.UpdateOrder(orderdata, () => {
        AppStore.filterByDate(
          this.state.start,
          this.state.perpage,
          AppStore.orderStartDate,
          AppStore.orderEndDate
        );
        this.setState({ updatingPerson: false });
        this.setState({ updatingId: "" });
      });
    }

    selectStatus(orderdata, status) {
      this.setState({ updatingStatus: true });
      this.setState({ updatingId: orderdata._id });
      orderdata.status = status;
      this.UpdateOrder(orderdata, () => {
        AppStore.filterByDate(
          this.state.start,
          this.state.perpage,
          AppStore.orderStartDate,
          AppStore.orderEndDate
        );
        this.setState({ updatingStatus: false });
        this.setState({ updatingId: "" });
      });
    }

    selectStatus2(orderdata, status2) {
      this.setState({ updatingStatus2: true });
      this.setState({ updatingId: orderdata._id });
      orderdata.status2 = status2;
      this.UpdateOrder(orderdata, () => {
        AppStore.filterByDate(
          this.state.start,
          this.state.perpage,
          AppStore.orderStartDate,
          AppStore.orderEndDate
        );
        this.setState({ updatingStatus2: false });
        this.setState({ updatingId: "" });
      });
    }

    activePage(index) {
      if (this.state.activePage === index + 1) {
        return "page-active";
      }
    }

    orderByphone(phone) {
      axios
        .get(baseurl() + "/orders?customerPhone=" + phone, {
          headers: { authorization: this.state.loggeduser.token },
        })
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.setState({ orderByphone: response.data.result });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    pagination(pageNo) {
      var start = pageNo * this.state.perpage;
      AppStore.filterByDate(
        start,
        this.state.perpage,
        AppStore.orderStartDate,
        AppStore.orderEndDate
      );
      this.setState({ activePage: pageNo + 1 });
      this.setState({ start: start });
    }

    renderperson(singleOrder) {
      const deliveryPerson = this.state.alldeliveryperson.map(function (data) {
        return (
          <span
            key={data._id}
            onClick={this.selectPerson.bind(this, singleOrder, data)}
            className="dropdown-item btn btn-default"
            href="#"
          >
            {data.name}
          </span>
        );
      }, this);
      return deliveryPerson;
    }

    rendercourier(singleOrder) {
      const CourierList = AppStore.courier.map(function (data) {
        return (
          <span
            key={data._id}
            onClick={this.selectCourier.bind(this, singleOrder, data.name)}
            className="dropdown-item btn btn-default"
            href="#"
          >
            {data.name}
          </span>
        );
      }, this);
      return CourierList;
    }

    setActiveOrderImage(data) {
      this.setState({ activeOrderImage: data });
    }

    changeDeliveryPerson(e) {
      AppStore.activeDeliveryPerson = e.target.value;
    }

    changeInput(e) {
      var input = e.target;
      if (input.name === "searchWord") {
        this.setState({ searchWord: input.value });
      }
    }

    ChkSearchEnter = (e) => {
      if (e.keyCode == 13) {
        this.searchOrder();
      }
    };

    ClrSearch() {
      AppStore.searchWord = "";
      this.setState({ searched: false });
    }

    HandleSelect = (value) => {
      var SelectedStatusList = this.state.SelectedStatusList;
      if (value == "all") {
        var StatusList = JSON.parse(JSON.stringify(this.state.StatusList));
        var yesOrNo = true;
        for (var i = 0; i < StatusList.length; i++) {
          if (this.state.SelectedStatusList.indexOf(StatusList[i]) == -1) {
            yesOrNo = false;
            break;
          }
        }

        if (yesOrNo) {
          this.setState({ SelectedStatusList: [] });
        } else {
          this.setState({ SelectedStatusList: StatusList });
        }
      } else {
        if (this.state.SelectedStatusList.indexOf(value) == -1) {
          SelectedStatusList.push(value);
          this.setState({ SelectedStatusList: SelectedStatusList });
        } else {
          var index = this.state.SelectedStatusList.indexOf(value);
          SelectedStatusList.splice(index, 1);
          this.setState({ SelectedStatusList: SelectedStatusList });
        }
      }
    };

    HandleSelect2 = (value) => {
      var SelectedStatusList2 = this.state.SelectedStatusList2;
      if (value == "all") {
        var StatusList2 = JSON.parse(JSON.stringify(this.state.StatusList2));
        var yesOrNo = true;
        for (var i = 0; i < StatusList2.length; i++) {
          if (this.state.SelectedStatusList2.indexOf(StatusList2[i]) == -1) {
            yesOrNo = false;
            break;
          }
        }

        if (yesOrNo) {
          this.setState({ SelectedStatusList2: [] });
        } else {
          this.setState({ SelectedStatusList2: StatusList2 });
        }
      } else {
        if (this.state.SelectedStatusList2.indexOf(value) == -1) {
          SelectedStatusList2.push(value);
          this.setState({ SelectedStatusList2: SelectedStatusList2 });
        } else {
          var index = this.state.SelectedStatusList2.indexOf(value);
          SelectedStatusList2.splice(index, 1);
          this.setState({ SelectedStatusList2: SelectedStatusList2 });
        }
      }
    };

    HandleCourierSelect = (value) => {
      var SelectedCourierList = this.state.SelectedCourierList;
      if (value == "all") {
        var CourierList = JSON.parse(
          JSON.stringify(this.GetCourierList(AppStore.courier))
        );
        var yesOrNo = true;
        for (var i = 0; i < CourierList.length; i++) {
          if (this.state.SelectedCourierList.indexOf(CourierList[i]) == -1) {
            yesOrNo = false;
            break;
          }
        }

        if (yesOrNo) {
          this.setState({ SelectedCourierList: [] });
        } else {
          this.setState({ SelectedCourierList: CourierList });
        }
      } else {
        if (this.state.SelectedCourierList.indexOf(value) == -1) {
          SelectedCourierList.push(value);
          this.setState({ SelectedCourierList: SelectedCourierList });
        } else {
          var index = this.state.SelectedCourierList.indexOf(value);
          SelectedCourierList.splice(index, 1);
          this.setState({ SelectedCourierList: SelectedCourierList });
        }
      }
    };

    ChkOrderRenderByStatus(status) {
      // if( this.state.SelectedStatusList.indexOf(status) !== -1){
      // 	return true;
      // }
      var exist = false;
      for (var i = 0; i < this.state.SelectedStatusList.length; i++) {
        if (
          this.state.SelectedStatusList[i].toLowerCase() == status.toLowerCase()
        ) {
          exist = true;
          break;
        }
      }
      return exist;
    }

    ChkOrderRenderByStatus2(status2) {
      // if( this.state.SelectedStatusList.indexOf(status) !== -1){
      // 	return true;
      // }

      if (
        this.state.SelectedStatusList2.length ==
          this.state.StatusList2.length &&
        !status2
      ) {
        return true;
      }

      if (
        this.state.SelectedStatusList2.indexOf("Case Not Collected") > -1 &&
        !status2
      ) {
        return true;
      }

      if (
        this.state.SelectedStatusList2.length !==
          this.state.StatusList2.length &&
        !status2
      ) {
        return false;
      }

      var exist = false;
      for (var i = 0; i < this.state.SelectedStatusList2.length; i++) {
        if (
          this.state.SelectedStatusList2[i].toLowerCase() ==
          status2.toLowerCase()
        ) {
          exist = true;
          break;
        }
      }
      return exist;
    }

    ChkOrderRenderByCourier(courier) {
      // if( this.state.SelectedStatusList.indexOf(status) !== -1){
      // 	return true;
      // }
      if (typeof courier == "undefined") {
        return true;
      }

      if (
        courier == "" &&
        this.state.SelectedCourierList.indexOf("Non Courier") !== -1
      ) {
        return true;
      }
      var exist = false;
      for (var i = 0; i < this.state.SelectedCourierList.length; i++) {
        if (
          this.state.SelectedCourierList[i].toLowerCase() ==
          courier.toLowerCase()
        ) {
          exist = true;
          break;
        }
      }
      return exist;
    }

    GetCourierList(courier) {
      var list = ["Non Courier"];
      for (var i = 0; i < courier.length; i++) {
        list.push(courier[i].name);
      }

      return list;
    }

    TypingSearch(e) {
      AppStore.searchWord = e.target.value;

      this.setState({ searched: false });
    }

    DownloadRaw = (productData) => {
      var link = document.createElement("a");
      link.href =
        baseurl() +
        "/uploads/productimages/" +
        productData.productType.trim() +
        "/" +
        productData.productType.trim() +
        "-" +
        productData.code.trim() +
        ".png";
      document.body.appendChild(link);
      link.download =
        productData.productType.trim() + "-" + productData.code.trim() + ".png";
      link.target = "_blank";
      link.click();
      document.body.removeChild(link);
    };

    ToogleActionElementSelect = (data) => {
      var actionEle = JSON.parse(JSON.stringify(this.state.actionEle));

      var index = this.FindActionEleIndex(data);

      if (index == -1) {
        actionEle.push(data);
      } else {
        actionEle.splice(index, 1);
      }

      this.setState({ actionEle: actionEle });
    };

    FindActionEleIndex = (data) => {
      var actionEle = JSON.parse(JSON.stringify(this.state.actionEle));
      return actionEle.findIndex((ele) => {
        return ele._id == data._id;
      });
    };

    SelectAllForAction = (data) => {
      if (this.state.allActionEleSeleted) {
        this.setState({ actionEle: [] });
        this.setState({ allActionEleSeleted: false });
      } else {
        this.setState({ actionEle: data });
        this.setState({ allActionEleSeleted: true });
      }
    };

    ToggleAction = (actionEle, field, value) => {
      this.setState({ actionGoingOn: true });
      if (actionEle.length > 0) {
        actionEle[0][field] = value;
        this.UpdateOrder(actionEle[0], (res) => {
          if (res.data && res.data.success) {
            actionEle.splice(0, 1);
            if (actionEle.length > 0) {
              this.ToggleAction(actionEle, field, value);
            } else {
              AppStore.filterByDate(
                this.state.start,
                this.state.perpage,
                AppStore.orderStartDate,
                AppStore.orderEndDate
              );
              this.setState({ actionGoingOn: false });
            }
          } else {
            this.setState({ actionGoingOn: false });
          }
        });
      } else {
        this.setState({ actionGoingOn: false });
      }
    };

    getCoverImageByName = (name) => {
      return this.state.allModels
        .find(
          (model) =>
            model.name.trim().toLowerCase() ===
            name.replace("-", " ").trim().toLowerCase()
        )
        ?.coverImage?.toLowerCase();
    };

    wrapInDoubleQuotes = (str) => {
      return `"${str}"`;
    };

    render() {
      const pageItem = _.times(AppStore.totalpage, (i) => (
        <span
          className="page-no"
          id={this.activePage(i)}
          onClick={this.pagination.bind(this, i)}
          key={i}
        >
          {i + 1}
        </span>
      ));

      var NumberOfFileterOrder = 0;
      var TotalIncomplete = 0;
      var TotalPrinted = 0;
      var TotalParcelled = 0;
      var TotalWait = 0;

      this.PathaoCSVData = [
        [
          "ItemType",
          "StoreName",
          "MerchantOrderId",
          "RecipientName(*)",
          "RecipientPhone(*)",
          "RecipientCity(*)",
          "RecipientZone(*)",
          "RecipientArea",
          "RecipientAddress(*)",
          "AmountToCollect(*)",
          "ItemQuantity",
          "ItemWeight",
          "ItemDesc",
          "SpecialInstruction",
        ],
      ];

      this.eCourierCSVData = [];

      this.redxCSVData = [
        [
          "Invoice",
          "Customer Name",
          "Contact No.",
          "Customer Address",
          "District",
          "Area",
          "Area ID",
          "Division",
          "Price",
          "Instruction",
          "Product Selling Price",
          "Seller Name",
          "Seller Phone",
        ],
      ];

      var filteredOrders = _.orderBy(
        this.state.searched ? this.state.orderdata : AppStore.orders,
        [this.state.defaultSort],
        [this.state.sortOrder]
      ).filter((data) => {
        return (
          (AppStore.activeDeliveryPerson == "" ||
            AppStore.activeDeliveryPerson == data.deliveryPerson) &&
          this.ChkOrderRenderByStatus(data.status) &&
          this.ChkOrderRenderByStatus2(data.status2) &&
          this.ChkOrderRenderByCourier(data.courierCompany)
        );
      });

      const orderitems = filteredOrders.map(function (data, index) {
        let { status } = data;
        if (status == "Incomplete") {
          TotalIncomplete += 1;
        } else if (status == "Printed") {
          TotalPrinted += 1;
        } else if (status == "Parcelled") {
          TotalParcelled += 1;
        } else if (status == "Waiting for Customer") {
          TotalWait += 1;
        }

        NumberOfFileterOrder += 1;

        if (data.courierCompany == "Pathao") {
          this.PathaoCSVData.push([
            "parcel",
            "FabGear Shop",
            "",
            data.customerName.replace(/\n/g, " ").replace(/,/g, " "),
            data.customerPhone
              .replace(/-/g, "")
              .replace(/\n/g, " ")
              .replace(/,/g, " "),
            "Dhaka",
            data.customerArea.replace(/\n/g, " ").replace(/,/g, " "),
            "",
            data.customerAddress
              .replace(/\n/g, " ")
              .replace(/,/g, " ")
              .replace(/'/g, ""),
            data.due,
            data.products.length,
            "0.5",
            "Mobile Accessories",
            data.notes?.replace(/\n/g, " ").replace(/,/g, " "),
          ]);
        }

        if (data.courierCompany == "redx") {
          this.redxCSVData.push([
            data.orderNumber,
            data.customerName,
            data.customerPhone,
            data.customerAddress,
            "Dhaka",
            "",
            "",
            "Dhaka",
            data.due,
            data.notes,
            data.total,
            "FAB GEAR",
            "01892555866",
          ]);
        }

        if (data.courierCompany == "eCourier") {
          this.eCourierCSVData.push([
            data.customerName.replace(/\n/g, " "),
            data.customerPhone.replace(/\n/g, " "),
            data.customerAddress.replace(/\n/g, " "),
            data.customerArea.replace(/\n/g, " "),
            "Dhaka",
            "",
            0,
            "#3312",
            data.notes,
            data.due == 0 ? 1 : 0,
            data.due,
            "",
            "",
            "",
            data.products.length,
            "",
          ]);
        }

        if (window.innerWidth > 768) {
          return (
            <tr key={data._id}>
              <td>
                <div
                  className="actionSelectBtn"
                  onClick={() => this.ToogleActionElementSelect(data)}
                >
                  <img
                    src={
                      this.FindActionEleIndex(data) == -1
                        ? "/img/unchecked.png"
                        : "/img/checked.png"
                    }
                    className="icon-img"
                  />
                </div>
              </td>
              <td>{index + 1}</td>
              <td>
                {moment(data.orderDate).format("DD/MM/YYYY")}
                {" ( " + renderWeekDay(data.orderDate) + " ) "}
                <br />
                <br />
                Internal Notes:
                <br />
                <b className="text-danger">{data.internalNotes}</b>
                <br />
                <br />
                <b>Customer Notes:</b>
                <br />
                <span className="text-success">{data.customerNotes}</span>
                <br />
                <br />
                {moment(data.updatedAt).diff(
                  moment(data.createdAt),
                  "minutes"
                ) > 0 && (
                  <b style={{ fontSize: 10, color: "red" }}>
                    Last Updated:{" "}
                    {moment(data.updatedAt).format("DD/MM/YYYY hh:mm A")}
                  </b>
                )}
                <br />
                <span>Order added by: {data.addedBy}</span>
              </td>
              <td>
                <b style={{ color: "#000" }}>{data.customerName} </b> (
                {data.customerPhone}) <br />
                {data.customerAddress} <br />
                {moment(data.orderDate).format("DD/MM/YYYY") !==
                moment(data.createdAt).format("DD/MM/YYYY") ? (
                  <b className="text-danger">
                    Created:{moment(data.createdAt).format("DD/MM/YYYY")}
                  </b>
                ) : null}
                <p>
                  <b>Due:</b> {data.due} <b>Total:</b> {data.total}
                </p>
                {/* <p>Total: {data.total}</p> */}
              </td>
              <td
                className="text-center"
                style={{
                  backgroundColor:
                    data.deletedProducts && data.deletedProducts.length > 0
                      ? "#f2a8a2"
                      : "#fff",
                }}
              >
                <div className="row">
                  {/* <button onClick={this.setActiveOrderImage.bind(this, data.products)} className="btn btn-default" data-toggle="modal" data-target="#OrderImages" data-whatever="@getbootstrap" >See Images</button> */}
                  {data.products.map((productData, productDataIndex) => (
                    <div className="col-6 mt-1" key={productDataIndex}>
                      {productData.facebookImageLink && (
                        <a href={productData.facebookImageLink} target="_blank">
                          <img
                            key={index}
                            className="full-width"
                            src={productData.facebookImageLink}
                          />
                          <p
                            className="mt-3 text-center"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            {productData.productType.trim()}-
                            {productData.code.trim()} {productData.model}
                            {"  "}
                            <span className="text-danger">
                              ({productData.quantity}){" "}
                            </span>
                          </p>
                        </a>
                      )}

                      {!productData.facebookImageLink && (
                        <a href={GetImagePathNew(productData)} target="_blank">
                          {this.getCoverImageByName(productData.model) && (
                            <img
                              key={index}
                              src={
                                productData.productType === "CC" ||
                                productData.productType === "GC"
                                  ? `https://api.fabgearshop.com/uploads/productimages/models/${this.getCoverImageByName(
                                      productData.model
                                    )}`
                                  : "https://d3tw4n1db3z9d9.cloudfront.net/psmockup/psmockup.png"
                              }
                              style={{
                                backgroundImage: `url(${GetImagePathNew(
                                  productData
                                )})`,
                                backgroundSize:
                                  productData.productType === "CC" ||
                                  productData.productType === "GC"
                                    ? "100%"
                                    : "40%",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                              }}
                              alt="Product"
                              width={180}
                            />
                          )}
                          {!this.getCoverImageByName(productData.model) && (
                            <img
                              key={index}
                              src={GetImagePathNew(productData)}
                              alt="Product"
                              width={180}
                            />
                          )}
                          <p
                            className="mt-3 text-center"
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            {productData.productType}-{productData.code}{" "}
                            {productData.model.trim().replace(/-/g, " ")}{" "}
                            <span className="text-danger">
                              ({productData.quantity}){" "}
                            </span>
                          </p>
                        </a>
                      )}

                      {productData.productType == "customizer" && (
                        <a
                          className="btn btn-success"
                          target="_blank"
                          href={GetRowImage(productData)}
                        >
                          Download Raw
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <h4>Area: {data.customerArea}</h4>

                {this.state.updatingPerson &&
                data._id === this.state.updatingId ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="dropdown">
                    <button
                      className="btn btn-default dropdown-toggle"
                      type="button"
                      id="deliveryPerson"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {data.deliveryPerson}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="deliveryPerson"
                    >
                      <span
                        onClick={this.selectPerson.bind(this, data, {
                          name: "Select Delivery Person",
                          email: "",
                          phone: "",
                        })}
                        className="dropdown-item btn btn-default"
                        href="#"
                      >
                        No Delivery Person
                      </span>
                      {this.renderperson(data)}
                    </div>
                  </div>
                )}

                <br />

                {this.state.updatingCourier &&
                data._id === this.state.updatingId ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="dropdown">
                    <button
                      className="btn btn-info dropdown-toggle"
                      type="button"
                      id="deliveryPerson"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {data.courierCompany
                        ? data.courierCompany
                        : "Select Courier"}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="deliveryPerson"
                    >
                      <span
                        onClick={this.selectCourier.bind(this, data, "")}
                        className="dropdown-item btn btn-default"
                        href="#"
                      >
                        No Courier
                      </span>
                      {this.rendercourier(data)}
                    </div>
                  </div>
                )}
              </td>
              <td>
                <div className="">
                  {data.sendMockup && (
                    <b className="text-danger">
                      {" "}
                      Send Mock Up within{" "}
                      {moment(data.mockupDate).format("DD/MM/YYYY")}{" "}
                    </b>
                  )}

                  {data.tasks.filter((ele) => {
                    return ele.complete === false;
                  }).length > 0 && (
                    <h3 className="text-danger mt-2">
                      {
                        data.tasks.filter((ele) => {
                          return ele.complete === false;
                        }).length
                      }{" "}
                      Incomplete Tasks
                    </h3>
                  )}
                </div>
              </td>
              {hasAccess("orders", "edit") && (
                <td>
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {/* <Link target="_blank" title="Update Order" onClick={()=> AppStore.fromDistribution = false} className="btn btn-primary" to={ "editOrder/" + data._id} > <i className="fa fa-pencil" aria-hidden="true"></i></Link>  */}

                    {hasAccess("orders", "edit") && (
                      <Button onClick={() => this.initUpdateModel(data)}>
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </Button>
                    )}

                    {/*{data.facebookLink &&*/}
                    {/*	<a title="Inbox" target='_blank' className="btn btn-secondary" href={ AppStore.fbbusinessHost + data.facebookLink} ><i className="fa fa-comments" aria-hidden="true"></i></a>*/}
                    {/*}*/}

                    <Link
                      title="Print Invoice"
                      target="_blank"
                      className="btn btn-warning"
                      to={"invoice/" + data._id}
                    >
                      <i className="fa fa-print" aria-hidden="true"></i>
                    </Link>

                    <Link
                      title="Copy Order"
                      className="btn btn-secondary"
                      to={{
                        pathname: "/addOrder",
                        state: {
                          isCoping: true,
                          copiedOrderData: JSON.parse(JSON.stringify(data)),
                        },
                      }}
                    >
                      <i className="fa fa-copy" aria-hidden="true"></i>
                    </Link>
                  </div>
                  <div className="text-center mt-1">
                    {this.state.updatingStatus &&
                    data._id === this.state.updatingId ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div className="dropdown">
                        <button
                          className={
                            "btn " +
                            GetStatusClr(data.status) +
                            " dropdown-toggle"
                          }
                          type="button"
                          id="orderStatus"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {data.status}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="orderStatus"
                        >
                          {this.state.StatusList.map((singleStatus, index) => (
                            <span
                              onClick={this.selectStatus.bind(
                                this,
                                data,
                                singleStatus
                              )}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              {singleStatus}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="text-center mt-1">
                    {this.state.updatingStatus2 &&
                    data._id === this.state.updatingId ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div className="dropdown">
                        <button
                          className={
                            "btn " +
                            GetStatusClr(data.status2) +
                            " dropdown-toggle"
                          }
                          type="button"
                          id="orderStatus"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {data.status2}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="orderStatus"
                        >
                          {this.state.StatusList2.map((singleStatus, index) => (
                            <span
                              onClick={this.selectStatus2.bind(
                                this,
                                data,
                                singleStatus
                              )}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              {singleStatus}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </td>
              )}
            </tr>
          );
        } else {
          return (
            <div className="ordersingle-drop">
              <div
                onClick={() => this.ToggleAccordion(data._id)}
                className="accordion"
              >
                {data.customerName}
                <i class="fa fa-chevron-down pull-right"></i>
              </div>
              <div
                class="panel"
                style={{
                  padding: 15,
                  display:
                    this.state.accordionid == data._id ? "block" : "none",
                  maxHeight: this.state.accordionid == data._id ? "none" : 0,
                }}
              >
                <h5>{moment(data.orderDate).format("DD/MM/YYYY")}</h5>
                <h5>{data.customerPhone}</h5>
                <h5>{data.customerAddress}</h5>
                <h5>Area: {data.customerArea}</h5>
                <h5 className="text-danger">Notes: {data.internalNotes}</h5>

                {this.state.updatingStatus &&
                data._id === this.state.updatingId ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="dropdown">
                    <button
                      className={
                        "btn " +
                        GetStatusClr(data.status) +
                        " dropdown-toggle full-in-sm"
                      }
                      type="button"
                      id="orderStatus"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {data.status}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="orderStatus"
                    >
                      {this.state.StatusList.map((singleStatus, index) => (
                        <span
                          onClick={this.selectStatus.bind(
                            this,
                            data,
                            singleStatus
                          )}
                          className="dropdown-item btn btn-default"
                          href="#"
                        >
                          {singleStatus}
                        </span>
                      ))}
                    </div>
                  </div>
                )}

                {this.state.updatingPerson &&
                data._id === this.state.updatingId ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="dropdown ">
                    <button
                      className="btn btn-default dropdown-toggle full-in-sm"
                      type="button"
                      id="deliveryPerson"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {data.deliveryPerson}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="deliveryPerson"
                    >
                      <span
                        onClick={this.selectPerson.bind(this, data, {
                          name: "Select Delivery Person",
                          email: "",
                          phone: "",
                        })}
                        className="dropdown-item btn btn-default"
                        href="#"
                      >
                        No Delivery Person
                      </span>
                      {this.renderperson(data)}
                    </div>
                  </div>
                )}

                {this.state.updatingCourier &&
                data._id === this.state.updatingId ? (
                  <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="dropdown">
                    <button
                      className="btn btn-info dropdown-toggle full-in-sm"
                      type="button"
                      id="deliveryPerson"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {data.courierCompany
                        ? data.courierCompany
                        : "Select Courier"}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="deliveryPerson"
                    >
                      <span
                        onClick={this.selectCourier.bind(this, data, "")}
                        className="dropdown-item btn btn-default"
                        href="#"
                      >
                        No Courier
                      </span>
                      {this.rendercourier(data)}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    margin: 10,
                  }}
                >
                  {/* <Link target='_blank' title="Update Order" onClick={()=> AppStore.fromDistribution = false} className="btn btn-primary" to={ "editOrder/" + data._id} > <i className="fa fa-pencil" aria-hidden="true"></i></Link>  */}

                  <Button onClick={() => this.initUpdateModel(data)}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                  </Button>

                  <Link
                    title="Print Invoice"
                    target="_blank"
                    className="btn btn-warning"
                    to={"invoice/" + data._id}
                  >
                    <i className="fa fa-print" aria-hidden="true"></i>
                  </Link>
                  <Link
                    title="Copy Order"
                    className="btn btn-secondary"
                    to={{
                      pathname: "/addOrder",
                      state: {
                        isCoping: true,
                        copiedOrderData: JSON.parse(JSON.stringify(data)),
                      },
                    }}
                  >
                    <i className="fa fa-copy" aria-hidden="true"></i>
                  </Link>
                </div>
                <div className="row text-center">
                  {/* <button onClick={this.setActiveOrderImage.bind(this, data.products)} className="btn btn-default" data-toggle="modal" data-target="#OrderImages" data-whatever="@getbootstrap" >See Images</button> */}
                  {data.products.map((productData, productDataIndex) => (
                    <div className="col-6 mt-1" key={productDataIndex}>
                      <a
                        href={(
                          baseurl() +
                          "/uploads/productimages/" +
                          productData.productType.trim() +
                          "/" +
                          productData.productType.trim() +
                          "-" +
                          productData.code.trim() +
                          ".jpg"
                        ).toLowerCase()}
                        target="_blank"
                      >
                        <img
                          key={index}
                          className="full-width"
                          src={(
                            baseurl() +
                            "/uploads/productimages/" +
                            productData.productType.trim() +
                            "/" +
                            productData.productType.trim() +
                            "-" +
                            productData.code.trim() +
                            ".jpg"
                          ).toLowerCase()}
                        />
                        <p
                          className="mt-3 text-center"
                          style={{
                            fontSize: 16,
                            fontWeight: "bold",
                            color: "#000",
                          }}
                        >
                          {productData.productType.trim()}-
                          {productData.code.trim()} {productData.model}{" "}
                          <span className="text-danger">
                            ({productData.quantity}){" "}
                          </span>{" "}
                        </p>
                      </a>
                      {productData.productType == "customizer" && (
                        <a
                          className="btn btn-success"
                          target="_blank"
                          href={
                            baseurl() +
                            "/uploads/productimages/" +
                            productData.productType.trim() +
                            "/" +
                            productData.productType.trim() +
                            "-" +
                            productData.code.trim() +
                            ".png"
                          }
                        >
                          Download Raw
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      }, this);

      const orderByphone = this.state.orderByphone.map(function (data, index) {
        return (
          <tr>
            <td>{moment(data.orderDate).format("DD/MM/YYYY")}</td>
            <td>{data.notes}</td>
            <td>{this.numberOfproductByphone(data.products)}</td>
            <td>{data.total}</td>
            <td>{data.due}</td>
          </tr>
        );
      }, this);

      const alldeliveryperson = this.state.alldeliveryperson.map(
        (data, index) => {
          return (
            <option key={index} value={data.name}>
              {data.name}
            </option>
          );
        }
      );

      return (
        <div className="products">
          <Sidebar orders="active" ordershow="show" />
          <div className="page">
            <Header />
            <div className="container-fluid">
              <div
                className="row pt-3 pb-3"
                style={{ borderBottom: "1px solid #ddd" }}
              >
                <div className="col-sm-12 col-md-5 mb-2">
                  <div className="row">
                    <div className="col-9">
                      <input
                        type="text"
                        name="searchWord"
                        value={AppStore.searchWord}
                        className="form-control"
                        placeholder="Search"
                        onChange={(e) => this.TypingSearch(e)}
                        onKeyDown={(e) => this.ChkSearchEnter(e)}
                      />
                    </div>
                    <div className="col-3">
                      {this.state.searched ? (
                        <button
                          onClick={this.ClrSearch.bind(this)}
                          className="btn btn-danger"
                        >
                          <i className="fa fa-times"></i>
                        </button>
                      ) : (
                        <button
                          onClick={this.searchOrder.bind(this)}
                          className="btn btn-default"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-5 mb-2 select-date-range">
                  <DatePicker
                    selected={moment(AppStore.orderStartDate)}
                    onChange={this.changeStartDate.bind(this)}
                    dateFormat="DD/MM/YYYY"
                  />
                  <label className="mt-1">To</label>
                  <DatePicker
                    className="adsasd"
                    selected={moment(AppStore.orderEndDate)}
                    onChange={this.changeEndDate.bind(this)}
                    dateFormat="DD/MM/YYYY"
                  />
                  <button
                    className="btn btn-success search-btn"
                    onClick={() =>
                      AppStore.filterByDate.bind(
                        this,
                        this.state.start,
                        this.state.perpage,
                        AppStore.orderStartDate,
                        AppStore.orderEndDate
                      )
                    }
                  >
                    <i className="fa fa-search"></i>
                  </button>
                </div>
                <div className="col-sm-12 col-md-2 mb-2 text-right">
                  {/* <button onClick={() => AppStore.selecttoday.bind(this)} className="btn btn-default mr-2">Clear Search</button> */}
                  <Link className="btn btn-primary full-in-sm" to="/addOrder">
                    Add Order
                  </Link>

                  {this.state.actionGoingOn ? (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div>
                      <div className="dropdown pull-left">
                        <button
                          className="btn btn-default dropdown-toggle"
                          type="button"
                          id="actionOfSelected"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Action
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="actionOfSelected"
                        >
                          <span
                            onClick={() =>
                              this.ToggleAction(
                                this.state.actionEle,
                                "status",
                                "Incomplete"
                              )
                            }
                            className="dropdown-item btn btn-default"
                          >
                            Incomplete
                          </span>
                          <span
                            onClick={() =>
                              this.ToggleAction(
                                this.state.actionEle,
                                "status",
                                "Printed"
                              )
                            }
                            className="dropdown-item btn btn-default"
                          >
                            Printed
                          </span>
                          <span
                            onClick={() =>
                              this.ToggleAction(
                                this.state.actionEle,
                                "status",
                                "Parcelled"
                              )
                            }
                            className="dropdown-item btn btn-default"
                          >
                            Parcelled
                          </span>
                          <span
                            onClick={() => {}}
                            className="dropdown-item btn btn-default"
                          >
                            <CSVLink data={this.PathaoCSVData}>
                              Pathao CSV
                            </CSVLink>
                          </span>
                          <span
                            onClick={() => {}}
                            className="dropdown-item btn btn-default"
                          >
                            <CSVLink data={this.eCourierCSVData}>
                              eCourier CSV
                            </CSVLink>
                          </span>
                          <span
                            onClick={() => {}}
                            className="dropdown-item btn btn-default"
                          >
                            <CSVLink data={this.redxCSVData}>Redx CSV</CSVLink>
                          </span>
                        </div>
                      </div>
                      <DatePicker
                        dateFormat="DD/MM/YYYY"
                        onChange={(date) => this.bulkOrderDateChange(date)}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div
                className="row pt-3 pb-3"
                style={{ borderBottom: "1px solid #ddd" }}
              >
                <div className="col-12">
                  <div className="row">
                    <div className="order-filter-header col-12 col-sm-12 col-md-5">
                      <span className="total-order-badge">
                        Total : {AppStore.totalOrder}
                      </span>
                      {/* <span className='ml-3'>Filter Order : {NumberOfFileterOrder}</span> */}

                      <span
                        className={
                          TotalIncomplete > 0
                            ? "total-order-badge badge-danger"
                            : "total-order-badge"
                        }
                      >
                        Incomplete : {TotalIncomplete}
                      </span>
                      <span className="total-order-badge">
                        Printed : {TotalPrinted}
                      </span>
                      <span className="total-order-badge">
                        Parcelled : {TotalParcelled}
                      </span>
                      <span className="total-order-badge">
                        Waiting For Customer: {TotalWait}
                      </span>
                    </div>

                    <div className="order-filter-header col-12 col-sm-12 col-md-7">
                      <div className="m-1">
                        <select
                          value={AppStore.activeDeliveryPerson}
                          className="form-control"
                          onChange={this.changeDeliveryPerson.bind(this)}
                        >
                          <option value="">All Delivery Person</option>
                          {alldeliveryperson}
                        </select>
                      </div>
                      <div className="m-1">
                        <div className="dropdown">
                          <button
                            className="btn btn-default dropdown-toggle pull-right"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {AppStore.activeOrderDate}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <option
                              onClick={() => AppStore.selecttoday()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              Today
                            </option>
                            <option
                              onClick={() => AppStore.selectyesterady()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              Yesterday
                            </option>
                            <option
                              onClick={() => AppStore.selectTwoDaysAge()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              2 Day Ago
                            </option>
                            <option
                              onClick={() => AppStore.selectTomorrowady()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              Tomorrow
                            </option>
                            <option
                              onClick={() => AppStore.selectTwoDayslater()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              2 Day Later
                            </option>
                            <option
                              onClick={() => AppStore.selectweek()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              This Week
                            </option>
                            <option
                              onClick={() => AppStore.selectmonth()}
                              className="dropdown-item btn btn-default"
                              href="#"
                            >
                              This month
                            </option>
                          </div>
                        </div>
                      </div>
                      <div className="m-1">
                        <button
                          className="btn bt-success"
                          onClick={() =>
                            this.setState({
                              SelectedStatusList: ["Incomplete", "Printed"],
                            })
                          }
                        >
                          Incomplete & Printed
                        </button>
                      </div>
                      <div className="m-1">
                        <MultiSelect
                          title="Status"
                          data={this.state.StatusList}
                          selected={this.state.SelectedStatusList}
                          HandleSelect={this.HandleSelect}
                        />
                      </div>
                      <div className="m-1">
                        <MultiSelect
                          title="Status"
                          data={this.state.StatusList2}
                          selected={this.state.SelectedStatusList2}
                          HandleSelect={this.HandleSelect2}
                        />
                      </div>
                      <div className="m-1">
                        <MultiSelect
                          title="Courier"
                          data={this.GetCourierList(AppStore.courier)}
                          selected={this.state.SelectedCourierList}
                          HandleSelect={this.HandleCourierSelect}
                        />
                      </div>

                      {AppStore.lastAddedOrderDate && (
                        <button
                          type="button"
                          onClick={this.clearLastOrderDate.bind(this)}
                          className="btn btn-primary pull-right"
                        >
                          Clear Order Date
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3 pb-3">
                <div className="col-12 table-responsive">
                  {!this.state.searched && (
                    <div className="row mb-3">
                      <div className="col-12">{pageItem}</div>
                    </div>
                  )}
                  {window.innerWidth > 768 ? (
                    <table
                      className="table table-bordered table-responsive "
                      id="orderTable"
                      style={{ maxHeight: 700 }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <div
                              className="actionSelectBtn"
                              onClick={() =>
                                this.SelectAllForAction(filteredOrders)
                              }
                            >
                              <img
                                src={
                                  this.state.allActionEleSeleted
                                    ? "/img/checked.png"
                                    : "/img/unchecked.png"
                                }
                                className="icon-img"
                              />
                            </div>
                          </th>
                          <th>Serial</th>
                          <th
                            style={{ width: 200 }}
                            onClick={this.sortBy.bind(this, "orderDate")}
                          >
                            Date & Notes
                          </th>
                          <th
                            style={{ width: 200 }}
                            onClick={this.sortBy.bind(this, "customerName")}
                          >
                            Customer Details
                          </th>
                          {/* <th>Total Product</th> */}
                          {/* <th>Amount</th> */}
                          <th style={{ width: 400, minWidth: 400 }}>Images</th>
                          {/* <th  onClick={this.sortBy.bind(this,'customerPhone')}>Phone Number</th> */}
                          <th
                            onClick={this.sortBy.bind(this, "deliveryPerson")}
                          >
                            Delivery Info
                          </th>
                          <th onClick={this.sortBy.bind(this, "sendMockup")}>
                            Tasks
                          </th>
                          {hasAccess("customers", "edit") && (
                            <th>Order Action</th>
                          )}
                        </tr>
                      </thead>
                      <tbody>{orderitems}</tbody>
                    </table>
                  ) : (
                    <div className="orders-dropdown">{orderitems}</div>
                  )}
                  {!this.state.searched && (
                    <div className="row mb-3">
                      <div className="col-12">{pageItem}</div>
                    </div>
                  )}
                  <div className="row">
                    <div
                      className="modal fade"
                      id="portfolioModal"
                      role="dialog"
                      aria-labelledby="portfolioModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-body">
                            <table className="table table-bordered table-responsive">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Notes</th>
                                  <th>Product Qty</th>
                                  <th>Total</th>
                                  <th>Due</th>
                                </tr>
                              </thead>
                              <tbody>{orderByphone}</tbody>
                            </table>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
          <OrderImage images={this.state.activeOrderImage} />

          <Modal
            className="modal-container"
            show={this.state.UpdateModelOpen}
            size="lg"
            onHide={() =>
              this.setState({ UpdateModelOpen: false, UpdatingOrderId: "" })
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Order</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <AddOrder
                fromDistribution={true}
                UpdatingOrderId={this.state.UpdatingOrderId}
                returnFunction={() => this.InUpdateReturnFunction()}
              />
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
);

export default Orders;
