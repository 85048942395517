import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {observer} from "mobx-react";
import {CSVLink} from "react-csv";

const InventoryReport = observer(class InventoryReport extends Component {

    puschaseListCSV = [['model', 'quantity']]

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			type:'',
			model:'',
			code:'',
			quantity:'',

            models:[],
            modelSearch:'',

            inventory:[],
            waste:[],
            used:[],
            lastThreeMonthOrders:[],



		};
	}

	componentDidMount(){
		sendToLoginIfNoAccess("products","edit", this, "/Inventory" , { headers: { authorization: this.state.loggeduser.token } });
        this.LoadInv();
        this.LoadWaste();
        this.LoadOrderReport();
        this.loadModels()
    }

    loadModels = () => {
        axios.get(baseurl()+'/models')
        .then(res => {
            if(res.data.success){
                this.setState({models:res.data.result})
            }
        })
    }

    LoadInv(){
        axios.get(baseurl()+'/inventory/report', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({inventory : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }

    LoadWaste = () => {
        axios.get(baseurl()+'/waste/report', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({waste : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }

    LoadOrderReport = () => {
        axios.get(baseurl()+'/orders/inventory/inventoryreport', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({used : response.data.result});
				this.setState({lastThreeMonthOrders : response.data.lastThreeMonthOrders});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }



    GetWaste = (model) => {
        var waste  = this.state.waste.find(ele => ele._id == model);

        const modelInfo = this.state.models.find(ele => ele.name.toLowerCase() == model.toLowerCase());

        var total = 0

        if(modelInfo && modelInfo.similarModels && modelInfo.similarModels.length > 0){

            for (let index = 0; index < modelInfo.similarModels.length; index++) {

                var  innerData = this.state.waste.find(ele => ele._id.toLowerCase() == modelInfo.similarModels[index].toLowerCase())

                if(innerData && innerData.total){
                    total += innerData.total
                }

            }

        }

        if(waste && waste.total){
            total += waste.total
        }
        return total;
    }

    GetUsed = (model) => {

        var used  = this.state.used.find(ele => ele._id == model);

        const modelInfo = this.state.models.find(ele => ele.name.toLowerCase() == model.toLowerCase());

        var total = 0

        if(modelInfo && modelInfo.similarModels && modelInfo.similarModels.length > 0){

            for (let index = 0; index < modelInfo.similarModels.length; index++) {

                var  innerData = this.state.used.find(ele => ele._id.toLowerCase() == modelInfo.similarModels[index].toLowerCase())

                if(innerData && innerData.total){
                    total += innerData.total
                }

            }

        }

        if(used && used.total){
            total += used.total
        }

        return total;

    }

    GetUsedLastThreeMonths = (model) => {

        var lastThree  = this.state.lastThreeMonthOrders.find(ele => ele._id == model);

        const modelInfo = this.state.models.find(ele => ele.name.toLowerCase() == model.toLowerCase());

        var total = 0

        if(modelInfo && modelInfo.similarModels && modelInfo.similarModels.length > 0){

            for (let index = 0; index < modelInfo.similarModels.length; index++) {

                var  innerData = this.state.lastThreeMonthOrders.find(ele => ele._id.toLowerCase() == modelInfo.similarModels[index].toLowerCase())

                if(innerData && innerData.total){
                    total += innerData.total
                }

            }

        }

        if(lastThree && lastThree.total){
            total += lastThree.total
        }

        return total;

    }

    ChkModelisChildren = (modelName) => {
        var yesOrNo = false
        for (let index = 0; index < this.state.models.length; index++) {

            if(this.state.models[index].similarModels.indexOf(modelName) > -1){
                yesOrNo = true
                break;
            }

        }
        return yesOrNo
    }

  render() {
    this.puschaseListCSV = [['model', 'quantity']]

  	const jsonitems=this.state.inventory.map(function(data){

        var wasted = this.GetWaste(data._id);
        var used = this.GetUsed(data._id);
        var UsedLastThree = this.GetUsedLastThreeMonths(data._id);

        var inStock = data.total - wasted - used;

        var parchaseReq = inStock > UsedLastThree ? 0 : UsedLastThree - inStock;

        const modelInfo = this.state.models.find(ele => ele.name == data._id)

        if(!this.ChkModelisChildren(data._id)){

            if(parchaseReq){
                this.puschaseListCSV.push([
                    data._id,
                    parchaseReq
                ])
            }

            return(
                <tr key={data._id}>
                    <td>
                        {data._id}
                        {(modelInfo && modelInfo.similarModels && modelInfo.similarModels.length) > 0 ?
                            <>
                                <h5>Similar Models:</h5>

                                {modelInfo.similarModels.map((simiData, simiIndex) =>
                                    <h6 key={simiIndex} >{simiData}</h6>
                                )}
                            </>
                        : null}
                    </td>
                    <td>{data.total}</td>
                    <td>{wasted}</td>
                    <td>{used}</td>
                    <td>{inStock}</td>
                    <td>{UsedLastThree}</td>
                    <td>{inStock > UsedLastThree ? 'No' : "Yes"}</td>
                    <td>{inStock > UsedLastThree ? 0 : UsedLastThree - inStock}</td>
                </tr>
            );

        }

    }, this);

    return (
        <div >
        	<Sidebar inventoryReport = "active" inventoryshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Inventory" style={{marginTop:10}}>
                <div className="row">
                        <div className="col-12">
                            <button
                                type="button"
                                className="btn btn-primary spd-mt-30 spd-mb-10"
                                onClick={() => this.setState({showBlukAdd:true})}
                             >
                                <CSVLink style={{color:'#fff'}} data={this.puschaseListCSV}>Dowload Required Purchanse List</CSVLink>

                            </button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    {/* <th>Type</th> */}
                                    <th>Models</th>
                                    <th>Total Add Stock</th>
                                    <th>Total Waste</th>
                                    <th>Total Used</th>
                                    <th>In Stock</th>
                                    <th>Last Three Month</th>
                                    <th>Purchase Required</th>
                                    <th>Number of Purchase Required</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {jsonitems}
                                </tbody>
                            </table>
                        </div>
                    </div>
	        	</div>
                <ToastContainer />
	        </div>
        </div>
    );
  }
})

export default InventoryReport;
