import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {Button, Form, Modal, Table} from 'react-bootstrap'
import {observer} from "mobx-react";
import AppStore from './store/AppStore'
import * as XLSX from 'xlsx';
import {Select} from 'antd'

import StringMatch from 'string-similarity'

const Inventory = observer(class Inventory extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			type:'',
			model:'',
			code:'',
			quantity:'',
            Inventory:[],

            filterModel:'',
            filterCode:'',
            filterType:'',

            showBlukAdd:false,

            models:[],
            modelSearch:'',

            showCSVModal:false,
            CSVData:[],
            CSVsearch:''
		};
	}

	componentDidMount(){
		sendToLoginIfNoAccess("products","edit", this, "/Inventory" , { headers: { authorization: this.state.loggeduser.token } });
        this.getData();
        this.loadModels()
    }



    loadModels = () => {
        axios.get(baseurl()+'/models')
        .then(res => {
            if(res.data.success){
                this.setState({models:res.data.result})
            }
        })
    }

    getData(){
        // console.log(this.state);
        // var extraUrl = '';
        // if(this.state.filterCode !== ''){
        //     extraUrl = '?code='+this.state.filterCode;
        // }else if(this.state.filterType !== '' && this.state.filterModel !== ''){
        //     extraUrl = '?type='+this.state.filterType+'&model='+this.state.filterModel;
        // }
        axios.get(baseurl()+'/inventory/report', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({Inventory : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }

    addData(){
        if(this.state.model !== '' && this.state.code && this.state.type !== '' && this.state.quantity){
            axios.post(baseurl()+'/Inventory', {
                type: this.state.type,
                code: this.state.code,
                quantity: this.state.quantity,
                model: this.state.model,
            } , { headers: { authorization: this.state.loggeduser.token } })
            .then((response) => {
                console.log(response);
                if(response.data.success === true){
                    this.setState({Inventory : response.data.result});
                    notify("Model Added", "#28a745");
                }else{
                    notify("Could Not Save", "#F44336");
                }
            })
            .catch(function (error) {
                notify("Could Not Save", "#F44336");
            });
        }else{
            notify("Fill Empty", "#F44336");
        }

    }

    addBulk = (withCSV =  false) => {


        if(withCSV){
            var formattedData = (this.state.CSVData.filter(ele => ele.quantity && ele.model)).map(ele => {
                return { model: ele.model, type: 'CC', quantity: ele.quantity}
            })
        }else{
            var formattedData = (this.state.models.filter(ele => ele.quantity && ele.name)).map(ele => {
                return { model: ele.name, type: 'CC', quantity: ele.quantity}
            })
        }

        console.log(formattedData)

        let wrongModelFound = false

        for (let index = 0; index < formattedData.length; index++) {
            console.log()
            if(!this.state.models.find(ele => ele.name == formattedData[index].model )){
                wrongModelFound = true
                break;
            }

        }

        if(wrongModelFound){
            notify('Please fix the error!','#F44336');
            return null;
        }

        // console.log(formattedData)
        // return null;

        axios.post(baseurl() + '/inventory/multiple/add', {
            data:formattedData
        })
        .then(res => {
            this.setState({showBlukAdd:false, showCSVModal:false})
            if(res.data.success){
                this.getData()
            }
            notify("Success", "#28a745");

        })
        .catch(e => {
            this.setState({showBlukAdd:false, showCSVModal:false})
            notify("Could Not Save", "#F44336");
        })
    }

    Getquantity = (name) => {

        var models = JSON.parse(JSON.stringify(this.state.models))

        var index = models.findIndex(ele => ele.name === name);

        if(index > -1){

            return models[index].quantity

        }

        return '';

    }

    Updatequantity = (value, name) => {

        console.log("eddd");

        var models = JSON.parse(JSON.stringify(this.state.models))

        var index = models.findIndex(ele => ele.name === name);

        if(index > -1){

            models[index].quantity = value

            this.setState({models:models}, () => {

                console.log(this.state.models)

            })

            // console.log(models)

        }


    }

    UpCSVModel = (value, index) => {

        var data = JSON.parse(JSON.stringify(this.state.CSVData))

        data[index].model = value;

        this.setState({CSVData:data})


    }

    processData = (dataString) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
          const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
          if (headers && row.length == headers.length) {
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
              let d = row[j];
              if (d.length > 0) {
                if (d[0] == '"')
                  d = d.substring(1, d.length - 1);
                if (d[d.length - 1] == '"')
                  d = d.substring(d.length - 2, 1);
              }
              if (headers[j]) {
                obj[headers[j]] = d;
              }
            }

            // remove the blank rows
            if (Object.values(obj).filter(x => x).length > 0) {
              list.push(obj);
            }
          }
        }

        // prepare columns list from headers
        const columns = headers.map(c => ({
          name: c,
          selector: c,
        }));

        // setData(list);
        // setColumns(columns);
        // console.log(list)
        // console.log(columns)
        this.setState({showCSVModal:true})

        var allModels = this.state.models.map(ele => ele.name);

        for (let index = 0; index < list.length; index++) {

            console.log(list[index].model)

            list[index].model = StringMatch.findBestMatch(list[index].model, allModels).bestMatch.target

        }

        this.setState({CSVData:list})

    }

    OnUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
        /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            this.processData(data);
        };
        reader.readAsBinaryString(file);
    }

    ChkModel = (name) => {
        return this.state.models.find(ele => ele.name == name)
    }

    ChkModelisChildren = (modelName) => {
        var yesOrNo = false
        for (let index = 0; index < this.state.models.length; index++) {

            if(this.state.models[index].similarModels.indexOf(modelName) > -1){
                yesOrNo = true
                break;
            }

        }
        return yesOrNo
    }


  render() {
  	const jsonitems=this.state.Inventory.map(function(data){
        const modelInfo = this.state.models.find(ele => ele.name == data._id)

            if(!this.ChkModelisChildren(data._id)){
                return(
                    <tr key={data._id}>
                    {/* <td>{data.type}</td> */}
                    {/* <td>{data.code}</td> */}
                    <td>
                        {data._id}
                        {(modelInfo && modelInfo.similarModels && modelInfo.similarModels.length) > 0 ?
                            <>
                                <h5 className="mt-2" >Similar Models:</h5>

                                {modelInfo.similarModels.map((simiData, simiIndex) =>
                                    <h6 key={simiIndex} >{simiData}</h6>
                                )}
                            </>
                        : null}
                    </td>
                    <td>{data.total}</td>
                    </tr>
                );
            }
    }, this);

    const productTypes = AppStore.productTypes.map((data, index)=>{
        return (
            <option key={index} value={data.name} >{data.name}</option>
        );
    });

    const productModel = AppStore.models.map((data, index)=>{
        return (
            <option key={index} value={data.name} >{data.name}</option>
        );
    });

    return (
        <div >
        	<Sidebar inventory = "active" inventoryshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Inventory">
                    <div className="row">
                        <div className="col-3">
                            <button
                                type="button"
                                className="btn btn-primary spd-mt-30 spd-mb-10"
                                onClick={() => this.setState({showBlukAdd:true})}
                             >
                                 Add Bulk Inventory
                            </button>

                            <Modal
                                show={this.state.showBlukAdd}
                                onHide={() => this.setState({showBlukAdd:false})}
                                // size='lg'
                            >
                                <Modal.Header closeButton>
                                <Modal.Title>Add Bulk Inventory</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{height:400, overflow:'auto'}}>
                                    <Form.Control
                                        type="text"
                                        placeholder="Search Model"
                                        className='mb-2'
                                        value={this.state.modelSearch}
                                        onChange={e => this.setState({modelSearch:e.target.value})}
                                    />
                                    <Table striped bordered hover  >
                                        <thead>
                                            <tr>
                                            <th>Model Name</th>
                                            <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.models.filter(ele => ele.name.toLowerCase().indexOf(this.state.modelSearch.toLowerCase()) > -1 ).map((modelData, index) => {

                                                if(!this.ChkModelisChildren(modelData.name)){
                                                    return(
                                                        <tr key={index} >
                                                            <td>{modelData.name}</td>
                                                            <td>
                                                                {modelData.quantity}<br/>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    size='sm'
                                                                    // value={this.Getquantity(modelData.name)}
                                                                    value={modelData.quantity}
                                                                    onChange={(e) => this.Updatequantity(e.target.value, modelData.name)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                }

                                            })}
                                        </tbody>
                                        </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({showBlukAdd:false})}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => this.addBulk()}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div className="col-6 text-right spd-mt-30 spd-mb-10" >
                            <h4 className='m-0' >Upload CSV or Excel File For Bulk Add</h4>
                        </div>
                        <div className="col-3 spd-mt-30 spd-mb-10" >
                            <Form.Group controlId="formFileSm" className="mb-3">
                                <Form.Control
                                    type="file"
                                    size="sm"
                                    onChange={e => this.OnUpload(e)}
                                    accept=".csv,.xlsx,.xls"

                                />
                            </Form.Group>
                            <Modal
                                show={this.state.showCSVModal}
                                onHide={() => this.setState({showCSVModal:false})}
                                // size='lg'
                            >
                                <Modal.Header closeButton>
                                <Modal.Title>Add Bulk Inventory</Modal.Title>
                                </Modal.Header>
                                <Modal.Body style={{height:400, overflow:'auto'}}>
                                    <Table striped bordered hover  >
                                        <thead>
                                            <tr>
                                            <th>Current Selected Model</th>
                                            <th>Select Model</th>
                                            <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.CSVData.map((modelData, index) => {
                                                if(!this.ChkModelisChildren(modelData.model)){
                                                    return(
                                                        <tr key={index} >
                                                            <td>
                                                                {modelData.model}
                                                            </td>
                                                            <td style={{backgroundColor:this.ChkModel(modelData.model) ? '' : 'red'}} >
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: 200 }}
                                                                    placeholder="Select A Model"
                                                                    onChange={(value) => this.UpCSVModel(value, index)}
                                                                    value={modelData.model}
                                                                >
                                                                    {this.state.models.map((cModelData, cModelIndex) =>

                                                                        <Select.Option key={cModelIndex} value={cModelData.name}>{cModelData.name}</Select.Option>

                                                                    )}
                                                                </Select>
                                                            </td>
                                                            <td>
                                                                {modelData.quantity}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                        </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="secondary" onClick={() => this.setState({showCSVModal:false})}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => this.addBulk(true)}>
                                    Save Changes
                                </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th>Models</th>
                                    <th>Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {jsonitems}
                                </tbody>
                            </table>
                        </div>
                    </div>
	        	</div>
                <ToastContainer />
	        </div>
        </div>
    );
  }
})

export default Inventory;
