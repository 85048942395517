import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';
import _ from 'lodash';
import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';
import {Button, Col, Row} from 'react-bootstrap';

const emptyEle = {
	name: "",
    title: "",
    imageLink:"",
    productTypeToPurchase:"",
    numberRequired:"",
    minimumPriceOfEachProductPurchase:"",
    productTypeForFree:"",
    numberFree:"",
    deliveryChargeInsideDhaka:"",
    deliveryChargeOutsideDhaka:"",
    minimumCartValue:"",
	storeCredit:"",
	discountTk:"",
	discountPercent:"",
	qualifyingProducts:[]
}

const Offers = observer(class Offers extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			update:false,
			eleData:emptyEle,
			Offers:[],

			multipleOffers:'',

			addingType:'',
			addingCode:'',

			bulkText:''
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/offers" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID

		this.LoadEle();
	 }

	 LoadEle = () => {
		axios.get(baseurl()+'/offers', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({offers : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	 }

	loadaddData(){
		this.setState({eleData : emptyEle});
		this.setState({update : false});
	}

	loadeditData(data){
		this.setState({eleData : data});
		this.setState({update : true});
	}

	addData(){
		axios.post(baseurl()+'/offers', this.state.eleData , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({offers : response.data.result});
				notify("Offer Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/offers/'+this.state.eleData._id, this.state.eleData, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({offers : response.data.result});
				notify("Offer Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/offers/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({Offers : response.data.result});
				AppStore.Offers = response.data.result;
				notify('Model Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	onChangeFile(e) {

		var imageData = e.target.files[0]

		console.log(imageData)

		const url = baseurl()+'/images';
		const data = new FormData();
		data.append('file',imageData);
		data.append('folder',"offers");
		axios.post(url,data, { headers: { authorization: this.state.loggeduser.token } })
		.then( (response) => {
			console.log(response)
			if(response.data.success){
				this.UpdateEleData({imageLink:response.data.modelFileName})
			}else{
				notify("File Didn't Uploaded!",'#F44336');
			}
		})
		.catch((error)=> {
		    console.log(error);
		    notify("File Didn't Uploaded!",'#F44336');
		});
	}

	UpdateEleData = (data) => {

		var updatingData = JSON.parse(JSON.stringify(this.state.eleData))
		updatingData = {...updatingData, ...data}
		this.setState({eleData: updatingData})

	}

	AddQulified = () => {
		let {addingCode, addingType} = this.state
		if(addingCode, addingType){
			var qualifyingProducts = this.state.eleData.qualifyingProducts;
			qualifyingProducts.push({
				code:addingCode,
				type:addingType
			})
			this.UpdateEleData({qualifyingProducts:qualifyingProducts})
		}
	}

	AddBulkQulified = () => {

		let {bulkText} = this.state;
		var bulkTextArray = bulkText.trim().split(',');
		var qualifyingProducts = this.state.eleData.qualifyingProducts;

		for (let index = 0; index < bulkTextArray.length; index++) {

			var text = bulkTextArray[index].trim();

			qualifyingProducts.push({
				code:text.replace(text.split('-')[0]+'-',''),
				type:text.split('-')[0]
			})

		}

		this.UpdateEleData({qualifyingProducts:qualifyingProducts})
	}

	RemoveQulified = (index) => {
		var qualifyingProducts = this.state.eleData.qualifyingProducts;
		qualifyingProducts.splice(index, 1)
		this.UpdateEleData({qualifyingProducts:qualifyingProducts})
	}

  render() {

	// _.orderBy(this.state.Offers, [Offers => Offers.name.toLowerCase()], ['asc']);
  	const jsonitems=(_.orderBy(this.state.offers, [Offers => Offers.name.toLowerCase()], ['asc'])).map(function(data){
			return(
				<tr key={data._id}>
				  <td>
				  	{data.imageLink ?
					  <img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/Offers/'+data.imageLink).toLowerCase()} />
					  :
					  <p>Image Not Avaliable</p>
					}
				  </td>
			      <td>{data.name}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
	}, this);

    return (
        <div >
        	<Sidebar saleOffer = "active" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Offers">
					<div className="row">
						<div className="col-12">
							<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Offers</button>

							<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="portfolioModalLabel">{this.state.update ? "Update" : "Add"}</h5>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">

											<div className="form-group">
												<label htmlFor="Name">Name</label>
												<input type="text" className="form-control" value={this.state.eleData.name} onChange={(e) => this.UpdateEleData({name:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="imageupload">Offer Image</label>
												<input className="form-control" type="file" onChange={this.onChangeFile.bind(this)} />
											</div>

											{this.state.eleData.imageLink &&
												<img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/offers/'+this.state.eleData.imageLink).toLowerCase()} />
											}

											<div className="form-group">
												<label htmlFor="Name">Title</label>
												<input type="text" className="form-control" value={this.state.eleData.title} onChange={(e) => this.UpdateEleData({title:e.target.value})}/>
											</div>



											<h2>Conditions</h2>

											<div className="form-group">
												<label htmlFor="Name">Product Type To Purchase</label>
												<select
													onChange={(e) => this.UpdateEleData({productTypeToPurchase:e.target.value})}
													value={this.state.eleData.productTypeToPurchase}
													className="form-control"
												>
													<option value=''>Select Product Type</option>
													{AppStore.productTypes.map((data) =>
														<option value={data.name} >{data.name}</option>
													)}
												</select>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Number of Purchase Required</label>
												<input type="number" className="form-control" value={this.state.eleData.numberRequired} onChange={(e) => this.UpdateEleData({numberRequired:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Minimum Price Of Each Product Purchase</label>
												<input type="number" className="form-control" value={this.state.eleData.minimumPriceOfEachProductPurchase} onChange={(e) => this.UpdateEleData({minimumPriceOfEachProductPurchase:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">minimum Cart Value</label>
												<input type="number" className="form-control" value={this.state.eleData.minimumCartValue} onChange={(e) => this.UpdateEleData({minimumCartValue:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name" style={{fontSize:16}}><b>Qualified Products</b></label>

											</div>

											<div style={{flexDirection:'row', flexWrap:'wrap', display:'flex', marginBottom:10}}>
												{this.state.eleData.qualifyingProducts.map((data, index) =>
													<div key={index} onClick={() => this.RemoveQulified(index)} style={{border:'1px solid #000', padding:10, borderRadius:30, margin:5, cursor:'pointer'}}>{data.type}-{data.code} <i className='fa fa-times'></i></div>
												)}

												{this.state.eleData.qualifyingProducts.length == 0 &&
													<span>No Qualified Product Added</span>
												}
											</div>

											<Row>
												<Col>
													<div className="form-group">
														<label htmlFor="Name">Select Type</label>
														<select onChange={(e) => this.setState({addingType:e.target.value})} value={this.state.addingType} className="form-control">
															<option value=''>Select Product Type</option>
															{AppStore.productTypes.map((data) =>
																<option value={data.name} >{data.name}</option>
															)}
														</select>
													</div>
												</Col>
												<Col>
													<div className="form-group">
														<label htmlFor="Name">Code</label>
														<input type="text" className="form-control" value={this.state.addingCode} onChange={(e) => this.setState({addingCode:e.target.value})}/>
													</div>
												</Col>
												<Col>
													<Button onClick={() => this.AddQulified()} style={{marginTop:30}} >ADD</Button>
												</Col>
											</Row>

											<Row>
												<Col xs={10}>
													<div className="form-group">
														<label style={{display:'block'}} htmlFor="Name">Add bulk qualified product</label>
														<textarea style={{display:'block', width:'100%'}} rows={3} onChange={e => this.setState({bulkText:e.target.value})}>
																{this.state.bulkText}
														</textarea>
													</div>
												</Col>
												<Col xs={2}>
													<Button onClick={() => this.AddBulkQulified()} style={{marginTop:30}} >ADD</Button>
												</Col>
											</Row>

											<h2>Offers</h2>

											<div className="form-group">
												<label htmlFor="Name">Free Product Type</label>
												<select
													onChange={(e) => this.UpdateEleData({productTypeForFree:e.target.value})}
													value={this.state.eleData.productTypeForFree}
													className="form-control"
												>
													<option value=''>Select Product Type</option>
													{AppStore.productTypes.map((data) =>
														<option value={data.name} >{data.name}</option>
													)}
												</select>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Number Free</label>
												<input type="number" className="form-control" value={this.state.eleData.numberFree} onChange={(e) => this.UpdateEleData({numberFree:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Discount Tk</label>
												<input type="number" className="form-control" value={this.state.eleData.discountTk} onChange={(e) => this.UpdateEleData({discountTk:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Discount Percent</label>
												<input type="number" className="form-control" value={this.state.eleData.discountPercent} onChange={(e) => this.UpdateEleData({discountPercent:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Delivery Charge Inside Dhaka</label>
												<input type="number" className="form-control" value={this.state.eleData.deliveryChargeInsideDhaka} onChange={(e) => this.UpdateEleData({deliveryChargeInsideDhaka:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Delivery Charge Outside Dhaka</label>
												<input type="number" className="form-control" value={this.state.eleData.deliveryChargeOutsideDhaka} onChange={(e) => this.UpdateEleData({deliveryChargeOutsideDhaka:e.target.value})}/>
											</div>

											<div className="form-group">
												<label htmlFor="Name">storeCredit</label>
												<input type="number" className="form-control" value={this.state.eleData.storeCredit} onChange={(e) => this.UpdateEleData({storeCredit:e.target.value})}/>
											</div>



										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
											<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.state.update ? this.editData.bind(this) : this.addData.bind(this)} >{this.state.update ? "Update" : "Add"} Offers</button>
										</div>
									</div>
								</div>
							</div>
						{/* Popup Modal Code End */}
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{width:200}}>Offer Image</th>
										<th>Name</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{jsonitems}
								</tbody>
							</table>
						</div>
					</div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default Offers;
