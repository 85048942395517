import React, {Component} from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';

class resetpassword extends Component {
	constructor(props){
		super(props);
		this.state={
			password:'',
			confirmPassword:'',
			resetToken: '',
			redirectTologin: false,
		};
	}

	componentWillMount(){
		var resetToken = this.props.match.params.id;
		if(resetToken !== undefined){
				this.varifyToken(resetToken);
		}else{
			notify("Invalid Token", "#dc3545");
		}
	}

	varifyToken(resetToken){
		console.log(resetToken);
		axios.get(baseurl()+'/account/verifyresettoken/'+resetToken)
		  .then((response)=> {
			    if(response.data.success === true){
			    	this.setState({resetToken : resetToken})
		      	}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "password"){
			this.setState({password : input.value});
		}
		if(input.name === "confirmPassword"){
			this.setState({confirmPassword : input.value});
		}
	}

	resetpassword(e){
		axios.post(baseurl()+'/account/reset/'+this.state.resetToken, {
		    password:this.state.password,
		    confirmPassword:this.state.confirmPassword,
		  })
		  .then((response)=> {
			    if(response.data.success === true){
		      	console.log(response);
		      	notify("Password Changed Successfully", "#28a745");
		      	setTimeout( () =>{this.setState({ redirectTologin: true })} , 3000);
		      }
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

		 e.preventDefault();
	}

	render() {
		const { redirectTologin } = this.state;
	    if (redirectTologin) {
	      return <Redirect to='/login'/>;
	    }

		return (
		    <div className="page login-page">
		      <div className="container">
		        <div className="text-center">
		          	<div className="row">
		          	{(this.state.resetToken !== '') &&
			          	<div className="form-inner col-md-6 col-md-offset-3 recover-password">
				            <div className="logo text-uppercase"><strong className="text-primary">Reset password</strong></div>
				            <form id="recoverpassword-form" >
				              <div className="form-group">
				                <input id="password" type="password" placeholder="password" name="password" required="" value={this.state.password}  onChange={this.changeInput.bind(this)} />
				              </div>
				              <div className="form-group">
				                <input id="confirmPassword" type="password" placeholder="confirmPassword" name="confirmPassword" required="" value={this.state.confirmPassword}  onChange={this.changeInput.bind(this)} />
				              </div>
				              <input onClick={this.resetpassword.bind(this)} type="submit" id="recoverpassword" className="btn btn-primary"  />

				            </form>
			            	<Link to="/login" className="forgot-pass">Login or Signup ..?</Link>
			          	</div>
			         }
		          	</div>
		          	<div className="copyrights text-center">
		            	<p>Design by Server Oasis</p>
		          	</div>
		        </div>
		      </div>
		      <ToastContainer />
		    </div>
		);
	}
}

export default resetpassword;
