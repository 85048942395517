import React, { Component } from "react";
import Sidebar from "./sidebar.js";
import Header from "./header.js";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import _ from "lodash";
import {
  AddBlackList,
  baseurl,
  GetStatusClr,
  GetURLParams,
  notify,
  setlog,
} from "./utility/utility.js";
import { loggeduser, sendToLoginIfNoAccess } from "./utility/security";
import { confirmAlert } from "react-confirm-alert";
import OrderTask from "./components/OrderTask";
import { observer } from "mobx-react";
import AppStore from "./store/AppStore";
import Autocomplete from "./components/Autocomplete.js";
import { DeepCompare } from "./utility/modifications.js";

const addOrder = observer(
  class addOrder extends Component {
    tempData = {
      products: [],
      discountPct: 0,
      discountTk: 0,
      deliveryFee: 70,
    };

    constructor(props) {
      super(props);
      this.state = {
        requesting: false,
        watchChange: false,
        mode: "Add",
        UpdateOrderCreatedAt: moment(),
        orderDate: moment(),
        orderNumber: "",
        facebookLink: "",
        fbSearchText: "",
        customerName: "",
        customerAddress: "",
        customerEmail: "",
        customerNotes:"",
        allcustomerArea: [],
        customerArea: "Select Customer Area",
        customerPhone: "",
        sendMockup: false,
        mockupDate: moment(),
        tasks: [],
        internalNotes: "",
        alldeliveryperson: [],
        deliveryPerson: "Select Delivery Person",
        deliveryPersonEmail: "",
        deliveryPersonPhone: "",
        courierCompany: "",
        notes: "",
        total: 0,
        loggeduser: loggeduser(),
        payments: [],
        allproducts: [],
        products: [],
        deletedProducts: [],
        discountTk: 0,
        discountPct: 0,
        deliveryFee: 70,
        status: "Incomplete",
        status2: "Case Not Collected",
        addedBy: "",
        modifications: [],
        paymenttype: "Cash",
        paymentAmount: "",
        due: 0,
        redirect: false,
        redirectToOrderEntry: false,
        conveyance: 60,
        orderByphone: [],
        productsBeforeUpdate: [],

        addproductType: AppStore.productTypes[0]
          ? AppStore.productTypes[0].name
          : "CC",
        addproductCode: "",
        addproductModel: "",
        addproductPrice: AppStore.productTypes[0]
          ? AppStore.productTypes[0].price
          : 500,

        searchProductModel: "",
        searchArea: "",
        addArea: "",

        existInBlackList: false,

        addingModelName: "",
        addingModel: false,

        SameDateOrder: [],

        accordionid: "",

        prevoiusId: "",

        dataBeforeUpdate: {},
      };

      this.LoadSameDateOrder(moment());
    }

    ToggleAccordion(id) {
      console.log(id);
      if (this.state.accordionid === id) {
        this.setState({ accordionid: "" });
      } else {
        this.setState({ accordionid: id });
      }
    }

    confirmBlock = () => {
      if (!this.state.customerPhone) {
        notify("Invalid Phone Number", "#dc3545");
        return null;
      }
      confirmAlert({
        title: "Confirm Block",
        message: "Are you sure to do this.",
        confirmLabel: "Confirm",
        cancelLabel: "Cancel",
        onConfirm: () =>
          AddBlackList(
            AppStore,
            this.state.loggeduser.token,
            this.state.customerPhone
          ),
      });
    };

    LoadSameDateOrder = (date) => {
      var startDate = moment(date).format("YYYY/MM/DD");
      var endDate = moment(date).format("YYYY/MM/DD");

      axios
        .get(
          baseurl() + "/orders?startDate=" + startDate + "&endDate=" + endDate,
          { headers: { authorization: this.state.loggeduser.token } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            //this.setState({orderdata  : this.sortBy('customerName', response.data.result)});
            this.setState({ SameDateOrder: response.data.result });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    AddNewModel() {
      this.setState({ addingModel: true });
      axios
        .post(
          baseurl() + "/models",
          {
            name: this.state.addingModelName,
          },
          { headers: { authorization: this.state.loggeduser.token } }
        )
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.setState({
              addingModel: false,
              addproductModel: this.state.addingModelName,
            });
            AppStore.models = response.data.result;
            notify("Model Added", "#28a745");
          } else {
            notify("Could Not Add Model", "#dc3545");
            this.setState({ addingModel: false });
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({ addingModel: false });
        });
    }

    changeDate = (newDate) => {
      let { UpdateOrderCreatedAt, mode } = this.state;

      this.setState({ orderDate: newDate });
      this.LoadSameDateOrder(newDate);
    };

    saveOrder() {
      this.setState({ requesting: true });
      if (this.state.mode === "Add") {
        axios
          .post(
            baseurl() + "/customers",
            {
              name: this.state.customerName,
              phone: this.state.customerPhone,
              address: this.state.customerAddress,
            },
            { headers: { authorization: this.state.loggeduser.token } }
          )
          .then((response) => {
            if (response.data.success === true) {
              axios
                .post(
                  baseurl() + "/orders",
                  {
                    facebookLink: this.state.facebookLink,
                    fbSearchText: this.state.fbSearchText,
                    orderDate: this.state.orderDate,
                    customerId: response.data.lastAdded._id,
                    customerName: this.state.customerName.trim(),
                    customerEmail: this.state.customerEmail,
                    customerAddress: this.state.customerAddress,
                    customerArea: this.state.customerArea,
                    customerPhone: this.state.customerPhone.trim(),
                    sendMockup: this.state.sendMockup,
                    mockupDate: this.state.mockupDate,
                    tasks: this.state.tasks,
                    internalNotes: this.state.internalNotes,
                    deliveryPerson: this.state.deliveryPerson,
                    deliveryPersonEmail: this.state.deliveryPersonEmail,
                    deliveryPersonPhone: this.state.deliveryPersonPhone,
                    courierCompany: this.state.courierCompany,
                    products: this.state.products,
                    deletedProducts: this.state.deletedProducts,
                    discountTk: this.state.discountTk,
                    discountPct: this.state.discountPct,
                    deliveryFee: this.state.deliveryFee,
                    payments: this.state.payments,
                    notes: this.state.notes,
                    status: this.state.status,
                    status2: this.state.status2,
                    total: this.state.total,
                    due: this.state.due,
                    conveyance: this.state.conveyance,
                  },
                  { headers: { authorization: this.state.loggeduser.token } }
                )
                .then((response) => {
                  if (response.data.success === true) {
                    console.log(response);
                    // return null;
                    AppStore.lastAddedOrderDate = this.state.orderDate;
                    setlog(
                      "Order added Customer Name: " +
                        this.state.customerName +
                        " Phone: " +
                        this.state.customerPhone,
                      "order add"
                    );
                    notify("Order Added", "#28a745");

                    if (
                      this.props.location.state &&
                      this.props.location.state.orderEntryData &&
                      this.props.location.state.orderEntryData._id
                    ) {
                      let { orderEntryData } = this.props.location.state;
                      orderEntryData.orders.push(response.data.lastAdded._id);
                      orderEntryData.order = false;
                      orderEntryData.urgent = false;
                      axios
                        .put(
                          baseurl() + "/fbchats/" + orderEntryData._id,
                          orderEntryData,
                          {
                            headers: {
                              authorization: this.state.loggeduser.token,
                            },
                          }
                        )
                        .then((res) => {
                          if (res.data.success) {
                            this.setState({ redirectToOrderEntry: true });
                          }

                          this.setState({ requesting: false });
                          // this.setState({ redirect: true });
                        })
                        .catch((error) => {
                          this.setState({ saving: false });
                          console.log(error);

                          this.setState({ requesting: false });
                        });
                    } else {
                      this.setState({ redirect: true });
                    }
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.setState({ requesting: false });
                });
            } else {
              this.setState({ requesting: false });
            }
          })
          .catch(function (error) {
            console.log(error);
            this.setState({ requesting: false });
          });
      } else if (this.state.mode === "Update") {
        var orderId = this.props.UpdatingOrderId
          ? this.props.UpdatingOrderId
          : this.props.match.params.id;

        var modifications = JSON.parse(
          JSON.stringify(this.state.modifications)
        );

        console.log(modifications);

        modifications.push({
          user: this.state.loggeduser.user.email,
          modifications: DeepCompare(this.state.dataBeforeUpdate, this.state),
        });

        console.log(modifications);
        // return null

        axios
          .put(
            baseurl() + "/orders/" + orderId,
            {
              facebookLink: this.state.facebookLink,
              fbSearchText: this.state.fbSearchText,
              orderDate: this.state.orderDate,
              customerName: this.state.customerName.trim(),
              orderNumber: this.state.orderNumber,
              customerEmail: this.state.customerEmail,
              customerAddress: this.state.customerAddress,
              customerArea: this.state.customerArea,
              customerPhone: this.state.customerPhone.trim(),
              sendMockup: this.state.sendMockup,
              mockupDate: this.state.mockupDate,
              tasks: this.state.tasks,
              internalNotes: this.state.internalNotes,
              deliveryPerson: this.state.deliveryPerson,
              deliveryPersonEmail: this.state.deliveryPersonEmail,
              deliveryPersonPhone: this.state.deliveryPersonPhone,
              courierCompany: this.state.courierCompany,
              products: this.state.products,
              deletedProducts: this.state.deletedProducts,
              discountTk: this.state.discountTk,
              discountPct: this.state.discountPct,
              deliveryFee: this.state.deliveryFee,
              payments: this.state.payments,
              notes: this.state.notes,
              status: this.state.status,
              status2: this.state.status2,
              total: this.state.total,
              due: this.state.due,
              conveyance: this.state.conveyance,
              modifications: modifications,
              loggedUserEmail: this.state.loggeduser.user.email,
            },
            { headers: { authorization: this.state.loggeduser.token } }
          )
          .then((response) => {
            console.log(response);
            if (this.props.fromDistribution) {
              this.props.returnFunction();
            }
            if (response.data.success === true) {
              axios
                .put(baseurl() + "/customers/" + this.state.customerId, {
                  name: this.state.customerName,
                  orderNumber: this.state.orderNumber,
                  phone: this.state.customerPhone,
                  address: this.state.customerAddress,
                })
                .then((response) => {
                  if (response.data.success === true) {
                    setlog(
                      "Order Updated Order Number: " +
                        this.state.orderNumber +
                        " Customer Name: " +
                        this.state.customerName +
                        " Phone: " +
                        this.state.customerPhone,
                      "order update"
                    );
                    notify("Order Updated", "#28a745");
                    this.setState({ requesting: false });
                    if (this.props.fromDistribution) {
                      this.props.returnFunction();
                    } else {
                      this.setState({ redirect: true });
                    }
                  } else {
                    this.setState({ requesting: false });
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  this.setState({ requesting: false });
                });
            } else {
              this.setState({ requesting: false });
            }
          })
          .catch(function (error) {
            console.log(error);
            this.setState({ requesting: false });
          });
      }
    }

    loadOrderData(id) {
      axios
        .get(baseurl() + "/orders/" + id, {
          headers: { authorization: this.state.loggeduser.token },
        })
        .then((response) => {
          console.log(response);
          if (response.data.success === true) {
            this.setState({
              dataBeforeUpdate: {
                facebookLink: response.data.result.facebookLink,
                fbSearchText: response.data.result.fbSearchText,
                orderDate: response.data.result.orderDate,
                customerId: response.data.result.customerId,
                customerName: response.data.result.customerName,
                orderNumber: response.data.result.orderNumber,
                customerEmail: response.data.result.customerEmail,
                customerAddress: response.data.result.customerAddress,
                customerPhone: response.data.result.customerPhone,
                customerArea: response.data.result.customerArea,
                customerNotes: response.data.result.customerNotes,
                sendMockup: response.data.result.sendMockup,
                mockupDate: response.data.result.mockupDate,
                tasks: response.data.result.tasks,
                internalNotes: response.data.result.internalNotes,
                deliveryPerson: response.data.result.deliveryPerson,
                deliveryPersonEmail: response.data.result.deliveryPersonEmail,
                deliveryPersonPhone: response.data.result.deliveryPersonPhone,
                courierCompany: response.data.result.courierCompany,
                notes: response.data.result.notes,
                payments: response.data.result.payments,
                products: response.data.result.products,
                deletedProducts: response.data.result.deletedProducts,
                productsBeforeUpdate: response.data.result.products,
                discountTk: response.data.result.discountTk,
                discountPct: response.data.result.discountPct,
                deliveryFee: response.data.result.deliveryFee,
                status: response.data.result.status,
                status2: response.data.result.status2,
                total: response.data.result.total,
                due: response.data.result.due,
                conveyance: response.data.result.conveyance,
                addedBy: response.data.result.addedBy,
                modifications: response.data.result.modifications,
              },
            });

            this.setState({ mode: "Update" });
            this.setState({
              UpdateOrderCreatedAt: response.data.result.orderDate,
            });

            this.setState({ facebookLink: response.data.result.facebookLink });
            this.setState({ fbSearchText: response.data.result.fbSearchText });
            this.setState({ orderDate: response.data.result.orderDate });
            this.setState({ customerId: response.data.result.customerId });
            this.setState({ customerName: response.data.result.customerName });
            this.setState({ orderNumber: response.data.result.orderNumber });
            this.setState({
              customerEmail: response.data.result.customerEmail,
            });
            this.setState({
              customerAddress: response.data.result.customerAddress,
            });
            this.setState({
              customerPhone: response.data.result.customerPhone,
            });
            this.setState({ customerArea: response.data.result.customerArea });
            this.setState({ customerNotes: response.data.result.customerNotes });
            this.setState({ sendMockup: response.data.result.sendMockup });
            this.setState({ mockupDate: response.data.result.mockupDate });
            this.setState({ tasks: response.data.result.tasks });
            this.setState({
              internalNotes: response.data.result.internalNotes,
            });
            this.setState({
              deliveryPerson: response.data.result.deliveryPerson,
            });
            this.setState({
              deliveryPersonEmail: response.data.result.deliveryPersonEmail,
            });
            this.setState({
              deliveryPersonPhone: response.data.result.deliveryPersonPhone,
            });
            this.setState({
              courierCompany: response.data.result.courierCompany,
            });
            this.setState({ notes: response.data.result.notes });
            this.setState({ payments: response.data.result.payments });
            this.setState({ products: response.data.result.products });
            this.setState({
              deletedProducts: response.data.result.deletedProducts,
            });
            this.setState({
              productsBeforeUpdate: response.data.result.products,
            });
            this.setState({ discountTk: response.data.result.discountTk });
            this.setState({ discountPct: response.data.result.discountPct });
            this.setState({ deliveryFee: response.data.result.deliveryFee });
            this.setState({ status: response.data.result.status });
            this.setState({ status2: response.data.result.status2 });
            this.setState({ total: response.data.result.total });
            this.setState({ due: response.data.result.due });
            this.setState({ conveyance: response.data.result.conveyance });
            this.setState({ addedBy: response.data.result.addedBy });
            this.setState({
              modifications: response.data.result.modifications,
            });

            this.LoadByPhone(response.data.result.customerPhone);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    LoadByPhone(phone) {
      axios
        .get(baseurl() + "/orders?customerPhone=" + phone)
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ orderByphone: response.data.result });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onChange = (orderDate) => this.setState({ orderDate });

    componentWillReceiveProps(props) {
      if (
        props.fromDistribution &&
        props.UpdatingOrderId !== this.state.prevoiusId
      ) {
        this.setState({ prevoiusId: props.UpdatingOrderId });
        var orderId = props.UpdatingOrderId;
        this.loadOrderData(orderId);
      }
    }

    componentWillMount() {
      console.log(this);
      sendToLoginIfNoAccess("orders", "add", this, "/addorder");

      var orderId = this.props.UpdatingOrderId
        ? this.props.UpdatingOrderId
        : this.props.match.params.id;

      if (orderId !== undefined) {
        this.loadOrderData(orderId);
      } else {
        if (AppStore.lastAddedOrderDate) {
          this.setState({ orderDate: AppStore.lastAddedOrderDate });
        }

        var urlParams = GetURLParams(decodeURIComponent(window.location.href));

        if (
          urlParams &&
          urlParams.name &&
          urlParams.phone &&
          urlParams.address
        ) {
          this.setState({ customerName: urlParams.name });
          this.setState({ customerPhone: urlParams.phone });
          this.setState({ customerAddress: urlParams.address });
          this.setState({
            internalNotes: urlParams.notes ? urlParams.notes : "",
          });
        }

        if (this.props.location.state && this.props.location.state.isCoping) {
          let { copiedOrderData } = this.props.location.state;
          if (copiedOrderData.facebookLink) {
            this.setState({ facebookLink: copiedOrderData.facebookLink });
          }
          if (copiedOrderData.fbSearchText) {
            this.setState({ fbSearchText: copiedOrderData.fbSearchText });
          }
          if (copiedOrderData.customerId) {
            this.setState({ customerId: copiedOrderData.customerId });
          }
          if (copiedOrderData.customerName) {
            this.setState({ customerName: copiedOrderData.customerName });
          }
          if (copiedOrderData.orderNumber) {
            this.setState({ orderNumber: copiedOrderData.orderNumber });
          }
          if (copiedOrderData.customerEmail) {
            this.setState({ customerEmail: copiedOrderData.customerEmail });
          }
          if (copiedOrderData.customerAddress) {
            this.setState({ customerAddress: copiedOrderData.customerAddress });
          }
          if (copiedOrderData.customerPhone) {
            this.setState({ customerPhone: copiedOrderData.customerPhone });
          }
          if (copiedOrderData.customerArea) {
            this.setState({ customerArea: copiedOrderData.customerArea });
          }
          if (copiedOrderData.sendMockup) {
            this.setState({ sendMockup: copiedOrderData.sendMockup });
          }
          if (copiedOrderData.mockupDate) {
            this.setState({ mockupDate: copiedOrderData.mockupDate });
          }
          if (copiedOrderData.tasks) {
            this.setState({ tasks: copiedOrderData.tasks });
          }
          if (copiedOrderData.internalNotes) {
            var urgent =
              this.props.location.state.orderEntryData &&
              this.props.location.state.orderEntryData.urgent
                ? " urgent"
                : "";
            this.setState({
              internalNotes: copiedOrderData.internalNotes + urgent,
            });
          } else {
            if (
              this.props.location.state.orderEntryData &&
              this.props.location.state.orderEntryData.urgent
            ) {
              this.setState({ internalNotes: "urgent" });
            }
          }
          if (copiedOrderData.notes) {
            this.setState({ notes: copiedOrderData.notes });
          }
          if (copiedOrderData.products) {
            this.setState({ products: copiedOrderData.products });
          }
          if (copiedOrderData.products) {
            this.setState({ productsBeforeUpdate: copiedOrderData.products });
          }
          if (copiedOrderData.discountTk) {
            this.setState({ discountTk: copiedOrderData.discountTk });
          }
          if (copiedOrderData.discountPct) {
            this.setState({ discountPct: copiedOrderData.discountPct });
          }
          if (copiedOrderData.deliveryFee) {
            this.setState({ deliveryFee: copiedOrderData.deliveryFee });
          }
          if (copiedOrderData.total) {
            this.setState({ total: copiedOrderData.total });
          }
          if (copiedOrderData.total) {
            this.setState({ due: copiedOrderData.total });
          }
          if (copiedOrderData.conveyance) {
            this.setState({ conveyance: copiedOrderData.conveyance });
          }
        }
      }

      axios
        .get(baseurl() + "/deliverypersons", {
          headers: { authorization: this.state.loggeduser.token },
        })
        .then((response) => {
          if (response.data.success === true) {
            this.setState({ alldeliveryperson: response.data.result });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    changeInput(e) {
      var input = e.target;
      if (input.name === "orderdate") {
        this.setState({ orderdate: input.value });
      } else if (input.name === "customerName") {
        this.setState({ customerName: input.value });
      } else if (input.name === "orderNumber") {
        this.setState({ orderNumber: input.value });
      } else if (input.name === "customerAddress") {
        this.setState({ customerAddress: input.value });
      } else if (input.name === "customerEmail") {
        this.setState({ customerEmail: input.value });
      } else if (input.name === "customerArea") {
        this.setState({ customerArea: input.value });
      } else if (input.name === "customerPhone") {
        this.setState({ customerPhone: input.value });
      } else if (input.name === "internalNotes") {
        this.setState({ internalNotes: input.value });
      } else if (input.name === "sendMockup") {
        this.setState({ sendMockup: input.value });
      } else if (input.name === "notes") {
        this.setState({ notes: input.value });
      } else if (input.name === "total") {
        this.setState({ total: input.value });
      } else if (input.name === "price") {
        this.setState({ price: input.value });
      } else if (input.name === "conveyance") {
        this.setState({ conveyance: input.value });
      } else if (input.name === "paymentAmount") {
        this.setState({ paymentAmount: input.value });
      } else if (input.name === "discountTk") {
        this.setState({ discountTk: input.value });
        this.fillTempData(
          this.state.products,
          input.value,
          this.state.discountPct,
          this.state.deliveryFee
        );
        this.calculateTotal();
      } else if (input.name === "discountPct") {
        this.setState({ discountPct: input.value });
        this.fillTempData(
          this.state.products,
          this.state.discountTk,
          input.value,
          this.state.deliveryFee
        );
        this.calculateTotal();
      } else if (input.name === "deliveryFee") {
        this.setState({ deliveryFee: input.value });
        this.fillTempData(
          this.state.products,
          this.state.discountTk,
          this.state.discountPct,
          input.value
        );
        this.calculateTotal();
      } else if (input.name === "addproductCode") {
        this.setState({ addproductCode: input.value });
      } else if (input.name === "addproductModel") {
        this.setState({ addproductModel: input.value });
      } else if (input.name === "searchProductModel") {
        this.setState({ searchProductModel: input.value });
      } else if (input.name === "searchArea") {
        this.setState({ searchArea: input.value });
      } else if (input.name === "addArea") {
        this.setState({ addArea: input.value });
      }
    }

    changeOrderedProducts(e) {
      var input = e.target;
      var clickedProducts = JSON.parse(JSON.stringify(this.state.products));
      clickedProducts[input.name].price = input.value;
      this.setState({ products: clickedProducts });
      this.fillTempData(
        clickedProducts,
        this.state.discountTk,
        this.state.discountPct,
        this.state.deliveryFee
      );
      this.calculateTotal();
    }

    changeOrderedDiscountTk(e) {
      var input = e.target;
      var clickedProducts = JSON.parse(JSON.stringify(this.state.products));
      clickedProducts[input.name].discountTk = input.value;
      this.setState({ products: clickedProducts });
      this.fillTempData(
        clickedProducts,
        this.state.discountTk,
        this.state.discountPct,
        this.state.deliveryFee
      );
      this.calculateTotal();
    }

    changeOrderedDiscountPct(e) {
      var input = e.target;
      var clickedProducts = JSON.parse(JSON.stringify(this.state.products));
      clickedProducts[input.name].discountPct = input.value;
      this.setState({ products: clickedProducts });
      this.fillTempData(
        clickedProducts,
        this.state.discountTk,
        this.state.discountPct,
        this.state.deliveryFee
      );
      this.calculateTotal();
    }

    changeQuantity(e) {
      var input = e.target;
      var arrayvar = JSON.parse(JSON.stringify(this.state.products));
      arrayvar[input.name].quantity = input.value;
      arrayvar[input.name].total = arrayvar[input.name].price * input.value;

      this.setState({ products: arrayvar });

      this.fillTempData(
        arrayvar,
        this.state.discountTk,
        this.state.discountPct,
        this.state.deliveryFee
      );

      this.calculateTotal();
    }

    changeNote(e) {
      var input = e.target;
      var arrayvar = JSON.parse(JSON.stringify(this.state.products));
      arrayvar[input.name].note = input.value;
      this.setState({ products: arrayvar });
    }

    changeCode(e) {
      var input = e.target;
      var arrayvar = JSON.parse(JSON.stringify(this.state.products));
      arrayvar[input.name].code = input.value;
      arrayvar[input.name].facebookImageLink = "";
      this.setState({ products: arrayvar });
    }

    fillTempData(products, discountTk, discountPct, deliveryFee) {
      this.tempData.products = products;
      this.tempData.discountTk = discountTk;
      this.tempData.discountPct = discountPct;
      this.tempData.deliveryFee = deliveryFee;
    }

    selectproduct() {
      if (
        this.state.addproductModel &&
        this.state.addproductCode &&
        this.state.addproductType
      ) {
        var data = {};
        data.discountTk = 0;
        data.discountPct = 0;
        data.quantity = 1;
        data.note = "";
        data.price = this.state.addproductPrice;
        data.model = this.state.addproductModel;
        data.productType = this.state.addproductType;
        data.code = this.state.addproductCode.replace(/^0+/, "").trim();
        data.total = data.price;
        var arrayvar = JSON.parse(JSON.stringify(this.state.products));
        arrayvar.push(data);
        this.setState({ products: arrayvar });

        this.fillTempData(
          arrayvar,
          this.state.discountTk,
          this.state.discountPct,
          this.state.deliveryFee
        );

        this.calculateTotal();
        setTimeout(() => {
          document
            .getElementsByClassName("productNote")
            [arrayvar.length - 1].focus();
        }, 500);
      } else {
        notify("All Field Required", "#F44336");
      }
    }

    removeProduct(index) {
      var arrayvar = JSON.parse(JSON.stringify(this.state.products));

      if (this.state.mode !== "Add") {
        var deletingProduct = JSON.parse(JSON.stringify(arrayvar[index]));
        var deletedProducts = JSON.parse(
          JSON.stringify(this.state.deletedProducts)
        );
        deletedProducts.push(deletingProduct);
        this.setState({ deletedProducts: deletedProducts });
      }

      arrayvar.splice(index, 1);
      this.setState({ products: arrayvar });
      this.fillTempData(
        arrayvar,
        this.state.discountTk,
        this.state.discountPct,
        this.state.deliveryFee
      );
      this.calculateTotal();
    }

    selectPerson(data) {
      this.setState({ deliveryPerson: data.name });
      this.setState({ deliveryPersonEmail: data.email });
      this.setState({ deliveryPersonPhone: data.phone });
    }

    selectcourierCompany(data) {
      this.setState({ courierCompany: data.name });
      if (data.name == "Pathao") {
        this.setState({ conveyance: 0 });
      }
    }

    selectCustomerarea = (data) => {
      this.setState({ customerArea: data });
    };

    selectProductModel = (data) => {
      this.setState({ addproductModel: data });
    };

    calculateTotal() {
      var total = 0;
      var products = this.tempData.products;
      for (var i = 0; i < products.length; i++) {
        products[i].total =
          ((products[i].price * products[i].quantity - products[i].discountTk) *
            (100 - products[i].discountPct)) /
          100;

        total += products[i].total;
      }
      total -= this.tempData.discountTk;
      total = (total * (100 - this.tempData.discountPct)) / 100;
      total = total + parseInt(this.tempData.deliveryFee);
      this.setState({ total: total });
      this.totalPayment(this.state.payments, total);
    }

    paymenttype(type) {
      this.setState({ paymenttype: type });
    }

    addpayment() {
      var data = {};
      data.date = moment(new Date()).format("DD/MM/YYYY");
      data.paymentAmount = this.state.paymentAmount;
      data.paymenttype = this.state.paymenttype;
      var arrayvar = JSON.parse(JSON.stringify(this.state.payments));
      arrayvar.push(data);
      this.setState({ payments: arrayvar });
      this.totalPayment(arrayvar, this.state.total);
    }

    removePayment(index) {
      var arrayvar = this.state.payments;
      arrayvar.splice(index, 1);
      this.setState({ payments: arrayvar });
      this.totalPayment(arrayvar, this.state.total);
      //this.calculateTotal();
    }

    totalPayment(arrayvar, grandTotal) {
      var total = 0;
      var payments = arrayvar;
      for (var i = 0; i < payments.length; i++) {
        var amount = parseFloat(payments[i].paymentAmount);
        total += amount;
      }
      var due = grandTotal - total;
      this.setState({ due: due });
    }

    numberOfproductByphone(products) {
      var totalProductByphone = 0;
      for (var i = 0; i < products.length; i++) {
        totalProductByphone =
          totalProductByphone + parseFloat(products[i].quantity);
      }
      return totalProductByphone;
    }

    chkmode() {
      if (this.state.mode === "Add") {
        return true;
      } else {
        return false;
      }
    }

    focusField(id) {
      setTimeout(() => {
        document.getElementById(id).focus();
      }, 500);
    }

    changeProductType(data) {
      this.setState({ addproductType: data.name, addproductPrice: data.price });
      document.getElementById("addProductCode").focus();
    }

    addArea() {
      if (this.state.addArea) {
        axios
          .post(
            baseurl() + "/customerareas",
            {
              name: this.state.addArea,
            },
            { headers: { authorization: this.state.loggeduser.token } }
          )
          .then((response) => {
            if (response.data.success === true) {
              AppStore.customerareas = response.data.result;
              notify("Area Added", "#28a745");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        notify("Please Type A Area", "#F44336");
      }
    }

    RenderAreas() {
      var customerareas = JSON.parse(JSON.stringify(AppStore.customerareas));
      var nameList = [];
      for (var i = 0; customerareas.length > i; i++) {
        nameList.push(customerareas[i].name);
      }
      return nameList;
    }

    RenderModels() {
      var models = JSON.parse(JSON.stringify(AppStore.models));
      var nameList = [];
      for (var i = 0; models.length > i; i++) {
        nameList.push(models[i].name);
      }
      return nameList;
    }

    chkInBlackList() {
      var blacklist = JSON.parse(JSON.stringify(AppStore.blacklist));
      var exist = false;
      for (var i = 0; blacklist.length > i; i++) {
        if (this.state.customerPhone == blacklist[i].phone) {
          exist = true;
          break;
        }
      }
      this.setState({ existInBlackList: exist });
      this.LoadByPhone(this.state.customerPhone);
    }

    selectDeginType(value) {
      var addproductCode = this.state.addproductCode;
      console.log(addproductCode, value);

      if (value == "") {
        addproductCode.replace("Custom-", "");
        this.setState({
          addproductCode: addproductCode.replace("Custom-", ""),
        });
      } else {
        if (addproductCode.substring(0, 6) !== "Custom-") {
          this.setState({ addproductCode: "Custom-" + addproductCode });
        }
      }

      document.getElementById("addProductCode").focus();
    }

    ChangeCode(index, e) {
      console.log(e);
      var products = this.state.products;
      products[index].code = e.target.value;
      this.setState({ products: products });
    }

    render() {
      console.log(this.state.deletedProducts);

      const { redirect, redirectToOrderEntry } = this.state;
      if (redirect) {
        return (
          <Redirect
            to={AppStore.fromDistribution ? "/reports/distribution" : "/orders"}
          />
        );
      }

      if (redirectToOrderEntry) {
        return <Redirect to="/order-entries" />;
      }

      const jsonitems = this.state.products.map(function (data, index) {
        // var imgUrl = baseurl()+'/uploads/productimages/'+data.productType.trim()+'/'+data.productType.trim()+'-'+data.code.trim()+'.jpg';

        var imgUrl =
          baseurl() +
          "/uploads/productimages/row/" +
          (data.productType == "CC" || data.productType == "GC" ? "covers" : data.productType) +
          "/" +
          data.code.trim() +
          ".jpg";

        if (window.innerWidth > 768) {
          return (
            <tr key={index}>
              <td>
                {data.facebookImageLink &&
                  (!data.code || data.code == "0000") && (
                    <a href={data.facebookImageLink} target="_blank">
                      <img
                        className="img-productspage"
                        src={data.facebookImageLink}
                        alt="product Missing"
                      />
                    </a>
                  )}

                {data.code && data.code != "0000" && (
                  <a href={imgUrl.toLowerCase()} target="_blank">
                    <img
                      className="img-productspage"
                      src={imgUrl.toLowerCase()}
                      alt="product Missing"
                    />
                  </a>
                )}
              </td>
              <td className="text-center">
                <span className="spd-mb-10">{data.productType.trim()}</span>
                <input
                  onChange={this.changeCode.bind(this)}
                  name={index}
                  className="form-control"
                  value={data.code.trim()}
                />
                <br />
                <span className="spd-mb-10">Model: {data.model}</span>
              </td>
              <td>
                <input
                  name={index}
                  className="form-control productNote"
                  value={this.state.products[index].note}
                  onChange={this.changeNote.bind(this)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name={index}
                  value={this.state.products[index].price}
                  onChange={this.changeOrderedProducts.bind(this)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name={index}
                  value={this.state.products[index].quantity}
                  onChange={this.changeQuantity.bind(this)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name={index}
                  value={this.state.products[index].discountTk}
                  onChange={this.changeOrderedDiscountTk.bind(this)}
                />
              </td>
              <td>
                <input
                  type="number"
                  className="form-control"
                  name={index}
                  value={this.state.products[index].discountPct}
                  onChange={this.changeOrderedDiscountPct.bind(this)}
                />
              </td>
              <td>{this.state.products[index].total}</td>
              <td>
                <a
                  className="btn btn-danger text-white"
                  onClick={this.removeProduct.bind(this, index)}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </a>
              </td>
            </tr>
          );
        } else {
          return (
            <div>
              <div
                onClick={() => this.ToggleAccordion(index)}
                className="accordion"
              >
                {data.productType.trim()}-{data.code.trim()} {data.model}
                <i class="fa fa-chevron-down pull-right"></i>
              </div>
              <div
                class="panel"
                style={{
                  display: this.state.accordionid === index ? "block" : "none",
                  maxHeight: this.state.accordionid === index ? "none" : 0,
                  padding: 15,
                }}
              >
                <div className="row">
                  <div className="col-6 text-center">
                    {data.facebookImageLink && (
                      <a
                        href={data.facebookImageLink}
                        target="_blank"
                        style={{ display: "block" }}
                      >
                        <img
                          className="img-productspage full-width"
                          src={data.facebookImageLink}
                          alt="product Missing"
                        />
                      </a>
                    )}

                    {!data.facebookImageLink && (
                      <a
                        href={imgUrl.toLowerCase()}
                        target="_blank"
                        style={{ display: "block" }}
                      >
                        <img
                          className="img-productspage full-width"
                          src={imgUrl.toLowerCase()}
                          alt="product Missing"
                        />
                      </a>
                    )}

                    <span className="spd-mb-10">
                      {data.productType.trim()} - {data.model}
                    </span>
                    <label>Product Code</label>
                    <input
                      onChange={this.changeCode.bind(this)}
                      name={index}
                      className="form-control"
                      value={data.code.trim()}
                    />
                    <br />
                    <label>Notes</label>
                    <input
                      name={index}
                      className="form-control productNote"
                      value={this.state.products[index].note}
                      onChange={this.changeNote.bind(this)}
                    />
                  </div>
                  <div className="col-6">
                    <div className="text-right">
                      <a
                        className="btn btn-danger text-white"
                        onClick={this.removeProduct.bind(this, index)}
                      >
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </a>
                    </div>
                    <label>Price</label>
                    <input
                      type="number"
                      className="form-control mb-2"
                      name={index}
                      value={this.state.products[index].price}
                      onChange={this.changeOrderedProducts.bind(this)}
                    />
                    <label>Quantity</label>
                    <input
                      type="number"
                      className="form-control mb-2"
                      name={index}
                      value={this.state.products[index].quantity}
                      onChange={this.changeQuantity.bind(this)}
                    />
                    <label>Discount TK</label>
                    <input
                      type="number"
                      className="form-control mb-2"
                      name={index}
                      value={this.state.products[index].discountTk}
                      onChange={this.changeOrderedDiscountTk.bind(this)}
                    />
                    <label>Discount Pct.</label>
                    <input
                      type="number"
                      className="form-control mb-2"
                      name={index}
                      value={this.state.products[index].discountPct}
                      onChange={this.changeOrderedDiscountPct.bind(this)}
                    />
                    Total: {this.state.products[index].total}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }, this);

      const deliveryPerson = this.state.alldeliveryperson.map(function (data) {
        return (
          <span
            key={data._id}
            onClick={this.selectPerson.bind(this, data)}
            className="dropdown-item btn btn-default"
          >
            {data.name}
          </span>
        );
      }, this);

      const AllCourier = AppStore.courier.map(function (data) {
        return (
          <span
            key={data._id}
            onClick={this.selectcourierCompany.bind(this, data)}
            className="dropdown-item btn btn-default"
          >
            {data.name}
          </span>
        );
      }, this);

      const payments = this.state.payments.map(function (data, index) {
        return (
          <tr key={index}>
            <td>{data.date}</td>
            <td>{data.paymentAmount}</td>
            <td>{data.paymenttype}</td>
            <td>
              <span
                className="btn btn-danger btn-sm"
                onClick={this.removePayment.bind(this, index)}
              >
                x
              </span>
            </td>
          </tr>
        );
      }, this);

      const orderByphone = this.state.orderByphone.map(function (data, index) {
        var deatailsurl = "/editOrder/" + data._id;
        return (
          <tr>
            <td>{moment(data.orderDate).format("DD/MM/YYYY")}</td>
            <td>{data.notes}</td>
            <td>{this.numberOfproductByphone(data.products)}</td>
            <td>{data.total}</td>
            <td>{data.due}</td>
            <td>
              <Link to={deatailsurl}>Details</Link>
            </td>
          </tr>
        );
      }, this);

      const productTypes = AppStore.productTypes.map((data, index) => {
        return (
          <span
            key={index}
            onClick={this.changeProductType.bind(this, data)}
            className="dropdown-item btn btn-default"
          >
            {data.name}
          </span>
        );
      });

      const productModel = AppStore.models.map((data, index) => {
        if (
          this.state.searchProductModel == "" ||
          data.name
            .toLowerCase()
            .indexOf(this.state.searchProductModel.toLowerCase()) !== -1
        ) {
          return (
            <span
              key={index}
              onClick={this.selectProductModel.bind(this, data.name)}
              className="dropdown-item btn btn-default"
              href="#"
            >
              {data.name}
            </span>
          );
        }
      });

      const ChkSameNameOrder = _.findIndex(this.state.SameDateOrder, {
        customerName: this.state.customerName.trim(),
      });
      const ChkSamePhoneOrder = _.findIndex(this.state.SameDateOrder, {
        customerPhone: this.state.customerPhone.trim(),
      });

      
      return (
        <div className="addorder">
          {!this.props.fromDistribution && (
            <Sidebar addOrder="active" ordershow="show" />
          )}

          <div className={this.props.fromDistribution ? "" : "page"}>
            {!this.props.fromDistribution && <Header />}
            <div className="container">
              <div className="row">
                <div className="col-12 spd-mt-30">
                  <div className="row customer-section">
                    <div className="col-12 mb-2">
                      <button
                        type="button"
                        className="btn btn-primary full-in-sm pull-right"
                        onClick={this.saveOrder.bind(this)}
                      >
                        {this.state.requesting ? (
                          <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        ) : this.state.mode == "Add" ? (
                          "Submit Order"
                        ) : (
                          "Update Order"
                        )}
                      </button>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="orderdate">Order Date</label>
                        <DatePicker
                          selected={moment(this.state.orderDate)}
                          onChange={this.changeDate.bind(this)}
                          dateFormat="DD/MM/YYYY"
                        />
                      </div>
                      {!this.chkmode() && (
                        <div className="form-group">
                          <label htmlFor="orderNumber">Order Number</label>
                          <input
                            type="text"
                            className="form-control"
                            id="orderNumber"
                            name="orderNumber"
                            value={this.state.orderNumber}
                            autoFocus="1"
                            tabIndex="1"
                            readOnly
                          />
                        </div>
                      )}
                      <div className="form-group">
                        <input
                          style={{
                            borderColor:
                              this.state.mode == "Add" &&
                              ChkSameNameOrder !== -1
                                ? "red"
                                : "",
                          }}
                          placeholder="Customer Name"
                          type="text"
                          className="form-control"
                          id="customerName"
                          name="customerName"
                          value={this.state.customerName}
                          onChange={this.changeInput.bind(this)}
                          autoFocus="1"
                          tabIndex="1"
                        />
                        {this.state.mode == "Add" &&
                          this.state.customerName !== "" &&
                          ChkSameNameOrder !== -1 && (
                            <small className="form-text text-danger">
                              Same Name Allready Exist On This Date
                            </small>
                          )}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          paddingRight: 10,
                        }}
                        className="mb-3"
                      >
                        <div
                          className="form-group m-0"
                          style={{ display: "flex", flex: 1 }}
                        >
                          <input
                            style={{
                              borderColor: this.state.existInBlackList
                                ? "red"
                                : "",
                            }}
                            placeholder="Phone number"
                            type="text"
                            className="form-control"
                            name="customerPhone"
                            id="customerPhone"
                            value={this.state.customerPhone}
                            onChange={this.changeInput.bind(this)}
                            tabIndex="2"
                            onBlur={this.chkInBlackList.bind(this)}
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => this.confirmBlock()}
                          className="btn btn-danger"
                          title="Add This Customer In Blacklist"
                        >
                          Block
                        </button>
                      </div>
                      <div className="form-group">
                        {this.state.existInBlackList && (
                          <small className="form-text text-danger">
                            Customer Exist In Blacklist
                          </small>
                        )}
                        {this.state.mode == "Add" &&
                          this.state.customerPhone !== "" &&
                          ChkSamePhoneOrder !== -1 && (
                            <small className="form-text text-danger">
                              Same Number Allready Exist On This Date
                            </small>
                          )}
                      </div>
                      <div className="form-group">
                        <textarea
                          rows="4"
                          placeholder="Customer Address"
                          className="form-control"
                          name="customerAddress"
                          id="customerAddress"
                          value={this.state.customerAddress}
                          onChange={this.changeInput.bind(this)}
                          tabIndex="3"
                        ></textarea>
                      </div>

                      {/* <div className="form-group" >
										<input placeholder="Customer Facebook Link" type="text" className="form-control" value={this.state.facebookLink} onChange={e => this.setState({facebookLink: e.target.value})} tabIndex="4"   />
									</div> */}

                      {this.state.fbSearchText ? (
                        <>
                          Search Text:{" "}
                          <b style={{ color: "red" }}>
                            {this.state.fbSearchText}
                          </b>{" "}
                        </>
                      ) : null}

                      <a
                        className="btn btn-primary"
                        href={AppStore.fbbusinessHost + this.state.facebookLink}
                        target="_blank"
                      >
                        Go to Chat
                      </a>

                      {this.props.location &&
                        this.props.location.state &&
                        this.props.location.state.orderEntryData &&
                        this.props.location.state.orderEntryData._id && (
                          <b className="ml-3">
                            Total Cover{" "}
                            {this.props.location.state.orderEntryData.covers
                              ? this.props.location.state.orderEntryData.covers
                              : 0}{" "}
                            & Pop Grip{" "}
                            {this.props.location.state.orderEntryData.popGrips
                              ? this.props.location.state.orderEntryData
                                  .popGrips
                              : 0}
                          </b>
                        )}

                      <hr />

                      <div className="mb-4">
                        Selected Customer Area:{" "}
                        {this.state.customerArea == "Select Customer Area"
                          ? "No Area Selected"
                          : this.state.customerArea}
                      </div>
                      <div className="form-group mb-3">
                        <Autocomplete
                          suggestions={this.RenderAreas()}
                          listName="Areas"
                          setState={this.selectCustomerarea}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          placeholder="Add Area"
                          name="addArea"
                          value={this.state.addArea}
                          onChange={this.changeInput.bind(this)}
                          className="form-control"
                        />
                      </div>
                      <div className="">
                        <button
                          className="btn btn-default full-in-sm"
                          type="button"
                          onClick={this.addArea.bind(this)}
                        >
                          Add Area
                        </button>
                      </div>
                    </div>
                    <div className="col-sm-12  col-md-6  cus-address">
                      {this.state.addedBy ? (
                        <div className="form-group">
                          <label>Added By</label>
                          <input
                            placeholder="Added By"
                            disabled
                            value={this.state.addedBy}
                            className="form-control"
                          />
                        </div>
                      ) : null}
                      <div className="form-group">
                        <label>internal Notes</label>
                        <textarea
                          placeholder="Internal Notes"
                          rows="5"
                          className="form-control"
                          name="internalNotes"
                          id="internalNotes"
                          value={this.state.internalNotes}
                          onChange={this.changeInput.bind(this)}
                          tabIndex="3"
                        ></textarea>
                      </div>
                      <div className="form-group">
                        <label className="mr-3">Send Mock Up</label>
                        <label className="checkbox-inline mr-3">
                          <input
                            name="sendMockup"
                            onChange={() =>
                              this.setState({
                                sendMockup: !this.state.sendMockup,
                              })
                            }
                            checked={this.state.sendMockup}
                            type="checkbox"
                          />{" "}
                          Yes
                        </label>
                      </div>
                      <div className="form-group">
                        <label htmlFor="orderdate">Mock Up Date</label>
                        <DatePicker
                          selected={moment(this.state.mockupDate)}
                          onChange={(date) =>
                            this.setState({ mockupDate: date })
                          }
                          dateFormat="DD/MM/YYYY"
                        />
                      </div>
                      <div className="dropdown">
                        <button
                          className={
                            "btn " +
                            GetStatusClr(this.state.status) +
                            " dropdown-toggle full-in-sm"
                          }
                          type="button"
                          id="orderStatus"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {this.state.status}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="orderStatus"
                        >
                          <span
                            onClick={() =>
                              this.setState({ status: "Incomplete" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Incomplete
                          </span>
                          <span
                            onClick={() => this.setState({ status: "Printed" })}
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Printed
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status: "Sublimated" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Sublimated
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status: "Bag Ready" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Bag Ready
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status: "Waiting for Customer" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Waiting for Customer
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status: "Do not send today" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Do not send today
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status: "Parcelled" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Parcelled
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status: "Cancelled" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Cancelled
                          </span>
                        </div>
                      </div>

                      <div className="dropdown">
                        <button
                          className={
                            "btn " +
                            GetStatusClr(this.state.status2) +
                            " dropdown-toggle full-in-sm"
                          }
                          type="button"
                          id="orderStatus2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {this.state.status2}
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="orderStatus"
                        >
                          <span
                            onClick={() =>
                              this.setState({ status2: "Case Collected" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Case Collected
                          </span>
                          <span
                            onClick={() =>
                              this.setState({ status2: "Case Not Collected" })
                            }
                            className="dropdown-item btn btn-default"
                            href="#"
                          >
                            Case Not Collected
                          </span>
                        </div>
                      </div>

                      <div className="shadow1">
                        <OrderTask
                          tasks={this.state.tasks}
                          UpdateTaskData={(data) =>
                            this.setState({ tasks: data })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  {/* Popup Modal Code */}
                  <div
                    className={this.props.fromDistribution ? "" : "modal fade"}
                    id="portfolioModal"
                    role="dialog"
                    aria-labelledby="portfolioModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-body">
                          <h1 className="text-center">Add Product In Order</h1>
                          <div className="row">
                            <div className="col-12 col-md-8">
                              <div className="dropdown mb-4 m-2">
                                <button
                                  className="btn btn-default dropdown-toggle"
                                  type="button"
                                  id="addproductType"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  tabIndex="4"
                                >
                                  {this.state.addproductType
                                    ? this.state.addproductType
                                    : "Select Product Type"}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="addproductType"
                                >
                                  {productTypes}
                                </div>
                              </div>
                              {/* <select className="form-control" onChange={alert("sdsd")}>
														<option value=''>Pre Design</option>
														<option value='Custom-'>Custom</option>
													</select> */}
                              <button
                                type="button"
                                className="btn btn-default m-2"
                                onClick={() => this.selectDeginType("")}
                              >
                                Pre Design
                              </button>
                              <button
                                type="button"
                                className="btn btn-default m-2"
                                onClick={() => this.selectDeginType("Custom-")}
                              >
                                Custom
                              </button>
                              <div className="form-group mt-4">
                                <label>Product Code</label>
                                <input
                                  className="form-control"
                                  id="addProductCode"
                                  name="addproductCode"
                                  value={this.state.addproductCode}
                                  onChange={this.changeInput.bind(this)}
                                />
                              </div>
                              <Autocomplete
                                listName="models"
                                suggestions={this.RenderModels()}
                                setState={this.selectProductModel}
                              />

                              <div className="mt-3 mb-3">
                                Selected Model :{" "}
                                {this.state.addproductModel == ""
                                  ? "No Model Selected"
                                  : this.state.addproductModel}
                              </div>
                            </div>
                            <div className="col-12 col-md-4 mb-2">
                              {this.state.addproductType &&
                                this.state.addproductCode && (
                                  <img
                                    className="full-width"
                                    alt="Prduct Image Missing"
                                    // src={baseurl()+'/uploads/productimages/'+this.state.addproductType+'/'+this.state.addproductType+'-'+(this.state.addproductCode.replace(/^0+/, '')).trim()+'.jpg'}

                                    src={(
                                      baseurl() +
                                      "/uploads/productimages/row/" +
                                      (this.state.addproductType == "CC" || this.state.addproductType == "GC"
                                        ? "covers"
                                        : this.state.addproductType) +
                                      "/" +
                                      this.state.addproductCode.trim() +
                                      ".jpg"
                                    ).toLowerCase()}
                                  />
                                )}
                            </div>
                          </div>

                          <hr />

                          <div className="row">
                            <div className="col-12 col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="addingModelName"
                                  name="addingModelName"
                                  value={this.state.addingModelName}
                                  placeholder="Add New Model"
                                  onChange={(e) =>
                                    this.setState({
                                      addingModelName: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-12 col-md-6">
                              {this.state.addingModel ? (
                                <div class="spinner-border" role="status">
                                  <span class="sr-only">Loading...</span>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-success"
                                  onClick={this.AddNewModel.bind(this)}
                                >
                                  Add Model
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="modal-footer">
                          {this.props.fromDistribution ? (
                            <button
                              type="button"
                              onClick={this.selectproduct.bind(this)}
                              className="btn btn-success"
                            >
                              Add
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={this.selectproduct.bind(this)}
                              className="btn btn-success"
                              data-dismiss="modal"
                            >
                              Add
                            </button>
                          )}

                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Popup Modal Code End */}
                  <div className="addproduct row">
                    <div className="col-12">
                      {window.innerWidth > 768 ? (
                        <table className="table table-bordered table-responsive">
                          <thead>
                            <tr>
                              <th>Image</th>
                              <th>Code & Model</th>
                              <th>Note</th>
                              <th className="sm-th">Price</th>
                              <th className="sm-th">Qty</th>
                              <th className="sm-th">Discount (Tk)</th>
                              <th className="sm-th">Discount (%)</th>
                              <th>Total</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>{jsonitems}</tbody>
                        </table>
                      ) : (
                        <div className=" mb-4 mt-4">{jsonitems}</div>
                      )}

                      {!this.props.fromDistribution && (
                        <button
                          tabIndex="5"
                          type="button"
                          className="btn btn-default spd-mb-30 full-in-sm"
                          data-toggle="modal"
                          data-target="#portfolioModal"
                          data-whatever="@getbootstrap"
                          onClick={this.focusField.bind(this, "addProductCode")}
                        >
                          Add Product
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row payment-section">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="notes">Delivery Notes</label>
                        <textarea
                          rows="3"
                          className="form-control"
                          name="notes"
                          id="notes"
                          value={this.state.notes}
                          onChange={this.changeInput.bind(this)}
                        ></textarea>
                      </div>
                      <div className="row">
                        <h4 className="col-12">Add Payment</h4>
                      </div>
                      <div className="row order-payment mb-4 mt-4">
                        <div className="col-12 col-md-5 mb-2">
                          <input
                            type="number"
                            tabIndex="6"
                            value={this.state.paymentAmount}
                            onChange={this.changeInput.bind(this)}
                            name="paymentAmount"
                            placeholder="Amount"
                          />
                        </div>
                        <div className="col-12 col-md-4 mb-2">
                          <div className="dropdown">
                            <button
                              tabIndex="7"
                              className="btn btn-default dropdown-toggle full-in-sm"
                              type="button"
                              id="payment-type"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {this.state.paymenttype}
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="payment-type"
                            >
                              <span
                                onClick={this.paymenttype.bind(this, "bKash")}
                                className="dropdown-item btn btn-default"
                                href="#"
                              >
                                bKash
                              </span>
                              <span
                                onClick={this.paymenttype.bind(
                                  this,
                                  "courierCompany"
                                )}
                                className="dropdown-item btn btn-default"
                                href="#"
                              >
                                courierCompany
                              </span>
                              <span
                                onClick={this.paymenttype.bind(
                                  this,
                                  "Credit Card"
                                )}
                                className="dropdown-item btn btn-default"
                                href="#"
                              >
                                Credit Card
                              </span>
                              <span
                                onClick={this.paymenttype.bind(this, "Cash")}
                                className="dropdown-item btn btn-default"
                                href="#"
                              >
                                Cash
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-3">
                          <span
                            tabIndex="8"
                            className="btn btn-primary full-in-sm"
                            onClick={this.addpayment.bind(this)}
                          >
                            Add
                          </span>
                        </div>
                      </div>
                      {this.state.payments.length > 0 && (
                        <div className="row">
                          <div className="col-12 payment-table table-responsive">
                            <table className="table table-bordered table-responsive">
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Amount</th>
                                  <th>Payment Type</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>{payments}</tbody>
                            </table>
                          </div>
                        </div>
                      )}
                      <div className="row d-none d-sm-block">
                        <div className="col-12 col-md-6 mb-2">
                          <div className="dropdown">
                            <button
                              tabIndex="11"
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              id="deliveryPerson"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {this.state.deliveryPerson == ""
                                ? "No Delivery Man Selected"
                                : this.state.deliveryPerson}
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="deliveryPerson"
                            >
                              <span
                                onClick={() =>
                                  this.setState({
                                    deliveryPerson: "Select Delivery Person",
                                  })
                                }
                                className="dropdown-item btn btn-default"
                              >
                                No Delivery Man
                              </span>
                              {deliveryPerson}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 mb-2">
                          <div className="dropdown">
                            <button
                              tabIndex="11"
                              className="btn btn-default dropdown-toggle"
                              type="button"
                              id="selectCourier"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {this.state.courierCompany == ""
                                ? "No Courier Selected"
                                : this.state.courierCompany}
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="selectCourier"
                            >
                              <span
                                onClick={() =>
                                  this.setState({ courierCompany: "" })
                                }
                                className="dropdown-item btn btn-default"
                              >
                                No courier
                              </span>
                              {AllCourier}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 spd-mt-10 mb-2">
                          <span className="spd-ml-10">Conveyance:</span>
                          <input
                            tabIndex="12"
                            className="inline-form"
                            onChange={this.changeInput.bind(this)}
                            name="conveyance"
                            value={this.state.conveyance}
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <label htmlFor="deliveryFee">Delivery Charge</label>
                        <input
                          tabIndex="9"
                          type="number"
                          className="form-control"
                          name="deliveryFee"
                          id="deliveryFee"
                          value={this.state.deliveryFee}
                          onChange={this.changeInput.bind(this)}
                          style={{ MozAppearance: "textfield" }}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="discountTk">Discount Tk</label>
                        <input
                          tabIndex="9"
                          type="number"
                          className="form-control"
                          name="discountTk"
                          id="discountTk"
                          value={this.state.discountTk}
                          onChange={this.changeInput.bind(this)}
                          style={{ MozAppearance: "textfield" }}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="discountPct">Discount Percent</label>
                        <input
                          tabIndex="10"
                          type="number"
                          className="form-control"
                          name="discountPct"
                          id="discountPct"
                          value={this.state.discountPct}
                          onChange={this.changeInput.bind(this)}
                          style={{ MozAppearance: "textfield" }}
                          onWheel={(e) => e.currentTarget.blur()}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="total">Totals</label>
                        <p id="grand-total">{this.state.total} </p>
                      </div>
                      <div className="form-group">
                        <label htmlFor="due">Due</label>
                        <p id="due">{this.state.due} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <button
                    className="btn btn-primary full-in-sm pull-right"
                    onClick={this.saveOrder.bind(this)}
                  >
                    {this.state.requesting ? (
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    ) : this.state.mode == "Add" ? (
                      "Submit Order"
                    ) : (
                      "Update Order"
                    )}
                  </button>
                </div>
              </div>

              <div className="row my-3">
                <div className="col-12">
                  <h1>Deleted Products</h1>
                </div>
                <div className="col-12">
                  <table>
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Code & Model</th>
                        <th>Note</th>
                        <th className="sm-th">Price</th>
                        <th className="sm-th">Qty</th>
                        <th className="sm-th">Discount (Tk)</th>
                        <th className="sm-th">Discount (%)</th>
                        <th>Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.deletedProducts.map((data, index) => {
                        var imgUrl =
                          baseurl() +
                          "/uploads/productimages/" +
                          data.productType.trim() +
                          "/" +
                          data.productType.trim() +
                          "-" +
                          data.code.trim() +
                          ".jpg";
                        return (
                          <tr key={index}>
                            <td>
                              {data.facebookImageLink &&
                                (!data.code || data.code == "0000") && (
                                  <a
                                    href={data.facebookImageLink}
                                    target="_blank"
                                  >
                                    <img
                                      className="img-productspage"
                                      src={data.facebookImageLink}
                                      alt="product Missing"
                                    />
                                  </a>
                                )}

                              {data.code && data.code != "0000" && (
                                <a href={imgUrl.toLowerCase()} target="_blank">
                                  <img
                                    className="img-productspage"
                                    src={imgUrl.toLowerCase()}
                                    alt="product Missing"
                                  />
                                </a>
                              )}
                            </td>
                            <td className="text-center">
                              <input
                                disabled
                                name={index}
                                className="form-control"
                                value={
                                  data.productType.trim() +
                                  "-" +
                                  data.code.trim() +
                                  " " +
                                  data.model
                                }
                              />
                              <br />
                            </td>
                            <td>
                              <input
                                disabled
                                name={index}
                                className="form-control productNote"
                                value={data.note}
                              />
                            </td>
                            <td>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                name={index}
                                value={data.price}
                              />
                            </td>
                            <td>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                name={index}
                                value={data.quantity}
                              />
                            </td>
                            <td>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                name={index}
                                value={data.discountTk}
                              />
                            </td>
                            <td>
                              <input
                                disabled
                                type="number"
                                className="form-control"
                                name={index}
                                value={data.discountPct}
                              />
                            </td>
                            <td>{data.total}</td>
                            <td>
                              <a
                                className="btn btn-danger text-white"
                                onClick={this.removeProduct.bind(this, index)}
                              >
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row spd-mt-30 d-none d-sm-block">
                <div>
                  <h1>Old order history</h1>
                </div>
                <div className="col-12 table-responsive">
                  <table className="table table-bordered ">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Notes</th>
                        <th>Product Qty</th>
                        <th>Total</th>
                        <th>Due</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>{orderByphone}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      );
    }
  }
);

export default addOrder;
