import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify, replaceAllSpaceToDash} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';
import _ from 'lodash';
import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';
import {Checkbox, Input, Select} from 'antd';

const models = observer(class models extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			update:false,
			id: '',
			name:'',
			phoneImage:'',
			verified:false,
			models:[],
			similarModels:[],

			multipleModels:'',

			searchWord:''
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentDidMount(){

		console.log("hello")

		sendToLoginIfNoAccess("products","edit", this, "/models" , { headers: { authorization: this.state.loggeduser.token } });
		console.log("hello")
		// Make a request for a user with a given ID
		axios.get(baseurl()+'/models', { headers: { authorization: this.state.loggeduser.token } })
		.then( (response)=> {
			console.log(response);
			if(response.data.success === true){
				this.setState({models : response.data.result});
				AppStore.models = response.data.result;
			}
		})
		.catch(function (error) {
			console.log(error);
			console.log(error.response);
		});

	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}else if(input.name === "multipleModels"){
			this.setState({multipleModels : input.value});
		}
	}

	loadaddData(){
		this.setState({id : ''});
		this.setState({name : ''});
		this.setState({verified : ''});
		this.setState({similarModels:[]})
		this.setState({update : false});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({name : data.name});
		this.setState({coverImage : data.coverImage});
		this.setState({verified : data.verified});
		this.setState({similarModels:data.similarModels})
		this.setState({update : true});
	}

	addMultiple(){

		var allModel = this.state.multipleModels.split('\n');
		var postData = [];
		for(var i= 0; allModel.length > i; i++){
			var singleModel = {};
			if(allModel[i] !== ''){
				singleModel.name = allModel[i];
				postData.push(singleModel);
			}
		}

		postData = _.uniqBy(postData, 'name');
		console.log(postData);

		axios.post(baseurl()+'/models/addmany', {
			models: postData,
		} , { headers: { authorization: this.state.loggeduser.token } })
		.then((response) => {
			if(response.data.success === true){
				this.setState({models : response.data.result});
				AppStore.models = response.data.result;
				notify("Model Added", "#28a745");
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	addData(){
		axios.post(baseurl()+'/models', {
		    name: this.state.name,
		    coverImage: this.state.coverImage,
		    verified: this.state.verified,
			similarModels: this.state.similarModels
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({models : response.data.result});
				AppStore.models = response.data.result;
				notify("Model Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		console.log(this.state)
		axios.put(baseurl()+'/models/'+this.state._id, {
		    name: this.state.name,
		    coverImage: this.state.coverImage,
		    verified: this.state.verified,
			similarModels: this.state.similarModels
		}, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({models : response.data.result});
				AppStore.models = response.data.result;
				notify("Model Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/models/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({models : response.data.result});
				AppStore.models = response.data.result;
				notify('Model Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	// onChangeFile(e) {

	// 	var imageData = e.target.files[0]

	// 	const url = baseurl()+'/images';
	// 	const data = new FormData();
	// 	data.append('file',imageData);
	// 	data.append('folder',"models");
	// 	axios.post(url,data, { headers: { authorization: this.state.loggeduser.token } })
	// 	.then( (response)=> {
	// 		console.log(response)
	// 		if(response.data.success){
	// 			this.setState({coverImage:response.data.modelFileName});
	// 		}else{
	// 			notify("File Didn't Uploaded!",'#F44336');
	// 		}
	// 	})
	// 	.catch((error)=> {
	// 	    console.log(error);
	// 	    notify("File Didn't Uploaded!",'#F44336');
	// 	});
	// }

	onChangeFile = (e) => {
		this.setState({activityMsg:'Uploading'})

		var formdata = new FormData();

		formdata.append('uploadFolder', "productimages/models/");

		let imageName = e.target.files[0]

		// for (let index = 0; index < e.target.files.length; index++) {

		// 	formdata.append("files", e.target.files[index]);

		// }

		formdata.append("files", e.target.files[0]);

		var requestOptions = {
			method: 'POST',
			body: formdata,
			redirect: 'follow'
		};


		fetch(baseurl()+ "/filemanager/uploadfiles", requestOptions)
		.then(response => response.json())
		.then(result => {
			console.log(result)
			this.setState({coverImage:imageName.name});
		})
		.catch(error => console.log('error', error));
	}

	// delete(data){
	// 	axios.delete(baseurl()+'/images/', {
	// 		data:{
	// 			images: ["models/" + data],
	// 		}
	// 	  } , { headers: { authorization: this.state.loggeduser.token } })
	// 	  .then((response) => {

	// 	  })
	// 	  .catch(function (error) {
	// 	    console.log(error);
	// 	  });
	// }

  render() {
	// _.orderBy(this.state.models, [models => models.name.toLowerCase()], ['asc']);
  	const jsonitems=(_.orderBy(this.state.models.filter((ele) => {return ele.name.toLowerCase().indexOf(this.state.searchWord.toLowerCase()) > -1}), [models => models.name.toLowerCase()], ['asc'])).map(function(data){

			return(
				<tr key={data._id}>
				  <td>
				  	{data.coverImage ?
					  <img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/models/'+data.coverImage).toLowerCase()} />
					  :
					  <p>Image Not Avaliable</p>
					}
				  </td>
			      <td style={{backgroundColor: replaceAllSpaceToDash(data.name).toLowerCase()+'.png' !== (data.coverImage ? data.coverImage : '').toLowerCase() ? '#ddd' : ''}}>
					  {data.name}<br/>
					  {data.coverImage}<br/>
					</td>
					<td>

					{data.size &&
						<b style={{color: data.size.height == 420 && data.size.width == 238 ? '#000' : 'red' }}>
							{data.size.height+" X "+data.size.width}
						</b>
	  				}
					</td>
					  <td>
						  {data.similarModels.map((simiData, simiINdex) =>
							<h6>{simiData}</h6>
						  )}
					  </td>
			      <td>{data.verified ? 'Yes' : 'No'}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
	}, this);
    return (
        <div >
        	<Sidebar models = "active" productshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container models">
					<div className="row">
						<div className="col-12">
							<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Models</button>
							<button type="button" className="btn btn-default spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#addMultipleModel" data-whatever="@getbootstrap" onClick={()=>this.setState({multipleModels:''})} >Add Multiple Models</button>
							{/* Popup Modal Code */}
							<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								<div className="modal-dialog" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title" id="portfolioModalLabel">{this.state.update ? "Update" : "Add"}</h5>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">

											<div className="form-group">
												<label className='mr-4'><b>Model Verified</b></label>
												<Checkbox onChange={e => this.setState({verified: e.target.checked})} checked={this.state.verified}></Checkbox>
											</div>

											<div className="form-group">
												<label htmlFor="Name">Name</label>
												<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>

											<div className="form-group">
												<label htmlFor="imageupload">Model Image</label>
												<input className="form-control" type="file" onChange={this.onChangeFile.bind(this)} />
											</div>
											{this.state.coverImage &&
												<img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/models/'+this.state.coverImage).toLowerCase()} />
											}

											<Select
												showSearch
												style={{ width: "100%" }}
												placeholder="Select A Model"
												onChange={(value) => this.setState({similarModels:value})}
												value={this.state.similarModels}
												mode='multiple'
											>
												{this.state.models.map((cModelData, cModelIndex) =>

													<Select.Option key={cModelIndex} value={cModelData.name}>{cModelData.name}</Select.Option>

												)}
                                            </Select>

										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
											<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.state.update ? this.editData.bind(this) : this.addData.bind(this)} >{this.state.update ? "Update" : "Add"} models</button>
										</div>
									</div>
								</div>
							</div>

							<h1>Verified {this.state.models.filter(ele => {return ele.verified }).length}, Unverified {this.state.models.filter(ele => {return !ele.verified}).length}</h1>
						{/* Popup Modal Code End */}
						{/* Popup Modal Code */}
						<div className="modal fade" id="addMultipleModel" role="dialog" aria-labelledby="addMultipleModel" aria-hidden="true">
								<div className="modal-dialog modal-lg" role="document">
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title">Add Multiple Model</h5>
											<button type="button" className="close" data-dismiss="modal" aria-label="Close">
												<span aria-hidden="true">&times;</span>
											</button>
										</div>
										<div className="modal-body">
										{/* <div className="text-right">
											<button type="button" className="btn btn-secondary mr-2" data-dismiss="modal">Close</button>
											<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addMultiple.bind(this)} >Add</button>
										</div> */}
											<div className="form-group">
												<label htmlFor="Name">Model List</label>
												<textarea rows={10} className="form-control" id="multipleModels" name="multipleModels" value={this.state.multipleModels} onChange={this.changeInput.bind(this)}></textarea>
											</div>
										</div>
										<div className="modal-footer">
											<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
											<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addMultiple.bind(this)} >Add</button>
										</div>
									</div>
								</div>
							</div>
						{/* Popup Modal Code End */}
						</div>
					</div>
					<div className="row">
						<div className="col-12 my-3">

							<Input placeholder="Search" value={this.state.searchWord} onChange={(e) => this.setState({searchWord:e.target.value})} />

						</div>
						<div className="col-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th style={{width:200}}>Model Image</th>
										<th>Name</th>
										<th>Dimension</th>
										<th>Similar Models</th>
										<th>Verified</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{jsonitems}
								</tbody>
							</table>
						</div>
					</div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default models;
