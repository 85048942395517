import React, { Component } from "react";
import Sidebar from "./sidebar.js";
import Header from "./header.js";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { baseurl, GetImagePath } from "./utility/utility.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import { loggeduser, sendToLoginIfNoAccess } from "./utility/security";
import { observer } from "mobx-react";
import { Pagination } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const emptyEle = {
  name: "",
  title: "",
  imageLink: "",
  productTypeToPurchase: "",
  numberRequired: "",
  minimumPriceOfEachProductPurchase: "",
  productTypeForFree: "",
  numberFree: "",
  deliveryChargeInsideDhaka: "",
  deliveryChargeOutsideDhaka: "",
  minimumCartValue: "",
  storeCredit: "",
  discountTk: "",
  discountPercent: "",
};

const PopularDesign = observer(
  class PopularDesign extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loggeduser: loggeduser(),
        design: [],
        count: 1,
        perpage: 50,
        startDate: moment().subtract(30, "days"),
        endDate: moment(),
      };
      //this.Data.bind(this)
    }

    componentWillMount() {
      sendToLoginIfNoAccess("products", "edit", this, "/PopularDesign", {
        headers: { authorization: this.state.loggeduser.token },
      });
      // Make a request for a user with a given ID

      this.LoadEle();
    }

    LoadEle = (start = 0, end = this.state.perpage) => {
		var startDate = moment(this.state.startDate).format("YYYY-MM-DD");
		var endDate = moment(this.state.endDate).format("YYYY-MM-DD");

      axios
        .get(
          baseurl() +
            "/reports/mostpopular?startDate=" +
			startDate +
			"&endDate=" +
			endDate +
            "&start=" +
            start +
            "&numRecords=" +
            end,
          { headers: { authorization: this.state.loggeduser.token } }
        )
        .then((response) => {
          console.log(response);
          if (response.data && response.data.products) {
            this.setState({ design: response.data.products });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    changeStartDate = (startDate) => {
      this.setState({ startDate: startDate }, () => {
        this.LoadEle();
      });
    };

    changeEndDate = (endDate) => {
      this.setState({ endDate: endDate }, () => {
        this.LoadEle();
      });
    };

    render() {
      // _.orderBy(this.state.PopularDesign, [PopularDesign => PopularDesign.name.toLowerCase()], ['asc']);
      const jsonitems = this.state.design.map(function (data) {
        return (
          <tr key={data._id}>
            <td>
              <img
                style={{ width: 200 }}
                src={GetImagePath(data.name + ".jpg").toLowerCase()}
              />
            </td>
            <td>{data.name}</td>
            <td>{data.count}</td>
          </tr>
        );
      }, this);
      return (
        <div>
          <Sidebar saleOffer="active" />
          <div className="page">
            <Header />
            <div className="container PopularDesign">
              <div className="row">
                <div className="col-12 my-4">
                  <span>From:</span>
                  <DatePicker
                    selected={moment(this.state.startDate)}
                    onChange={this.changeStartDate.bind(this)}
                    dateFormat="DD/MM/YYYY"
                  />
                  <span>To:</span>
                  <DatePicker
                    selected={moment(this.state.endDate)}
                    onChange={this.changeEndDate.bind(this)}
                    dateFormat="DD/MM/YYYY"
                  />
                </div>
                <div className="col-12">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Design</th>
                        <th>Name</th>
                        <th>Count</th>
                      </tr>
                    </thead>
                    <tbody>{jsonitems}</tbody>
                  </table>
                </div>
                <div>
                  <Pagination
                    defaultCurrent={1}
                    total={this.state.count}
                    pageSize={this.state.perpage}
                    onChange={(page) => {
                      this.LoadEle(
                        (page - 1) * this.state.perpage,
                        this.state.perpage
                      );
                    }}
                    showSizeChanger={false}
                  />
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      );
    }
  }
);

export default PopularDesign;
