import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import './App.css';
import axios from 'axios';
import {baseurl} from './utility/utility.js';

import {observer} from "mobx-react";

import {loggeduser, sendToLoginIfNoAccess} from './utility/security';

const Dashboard = observer(class App extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			currentMonthsOrderTotal:0,
			currentMonthsProductsTotal:0,
			currentMonthsDelivery:[],
			currentMonthsRC:0,
			currentMonthsCC:0,
			todaysOrderTotal:0,
			todaysProductsTotal:0,
			todaysDelivery:[],
			todaysRC:0,
			todaysCC:0,
		};
	}

	componentWillMount(){
		sendToLoginIfNoAccess("reports","distribution", this, "/dashboard");
	    axios.get(baseurl()+'/reports/dashboard' , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			this.setState({currentMonthsOrderTotal : response.data.currentMonthsOrderTotal.toFixed(2)});
			this.setState({currentMonthsProductsTotal : response.data.currentMonthsProductsTotal});
			this.setState({currentMonthsDelivery : response.data.currentMonthsDelivery});
			this.setState({currentMonthsRC : response.data.currentMonthsRC});
			this.setState({currentMonthsCC : response.data.currentMonthsCC});
			this.setState({todaysOrderTotal : response.data.todaysOrderTotal.toFixed(2)});
			this.setState({todaysProductsTotal : response.data.todaysProductsTotal});
			this.setState({todaysDelivery : response.data.todaysDelivery});
			this.setState({todaysRC : response.data.todaysRC});
			this.setState({todaysCC : response.data.todaysCC});
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}


	render() {

		const thismonthDelivery = this.state.currentMonthsDelivery.map(function(data, index){
			return(
				<tr>
			      <td>{data.deliveryPerson}</td>
			      <td>{data.count}</td>
			    </tr>
			)
		},this);

		const todayDelivery = this.state.todaysDelivery.map(function(data, index){
			return(
				<tr>
			      <td>{data.deliveryPerson}</td>
			      <td>{data.count}</td>
			    </tr>
			)
		},this);

		return (
		  	<div className="home">
		    	<Sidebar home="active" />
		        <div className="page">
		        	<Header />
		        	<div className="container">
		        		<div className="row spd-mt-30">
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-pink">
		        						<i className="fa fa-credit-card fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-pink-light">
			        					<h1>{this.state.currentMonthsOrderTotal}</h1>
			        					<p>Total Order In this Month</p>
			        				</div>
			        			</div>
		        			</div>
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-cyan">
		        						<i className="fa fa-bar-chart fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-cyan-light">
			        					<h1>{this.state.currentMonthsProductsTotal}</h1>
			        					<p>Sold Products In this Month</p>
			        				</div>
			        			</div>
		        			</div>
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-green">
		        						<i className="fa fa-registered fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-green-light">
			        					<h1>{this.state.currentMonthsRC}</h1>
			        					<p>RC Sold This Month</p>
			        				</div>
			        			</div>
		        			</div>
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-orange">
		        						<i className="fa fa-cc fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-orange-light">
			        					<h1>{this.state.currentMonthsCC}</h1>
			        					<p>CC Sold This Month</p>
			        				</div>
			        			</div>
		        			</div>
		        		</div>
		        		<div className="row spd-mt-30">
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-orange">
		        						<i className="fa fa-usd fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-orange-light">
			        					<h1>{this.state.todaysOrderTotal}</h1>
			        					<p>Order Total Today</p>
			        				</div>
			        			</div>
		        			</div>
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-green">
		        						<i className="fa fa-shopping-cart fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-green-light">
			        					<h1>{this.state.todaysProductsTotal}</h1>
			        					<p>Total Products Today</p>
			        				</div>
			        			</div>
		        			</div>
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-cyan">
		        						<i className="fa fa-area-chart fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-cyan-light">
			        					<h1>{this.state.todaysRC}</h1>
			        					<p>Ordered RC Today</p>
			        				</div>
			        			</div>
		        			</div>
		        			<div className="col-3">
		        				<div className="row dashboard-wrapper">
		        					<div className="col-4 dashboard-icon bg-pink">
		        						<i className="fa fa-bar-chart fa-3x" aria-hidden="true"></i>
		        					</div>
		        					<div  className="col-8 dashboard-block bg-pink-light">
			        					<h1>{this.state.todaysCC}</h1>
			        					<p>Ordered CC Today</p>
			        				</div>
			        			</div>
		        			</div>
		        		</div>
		        		<div className="row spd-mt-30">
		        			<div className="col-6">
		        				<h3 className="month-delivery">Today's Delivery</h3>
		        				<table className="table table-bordered" id="orderTable">
									<thead>
										<tr>
											<th>Delivery Person</th>
											<th>Number Of  Delivery</th>
										</tr>
									</thead>
									<tbody>
										{todayDelivery}
									</tbody>
								</table>
		        			</div>
		        			<div className="col-6">
		        				<h3 className="month-delivery">All Delivery This Month</h3>
		        				<table className="table table-bordered" id="orderTable">
									<thead>
										<tr>
											<th>Delivery Person</th>
											<th>Number Of  Delivery</th>
										</tr>
									</thead>
									<tbody>
										{thismonthDelivery}
									</tbody>
								</table>
		        			</div>
		        		</div>
		        	</div>
		        </div>
		  	</div>
		);
	}
})

export default Dashboard;
