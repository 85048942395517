import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import axios from 'axios';
import {hasAccess, loggeduser, loginchk} from './utility/security';
import {baseurl, setLocalItem} from './utility/utility.js';
import AppStore from './store/AppStore';
import {observer} from 'mobx-react';


const Header = observer(class Header extends Component{

  constructor(props){
    super(props);
      this.state={
        redirectTologin: false,
        name:'',
        loggeduser:loggeduser(),
        sidebarOpen:false,
      }
  }

  componentWillMount(){
    if(localStorage.getItem('account') !== null && localStorage.getItem('account') !== 'null'){
      axios.get(baseurl()+"/account/profile/",  { headers: { authorization: this.state.loggeduser.token } })
        .then( (response)=> {
         //console.log(response);
          if(response.data.success === false){
            setLocalItem( "account" , null);
          }
        })
        .catch(function (error){
          //console.log(error);
    });
    }
  }

  logout(){
    setLocalItem( "account" , null);
    this.setState({ redirectTologin: true });
  }

  toggleSidebar = () => {
    if(this.state.sidebarOpen && window.innerWidth <= 768){
      this.setState({sidebarOpen: false})
      document.getElementById('sidebar-nav').style.display = 'none';
    }else{
      this.setState({sidebarOpen: true})
      document.getElementById('sidebar-nav').style.display = 'block';
    }

  }

  render(){
    const { redirectTologin } = this.state;
    if (redirectTologin) {
      return <Redirect to='/'/>;
    }

    return(
          <header className="header">
            <nav className="navbar">
              <div className="container-fluid">
                <div className="navbar-holder d-flex align-items-center justify-content-between">
                    {/*
                      <button className="navbar-toggler navbar-toggler-right" id="open-btn" type="button" data-toggle="collapse" data-target="#sidebar-nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </button>
                    */}
                  <div className="navbar-header"><Link to="/" className="navbar-brand">
                      <div className="brand-text {/*d-none */} d-md-inline-block">
                        <img src="/img/footerlogo.png" alt="logo missing"/>
                      </div></Link></div>
                  <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
                    <li  className="nav-item">
                      {hasAccess('orders', '*') ?
                        <Link className="nav-link" to='/approvals' >
                          <span style={{color:AppStore.approvals.length > 0 ? 'red' : 'white'}} >{AppStore.approvals.length} approval pending</span>
                        </Link>
                      :
                        <span style={{color:AppStore.approvals.length > 0 ? 'red' : 'white'}} >{AppStore.approvals.length} approval pending</span>
                      }
                    </li>
                    {/* <li  className="nav-item"><a className="nav-link" href="tel:018-3254-6244"><i className="fa fa-phone fa-lg"></i> 018-3254-6244</a></li> */}
                    {/* <div className="nav-dropdown">
                      <button className="dropbtn">MY FABGEAR <i className="fa fa-caret-down"></i>
                      </button>
                      <div className="dropdown-content">
                          <Link hidden={loginchk()} className="dropdown-item btn btn-default" to="/profile"><i className="fa fa-user" aria-hidden="true"></i> My Profile</Link>
                          <Link hidden={loginchk()} className="dropdown-item btn btn-default" to="/myorders"><i className="fa fa-opencart" aria-hidden="true"></i> My Orders</Link>
                          <Link className="dropdown-item btn btn-default" to="/wishlist"><i className="fa fa-heart" aria-hidden="true"></i> My Wishlist</Link>
                      </div>
                    </div> */}
                    {/* <li hidden={loginchk()}  className="nav-item"><Link to="/login" className="nav-link logout" onClick={this.logout.bind(this)} >LOGOUT<i className="fa fa-sign-out"></i></Link></li> */}
                    <li hidden={!loginchk()} className="nav-item"><Link to="/login" className="nav-link logout" onClick={this.logout.bind(this)} >LOGIN<i className="fa fa-sign-in"></i></Link></li>
                    <li hidden={loginchk()} className="nav-item sidebar-toggle-btn">
                      <button  className="btn btn-primary" onClick={this.toggleSidebar.bind(this)} ><i className="fa fa-bars"></i></button>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </header>
    );
  }
})


export default Header;
