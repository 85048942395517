import React, {Component} from 'react';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {ToastContainer} from 'react-toastify';
import {baseurl, getLocalItem, notify, setLocalItem} from './utility/utility.js';
import Cart from './cart.js';

class Productlist extends Component {
	constructor(props){
		super(props);
		this.state = {
			title:'',
			allmodels:[],
			allcategory:[],
			allmaterials:[],
			price:'',
			quantity:'',
			description:'',
			imageupload:'',
			_id:'',
			products:[],
			filterProduct:[],
			allwishlistProduct:[],
			cartQty:0,
			total:0.00,
			discountedTotal:0.00,
			selectedModel:[],
			selectedcategory:[],
			selectedmaterials:[],
			start:0,
			perpage:51,
			paginatedArray:[],
			totalpage:0,
			activepage:1,
			searchword:'',
			email:'',
			password:'',
			confirmPassword:'',
			customerName:'',
			customerPhone:'',
			customerAddress:'',
			userExists: false,
			couponData:{"discountTk":0,"discountPct":0},
		};
	}

	componentWillMount(){
	  // Make a request for a user with a given ID
	  	this.searchedProduct(this.state.searchword, this.state.selectedModel, this.state.selectedcategory, this.state.selectedmaterials, this.state.start, this.state.perpage);

	    axios.get(baseurl()+'/models')
	    .then( (response)=> {
	      console.log(response);
	      if(response.data.success === true){
	      	this.setState({allmodels : response.data.result});
	      }
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	    axios.get(baseurl()+'/categories')
	    .then( (response)=> {
	      console.log(response);
	      if(response.data.success === true){
	      	this.setState({allcategory : response.data.result});
	      }
	    })

	    axios.get(baseurl()+'/materials')
	    .then( (response)=> {
	      console.log(response);
	      if(response.data.success === true){
	      	this.setState({allmaterials : response.data.result});
	      }
	    })

	}



	changeInput(e){
		var input=e.target;
		if(input.name === "searchword"){
			this.setState({searchword : input.value});
			this.searchedProduct(input.value,  this.state.selectedModel, this.state.selectedcategory, this.state.selectedmaterials, this.state.start, this.state.perpage);
		}

	}

	selectFilter(filterList,filterName){
		var selectedFilter = this.state[filterList];
		var index = selectedFilter.indexOf(filterName);
		if(index !== -1){
			selectedFilter.splice(index, 1);
			this.setState({[filterList] : selectedFilter});
		}else{
			selectedFilter.push(filterName);
			this.setState({[filterList] : selectedFilter});
		}
		this.searchedProduct(this.state.searchword, this.state.selectedModel, this.state.selectedcategory, this.state.selectedmaterials, this.state.start, this.state.perpage);
	}



	searchedProduct(searchword , models, categories, materials, start, perpage){
		var url = baseurl()+'/products/search';

		console.log(materials);
		axios.post(url, {
	    	title: searchword,
	    	model: models,
	    	category: categories,
	    	material: materials,
	    	start: start,
	    	numRecords: perpage,
	    	shuffle:true,
	    })
	    .then( (response)=> {
	      console.log(response);
	      if(response.data.success === true){
	      	this.setState({products : response.data.result});
	      	this.setState({totalpage : Math.ceil(response.data.count/this.state.perpage)});
	      }
	    })
	}

	addCart(product){
		var allcartProduct = getLocalItem('cart');
		//if(allcartProduct.map(function(e) { return e._id; }).indexOf(product._id) === -1){
			product.quantity = 1;
			product.discountTk = 0;
			product.discountPct = 0;
			product.selectedModel = 'Select Model'
			product.total = product.price
			allcartProduct.push(product);
			setLocalItem( "cart" , allcartProduct);
			this.setState({allcartProduct  : allcartProduct});
			notify("Added To Cart", "#28a745");
		// }else{
		// 	notify("Already Added", "#0275d8");
		// }
	}

	pagination(pageNo){
			var start = pageNo*this.state.perpage;
			this.searchedProduct(this.state.searchword,  this.state.selectedModel, this.state.selectedcategory, this.state.selectedmaterials, start, this.state.perpage);
			this.setState({activepage  : pageNo+1});
			console.log(pageNo);
	}

	activepage(index){
		if(this.state.activepage === index+1){
			return "page-active";
		}
		console.log(index);
	}

	addwishlist(product){
		var allwishlistProduct = getLocalItem('wishlist');
		if(allwishlistProduct.map(function(e) { return e._id; }).indexOf(product._id) === -1){
			allwishlistProduct.push(product);
			setLocalItem( "wishlist" , allwishlistProduct);
			notify("Added To Wishlist", "#0275d8");
		}else{
			notify("Already Added", "#0275d8");
		}
	}

	filterChecked(filterList, filter){
		if(_.includes(filterList,filter)){
			return true;
		}else{
			return false;
		}
	}

	clearSearchWord(){
		this.setState({searchword  : ''});
		this.searchedProduct('', this.state.selectedModel, this.state.selectedcategory, this.state.selectedmaterials, this.state.start, this.state.perpage);
	}


	render() {

		const productItem=this.state.products.map(function(data){
			if(data.enabled == true){
				var imgUrl = baseurl()+'/uploads/productimages/'+data.imageFolder+data.images[0];
				return(
					<div key={data._id} className="col-lg-4 col-md-6 col-12">
						<div className="product spd-mt-30">
							<Link className="full-anchor" to={ "viewproduct/" + data._id}>
		    					<img src={imgUrl.toLowerCase()} alt="Product" />
		    					{(data.hot === true) &&
		    					<p className="post-tag">hot</p>
		    					}
		    					<p className="title">{data.title}</p>
								<p className="money">
									৳ {data.price}
									{(data.originalPrice !== null && data.originalPrice !== undefined) &&
									<span className="cross-tk">৳ {data.originalPrice}</span>
									}
								</p>

	    					</Link>
	    					<div className="product-content">
								<button className="btn btn-default btn-add-cart" title="Add To Cart" onClick={this.addCart.bind(this,  data)} >Add To Cart</button>
								<button title="Add To Wishlist" onClick={this.addwishlist.bind(this,  data)} className="btn btn-danger"><i className="fa fa-heart-o" aria-hidden="true"></i></button>
	    					</div>
	    				</div>
					</div>
				);
			}
		},this);

		const pageItem = _.times(this.state.totalpage, i =>
				<span className="page-no" id={this.activepage(i)} onClick={this.pagination.bind(this, i)} key={i}>{i + 1}</span>
		);

		const allmodels = this.state.allmodels.map(function(data){
			return(
				<li key={data._id}><label htmlFor={data._id}><input onClick={this.selectFilter.bind(this, "selectedModel",data.name)} id={data._id} type="checkbox" checked={this.filterChecked(this.state.selectedModel,data.name)} />{data.name}</label></li>
				)
		}, this);

		const allcategory = this.state.allcategory.map(function(data){
			return(
				<li key={data._id}><label htmlFor={data._id}><input onClick={this.selectFilter.bind(this, "selectedcategory",data.name)} id={data._id} type="checkbox"  checked={this.filterChecked(this.state.selectedcategory,data.name)}/>{data.name}</label></li>
				)
		}, this);

		const allmaterials = this.state.allmaterials.map(function(data){
			return(
				<li key={data._id}><label htmlFor={data._id}><input onClick={this.selectFilter.bind(this, "selectedmaterials",data.name)} id={data._id} type="checkbox" checked={this.filterChecked(this.state.selectedmaterials,data.name)} />{data.name}</label></li>
				)
		}, this);

		const allselectedmodels = this.state.selectedModel.map(function(data, index){
			return(
				<span key={data} onClick={this.selectFilter.bind(this, "selectedModel",data)} className="searched-tag">{data} <i className="fa fa-times"></i></span>
				)
		}, this);

		const allselectedcategory = this.state.selectedcategory.map(function(data, index){
			return(
				<span key={data} onClick={this.selectFilter.bind(this, "selectedcategory",data)} className="searched-tag">{data} <i className="fa fa-times"></i></span>
				)
		}, this);

		const allselectedmaterials = this.state.selectedmaterials.map(function(data, index){
			return(
				<span key={data} onClick={this.selectFilter.bind(this, "selectedmaterials",data)} className="searched-tag">{data} <i className="fa fa-times"></i></span>
				)
		}, this);

	    return (
	        <div className="productlist">
		        <div className="">
		        	<Header cartQty={this.state.cartQty} />
		        	<Cart cartProduct={this.state.allcartProduct}  />

		        	<div className="container-fluid">
		        		<div className="row">
							<div className="col-lg-3 col-md-4 col-12 spd-mt-30">

								<div id="accordion" className="accordion product-sidebar">
								<input name="searchword" className="form-control" value={this.state.searchword} onChange={this.changeInput.bind(this) } placeholder="Search Product"  />
							        <div className="card m-b-0">
							        	<div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
							                <a className="card-title">
							                  Select Model
							                </a>
							            </div>
							            <div id="collapseTwo" className="card-block collapse show">
							            	<ul className="list">
							                	{allmodels}
							                </ul>
							            </div>
							            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
							                <a className="card-title">
							                    Select Category
							                </a>
							            </div>
							            <div id="collapseOne" className="card-block collapse">
							            	<ul className="list">
							                	{allcategory}
							                </ul>
							            </div>

							            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
							                <a className="card-title">
							                  Select Materials
							                </a>
							            </div>
							            <div id="collapseThree" className="panel-collapse collapse">
							                <div className="card-block">
							                	<ul className="list">
							                		{allmaterials}
							                	</ul>
							                </div>
							            </div>
							        </div>
							    </div>
							</div>
							<div className="col-12 col-lg-9 col-md-8">
								<div className="row searched-tag-warp">
									<div className="col-12">
										{(this.state.searchword !== '') &&
										<span onClick={this.clearSearchWord.bind(this)} className="searched-tag">{this.state.searchword} <i className="fa fa-times"></i></span>
										}
					        			{allselectedmodels}
					        			{allselectedcategory}
										{allselectedmaterials}
				        			</div>
				        		</div>
				        		<div className="row">
				       				{productItem}
				        		</div>
				        		<div className="row">
					        		<div className="col-12">
					        			{pageItem}
					        		</div>
				        		</div>
							</div>
		        		</div>
		        	</div><ToastContainer />
		        </div>
		        <Footer />
		    </div>
	    );
	}
}

export default Productlist;
