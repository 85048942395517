import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {hasAccess} from './utility/security';
import {observer} from "mobx-react";
import AppStore from './store/AppStore';
import {setLocalItem} from './utility/utility';

const Sidebar = observer(class Sidebar extends Component{

	constructor(props){
		super(props);
		  this.state={
			redirectTologin: false,
		  }
	  }

	countTask(){
		var count = 0;
		for(var i = 0; AppStore.tasks.length > i; i++){
			if(!AppStore.tasks[i].complete){
				count = count+1;
			}
		}

		return count;
	}

	logout(){
		setLocalItem( "account" , null);
		this.setState({ redirectTologin: true });
	  }

	render(){

		const { redirectTologin } = this.state;
		if (redirectTologin) {
			return <Redirect to='/'/>;
		}

		return(
			<nav className="side-navbar" id="sidebar-nav">
	          <div className="side-navbar-wrapper">
	          		  {/* <button className="navbar-toggler navbar-toggler-right" id="cls-btn" type="button" data-toggle="collapse" data-target="#sidebar-nav" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button> */}
	            <div className="sidenav-header d-flex align-items-center justify-content-center">

	              <div className="sidenav-header-inner text-center">
	              	<img style={{width:'100%'}} src="/img/fabgearlogo.png" alt="person" className="img-fluid" />
	                <h2 className="h5 text-uppercase">FABGEAR</h2>
					{/* <span className="text-uppercase">Web Developer</span> */}
	              </div>
	              <div className="sidenav-header-logo"><a href="index.html" className="brand-small text-center"> <strong>B</strong><strong className="text-primary">D</strong></a></div>
	            </div>
	            <div className="main-menu">

	            	{hasAccess("reports", "distribution")  &&
						<ul id="side-main-menu" className="side-menu list-unstyled">
							<li className={this.props.home} ><Link to="/dashboard"><i className="fa fa-home"></i><span>Dashboard</span></Link></li>
						</ul>
					}

	            	{hasAccess("orders", "add")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.addOrder} ><Link to="/addOrder"><i className="icon-interface-windows"></i><span>Add Orders</span></Link></li>
						</ul>
					}

	            	{hasAccess("orders", "view")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.orders} ><Link to="/orders"><i className="icon-interface-windows"></i><span>Orders</span></Link></li>
						</ul>
					}

					{hasAccess("orders", "edit")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.OrderEntries} ><Link to="/pending-orders"><i className="icon-interface-windows"></i><span>Pending Orders</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.appNWebOrders} ><Link to="/app-web-orders"><i className="icon-interface-windows"></i><span>App & Web Orders</span></Link></li>
						</ul>
					}

					{hasAccess("orders", "edit")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.OrderEntries} ><Link to="/order-entries"><i className="icon-interface-windows"></i><span>Order Entry List</span></Link></li>
						</ul>
					}

					{hasAccess("orders", "*")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.ordertasks} ><Link to="/approvals"><i className="icon-interface-windows"></i><span>Approvals</span></Link></li>
						</ul>
					}
					{hasAccess("reports", "distribution")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.ordertasks} ><Link to="/order-tasks"><i className="icon-interface-windows"></i><span>Order Tasks</span></Link></li>
						</ul>
					}
					{hasAccess("orders", "edit")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.fachats} ><Link to="/fb-chats"><i className="icon-interface-windows"></i><span>Facebook Chats</span></Link></li>
						</ul>
					}
					{hasAccess("orders", "view")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.BlankCovers} ><Link to="/blankcovers"><i className="icon-interface-windows"></i><span>Blank Cover List</span></Link></li>
						</ul>
					}

					{hasAccess("products", "edit")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.fileManager} ><Link to="/file-manager"><i className="icon-interface-windows"></i><span>File Manager</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.ModelRequest} ><Link to="/model-request"><i className="icon-interface-windows"></i><span>Model Request</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.distribution} > <Link to="/reports/distribution"><i className="icon-interface-windows" aria-hidden="true"></i><span>Distribution</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul className="side-menu list-unstyled">
							<li className={this.props.courierDistribution} > <Link to="/reports/courier-distribution"><i className="icon-interface-windows" aria-hidden="true"></i><span>Courier Dist</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul className="side-menu list-unstyled">
							<ul id="side-admin-menu" className='side-menu list-unstyled' >
								<li>
									<a href="#pages-nav-account" data-toggle="collapse" aria-expanded="false">
										<i className="icon-interface-windows"></i>Account
										<div className="arrow pull-right"><i className="fa fa-angle-down"></i></div>
									</a>
									<ul id="pages-nav-account" className={"collapse list-unstyled "+this.props.accountShow} >
										<li className={this.props.Accounts} > <Link to="/accounts"><i className="fa fa-money" aria-hidden="true"></i><span>Account</span></Link></li>
										<li className={this.props.EntryCategories} > <Link to="/entrycategories"><i className="fa fa-money" aria-hidden="true"></i><span>Entry Category</span></Link></li>
									</ul>
								</li>
							</ul>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul id="side-admin-menu" className='side-menu list-unstyled' >
							<li> <a href="#pages-nav-tasks" data-toggle="collapse" aria-expanded="false"><i className="icon-interface-windows"></i><span>Tasks</span> <span className="badge badge-danger">{this.countTask()}</span>
									<div className="arrow pull-right"><i className="fa fa-angle-down"></i></div></a>
								<ul id="pages-nav-tasks" className={"collapse list-unstyled "+this.props.taskshow} >
									<li className={this.props.tasks} > <Link to="/tasks"><i className="fa fa-users" aria-hidden="true"></i><span>Tasks</span></Link></li>
									<li className={this.props.CompleteTasks} > <Link to="/tasks/complete"><i className="fa fa-users" aria-hidden="true"></i><span>Completed</span></Link></li>
								</ul>
							</li>
						</ul>
					}

		            {hasAccess("products", "view")  &&
	            		<ul id="side-admin-menu" className="side-menu list-unstyled">
		                <li> <a href="#pages-nav-list1" data-toggle="collapse" aria-expanded="false"><i className="icon-interface-windows"></i><span>Products</span>
	                      <div className="arrow pull-right"><i className="fa fa-angle-down"></i></div></a>
		                  <ul id="pages-nav-list1" className={"collapse list-unstyled "+this.props.productshow} >

		                    <li  hidden={!hasAccess("products", "add")}  className={this.props.addProducts} > <Link to="/addProducts"><i className="fa fa-cubes" aria-hidden="true"></i><span>Add Products</span></Link></li>

			                <li hidden={!hasAccess("products", "add")} className={this.props.UploadImages} > <Link to="/UploadImages"><i className="fa fa-cubes" aria-hidden="true"></i><span>Upload Images</span></Link></li>

							<li hidden={!hasAccess("products", "add")} className={this.props.GenarateProducts} > <Link to="/GenarateProducts"><i className="fa fa-cubes" aria-hidden="true"></i><span>Generate Products</span></Link></li>

			                <li  className={this.props.products} > <Link to="/products"><i className="fa fa-cubes" aria-hidden="true"></i><span>Products</span></Link></li>
			                <li hidden={!hasAccess("products", "edit")} className={this.props.ModelGroup} > <Link to="/ModelGroup"><i className="fa fa-users" aria-hidden="true"></i><span>Model Group</span></Link></li>
											<li hidden={!hasAccess("products", "edit")} className={this.props.models} > <Link to="/models"><i className="fa fa-users" aria-hidden="true"></i><span>Models</span></Link></li>
			                <li hidden={!hasAccess("products", "edit")} className={this.props.categories} > <Link to="/categories"><i className="fa fa-users" aria-hidden="true"></i><span>Categories</span></Link></li>
			                <li hidden={!hasAccess("products", "edit")} className={this.props.materials} > <Link to="/materials"><i className="fa fa-users" aria-hidden="true"></i><span>Materials</span></Link></li>
							<li hidden={!hasAccess("products", "edit")} className={this.props.ProductType} > <Link to="/product-types"><i className="fa fa-users" aria-hidden="true"></i><span>Product Type</span></Link></li>
			                <li hidden={!hasAccess("products", "edit")} className={this.props.coupons} > <Link to="/coupon"><i className="fa fa-users" aria-hidden="true"></i><span>Coupons</span></Link></li>

							<li hidden={!hasAccess("products", "edit")} className={this.props.coupons} > <Link to="/album-links"><i className="fa fa-image" aria-hidden="true"></i><span>Album Links</span></Link></li>
		                  </ul>
		                </li>
		              </ul>
		          	}

					{hasAccess("products", "view")  &&
	            		<ul id="side-admin-menu" className="side-menu list-unstyled">
		                <li> <a href="#pages-nav-list-customi" data-toggle="collapse" aria-expanded="false"><i className="icon-interface-windows"></i><span>Customizer</span>
	                      <div className="arrow pull-right"><i className="fa fa-angle-down"></i></div></a>
		                  <ul id="pages-nav-list-customi" className={"collapse list-unstyled "+this.props.Customizershow} >
		                    <li  hidden={!hasAccess("products", "add")}  className={this.props.Customizer} > <Link to="/customizer"><i className="fa fa-cubes" aria-hidden="true"></i><span>New Customize</span></Link></li>
							<li  hidden={!hasAccess("products", "add")}  className={this.props.Templates} > <Link to="/templates"><i className="fa fa-cubes" aria-hidden="true"></i><span>Templates</span></Link></li>
							<li  hidden={!hasAccess("products", "add")}  className={this.props.CustomizerFonts} > <Link to="/customizer-fonts"><i className="fa fa-cubes" aria-hidden="true"></i><span>Fonts</span></Link></li>
		                  </ul>
		                </li>
		              </ul>
		          	}

		          	{hasAccess("orders", "view")  &&
						<ul id="side-admin-menu" className="side-menu list-unstyled ">
							<li>
								<a href="#pages-nav-list2" data-toggle="collapse" aria-expanded="false">
									<i className="icon-interface-windows"></i><span>Orders</span>
									<div className="arrow pull-right"><i className="fa fa-angle-down"></i></div>
								</a>
								<ul id="pages-nav-list2" className={"collapse list-unstyled "+this.props.ordershow} >
									<li className={this.props.orders} > <Link to="/orders"><i className="fa fa-shopping-cart" aria-hidden="true"></i><span>Orders</span></Link></li>
									<li hidden={!hasAccess("orders", "add")} className={this.props.addOrder} > <Link to="/addOrder"><i className="fa fa-cart-plus" aria-hidden="true"></i><span>Add Order</span></Link></li>

									{hasAccess("reports", "distribution")  &&
										<>
											<li className={this.props.blacklist} > <Link to="/blacklist"><i className="fa fa-shopping-cart" aria-hidden="true"></i><span>Blacklist</span></Link></li>

											<li hidden={!hasAccess("orders", "edit")} className={this.props.courier} > <Link to="/courier"><i className="fa fa-truck" aria-hidden="true"></i><span>Courier</span></Link></li>

											<li hidden={!hasAccess("orders", "edit")} className={this.props.deliveryPerson} > <Link to="/deliveryPerson"><i className="fa fa-truck" aria-hidden="true"></i><span>Delivery Person</span></Link></li>

											<li hidden={!hasAccess("orders", "edit")} className={this.props.Locations} > <Link to="/locations"><i className="fa fa-map-marker" aria-hidden="true"></i><span>Delivery Location</span></Link></li>
										</>
									}
								</ul>
							</li>
						</ul>
		          	}

		          	{hasAccess("customers", "view")  &&
		              <ul id="side-admin-menu" className='side-menu list-unstyled' >
		                <li> <a href="#pages-nav-list3" data-toggle="collapse" aria-expanded="false"><i className="icon-interface-windows"></i><span>Customers</span>
		                    <div className="arrow pull-right"><i className="fa fa-angle-down"></i></div></a>
		                  <ul id="pages-nav-list3" className={"collapse list-unstyled "+this.props.customershow} >
		                     <li className={this.props.customers} > <Link to="/customer"><i className="fa fa-users" aria-hidden="true"></i><span>Customer</span></Link></li>
	                		 <li hidden={!hasAccess("customers", "edit")} className={this.props.customerareas} > <Link to="/customerareas"><i className="fa fa-users" aria-hidden="true"></i><span>Customer Areas</span></Link></li>
		                  </ul>
		                </li>
		              </ul>
					}

					{hasAccess("products", "edit")  &&
		              <ul id="side-admin-menu" className="side-menu list-unstyled ">
		                <li>
											<a href="#pages-nav-inventory" data-toggle="collapse" aria-expanded="false">
												<i className="icon-interface-windows"></i>
												<span>Inventory</span>
		                    <div className="arrow pull-right"><i className="fa fa-angle-down"></i></div>
											</a>
		                  <ul id="pages-nav-inventory" className={"collapse list-unstyled "+this.props.inventoryshow} >
								<li className={this.props.inventory} >
									<Link to="/inventory">
										<i className="fa fa-shopping-cart" aria-hidden="true"></i>
										<span>Inventory</span>
									</Link>
								</li>

								<li className={this.props.waste} >
									<Link to="/waste">
										<i className="fa fa-shopping-cart" aria-hidden="true"></i>
										<span>Waste</span>
									</Link>
								</li>

								<li className={this.props.inventoryReport} >
									<Link to="/inventory-report">
										<i className="fa fa-shopping-cart" aria-hidden="true"></i>
										<span>Report</span>
									</Link>
								</li>
		                  </ul>
		                </li>
		              </ul>
					}

					{hasAccess("reports", "distribution")  &&
		              <ul id="side-admin-menu" className="side-menu list-unstyled ">
		                <li>
											<a href="#pages-nav-reports" data-toggle="collapse" aria-expanded="false">
												<i className="icon-interface-windows"></i>
												<span>Reports</span>
		                    <div className="arrow pull-right"><i className="fa fa-angle-down"></i></div>
											</a>
		                  <ul id="pages-nav-reports" className={"collapse list-unstyled "+this.props.reportshow} >
								<li className={this.props.popularDesign} >
									<Link to="/popular-design">
										<i className="fa fa-shopping-cart" aria-hidden="true"></i>
										<span>Popular Design</span>
									</Link>
								</li>

								<li className={this.props.popularModel} >
									<Link to="/popular-model">
										<i className="fa fa-shopping-cart" aria-hidden="true"></i>
										<span>Popular Model</span>
									</Link>
								</li>
		                  </ul>
		                </li>
		              </ul>
					}

		            {/* {hasAccess("reports", "distribution")  &&
		              <ul id="side-admin-menu" className="side-menu list-unstyled ">
		                <li> <a href="#pages-nav-list-report" data-toggle="collapse" aria-expanded="false"><i className="icon-interface-windows"></i><span>Reports</span>
		                    <div className="arrow pull-right"><i className="fa fa-angle-down"></i></div></a>
		                  <ul id="pages-nav-list-report" className={"collapse list-unstyled "+this.props.reportshow} >
	                  		<li className={this.props.orders} > <Link to="/reports/distribution"><i className="fa fa-shopping-cart" aria-hidden="true"></i><span>Distribution</span></Link></li>
		                  </ul>
		                </li>
		              </ul>
		          	} */}

					{hasAccess("reports", "distribution")  &&
						<ul id="side-main-menu" className="side-menu list-unstyled">
							<li className={this.props.Logs} ><Link to="/logs"><i className="fa fa-home"></i><span>Logs</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul id="side-main-menu" className="side-menu list-unstyled">
							<li className={this.props.saleOffer} ><Link to="/sale-offer"><i className="fa fa-bell"></i><span>Sale Offer</span></Link></li>
						</ul>
					}

					{hasAccess("reports", "distribution")  &&
						<ul id="side-main-menu" className="side-menu list-unstyled">
							<li className={this.props.offers} ><Link to="/offers"><i className="fa fa-bell"></i><span>Offers & Notifications</span></Link></li>
						</ul>
					}

					<ul id="side-main-menu" className="side-menu list-unstyled">
		                <li className={this.props.profile} ><Link to="/profile"><i className="fa fa-user"></i><span>Profile</span></Link></li>
		            </ul>
					<ul id="side-main-menu" className="side-menu list-unstyled">
		                <li ><Link onClick={this.logout.bind(this)} to="/login"><i className="fa fa-sign-out"></i><span>LOGOUT</span></Link></li>
		            </ul>
	            </div>

	          </div>
	        </nav>
		);
	}
})


export default Sidebar;
