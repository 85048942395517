import React, {useEffect, useState} from 'react'

export default function FunctionalPage() {
	const [testvar, setTestvar] = useState(0);

	useEffect(() => {
		setTestvar(5)
	}, []);
	return (
		<div>functionalpage {testvar}</div>
	)
}
