import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {baseurl} from './utility/utility.js';

const { compose, withProps, withState, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");


class Locations extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			locationData:[],
			persons:[],
			isMarkerShown: false,
			latitude:'',
			longitude:'',
		};
	}

	componentWillMount(){
		sendToLoginIfNoAccess("orders","edit", this, "/Locations");
		 axios.get(baseurl()+'/deliverypersons', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response) => {
	     	console.log(response);
			if(response.data.success === true){
				this.setState({persons: response.data.result})
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
		this.loadLocations();
	}

	loadLocations(name){
		axios.get(baseurl()+'/locations/'+name , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response, data)=> {
	     console.log(response);
			if(response.data.result !== null){
				data.latitude =  response.data.result.latitude;
				data.longitude =  response.data.result.longitude;
				return data;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}


  render() {
  	var location = [{"_id":"5ab9ff0b67d6d60c747954e9","updatedAt":"2018-03-27T08:21:31.881Z","createdAt":"2018-03-27T08:21:31.881Z","deliveryPerson":"Habib","longitude":90.08400000000002,"latitude":24.231998333333335,"__v":0},{"_id":"5aba17beca834a237c7cbb6b","updatedAt":"2018-03-27T10:06:54.449Z","createdAt":"2018-03-27T10:06:54.449Z","deliveryPerson":"Babu","longitude":90.3949227,"latitude":23.7462261,"__v":0}];

  	const allmodels = location.map(function(data, props){
		return(
			<Marker
		      position={{ lat: data.latitude, lng: data.longitude }}
		      onClick={props.onToggleOpen}
		    >
		      <InfoWindow onCloseClick={props.onToggleOpen}>
		        <div>
		          {data.deliveryPerson}
		        </div>
		      </InfoWindow>
		    </Marker>
			)
	}, this);

	const MapWithControlledZoom = compose(
	  withProps({
	    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBvWqM6bx4BetPvO6nAbwTf2Ay6_QtEpLE&v=3.exp&libraries=geometry,drawing,places",
	    loadingElement: <div style={{ height: `100%` }} />,
	    containerElement: <div style={{ height: `400px` }} />,
	    mapElement: <div style={{ height: `100%` }} />,
	  }),
	  withState('zoom', 'onZoomChange', 8),
	  withHandlers(() => {
	    const refs = {
	      map: undefined,
	    }

	    return {
	      onMapMounted: () => ref => {
	        refs.map = ref
	      },
	      onZoomChanged: ({ onZoomChange }) => () => {
	        onZoomChange(refs.map.getZoom())
	      }
	    }
	  }),
	  withScriptjs,
	  withGoogleMap
	)(props =>
	  <GoogleMap
	    defaultCenter={{ lat: 23.746806, lng: 90.394562 }}
	    zoom={props.zoom}
	    ref={props.onMapMounted}
	    onZoomChanged={props.onZoomChanged}
	  >
	    {allmodels}
	  </GoogleMap>
	);

    return (
        <div >
        	<Sidebar Locations = "active" ordershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container Log spd-mt-30">
					<div className="row">
						<div className="col-12 text-center" >
							<h1>Deliveries Last Location</h1>
						</div>
						<div className="col-12">
							<MapWithControlledZoom />
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default Locations;
