import React, {Component} from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import {loginchk} from './utility/security';
import {ToastContainer} from 'react-toastify';
import {baseurl, getLocalItem, notify, setLocalItem} from './utility/utility.js';


class Cart extends Component {
	constructor(props){
		super(props);
		this.state = {
			allcartProduct:[],
			cartQty:0,
			total:0.00,
			discountedTotal:0.00,
			discountTk:0,
			email:'',
			password:'',
			confirmPassword:'',
			customerName:'',
			customerPhone:'',
			customerAddress:'',
			userExists: false,
			couponData:{"discountTk":0,"discountPct":0},
		};
	}

	componentWillReceiveProps(nextProps){
		this.loadCartWishlist();
	}

	componentWillMount(){
	  	if(localStorage.getItem('account') !== null && localStorage.getItem('account') !== 'null'){
	  		var account = getLocalItem('account');
	  		this.setState({email : account.user.email});
	  		this.setState({customerName : account.user.customer.name});
	  		this.setState({customerPhone : account.user.customer.phone});
	  		this.setState({customerAddress : account.user.customer.address});
		  }
		  this.loadCartWishlist();
	}

	loadCartWishlist(){
		if(getLocalItem('cart') !== 'null'){
			var cart = getLocalItem('cart');
			this.setState({allcartProduct : cart});
			this.setState({cartQty : cart.length});
			this.calculateTotal(cart);
		}
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "customerName"){
			this.setState({customerName : input.value});
		}
		else if(input.name === "customerPhone"){
			this.setState({customerPhone : input.value});
		}
		else if(input.name === "customerAddress"){
			this.setState({customerAddress : input.value});
		}
		else if(input.name === "notes"){
			this.setState({notes : input.value});
		}
		else if(input.name === "email"){
			this.setState({email : input.value});
		}
		else if(input.name === "password"){
			this.setState({password : input.value});
		}
		else if(input.name === "confirmPassword"){
			this.setState({confirmPassword : input.value});
		}
		else if(input.name === "couponCode"){
			this.setState({couponCode : input.value});
		}
	}

	changeQuantity(e){
		var input = e.target;
		if(input.value > 0 || input.value === ''){
			var arrayvar = getLocalItem("cart");
			arrayvar[input.name].quantity = input.value;
			setLocalItem( "cart" , arrayvar);
			this.loadCartWishlist();
		}
	}

	calculatelowestPriceProduct(products, freeProduct){
		var chars = _.orderBy(products, ['price'],['asc']);
		//console.log(chars);
		//console.log(freeProduct);
		var discount = 0;
		var countedProduct = 0;
		for(var n = 0; n < chars.length; n++){
			if(countedProduct >= freeProduct){
				break;
			}else{
				for(var x = 0; x < chars[n].quantity; x++){
					if(countedProduct >= freeProduct){
						break;
					}else{
						//console.log(countedProduct);
						discount += chars[n].price;
						countedProduct += 1;
					}
				}
			}
		}
		return discount;
	}

	calculateDiscount(products){
		var freeOnBuy = 4;
		var disOnBuy = 2;
		var freeProduct  = 0;
		var totalnumberOFProduct = 0;
		var tkdiscount = 0;
		for(var i= 0; i < products.length; i++){
			totalnumberOFProduct += parseFloat(products[i].quantity);
		}
		if(totalnumberOFProduct >= freeOnBuy){
			freeProduct = Math.floor(totalnumberOFProduct/freeOnBuy);
		}
		if(totalnumberOFProduct % freeOnBuy >= disOnBuy){
			tkdiscount = 100;
		}
		////console.log(freeProduct, remainder, tkdiscount);
		var  productDiscount = this.calculatelowestPriceProduct(products, freeProduct);
		//console.log(productDiscount, tkdiscount);
		this.setState({discountTk : tkdiscount+productDiscount});
		var totalDisOnbuy = tkdiscount+productDiscount;
		return totalDisOnbuy;
	}

	calculateTotal(arrayvar, couponData){
		if(couponData === undefined || couponData === null){
			couponData = this.state.couponData;
		}
		var totalDisOnbuy = this.calculateDiscount(arrayvar, couponData);
		var total = 0;
		var discountedTotal = 0;
		var products = arrayvar;
		for(var i =0; i < products.length; i++){
			products[i].total = (products[i].price * products[i].quantity - products[i].discountTk ) * (100 - products[i].discountPct)/100;
			total += products[i].total;
		}
		if(couponData !== '' && total >= couponData.minOrder){
			discountedTotal = (total-parseFloat(couponData.discountTk))*(100-parseFloat(couponData.discountPct))/100;
		}else{
			discountedTotal = total;
		}
		this.setState({total : total});
		this.setState({discountedTotal : discountedTotal-totalDisOnbuy});
		this.setState({due : discountedTotal-totalDisOnbuy});
	}

	selectOrderModel(index, model){
		var arrayvar = getLocalItem("cart");
		arrayvar[index].selectedModel  = model
		this.setState({ allcartProduct: arrayvar });
		//console.log(arrayvar);
		setLocalItem( "cart" , arrayvar);
	}

	login(){
		axios.post(baseurl()+'/account/login', {
		    email:this.state.email,
		    password:this.state.password,
		  })
		  .then((response)=> {
		  	//console.log(response);
			  if(response.data.success === true){
			  	var account = {
			  		token:response.data.token,
			  		user:response.data.user,
			  		access:response.data.access,
			  	}
		      	setLocalItem( "account" , account);
		      	this.submitOrder(response.data.user.customer._id);
		      }else{
		      	for(var i = 0; i<response.data.msg.length; i++){
		      		notify(response.data.msg[i].msg, "#F44336");
		      	}
		      }
		  })
		  .catch(function (error) {
		    //console.log(error);
		  });
	}

	register(){
		 axios.post(baseurl()+'/account/signup', {
		    email:this.state.email,
		    password:this.state.password,
			confirmPassword:this.state.confirmPassword,
		  })
		  .then((response)=> {
		  	//console.log(response);
			    if(response.data.success === true){
			    	var account = {
				  		token:response.data.token,
				  		user:response.data.user,
				  		access:response.data.access,
				  	}
			      	setLocalItem( "account" , account);
			    	this.submitOrder(response.data.user.customer._id);
		      	}else{
		      		for(var i = 0; i < response.data.msg.length; i++){
		      			notify(response.data.msg[i].msg, "#F44336");
		      		}
		      	}
		  })
		  .catch(function (error) {
		    //console.log(error);
		  });
	}

	confirmOrder(e){
		if(loginchk() === false){
			var account = getLocalItem('account');
			this.submitOrder(account.user.customer._id);
		}else{
			if(this.state.userExists === true){
				this.login();
			}else if([this.state.email,this.state.password,this.state.confirmPassword,this.state.customerName,this.state.customerPhone,this.state.customerAddress].includes("")){
				notify("All Fill Required", "#F44336");
			}else{
				this.register();
			}
		}
		e.preventDefault();
	}

	submitOrder(customerId){
		axios.post(baseurl()+'/orders', {
		 	orderDate: new Date(),
		 	customerId: customerId,
			customerName: this.state.customerName,
			customerAddress:this.state.customerAddress,
			customerArea:'Select Area',
			customerPhone: this.state.customerPhone,
			customerEmail: this.state.email,
			deliveryPerson: 'Select Delivery Person',
			deliveryPersonEmail:'Not Selected',
			deliveryPersonPhone:'Not Selected',
			conveyance:70,
			products: this.state.allcartProduct,
			discountTk: parseFloat(this.state.couponData.discountTk)+parseFloat(this.state.discountTk),
			discountPct: this.state.couponData.discountPct,
			payments:[],
			notes:this.state.notes,
			status: 'pending',
			total: this.state.discountedTotal,
			due: this.state.due,
		  })
		  .then((response) => {
		    //console.log(response);
		    if(response.data.success === true){
		    	//console.log(response.data.result);
		    	setLocalItem( "cart" , null);
  				this.loadCartWishlist();
  				this.backcart();
  				this.hidecouponSection();
  				notify("Order Submitted", "#28a745");
  				this.setState({ redirect: true });
			}
		  })
		  .catch(function (error) {
		    //console.log(error);
		  });
	}

	removeCart(index){
		var arrayvar = getLocalItem('cart');
		arrayvar.splice(index, 1);
		setLocalItem( "cart" , arrayvar);
		this.loadCartWishlist();
		notify("Remove From Cart!" , "#dc3545");
	}

	chkuserexists(){
		//console.log(this.state.email);
		axios.post(baseurl()+'/account/userexists', {
			email: this.state.email,
		})
		.then((response) => {
			//console.log(response);
			if(response.data.userExists === false){
				this.setState({userExists  : false});
				document.getElementById("hiddenConPass").style.display = "block";
			}else{
				this.setState({userExists  : true});
				document.getElementById("hiddenConPass").style.display = "none";
			}
		})
		.catch(function (error) {
			//console.log(error);
		});
	}

	varifyCopun(){
		axios.post(baseurl()+'/coupons/verify', {
			 code:this.state.couponCode,
		})
		.then((response) => {
			//console.log(response);
			if(response.data.success === true){
				var expiryDate = moment(response.data.coupon.expiryDate).endOf('day');
				var startDate = moment(response.data.coupon.startDate).endOf('day');
				var today = moment().endOf('day');
				if (expiryDate < today) {
				   notify("Coupon Expired!" , "#dc3545");
				}else if (startDate > today) {
				   notify("Coupon Will Avaliable After "+moment(startDate).format('DD/MM/YYYY') , "#dc3545");
				}else if(this.state.total < response.data.coupon.minOrder){
				   notify("Minimum Order Amount "+ response.data.coupon.minOrder+ " TK!" , "#dc3545");
				}else{
					this.setState({couponData  : response.data.coupon});
					this.calculateTotal(this.state.allcartProduct, response.data.coupon);
					notify("Coupon Added", "#28a745");
				}
			}else{
				notify(response.data.msg, "#dc3545");
			}
		})
		.catch(function (error) {
			//console.log(error);
		});
	}

	opencart(){
		document.getElementById("cartlist").style.display = "block";
	}

	backcart(){
		document.getElementById("cartlist").style.display = "none";
		document.getElementById("cartlist-body").style.display = "block";
		document.getElementById("checkout-form").style.display = "none";
		document.getElementById("back-btn").style.display = "none";
	}

	chkallmodelSelect(){

	}

	checkoutForm(){
		var allcartProduct = getLocalItem('cart');
		if(localStorage.getItem('cart') === null || typeof allcartProduct[0] === 'undefined' || localStorage.getItem('cart') === 'null'){
			notify("Cart Empty!" , "#dc3545");
		}else if(_.findIndex(allcartProduct, function(o) { return o.selectedModel === 'Select Model'; }) !== -1){
			notify("Please Select Model!" , "#dc3545");
		}else{
			document.getElementById("cartlist-body").style.display = "none";
			document.getElementById("checkout-form").style.display = "block";
			document.getElementById("back-btn").style.display = "block";
		}
	}

	showCartList(){
		document.getElementById("cartlist-body").style.display = "block";
		document.getElementById("checkout-form").style.display = "none";
		document.getElementById("back-btn").style.display = "none";
	}

	showcouponSection(){
		document.getElementById("coupon-section").style.display = "block";
		document.getElementById("coupon-code-input").focus()
	}

	hidecouponSection(){
		var couponData = {"discountTk":0,"discountPct":0};
		this.setState({couponData  : couponData});
		this.setState({couponCode  : ''});
		this.calculateTotal(this.state.allcartProduct, couponData);
		document.getElementById("coupon-section").style.display = "none";
	}

	render() {

		const { redirect } = this.state;
		 if (redirect) {
		   return <Redirect to='/myorders'/>;
		 }

		const allcartProduct = this.state.allcartProduct.map(function(data, index){
			const singleProModel = data.models.map(function(data){
				return(
					<span key={data.model} onClick={this.selectOrderModel.bind(this , index, data.model)}  className="dropdown-item btn btn-default" href="#">{data.model}</span>
				)
			}, this);
			var imgUrl = baseurl()+'/uploads/productimages/'+data.imageFolder+data.images[0];
			return(
				<div key={index} className="row">
					<div className="col-md-2">
						<img className="banner-img" src={imgUrl.toLowerCase()}  alt="banner" />
					</div>
					<div className="col-md-5">
						<p className="model-title"><Link to={"/viewproduct/"+data._id} >{data.title}</Link></p>
						<p className="price">৳ {data.price}</p>
					</div>
					<div className="col-md-3">
						<div className="dropdown">
							<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					    		{data.selectedModel}
					 		</button>
				  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    			{singleProModel}
				  			</div>
						</div>
					</div>
					<div className="col-md-1">
						<input value={data.quantity}  name={index}  onChange={this.changeQuantity.bind(this)} className="form-control quanty-txt" id="number" type="number"/>
					</div>
					<div className="col-md-1 top-right"><i onClick={this.removeCart.bind(this, index)} className="fa fa-times pull-right"></i>
					</div>
				</div>
				)
		}, this);

	    return (
	        	<div className="cart-wrap">
	        		<div className="carts">
						<button type="button" onClick={this.opencart.bind()} className="btn cart-btn"><i className="fa fa-shopping-bag" aria-hidden="true"></i><span className="cart-total">৳ {this.state.discountedTotal}</span></button>
	        		</div>
	        		<div className="cart-count">
						<span>{this.state.cartQty}</span>
	        		</div>
	        		<div className="cartlist-wrap" id="cartlist">
						<div className="cart-header">
							<p className="whistlist"><button onClick={this.showCartList.bind()} className="btn btn-default" id="back-btn">Back to Checkout</button></p>
							<p className="total">৳ {this.state.discountedTotal} (Discounted {this.state.discountTk+this.state.couponData.discountTk})</p>
							<button onClick={this.backcart.bind()}><i className="fa fa-times" aria-hidden="true"></i></button>
						</div>
						<div className="cartlist-body" id="cartlist-body">
							{/* row reapeat start */}
							{allcartProduct}
							<div className="row" id="coupon-head" onClick={this.showcouponSection.bind(this)} >
								<div className="col-12">
									Have a Special Code?
								</div>
							</div>
							<div id="coupon-section">
								<div className="row coupon-section" >
									<div className="col-md-10 col-sm-10 col-7">
										<input id="coupon-code-input" type="text" name="couponCode" className="form-control" value={this.state.couponCode} onChange={this.changeInput.bind(this)} placeholder='Code' />
									</div>
									<div className="col-md-1 col-sm-1 col-3">
										<button onClick={this.varifyCopun.bind(this)}  className="btn btn-primary">Go</button>
									</div>
									<div className="col-md-1 col-sm-1 col-2" onClick={this.hidecouponSection.bind(this)}>
										<i className="fa fa-times pull-right"></i>
									</div>
								</div>
							</div>
							<div className="cartlist-footer">
								<span onClick={this.checkoutForm.bind(this)} >Checkout</span>
							</div>
						{/* row reapeat end */}
						</div>
						<div className="cartlist-body" id="checkout-form">
		        			<div className="col-12 billing-information  spd-mt-30">
		        				<h1 className="btn btn-billing col-12">Billing Information</h1>
		        				<form>
		        				<input hidden={!loginchk()} onBlur={this.chkuserexists.bind(this)} type="email" className="form-control" name="email" value={this.state.email} onChange={this.changeInput.bind(this)} placeholder='Email'  />
		        				<input hidden={!loginchk()} type="password" className="form-control" name="password" value={this.state.password} onChange={this.changeInput.bind(this)} placeholder='Password'  />
		        				<input id="hiddenConPass" type="password" className="form-control" name="confirmPassword" value={this.state.confirmPassword} onChange={this.changeInput.bind(this)} placeholder='Confirm Password'  />
		        				<input type="text" className="form-control" name="customerName" value={this.state.customerName} onChange={this.changeInput.bind(this)} placeholder='Name'  />
		        				<input type="text" className="form-control" name="customerPhone" value={this.state.customerPhone} onChange={this.changeInput.bind(this)} placeholder='Phone'  />
		        				<textarea className="form-control" rows='3' name="customerAddress" value={this.state.customerAddress} onChange={this.changeInput.bind(this)} placeholder='Address' >
		        				</textarea>
		        				{/*
		        				<textarea className="form-control" rows='3' name="notes" value={this.state.notes} onChange={this.changeInput.bind(this)} placeholder='Please Inform us with Extra Query'>
		        				</textarea>
		        				*/}
		        				<button type="submit" onClick={this.confirmOrder.bind(this)} className='btn btn-billing spd-mt-30 pull-right'>Confirm Order</button>
		        				</form>
		        			</div>
						</div>

		        	</div>
		        	<ToastContainer />
	        	</div>
	    );
	}
}

export default Cart;
