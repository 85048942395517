import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import './App.css';
import axios from 'axios';
import {baseurl} from './utility/utility.js';

import {observer} from "mobx-react";
import _ from "lodash";

import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {Button, Checkbox, Input, Modal, Popover, Select, Spin} from 'antd';


const Dashboard = observer(class App extends Component {
	constructor(props){

		super(props);

		this.state={

			loggeduser:loggeduser(),
			folderData:{
				children:[]
			},
			activeFolderData:{
				children:[],
				path:''
			},
			clickHistory:[],
			selectedEle:[],
			activityMsg:'Loading',

			searchWord:'',
			replaceWord:'',

			addingFolderName:'',
			showAddFolderModal:false,

			showTreeModal:false,
			copyNRemoveSelectedPath:'',
			treeMode:'add',

			seletedFormat:'',

			width:'',
			quality:'',

			sortBy:'type',
			sort:'asc',

			filterSearchWord:''
		};
	}

	componentWillMount(){

		sendToLoginIfNoAccess("products","edit", this, "/dashboard");

		this.LoadFolderData()

	}

	LoadFolderData = (activePath) => {
    this.setState({ activityMsg: "Loading Directory" });

    axios
      .post(
        baseurl() + "/filemanager/getfiles",
        {},
        { headers: { authorization: this.state.loggeduser.token } }
      )
      .then((response) => {
        console.log(response);

        if (response.data) {
          if (activePath) {
            this.setState({ folderData: response.data });

            var addressArray = activePath
              .replace(
                "/var/www/images.fabgearshop.com/ftp/imageuploader/api.fabgearshop.com/public/uploads/",
                ""
              )
              .split("/");

            var data = response.data;

            for (let index = 0; index < addressArray.length; index++) {
              data = data.children.find((ele) => {
                return ele.name == addressArray[index];
              });
            }

            this.setState({ activeFolderData: data });
          } else {
            this.setState({
              folderData: response.data,
              activeFolderData: response.data,
            });
          }

          this.setState({ activityMsg: "" });
        } else {
          this.setState({ activityMsg: "" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ activityMsg: "" });
      });
  };

  SetActiveFolder = (folderData) => {
    var clickHistory = this.state.clickHistory;
    clickHistory.push(folderData.path);
    this.setState({
      activeFolderData: folderData,
      selectedEle: [],
      clickHistory: clickHistory,
    });
  };

  Back = () => {
    var folderData = this.state.folderData;
    var clickHistory = this.state.clickHistory;
    var lastItem = clickHistory[clickHistory.length - 2];

    if (lastItem) {
      var addressArray = lastItem
        .replace(
          "/var/www/images.fabgearshop.com/ftp/imageuploader/api.fabgearshop.com/public/uploads/",
          ""
        )
        .split("/");

      var data = folderData;

      for (let index = 0; index < addressArray.length; index++) {
        data = data.children.find((ele) => {
          return ele.name == addressArray[index];
        });
      }

      clickHistory.splice(clickHistory.length - 1, 1);
      this.setState({ activeFolderData: data, clickHistory: clickHistory });
    } else {
      this.setState({
        activeFolderData: this.state.folderData,
        clickHistory: [],
      });
    }
  };

  SelectAll = () => {
    this.setState({
      selectedEle: this.state.activeFolderData.children.filter((ele) => {
        return (
          ele.type == "file" &&
          ele.name
            .toLowerCase()
            .indexOf(this.state.filterSearchWord.toLowerCase()) > -1
        );
      }),
    });
  };

  SelectEle = (e, data) => {
    console.log(e.shiftKey);

    if (e.shiftKey && this.state.selectedEle.length > 0) {
      var allEle = this.state.activeFolderData.children.filter((ele) => {
        return (
          ele.type == "file" &&
          ele.name
            .toLowerCase()
            .indexOf(this.state.filterSearchWord.toLowerCase()) > -1
        );
      });

      var previousSelectedIndex = allEle.findIndex((ele) => {
        return ele.path == this.state.selectedEle[0].path;
      });
      var latestSelectedIndex = allEle.findIndex((ele) => {
        return ele.path == data.path;
      });

      if (previousSelectedIndex < latestSelectedIndex) {
        this.setState({
          selectedEle: allEle.slice(
            previousSelectedIndex,
            latestSelectedIndex + 1
          ),
        });
      } else {
        this.setState({
          selectedEle: allEle.slice(
            latestSelectedIndex,
            previousSelectedIndex + 1
          ),
        });
      }
    } else {
      var selectedEle = this.state.selectedEle;
      selectedEle.push(data);
      this.setState({ selectedEle: selectedEle });
    }
  };

  RemoveSelectEle = (data) => {
    var selectedEle = this.state.selectedEle;
    var index = selectedEle.findIndex((ele) => {
      return ele.path == data.path;
    });
    selectedEle.splice(index, 1);
    this.setState({ selectedEle: selectedEle });
  };

  ChkEleIsSelected = (data) => {
    return (
      this.state.selectedEle.findIndex((ele) => {
        return ele.path == data.path;
      }) > -1
    );
  };

  GetPathList = (data) => {
    return data.map((ele) => ele.path);
  };

  ReFresh = () => {
    this.LoadFolderData(
      this.state.clickHistory[this.state.clickHistory.length - 1]
    );
  };

  UploadFile = (e) => {
    console.log(this.state.activeFolderData);

    this.setState({ activityMsg: "Uploading" });

    var formdata = new FormData();

    formdata.append(
      "uploadFolder",
      this.state.activeFolderData.path.replace(
        "/var/www/images.fabgearshop.com/ftp/imageuploader/api.fabgearshop.com/public/uploads/",
        ""
      ) + "/"
    );

    for (let index = 0; index < e.target.files.length; index++) {
      formdata.append("files", e.target.files[index]);
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    console.log(JSON.stringify(formdata));

    fetch(baseurl() + "/filemanager/uploadfiles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        this.setState({ activityMsg: "" });
        this.ReFresh();
      })
      .catch((error) => console.log("error", error));
  };

	DeleteConfirm = () => {
		Modal.confirm({
			title: 'Do you Want to delete these items?',
			// icon: <i className='fa fa-info'></i>,
			// content: 'Some descriptions',
			onOk: () => {
				this.DeleteFiles()
			},
			onCancel: () => {
				console.log('Cancel');
			},
		})
	}

	Confirm = (onOk) => {
		Modal.confirm({
			title: 'Sure this action?',
			// icon: <i className='fa fa-info'></i>,
			// content: 'Some descriptions',
			onOk: () => {
				onOk()
			},
			onCancel: () => {
				console.log('Cancel');
			},
		})
	}

	DeleteFiles = () => {

		this.setState({activityMsg:'Deleting'})


		axios.post(baseurl()+'/filemanager/deleteFiles' , {

			files:this.GetPathList(this.state.selectedEle)

		}, { headers: { authorization: this.state.loggeduser.token } })
	    .then((response) => {

			console.log(response)

			if(response.data.success){

				this.setState({selectedEle:[]});
				this.ReFresh()

			}else{

				this.setState({activityMsg:''})

			}

	    })
	    .catch((error) => {
		  console.log(error);
			this.setState({activityMsg:''})

	    });

	}

	SearchNReplace = () => {

		this.setState({activityMsg:'Replacing'})


		axios.post(baseurl()+'/filemanager/renameFiles' , {

			files:this.GetPathList(this.state.selectedEle),
			"search":this.state.searchWord,
    		"replaceWith":this.state.replaceWord

		}, { headers: { authorization: this.state.loggeduser.token } })
	    .then((response) => {

			console.log(response)

			if(response.data.success){

				this.setState({selectedEle:[], searchWord:'', replaceWord:''});
				this.ReFresh()

			}else{

				this.setState({activityMsg:''})

			}

	    })
	    .catch((error) => {
		  console.log(error);
			this.setState({activityMsg:''})

	    });
	}

	AddFolder = () => {

		this.setState({activityMsg:'Adding Folder'})

		axios
      .post(
        baseurl() + "/filemanager/makedir",
        {
          directory:
            this.state.activeFolderData.path.replace(
              "/var/www/images.fabgearshop.com/ftp/imageuploader/api.fabgearshop.com/public/uploads/",
              ""
            ) +
            "/" +
            this.state.addingFolderName,
        },
        { headers: { authorization: this.state.loggeduser.token } }
      )
      .then((response) => {
        console.log(response);

        if (response.data.success) {
          this.setState({ showAddFolderModal: false, addingFolderName: "" });
          this.ReFresh();
        } else {
          this.setState({ activityMsg: "" });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ activityMsg: "" });
      });
	}

	FolderTree = (data) => {
		return (
			<ul style={{paddingLeft:30}} className='treeUl'>
				<li><Checkbox onChange={() => this.setState({copyNRemoveSelectedPath:data.path})} checked={data.path == this.state.copyNRemoveSelectedPath} /> <i className='fa fa-folder-open-o' style={{marginLeft:10}}></i> {data.name}</li>
				{data.children.filter((ele) => ele.type == 'directory' ).map(childrenData =>
					<>
					{childrenData.children && childrenData.children.length > 0 ?
						this.FolderTree(childrenData)
						:
						<li><Checkbox onChange={() => this.setState({copyNRemoveSelectedPath:childrenData.path})} checked={childrenData.path == this.state.copyNRemoveSelectedPath} /> <i className='fa fa-folder-open-o' style={{marginLeft:10}}></i> {childrenData.name}</li>
					}
					</>
				)}
			</ul>
		);
	}

	CopyOrMove = () => {

		this.setState({activityMsg: this.state.treeMode == 'add' ? 'Copying' : 'Moving'})

		var url = this.state.treeMode == 'add' ? '/filemanager/copyFiles' : '/filemanager/moveFiles';

		axios.post(baseurl()+url , {

			files:this.GetPathList(this.state.selectedEle),
    		"newPath":this.state.copyNRemoveSelectedPath + '/'

		}, { headers: { authorization: this.state.loggeduser.token } })
	    .then((response) => {

			console.log(response)

			if(response.data.successes){

				this.setState({selectedEle:[], copyNRemoveSelectedPath:'', showTreeModal:false});
				this.ReFresh();

			}else{

				this.setState({activityMsg:''})

			}

	    })
	    .catch((error) => {
		  console.log(error);
			this.setState({activityMsg:''})
	    });
	}

	ChangeFormat = () => {

		this.setState({activityMsg: 'Changing Format'})

		axios.post(baseurl()+"/filemanager/changeImageFormat" , {

			files:this.GetPathList(this.state.selectedEle),
    		"newFormat": this.state.seletedFormat

		}, { headers: { authorization: this.state.loggeduser.token } })
	    .then((response) => {

			console.log(response)

			if(response.data.successes){

				this.setState({selectedEle:[], seletedFormat:'',});
				this.ReFresh();

			}else{

				this.setState({activityMsg:''})

			}

	    })
	    .catch((error) => {
		  console.log(error);
			this.setState({activityMsg:''})
	    });
	}

	Optimize = () => {

		console.log({
			images:this.GetPathList(this.state.selectedEle),
    		"width":this.state.width,
    		"quality":this.state.quality
		})

		this.setState({activityMsg: 'Optimizing'})

		axios.post(baseurl()+"/filemanager/optimizeFiles" , {

			images:this.GetPathList(this.state.selectedEle),
    		"width":parseInt(this.state.width),
    		"quality":parseInt(this.state.quality)

		}, { headers: { authorization: this.state.loggeduser.token } })
	    .then((response) => {

			console.log(response)

			if(response.data.success){

				this.setState({selectedEle:[], seletedFormat:'',});
				this.ReFresh();

			}else{

				this.setState({activityMsg:''})

			}

	    })
	    .catch((error) => {
		  console.log(error);
			this.setState({activityMsg:''})
	    });
	}


	render() {

		let {Option} = Select;

		let {activeFolderData, clickHistory} = this.state

		const content = (path) => {
			return (
        <div>
          <img
            style={{ width: 100 }}
            src={path.replace(
              "/var/www/images.fabgearshop.com/ftp/imageuploader/api.fabgearshop.com/public/uploads/",
              "https://api.fabgearshop.com/uploads/"
            )}
          />
        </div>
      );
		};

		return (
      <div className="home">
        <Sidebar home="active" />
        <div className="page">
          <Header />
          <div className="container-fluid">
            <div className="row spd-mt-30">
              {clickHistory.length > 0 && (
                <div className="col-1">
                  <Button onClick={() => this.Back()}>
                    <i
                      className="fa fa-arrow-left"
                      style={{ marginRight: 5 }}
                    ></i>
                    back
                  </Button>
                </div>
              )}
              <div className="col-11"> 
                <h2>
                  uploads/
                  {activeFolderData.path.replace(
                    "/var/www/images.fabgearshop.com/ftp/imageuploader/api.fabgearshop.com/public/uploads/",
                    ""
                  )}
                </h2>
              </div>
            </div>
            <div className="row spd-mt-30">
              <div className="col-5">
                <Button onClick={this.SelectAll}>Select All</Button>
                <Button
                  onClick={() => document.getElementById("uploadInput").click()}
                >
                  Upload File
                </Button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => this.UploadFile(e)}
                  id="uploadInput"
                  multiple
                />
                <Button onClick={this.DeleteConfirm}>
                  <i className="fa fa-trash"></i>
                </Button>
                <Button onClick={this.ReFresh}>
                  <i className="fa fa-refresh"></i>
                </Button>
                <Button
                  onClick={() => this.setState({ showAddFolderModal: true })}
                  title="Add folder"
                >
                  <i className="fa fa-folder-open-o"></i>
                </Button>

                <Button
                  onClick={() =>
                    this.setState({ showTreeModal: true, treeMode: "add" })
                  }
                  title="Copy"
                >
                  <i className="fa fa-copy"></i>
                </Button>
                <Button
                  onClick={() =>
                    this.setState({ showTreeModal: true, treeMode: "update" })
                  }
                  title="Move"
                >
                  <i className="fa fa-arrows"></i>
                </Button>
                <Select
                  defaultValue={this.state.seletedFormat}
                  style={{ width: 120 }}
                  onChange={(value) =>
                    value
                      ? this.setState(
                          { seletedFormat: value },
                          this.ChangeFormat
                        )
                      : null
                  }
                >
                  <Option value="">Select Format</Option>
                  <Option value=".jpg">JPG</Option>
                  <Option value=".png">PNG</Option>
                  <Option value=".gif">gif</Option>
                </Select>
              </div>
              <div className="col-4">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Input
                    placeholder="Search"
                    value={this.state.searchWord}
                    onChange={(e) =>
                      this.setState({ searchWord: e.target.value })
                    }
                  />
                  <Input
                    placeholder="Replace"
                    value={this.state.replaceWord}
                    onChange={(e) =>
                      this.setState({ replaceWord: e.target.value })
                    }
                  />
                  <Button onClick={this.SearchNReplace} type="primary">
                    <i className="fa fa-check"></i>
                  </Button>
                </div>
              </div>
              <div className="col-3">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Input
                    placeholder="Width"
                    value={this.state.width}
                    onChange={(e) => this.setState({ width: e.target.value })}
                  />
                  <Input
                    type="number"
                    placeholder="Quality"
                    value={this.state.quality}
                    onChange={(e) => this.setState({ quality: e.target.value })}
                  />
                  <Button onClick={this.Optimize} type="primary">
                    Optimize
                  </Button>
                </div>
              </div>
            </div>

            <div className="row spd-mt-30">
              <div className="col-12">
                <Input
                  placeholder="Search in folder"
                  value={this.state.filterSearchWord}
                  onChange={(e) =>
                    this.setState({ filterSearchWord: e.target.value })
                  }
                />
                {/* <Button onClick={this.Optimize} type='primary'>Optimize</Button> */}
              </div>
            </div>

            <div className="row spd-mt-30">
              <table style={{ width: "100%" }}>
                <tr style={{ cursor: "pointer" }}>
                  <th style={{ width: 50 }}>Select</th>
                  <th style={{ width: 50 }}></th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortBy: "name",
                        sort: this.state.sort == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    Name <i class="fa fa-sort" aria-hidden="true"></i>
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortBy: "size",
                        sort: this.state.sort == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    Size <i class="fa fa-sort" aria-hidden="true"></i>
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortBy: "type",
                        sort: this.state.sort == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    Type <i class="fa fa-sort" aria-hidden="true"></i>
                  </th>
                  <th
                    onClick={() =>
                      this.setState({
                        sortBy: "extension",
                        sort: this.state.sort == "asc" ? "desc" : "asc",
                      })
                    }
                  >
                    Extension <i class="fa fa-sort" aria-hidden="true"></i>
                  </th>
                </tr>
                {_.orderBy(
                  this.state.activeFolderData.children.filter((ele) => {
                    return (
                      ele.name
                        .toLowerCase()
                        .indexOf(this.state.filterSearchWord.toLowerCase()) > -1
                    );
                  }),
                  [this.state.sortBy],
                  [this.state.sort]
                ).map((data) => (
                  <tr>
                    <td>
                      {data.type == "file" && (
                        <Checkbox
                          checked={this.ChkEleIsSelected(data)}
                          onClick={(e) =>
                            this.ChkEleIsSelected(data)
                              ? this.RemoveSelectEle(data)
                              : this.SelectEle(e, data)
                          }
                        />
                      )}
                    </td>
                    <td>
                      {data.type == "file" ? (
                        <Popover content={content(data.path)}>
                          <div
                            onClick={() =>
                              data.type == "directory"
                                ? this.SetActiveFolder(data)
                                : {}
                            }
                            className="col-1"
                            style={{
                              margin: "10px 0px",
                              textAlign: "center",
                              cursor: "pointer",
                              position: "relative",
                            }}
                          >
                            <i
                              style={{
                                color:
                                  data.type == "directory" ? "#f5d370" : null,
                              }}
                              className={
                                data.type == "directory"
                                  ? "fa fa-folder fa-3x"
                                  : "fa fa-file fa-3x"
                              }
                            ></i>
                          </div>
                        </Popover>
                      ) : (
                        <div
                          onClick={() =>
                            data.type == "directory"
                              ? this.SetActiveFolder(data)
                              : {}
                          }
                          className="col-1"
                          style={{
                            margin: "10px 0px",
                            textAlign: "center",
                            cursor: "pointer",
                            position: "relative",
                          }}
                        >
                          <i
                            style={{
                              color:
                                data.type == "directory" ? "#f5d370" : null,
                            }}
                            className={
                              data.type == "directory"
                                ? "fa fa-folder fa-3x"
                                : "fa fa-file fa-3x"
                            }
                          ></i>
                        </div>
                      )}
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {data.name}
                      </p>
                    </td>

                    <td>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {data.size > 1024
                          ? (data.size / 1024).toFixed(2) + " KB"
                          : data.size + " bytes"}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {data.type}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {data.extension}
                      </p>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
        {this.state.activityMsg && (
          <div
            style={{
              position: "fixed",
              bottom: 50,
              right: 50,
              padding: 20,
              backgroundColor: "#fff",
              borderRadius: 5,
            }}
            className="shadow"
          >
            <h1 style={{ margin: 0 }}>
              <Spin /> {this.state.activityMsg}
            </h1>
          </div>
        )}

        <Modal
          title="Add Folder"
          visible={this.state.showAddFolderModal}
          onOk={this.AddFolder}
          onCancel={() => this.setState({ showAddFolderModal: false })}
        >
          <Input
            value={this.state.addingFolderName}
            placeholder="Folder Name"
            onChange={(e) =>
              this.setState({ addingFolderName: e.target.value })
            }
          />
        </Modal>

        {/* <Modal
				title="Add Folder"
				visible={this.state.showAddFolderModal}
				onOk={this.AddFolder}
				onCancel={() => this.setState({showAddFolderModal: false})}
			>
				<Input value={this.state.addingFolderName} placeholder='Folder Name' onChange={e => this.setState({addingFolderName:e.target.value})}   />
        	</Modal> */}

        <Modal
          title="Select Directory"
          visible={this.state.showTreeModal}
          onOk={this.CopyOrMove}
          onCancel={() => this.setState({ showTreeModal: false })}
        >
          {this.FolderTree(this.state.folderData)}
        </Modal>
      </div>
    );
	}
})

export default Dashboard;
