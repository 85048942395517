import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {ApprovalFields, baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {Button, Checkbox, Pagination} from 'antd'
import moment from 'moment'

import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import AppStore from './store/AppStore.js';
import {observer} from 'mobx-react';

const Approvals = observer(class Approvals extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			data:[],
			count:0,
			perpage:10,
			status:'pending',
			sortOrder:"asc",
			selected:[],
		};
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data._id),
		})
	};


	componentWillMount(){

		sendToLoginIfNoAccess("orders","edit", this, "/deliveryPersons");
		// sendToLoginIfNoAccess("orders","*", this, "/orders");

		this.LoadData(0, this.state.perpage)

	}

	LoadData = (start = 0, numRecords = 100) => {
		axios.get(baseurl()+`/approvals?start=${start}&numRecords=${numRecords}&status=${this.state.status}`, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				AppStore.approvals = response.data.result
				this.setState({
					data : response.data.result,
					count : response.data.count
				});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}


	Select(id){

		console.log(id)
		let selected = this.state.selected

		const index = selected.indexOf(id)

		if(index === -1){
			selected.push(id)
		}else{
			selected.splice(index, 1)
		}

		this.setState({selected:selected})
	}

	async MultipleAccept(data){
		if(data.length > 0){
			await this.accept(data[0])
			data.shift()
			this.MultipleAccept(data)
		}else{
			this.LoadData()
			notify("Success", "#0275d8");
		}
	}

	async MultipleReject(data){
		if(data.length > 0){
			await this.reject(data[0])
			data.shift()
			this.MultipleReject(data)
		}else{
			this.LoadData()
			notify("Success", "#0275d8");
		}
	}

	async MultipleDelete(data){
		if(data.length > 0){
			const res = await this.delete(data[0])
			console.log(res)
			data.shift()
			this.MultipleDelete(data)
		}else{
			this.LoadData()
			notify("Success", "#0275d8");
		}
	}

	async accept(_id){
		// axios.get(baseurl()+'/approvals/accept/'+data._id, {
		// 	headers: { authorization: this.state.loggeduser.token }
		// })
		// .then( (response) => {
		// 	console.log(response);
		// 	if(response.data.success === true){
		// 		this.LoadData()
		// 		notify("Success", "#0275d8");
		// 	}
		// })
		// .catch(function (error) {
		// 	console.log(error);
		// });
		try{
			const res = await axios.get(baseurl()+'/approvals/accept/'+_id, {
				headers: { authorization: this.state.loggeduser.token }
			})
			if(res.data.success === true){
				this.LoadData()
				notify("Success", "#0275d8");
			}
		}
		catch(e){
			console.log(e)
			return false
		}
	}

	async reject(_id){
		try{
			const res = await axios.get(baseurl()+'/approvals/reject/'+_id, {
				headers: { authorization: this.state.loggeduser.token }
			})
			if(res.data.success === true){
				this.LoadData()
				notify("Success", "#0275d8");
			}
		}
		catch(e){
			console.log(e)
			return false
		}
	}

	async delete(_id){
		try{
			const res = await axios.delete(baseurl()+'/approvals/'+_id, {
				headers: { authorization: this.state.loggeduser.token }
			})
			if(res.data.success === true){
				this.LoadData()
				notify("Success", "#0275d8");
			}
		}
		catch(e){
			console.log(e)
			return false
		}

	}

	ChkNMarkDifference(data, string){

		// console.log(string)

		const approvalList = ApprovalFields()

		const fieldArray = approvalList[data.table]

		fieldArray.map(ele => {
			string = string.replace(ele, `<b style="color:red;" >${ele}</b>`);
		})

		// console.log(string)

		return string
	}


  render() {

  	const jsonitems=AppStore.approvals.map(function(data){
		return(
			<tr key={data._id}>
				<td>
					<Checkbox
						checked={this.state.selected.indexOf(data._id) > -1}
						onClick={() => this.Select(data._id)}
					/>
				</td>
				<td>{data.table}</td>
				<td>
					Status: <b>{data.status}</b> <br/>
					Created: <b>{moment(data.createdAt).format(" hh:mm a - Do MMM YYYY")}</b>
				</td>
				<td>
					{/* <ReactJson
						src={data.currentData}
						displayDataTypes={false}
						theme="monokai"
						// collapsed={true}
						style={{fontSize:20}}
					/> */}
					{/* <pre>{this.ChkNMarkDifference(data, JSON.stringify(data.currentData, null, 2))}</pre> */}
					<div
						dangerouslySetInnerHTML={{__html:`<pre>${this.ChkNMarkDifference(data, JSON.stringify(data.currentData, null, 2))}}</pre>`}}
					>

					</div>
				</td>
				<td>
					{/* <ReactJson
						src={data.difference}
						displayDataTypes={false}
						theme="monokai"
						// collapsed={true}
						style={{fontSize:20}}
					/> */}
					{/* <pre>{JSON.stringify(data.difference, null, 2)}</pre> */}
					<div
						dangerouslySetInnerHTML={{__html:`<pre>${this.ChkNMarkDifference(data, JSON.stringify(data.difference, null, 2))}</pre>`}}
					>

					</div>
				</td>
				<td>{data.notes}</td>
				<td>
					{data.status == 'pending' ?
					<>
						<button className="btn btn-primary" onClick={() => this.accept(data._id)} ><i className="fa fa-check" aria-hidden="true"></i></button>
						<button className="btn btn-warning" onClick={() => this.reject(data._id)} ><i className="fa fa-times" aria-hidden="true"></i></button>
					</>
					: null }
					<button className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
				</td>
			</tr>
		);
	}, this);

    return (
        <div >
        	<Sidebar Approvals = "active" ordershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container-fluid Approvals">
					<div className="row">

						<div className='col-8 my-3' >
							<h2>Pending Approvals</h2>
						</div>
						{this.state.selected.length > 0 &&
							<div className='col-4 my-3' >
								<Button
									onClick={() => this.MultipleAccept(this.state.selected)}
									type='primary'
								>
									Accept
								</Button>
								<Button
									onClick={() => this.MultipleReject(this.state.selected)}
									type=''

								>
									Reject
								</Button>
								<Button
									onClick={() => this.MultipleDelete(this.state.selected)}
									type='danger'
								>
									Delete
								</Button>
							</div>
  						}
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th >
									  <Button
									  	onClick={() => this.setState({selected: this.state.data.map(ele => ele._id)})}
									  >Mark All</Button>
								  </th>
							      <th >Table</th>
							      <th >Time & status</th>
							      <th >CurrentData</th>
								  <th>to</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
						<div className="col-12">
							<Pagination
								defaultCurrent={1}
								total={this.state.count}
								pageSize={this.state.perpage}
								onChange={(page) => {this.LoadData((page -1) * this.state.perpage , this.state.perpage)}}
								showSizeChanger={false}
							/>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default Approvals;
