import React, { Component } from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import _, { sample } from 'lodash';
import { ToastContainer } from 'react-toastify';
import {notify, baseurl, ObjToURLString, setlog, numberOfproductByphone} from './utility/utility.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, sendToLoginIfNoAccess, loggeduser} from './utility/security';
import { observer } from 'mobx-react';
import AppStore from './store/AppStore';
import {Modal , Button, OverlayTrigger, Popover, Row, Col, Form,FormControl, InputGroup} from 'react-bootstrap';
import moment from 'moment';
import SentMessageComponent from './components/SentMessageComponenet';
import ConversationLabels from './components/ConversationLabels';
import {Link} from 'react-router-dom';
import {Select, Input, Button as AntBtn, List, Tabs, Badge, Tag, Checkbox, notification } from 'antd';
import DatePicker from 'react-datepicker';


const FBChats = observer(class FBChats extends Component {

	loadingMsgPagination = false;

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			FBChats:[],
			fullResponse:{paging:{next:''}},
			loadingFBChats: false,
			prevY:0,
			orderByphone:[],
			allLabels:[],

			chatModalVisible:false,
			singleChats:[],
			singleChatsFullResponse:{},
			loadingSingleChat:false,
			activeUserName:'User',
			activeUserInfo:{id:''},
			textCopied: false,

			activeInput: 'customerPhone',
			orderData:{
				orderDate:moment(),
				customerName: "",
				customerEmail: "",
				customerAddress:"",
				customerArea:"Select Customer Area",
				customerPhone: "",
				sendMockup: false,
				mockupDate: moment(),
				tasks: [],
				internalNotes: '',
				deliveryPerson:'Select Delivery Person',
				deliveryPersonEmail:'',
				deliveryPersonPhone:'',
				courierCompany:'',
				products : [],
				discountTk:0,
				discountPct:0,
				deliveryFee: 50,
				payments:[],
				notes:"",
				status: "Incomplete",
				status2: 'Case Not Collected',
				total: 0,
				due: 0,
				conveyance: 50,
			},

			selectingProductData:{
				addproductType: AppStore.productTypes[0] ? AppStore.productTypes[0].name : 'CC',
				addproductCode: '0000',
				addproductModel: '',
				facebookImageLink: '',
				addproductPrice: AppStore.productTypes[0] ? AppStore.productTypes[0].price : 500,
			},
			showMsg: true,

			showModelSelectModal: false,
			showImageSelectModal: false,
			filterModels:AppStore.models,
		};
		//this.Data.bind(this)
	}

	changeDate = (newDate) => {
		// let {UpdateOrderCreatedAt, mode} = this.state;
		// if(mode === 'Add'){
		// 	this.setState({ orderDate : newDate });
		// 	this.LoadSameDateOrder(newDate);
		// }else{
		// 	var difference = moment(newDate).diff(UpdateOrderCreatedAt, 'days');
		// 	if(difference > -1 && difference < 15){
		// 		this.setState({ orderDate : newDate });
		// 		this.LoadSameDateOrder(newDate);
		// 	}else{
		// 		notify("Invalid Date Select", "#dc3545");
		// 	}
		// }
		// console.log(moment(newDate).diff(UpdateOrderCreatedAt, 'days'));
		this.UpdateOrderData('orderDate', newDate);
	}

	componentWillUnmount(){
		clearInterval(this.state.allChatInterval);
		clearInterval(this.state.singleChatInterval);
	 }

	componentWillMount(){
		// Make a request for a user with a given ID
		sendToLoginIfNoAccess("customers","edit", this, "/FBChats");
		this.LoadAllChat();
	}

	LoadAllChat = () => {

		var limit = this.state.FBChats.length > 0 ? this.state.FBChats.length : 25

		axios.get(AppStore.fbBaseUrl+"/"+AppStore.pageId+"/conversations?fields=participants,snippet,link&access_token="+AppStore.FBapiToken+"&limit="+limit)
		.then((res) => {
			//console.log(res.data)
			if(res.data && res.data.data){
				this.setState({fullResponse:res.data, FBChats: res.data.data});
				this.LoadLabels(res.data.data);
			}
		})
		.catch((err) => {
			//console.log(err)
		})
	}

	LoadByPhone(phone){
		axios.get(baseurl()+'/orders?customerPhone='+phone)
		.then( (response)=> {
			if(response.data.success === true){
				this.setState({orderByphone  : response.data.result});
			}
		})
		.catch(function (error) {
			//console.log(error);
		});
	}

	componentDidMount() {
		this.ScrollToBottom();
		var options = {
			root: null,
			rootMargin: "0px",
			threshold: 1.0
		};

		this.observer = new IntersectionObserver(
			this.handleObserver.bind(this),
			options
		);
		this.observer.observe(this.loadingRef);

		this.setState({filterModels:AppStore.models})

		var allChatInterval = setInterval(this.LoadAllChat, 10000);
		this.setState({allChatInterval: allChatInterval});
		this.setState({singleChatInterval: setInterval(this.LoadSingleChat, 10000)});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.location.hash == '#chat-modal' && this.props.location.hash !== '#chat-modal' ) {
		  this.handleChatClose()
		}
	}

	onScrollSingleChat = () => {
		var objDiv = document.getElementById("singleConList");
		if (objDiv.scrollTop == 0) {
			if(this.state.singleChatsFullResponse.paging.next){
				this.LoadMessage(false)
			}
		}
	}

	LoadMessage = (scrollToBottom) => {
		const oldScrollHeight = document.getElementById("singleConList").scrollHeight;
		if (!this.loadingMsgPagination) {
		//   if (this.state.totalMsgCount <= this.state.messages.length) {
		// 	return null
		//   }
		  this.loadingMsgPagination = true;
		  this.ScrollToBottom(40)
		  axios.get(this.state.singleChatsFullResponse.paging.next)
			.then((res) => {
			  if (res.data) {
				this.setState(
					{
						singleChats: [...this.state.singleChats, ...res.data.data],
						singleChatsFullResponse: res.data,
						loadingSingleChat: false
					},
					() => {
						if (scrollToBottom) {
							this.ScrollToBottom();
						} else {
							this.ScrollToBottom(document.getElementById("singleConList").scrollHeight - oldScrollHeight)
						}
						this.loadingMsgPagination = false;
					}
				);
			  } else {
				this.setState({ loadingSingleChat: false })
			  }
			})
			.catch((err) => {
			  this.setState({ loadingSingleChat: false })
			})
		}
	  }

	ScrollToBottom = (position) => {
		var objDiv = document.getElementById("singleConList");
		if (objDiv) {
			objDiv.scrollTop = position ? position : objDiv.scrollHeight;
		}
	}

	InjectLabelIntoConversation = (conversationsId, chatsLabel) => {
		var FBChats = this.state.FBChats;
		var index = FBChats.findIndex((ele) => {return ele.id == conversationsId});
		if(index > -1){
			FBChats[index].chatsLabel = chatsLabel;
		}
		this.setState({FBChats:FBChats});
	}

	GetChatsLabel = (data) => {

		return this.state.allLabels.find(ele => ele.fbChatId == data.id)

	}

	SelectChat = (conversation, runLoading = true) => {


		if(conversation.chatsLabel && conversation.chatsLabel._id){
			AppStore.selectedChatLabels = conversation.chatsLabel
		}else{
			AppStore.selectedChatLabels = {
				_id:'',
				fbChatId:'',
				labels: [],
				notes: '',
				order:false,
				sample:false,
				urgent:false,
				newModelRequest:'',
			}
		}

		window.location.hash = 'chat-modal';


		let name = conversation.participants.data[0].name

		this.copyToClipboard(name);

		this.setState({
			activeUserName : name,
			chatModalVisible: true,
			activeUserInfo: conversation
		})

		if(runLoading){
			this.setState({loadingSingleChat: true})
		}

		var orderData = this.state.orderData;
		orderData.customerName = name;
		orderData.facebookLink = conversation.link;
		this.setState({orderData:orderData});
		// this.UpdateOrderData('facebookLink', conversation.link);

		this.LoadSingleChat(conversation, true);
	}

	LoadSingleChat = (conversation = this.state.activeUserInfo, scrollToBottom = false) => {

		var limit = this.state.singleChats.length > 0 ? this.state.singleChats.length : 25;

		axios.get(AppStore.fbBaseUrl+"/"+conversation.id+"/messages?fields=message,attachments,from,created_time,sticker&access_token="+AppStore.FBapiToken+"&limit="+limit)
		.then((res) => {
			if(res.data && res.data.data){
				if(this.state.singleChats.length > 0 && res.data.data.length > 0 && res.data.data[0].message != this.state.singleChats[0].message){
					scrollToBottom = true;
				}
				this.setState({
					singleChatsFullResponse:res.data,
					singleChats: res.data.data,
					loadingSingleChat: false
				}, () => {
					if(scrollToBottom){
						this.ScrollToBottom();
					}

					var mainMsgField  = document.getElementById('mainMsgField');
					if(mainMsgField){
						mainMsgField.focus();
					}
				})
				// this.ScrollToBottom()
			}
		})
		.catch((err) => {
			//console.log(err)
		})
	}

	handleChatClose = () => {
		this.setState({
			chatModalVisible: false,
			activeInput:'customerPhone',
			orderData:{
				customerName:'',
				customerPhone:'',
				customerAddress:'',
				internalNotes:'',
				facebookLink:'',
				deliveryFee:50,
				conveyance:50,
				discountTk:0,
				products:[],
				orderDate:moment(),
				customerName: "",
				customerEmail: "",
				customerAddress:"",
				customerArea:"Select Customer Area",
				customerPhone: "",
				sendMockup: false,
				mockupDate: moment(),
				tasks: [],
				internalNotes: '',
				deliveryPerson:'Select Delivery Person',
				deliveryPersonEmail:'',
				deliveryPersonPhone:'',
				courierCompany:'',
				products : [],
				discountTk:0,
				discountPct:0,
				deliveryFee: 50,
				payments:[],
				notes:"",
				status: "Incomplete",
				status2: 'Case Not Collected',
				total: 0,
				due: 0,
				conveyance: 50,
			}
		});
	}

	copyToClipboard = str => {
		const el = document.createElement('textarea');
		el.value = str;
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);
	}

	UpdateOrderData = (field, value, index = null) => {
		var orderData = this.state.orderData;
		orderData[field] = value;
		this.setState({orderData:orderData});

		if(field == "customerPhone" && value && value.length > 10){
			this.LoadByPhone(value)
		}
	}

	UpdateProductData = (field, value) => {
		var selectingProductData = this.state.selectingProductData;
		selectingProductData[field] = value;
		this.setState({selectingProductData:selectingProductData});
	}

	AddOrder = () => {

		let {orderData} = this.state;
		let {customerName, customerPhone, customerAddress, internalNotes, products} = orderData;

		// return null;

		var total = 0;

		for (let index = 0; index < products.length; index++) {
			total = total + products[index].price;
		}


		if(customerName && customerAddress && customerPhone && products.length > 0){

			this.setState({addingOrder: true});

			axios.post(baseurl()+'/customers', orderData, { headers: { authorization: this.state.loggeduser.token } })
			.then((response) => {
				if(response.data.success === true){
					orderData.customerId = response.data.lastAdded._id;
					orderData.discountTk = parseInt(orderData.discountTk);
					orderData.deliveryFee = parseInt(orderData.deliveryFee);
					orderData.total = total - orderData.discountTk + orderData.deliveryFee;
					axios.post(baseurl()+'/orders', orderData)
					  .then((response) => {
						if(response.data.success === true){
							AppStore.lastAddedOrderDate = this.state.orderData.orderDate;
							setlog("Order added Customer Name: "+this.state.customerName+" Phone: "+this.state.customerPhone ,  'order add')
							notify("Order Added", "#28a745");
							this.setState({addingOrder: false});
						}
					  })
					  .catch(function (error) {
						//console.log(error);
						this.setState({addingOrder: false});
					  });
				}else{
					this.setState({addingOrder: false});
				}

			  })
			  .catch(function (error) {
				//console.log(error);
				this.setState({addingOrder: false});
			  });
		}else{
			notify('Fields Empty!','#F44336');
		}
	}

	handleObserver(entities, observer) {
		const y = entities[0].boundingClientRect.y;
		if (this.state.prevY > y) {
			this.LoadChats();
		}
		this.setState({ prevY: y });
	}

	LoadChats = () => {

		if(this.state.fullResponse.paging && this.state.fullResponse.paging.next){

			this.setState({loadingFBChats: true});

			axios.get(this.state.fullResponse.paging.next)
			.then((res) => {
				var FBChats = [...this.state.FBChats, ...res.data.data];
				this.setState({
					fullResponse:res.data,
					FBChats:FBChats,
					loadingFBChats: false
				});
				this.LoadLabels(FBChats);
				this.setState({selectingProductData:{
					addproductType: AppStore.productTypes[0] ? AppStore.productTypes[0].name : 'CC',
					addproductCode: '0000',
					addproductModel: '',
					facebookImageLink:'',
					addproductPrice: AppStore.productTypes[0] ? AppStore.productTypes[0].price : 500,
				}})
			})
			.catch((err) => {
				this.setState({loadingFBChats: true});
			})
		}
	}

	LoadLabels = (FBChats) => {
		// var FBChats = this.state.FBChats;
		var ids = FBChats.map((ele) => ele.id);
		axios.post(baseurl()+'/fbchats/ids' , {ids:ids} ,{ headers: { authorization: this.state.loggeduser.token } })
		.then((response)=> {
			console.log(response)
			if(response.data.success){
				var allLabels = response.data.result;
				this.setState({allLabels:allLabels})
				for (let index = 0; index < allLabels.length; index++) {
					var chatIndex = FBChats.findIndex((ele) => { return ele.id == allLabels[index].fbChatId });
					if(chatIndex > -1){
						FBChats[chatIndex].chatsLabel = allLabels[index];
					}
				}
				this.setState({FBChats:FBChats});
			}
		})
		.catch((error) => {
			//console.log(error);
		});
	}

	SelectModels = (value) => {
		this.UpdateProductData('addproductModel', value)
	}

	SelectType = (value) => {
		var typeData = AppStore.productTypes.find((ele) => {return ele.name == value});
		if(typeData){
			var selectingProductData = this.state.selectingProductData;
			selectingProductData.addproductPrice = typeData.price;
			selectingProductData.addproductType = typeData.name;
			this.setState({selectingProductData:selectingProductData});
		}
	}

	FinishAddModel = () => {
		var data = {};
		data.discountTk = 0;
		data.discountPct = 0;
		data.quantity = 1;
		data.note = "";
		data.price = parseInt(this.state.selectingProductData.addproductPrice);
		data.model = this.state.selectingProductData.addproductModel;
		data.productType = this.state.selectingProductData.addproductType;
		data.code = this.state.selectingProductData.addproductCode;
		data.facebookImageLink = this.state.selectingProductData.facebookImageLink
		data.total = parseInt(this.state.selectingProductData.addproductPrice);

		var products = JSON.parse(JSON.stringify(this.state.orderData.products));
		products.push(data);
		this.UpdateOrderData('products', products);
		this.setState({selectingProductData:{
			addproductType: AppStore.productTypes[0] ? AppStore.productTypes[0].name : 'CC',
			addproductCode: '0000',
			addproductModel: '',
			facebookImageLink:'',
			addproductPrice: AppStore.productTypes[0] ? AppStore.productTypes[0].price : 500,
		}})

	}

	RemoveModel = (index) => {
		var products = JSON.parse(JSON.stringify(this.state.orderData.products));
		products.splice(index, 1);
		this.UpdateOrderData('products', products);
	}

	SearchModel = (word) => {
		// return null
		var models = AppStore.models.filter((ele) => {return ( ele.name && ele.name.toLowerCase()).indexOf(word.toLowerCase()) > -1 });
		this.setState({filterModels:models});
	}

	SaveLabels = async (chatsLabel, field, value, conversation) => {

		//console.log(conversation)

		var cusPhone = null;
		var searchText = null;

		try{

			let res = await axios.get(AppStore.fbBaseUrl+"/"+conversation.id+"/messages?fields=message,attachments,from,created_time,sticker&access_token="+AppStore.FBapiToken+"&limit="+100)

			if(res.data.data){
				res.data.data.map(ele => {
					let match = ele.message.match(/[\+]?\d{11}|\(\d{3}\)\s?-\d{6}/)
					if(match && ele.from.id !== AppStore.pageId){

						cusPhone = match[0]
						searchText = match.input

					}
				})
			}

			if(!cusPhone){

				//let match = res.data.data.filter(ele => ele.from.id !== AppStore.pageId).sort(function(a, b){return b.length - a.length})[0];

				var matcharray = res.data.data.filter(ele => ele.from.id !== AppStore.pageId);

				var bigText = "";

				for (let index = 0; index < matcharray.length; index++) {

					if(matcharray[index] && matcharray[index].message && bigText.length < matcharray[index].message.length){
						bigText = matcharray[index].message
					}

				}

				if(bigText){
					cusPhone = bigText
					searchText = bigText
				}
			}

			//console.log(res)

		}catch(e){
			console.log(e)
		}

		// if(!cusPhone || !searchText){

		// 	notification.error({
		// 		message:"No Indentical Text Found"
		// 	})

		// 	return null
		// }

		// return null

		if(!chatsLabel){
			var chatsLabel = {};
		}

		chatsLabel.fbChatId = conversation.id;
		chatsLabel.fbChatLink = conversation.link;
		chatsLabel.name = conversation.participants.data[0].name;

		chatsLabel[field] = value;

		chatsLabel.searchText = searchText;
		chatsLabel.cusPhone = cusPhone;

		var allLabels = JSON.parse(JSON.stringify(this.state.allLabels));

		var index = allLabels.findIndex(ele => ele._id == chatsLabel._id)

		if(index > -1){
			allLabels[index] = chatsLabel
		}

		this.setState({saving:true, allLabels:allLabels})

		var extraUrl = chatsLabel._id ? '/'+chatsLabel._id : '';
		axios[chatsLabel._id ? 'put' : 'post'](baseurl()+'/fbchats' + extraUrl, chatsLabel ,{ headers: { authorization: this.state.loggeduser.token } })
		.then((response)=> {

			console.log(response)

			notification.success({
				message:"Saved"
			})

			this.setState({saving:false});

			this.InjectLabelIntoConversation(conversation.id, chatsLabel)

			this.LoadChats();

		})
		.catch((error) => {
			this.setState({saving:false})
			console.log(error);
		});
	}

	onChangeLabelData = (chatsLabel, value, field) => {

		var allLabels = JSON.parse(JSON.stringify(this.state.allLabels));

		var index = allLabels.findIndex(ele => ele._id == chatsLabel._id)

		if(index > -1){
			allLabels[index][field] = value
		}

		this.setState({allLabels:allLabels})

	}


	render() {

		const { TabPane } = Tabs;


		let {activeInput, orderData, showMsg} = this.state;

		const loadingCSS = {
			height: "100px",
			margin: "30px"
		};

		const orderByphone = this.state.orderByphone.map(function(data, index){
			var deatailsurl = "/editOrder/"+data._id;
			return(
				<tr>
				  <td>{moment(data.orderDate).format("DD/MM/YYYY")}</td>
				  <td>{data.notes}</td>
				  <td>{numberOfproductByphone(data.products)}</td>
				  <td>{data.total}</td>
				  <td>{data.due}</td>
				  <td><Link to={deatailsurl} >Details</Link></td>
				</tr>
			)
		},this);

		const loadingTextCSS = { display: this.state.loadingFBChats ? "block" : "none" };

		const jsonitems = this.state.FBChats.map((data) => {

				var chatsLabel = this.GetChatsLabel(data);

				return(
					<div style={{border:'0.5px solid #ddd'}}>
						<div onClick={() => this.SelectChat(data)} key={data.id} className='p-2' style={{display:'flex', alignItems:'center', flexDirection:'row', justifyContent:'space-between', cursor:'pointer'}}>
							<div style={{display:'flex', alignItems:'center', flex:1, overflow:'hidden'}}>
								<img src={require('./images/user.jpg')} width={40}  />
								<span className='m-3'><b>{data.participants.data[0].name}</b>
								<br/>
								<i className='fa fa-comment'></i> {data.snippet}
								</span>
							</div>
							{(chatsLabel && chatsLabel._id) &&
								<div className='text-right'>
									<div className="my-2" style={{display:'flex', flexDirection:'column'}}>
										{chatsLabel.labels.map((labelData, labelIndex) =>
											<Tag color='red' key={labelIndex}>{labelData}</Tag>
										)}
									</div>
									<div className="my-2" style={{display:'flex', flexDirection:'column'}}>
										{chatsLabel.order && <Tag color="red">O</Tag>}
										{chatsLabel.urgent && <Tag color="orange">U</Tag>}
										{chatsLabel.sample && <Tag color="blue">S</Tag>}
									</div>
									<b>
										{chatsLabel.newModelRequest && <Tag color="red" >M</Tag> }
										{chatsLabel.notes && <Tag color="red" >N</Tag> }
									</b>
								</div>
							}
						</div>
						<div className='my-2' style={{display:'flex', flexDirection:'row', flexWrap:'wrap', padding:10, alignItems:'center'}}>
							<Checkbox checked={chatsLabel && chatsLabel.order ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'order', e.target.checked, data)} >Order</Checkbox>
							<Checkbox checked={chatsLabel && chatsLabel.sample ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'sample', e.target.checked, data)}>Sample</Checkbox>
							<Checkbox checked={chatsLabel && chatsLabel.urgent ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'urgent', e.target.checked, data)}>Urgent</Checkbox>
							<Checkbox checked={chatsLabel && chatsLabel.followup ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'followup', e.target.checked, data)}>Follow Up</Checkbox>

							<Checkbox checked={chatsLabel && chatsLabel.Replacement ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'Replacement', e.target.checked, data)}>Replacement</Checkbox>
							<Checkbox checked={chatsLabel && chatsLabel.OutDhaka ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'OutDhaka', e.target.checked, data)}>Out Dhaka</Checkbox>
							<Checkbox checked={chatsLabel && chatsLabel.SameDay ? true : false} onChange={(e) => this.SaveLabels(chatsLabel, 'SameDay', e.target.checked, data)}>Same Day</Checkbox>

							Cover
							<Select
								value={chatsLabel && chatsLabel.covers ? chatsLabel.covers : 0}
								onChange={(value) => {
									this.SaveLabels(chatsLabel, 'covers', value, data)
									this.onChangeLabelData(chatsLabel, value, 'covers')
								}}
								style={{margin:"0px 5px", width:60}}
							>
								<Select.Option value={0} >0</Select.Option>
								<Select.Option value={1} >1</Select.Option>
								<Select.Option value={2} >2</Select.Option>
								<Select.Option value={3} >3</Select.Option>
								<Select.Option value={4} >4</Select.Option>
								<Select.Option value={5} >5</Select.Option>
								<Select.Option value={6} >6</Select.Option>
								<Select.Option value={7} >7</Select.Option>
								<Select.Option value={8} >8</Select.Option>
								<Select.Option value={9} >9</Select.Option>
								<Select.Option value={10} >10</Select.Option>
								<Select.Option value={11} >11</Select.Option>
								<Select.Option value={12} >12</Select.Option>
								<Select.Option value={13} >13</Select.Option>
								<Select.Option value={14} >14</Select.Option>
								<Select.Option value={15} >15</Select.Option>
								<Select.Option value={16} >16</Select.Option>
								<Select.Option value={17} >17</Select.Option>
								<Select.Option value={18} >18</Select.Option>
								<Select.Option value={19} >19</Select.Option>
								<Select.Option value={20} >20</Select.Option>
							</Select>

							Pop Grips
							<Select
								value={chatsLabel && chatsLabel.popGrips ? chatsLabel.popGrips : 0}
								onChange={(value) => {
									this.SaveLabels(chatsLabel, 'popGrips', value, data)
									this.onChangeLabelData(chatsLabel, value, 'popGrips')
								}}
								style={{margin:"0px 5px", width:60}}
							>
								<Select.Option value={0} >0</Select.Option>
								<Select.Option value={1} >1</Select.Option>
								<Select.Option value={2} >2</Select.Option>
								<Select.Option value={3} >3</Select.Option>
								<Select.Option value={4} >4</Select.Option>
								<Select.Option value={5} >5</Select.Option>
								<Select.Option value={6} >6</Select.Option>
								<Select.Option value={7} >7</Select.Option>
								<Select.Option value={8} >8</Select.Option>
								<Select.Option value={9} >9</Select.Option>
								<Select.Option value={10} >10</Select.Option>
								<Select.Option value={11} >11</Select.Option>
								<Select.Option value={12} >12</Select.Option>
								<Select.Option value={13} >13</Select.Option>
								<Select.Option value={14} >14</Select.Option>
								<Select.Option value={15} >15</Select.Option>
								<Select.Option value={16} >16</Select.Option>
								<Select.Option value={17} >17</Select.Option>
								<Select.Option value={18} >18</Select.Option>
								<Select.Option value={19} >19</Select.Option>
								<Select.Option value={20} >20</Select.Option>
							</Select>

							{chatsLabel && chatsLabel.notes ?

								<Input.Search
									style={{flex:1}}
									onSearch={(value) => this.SaveLabels(chatsLabel, 'notes', value, data)}
									enterButton="Save"
									size='large'
									placeholder="Notes"
									onChange={(e) => this.onChangeLabelData(chatsLabel, e.target.value, 'notes')}
									value={chatsLabel && chatsLabel.notes ? chatsLabel.notes : ""}
								/>
							:
								<Input.Search
									style={{flex:1}}
									onSearch={(value) => this.SaveLabels(chatsLabel, 'notes', value, data)}
									enterButton="Savef"
									size='large'
									placeholder="Notes"
								/>
							}
						</div>
						{(chatsLabel && chatsLabel._id && chatsLabel.orders && chatsLabel.orders.length > 0 ) &&
							<>
								{/* {moment(_.orderBy(labelData.orders, (o) => {
                          return moment(o.orderDate).format('YYYYMMDD')
                          }, ['desc'])} */}
								{(_.orderBy(chatsLabel.orders, (o) => {return moment(o.orderDate).format('YYYYMMDD')}, ['desc'])).map((orderData) =>
									<Link to={'/editOrder/'+ orderData._id}><Button className='m-2'>{moment(orderData.orderDate).format('DD/MM/YYYY')}</Button></Link>
								)}
							</>
						}
					</div>
				);
			}, this);

		const popover = (
			<Popover id="popover-basic">
				<Popover.Title as="h3">Copied</Popover.Title>
			</Popover>
		);

		const { Option } = Select;

		return (
			<div >
				<Sidebar FBChats="active" customershow="show" />
				<div className="page">
					<Header  />
					<div className="container-fluid FBChats">
						<div className="row">
							<div className="col-12 my-3 text-center">
								<h1 >All Conversations</h1>
							</div>
							<div className="col-12">
								{jsonitems}
								<div
									ref={loadingRef => (this.loadingRef = loadingRef)}
									style={loadingCSS}
									>
									<h1 style={loadingTextCSS}>Loading...</h1>
								</div>
							</div>
						</div>
					</div><ToastContainer />
				</div>
				<Modal size="lg" show={this.state.chatModalVisible} onHide={this.handleChatClose}>
					<Modal.Header closeButton>
					<Modal.Title>
						<span className="mr-2" onClick={() => this.UpdateOrderData(activeInput, this.state.activeUserName)} style={{cursor:'pointer'}} > {this.state.activeUserName}</span>
						<OverlayTrigger trigger="click" placement="right" overlay={popover}>
							<button onClick={() => this.copyToClipboard(this.state.activeUserName)}  className="btn btn-default btn-sm" > <i className="fa fa-copy"></i></button>
						</OverlayTrigger>

					</Modal.Title>
					</Modal.Header>
					{showMsg &&
						<Modal.Body>
							<div style={{maxHeight:'50vh', overflow:'auto'}} id="singleConList" onScroll={this.onScrollSingleChat}>

								{this.state.loadingSingleChat ?
									<h1>Loading...</h1>
								:
								<>
									{(JSON.parse(JSON.stringify(this.state.singleChats)).reverse()).map((data, index) =>
										<div key={data.id} onClick={() => this.UpdateOrderData(activeInput, data.message, index)} key={data.id} className='p-2' style={{display:'flex', justifyContent:'flex-end', cursor:'pointer'}}>
											{data.from.id == AppStore.pageId ?
												<div style={{display:'flex', alignItems:'center', justifyContent:'flex-end', flex:1}}>
													<span className='m-3 text-right' >
														{(data.attachments) &&
															<>
																{data.attachments.data.map((attData, attIndex) =>
																	<a target='_blank' href={attData.image_data.url} className='m-2'>
																		<img style={{height:200, borderRadius:10}} src={attData.image_data.preview_url} />
																	</a>
																)}
															</>

														}
														{data.sticker &&
															<img src={data.sticker} style={{maxWidth:100}} />
														}
														{data.message &&
															<b id={"message"+index} style={{backgroundColor:'blue', color:'#fff', padding:10, borderRadius:10, display:'inline-block'}}>{data.message}</b>
														}

														<br/>
														<p className='mt-2'>{moment(data.created_time).fromNow()}</p>
													</span>
												</div>
											:
												<div style={{display:'flex', alignItems:'center', flex:1}}>
													<img src={require('./images/user.jpg')} width={40}  />
													<span className='m-3'>
														{(data.attachments) &&
															<>
																{data.attachments.data.map((attData, attIndex) =>
																	<a target='_blank' href={attData.image_data.url} className='m-2'>
																		<img style={{height:200, borderRadius:10}} src={attData.image_data.preview_url} />
																	</a>
																)}
															</>

														}
														{data.sticker &&
															<img src={data.sticker} style={{maxWidth:100}} />
														}
														{data.message &&
															<b>{data.message}</b>
														}
														<br/>
														{moment(data.created_time).fromNow()}
													</span>
												</div>
											}
										</div>
									)}
								</>
								}
							</div>
						</Modal.Body>
					}
					<Modal.Footer>
						<Tabs defaultActiveKey="1" style={{width:'100%'}}>
							<TabPane tab="Chat" key="1">
								<SentMessageComponent UpdateChat={this.SelectChat} activeUserInfo={this.state.activeUserInfo} />
							</TabPane>
							<TabPane tab="Add Order" key="2">
								<div style={{width:'100%', minHeight:showMsg ? "" : window.innerHeight*0.8}}>
									<Row className='mb-3 text-center'>
										<Col>
											<div className='' style={{overflowX:'auto', display:'flex', alignItems:'row'}}>

												<Button onClick={() => this.setState({showMsg:!showMsg})} variant="light" size='sm' ><i className={showMsg ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i></Button>

												<div style={{minWidth:100, overflow:'hidden'}}>
												<DatePicker
													selected={moment(orderData.orderDate)}
													onChange={this.changeDate.bind(this)}
													dateFormat="DD/MM/YYYY"
												/>
												</div>

												<Button onClick={() => this.setState({activeInput:'customerName'})} variant={activeInput == "customerName" ? "success" : "light"} size='sm' >Name</Button>
												<Button onClick={() => this.setState({activeInput:'customerPhone'})} variant={activeInput == "customerPhone" ? "success" : "light"} size='sm' >Phone</Button>
												<Button onClick={() => this.setState({activeInput:'customerAddress'})} variant={activeInput == "customerAddress" ? "success" : "light"} size='sm' >Address</Button>
												<Button onClick={() => this.setState({activeInput:'internalNotes'})} variant={activeInput == "internalNotes" ? "success" : "light"} size='sm' >Notes</Button>
												<Button onClick={() => this.setState({activeInput:'models'})} variant={activeInput == "models" ? "success" : "light"} size='sm' >Models</Button>
												<Button onClick={() => this.setState({activeInput:'discountTk'})} variant={activeInput == "discountTk" ? "success" : "light"} size='sm' >DiscountTk</Button>
												<Button onClick={() => this.setState({activeInput:'deliveryFee'})} variant={activeInput == "deliveryFee" ? "success" : "light"} size='sm' >Charge</Button>
												<Button onClick={() => this.setState({activeInput:'conveyance'})} variant={activeInput == "conveyance" ? "success" : "light"} size='sm' >Conveyance</Button>

											</div>
										</Col>
									</Row>
									<Row className='modelSelectFromChat'>
										{activeInput == 'models' ?
											<>
												<Col sm={2}>
													<Select
														// mode="multiple"
														placeholder="Select Types"
														value={this.state.selectingProductData.addproductType}
														onChange={(value) => this.SelectType(value)}
														style={{ width: '100%' }}
													>
														{AppStore.productTypes.map((data, index) =>
															<Option key={index} value={data.name} >{data.name}</Option>
														)}
													</Select>
												</Col>

												<Col sm={3}>
													<Input
														placeholder="Code"
														onChange={(e) => this.UpdateProductData("addproductCode", e.target.value)}
														value={this.state.selectingProductData.addproductCode}
													/>
												</Col>

												<Col sm={2}>
													{/* <Select
														showSearch
														placeholder="Select Modals"
														onChange={(value) => this.SelectModels(value)}
														value={this.state.selectingProductData.addproductModel}
														style={{ width: '100%' }}
														>
														{AppStore.models.map((data, index) =>
															<Option key={index} value={data.name} >{data.name}</Option>
														)}
													</Select> */}
													<Button block size='sm' onClick={() => this.setState({showModelSelectModal:true}, () => document.getElementById('ModelSearchInput').focus())}>{this.state.selectingProductData.addproductModel ? this.state.selectingProductData.addproductModel : "Model"}</Button>
												</Col>
												<Col sm={2}>
													{this.state.selectingProductData.facebookImageLink ?
														<img style={{maxWidth:'100%'}} src={this.state.selectingProductData.facebookImageLink} />
													:
														<Button block size='sm' onClick={() => this.setState({showImageSelectModal:true})}>Image</Button>
													}


												</Col>

												<Col sm={3}>
													<Input
														placeholder="Price"
														onChange={(e) => this.UpdateProductData("addproductPrice", e.target.value)}
														value={this.state.selectingProductData.addproductPrice}
													/>
												</Col>
												<Col sm={2}>
													<AntBtn block onClick={() => this.FinishAddModel()}><i className='fa fa-check'></i></AntBtn>
												</Col>

												<Col sm={12}>
													{orderData.products.map((data, index) =>
														<div key={index}>{data.productType} - {data.model} {data.price} <Button size="sm" onClick={() => this.RemoveModel(index)}><i className='fa fa-times'></i></Button></div>
													)}
												</Col>
											</>
										:

											<Col sm={12}>
													<Input.TextArea
														rows={3}
														placeholder={activeInput}
														value={orderData[activeInput]}
														onChange={(e) => this.UpdateOrderData(activeInput, e.target.value)}
													/>

											</Col>
										}

										<Col sm={6}>
											<AntBtn block type='primary' loading={this.state.addingOrder} onClick={this.AddOrder}  variant="outline-secondary">ADD</AntBtn>

										</Col>
										<Col sm={6}>
											<AntBtn block type='default' onClick={this.handleChatClose}  variant="outline-danger">CLOSE</AntBtn>
										</Col>
									</Row>
									{this.state.orderByphone.length > 0 &&
										<div className="row spd-mt-30 d-none d-sm-block">
											<div className="col-12 table-responsive">
												<table className="table table-bordered " >
												<thead>
													<tr>
													<th>Date</th>
													<th>Notes</th>
													<th>Product Qty</th>
													<th>Total</th>
													<th>Due</th>
													<th>Details</th>
													</tr>
												</thead>
												<tbody>
													{orderByphone}
												</tbody>
												</table>
											</div>
										</div>
									}
								</div>
							</TabPane>
							<TabPane tab="Labels" key="3">
								<ConversationLabels InjectLabelIntoConversation={this.InjectLabelIntoConversation} UpdateChat={this.SelectChat} activeUserInfo={this.state.activeUserInfo} />
							</TabPane>
						</Tabs>
					</Modal.Footer>
				</Modal>
				<Modal size="lg" show={this.state.showModelSelectModal} onHide={() => this.setState({showModelSelectModal: false})} >
					<Modal.Header closeButton>
					<Modal.Title>
						Select Model
					</Modal.Title>
					</Modal.Header>
						<Modal.Body style={{minHeight:'100vh'}}>
							<Input
								allowClear
								placeholder="Search Model"
								id="ModelSearchInput"
								onChange={(e) => this.SearchModel(e.target.value)}
							/>
							{this.state.filterModels.map((data, index) =>
								<div onClick={() => this.setState({showModelSelectModal: false}, () => this.UpdateProductData("addproductModel", data.name))} key={index} className='p-2 a-c-r' style={{display:'flex', flexDirection:'row'}}>
									<b style={{flex:1}}>{data.name}</b>
									<Button size='sm' >Select</Button>
								</div>
							)}
						</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>

				<Modal size="lg" show={this.state.showImageSelectModal} onHide={() => this.setState({showImageSelectModal: false})} scrollable={true}>
					<Modal.Header closeButton>
					<Modal.Title>
						Select Image
					</Modal.Title>
					</Modal.Header>
						<Modal.Body style={{minHeight:'100vh'}}>
							<Row>
								{(JSON.parse(JSON.stringify(this.state.singleChats)).reverse()).map((data, index) =>
								<>
									{(data.attachments) &&
										<>
											{data.attachments.data.map((attData, attIndex) =>
												<Col xs={6}>
													<img onClick={() => this.setState({showImageSelectModal: false}, () => this.UpdateProductData("facebookImageLink",  attData.image_data.preview_url))} style={{width:'100%', borderRadius:10, cursor:'pointer'}} src={attData.image_data.preview_url} />
												</Col>
											)}
										</>

									}
								</>

								)}
							</Row>
						</Modal.Body>
					<Modal.Footer>

					</Modal.Footer>
				</Modal>

			</div>
		);
	}
})

export default FBChats;
