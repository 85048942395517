import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';

const AlbumLinks = observer(class AlbumLinks extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			update:false,
			id: '',
			name:'',
			link:'',
			complete: false,
			albumLinks:[],
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/albumLinks" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID

		this.LoadData();
	 }

	LoadData = () => {
		axios.get(baseurl()+'/albumLinks', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({albumLinks : response.data.result});
				AppStore.albumLinks = response.data.result;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}



	changeInput(e){
		var input=e.target;
		if(input.name === "link"){
			this.setState({link : input.value});
		}else if(input.name === "name"){
			this.setState({name : input.value});
		}
	}

	loadaddData(){
		this.setState({link : ''});
		this.setState({name : ''});
		this.setState({update : false});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({name : data.name});
		this.setState({link : data.link});
		this.setState({update : true});
	}

	addData(){
		axios.post(baseurl()+'/albumLinks', {
		    link: this.state.link,
		    name: this.state.name,
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
				this.LoadData()
		    	notify("Link Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/albumLinks/'+this.state._id, {
            link: this.state.link,
            name: this.state.name,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
				this.LoadData()
				notify("Link Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/albumLinks/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
				this.LoadData();
		    	notify('Album Link Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
    }

  render() {

  	const jsonitems=this.state.albumLinks.map(function(data){
		return(
			<tr key={data._id}>
				<td>{data.name}</td>
				<td>{data.link}</td>
				<td>
					<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
					<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
				</td>
			</tr>
		);
	}, this);

    return (
        <div >
        	<Sidebar albumLinks = "active" albumLinkshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container albumLinks">
	        	<div className="row">
	        		<div className="col-12">
	        					<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Album Links</button>

								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog modal-lg" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">{this.state.update ? "Update" : "Add"}</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
										<div className="form-group">
											<label>Name</label>
											<input
												rows={4}
												type="text"
												className="form-control"
												name="name"
												onChange={this.changeInput.bind(this)}
												value={this.state.name}
											/>
										</div>
										<div className="form-group">
											<label>Link</label>
											<textarea rows={4} type="text" className="form-control" name="link" value={this.state.link} onChange={this.changeInput.bind(this)}>
													{this.state.link}
											</textarea>
										</div>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.state.update ? this.editData.bind(this) : this.addData.bind(this)} >{this.state.update ? "Update" : "Add"} albumLinks</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Name</th>
							      <th>Links</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default AlbumLinks;
