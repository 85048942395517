import { toast } from "react-toastify";
import toastr from "toastr";
import "react-toastify/dist/ReactToastify.css";
import { css } from "glamor";
import ZoomInAndOut from "./toastanimation.js";
import axios from "axios";
import moment from "moment";
import AppStore from "../store/AppStore";
import { func } from "prop-types";

export const customizerImageBaseUrl =
  "http://customizer.fabgearshop.com/data/orders/";

export function notify(msg, color) {
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_RIGHT,
    // transition: ZoomInAndOut,
    autoClose: 2000,
    hideProgressBar: true,
    className: css({
      background: color,
    }),
  });
}

export function setLocalItem(itemName, data) {
  localStorage.setItem(itemName, JSON.stringify(data));
}

export function getLocalItem(itemName) {
  if (
    localStorage.getItem(itemName) === null ||
    localStorage.getItem(itemName) === "null"
  ) {
    return [];
  } else {
    return JSON.parse(localStorage.getItem(itemName));
  }
}

export function setlog(msg, logType) {
  var date = new Date();
  var user = JSON.parse(localStorage.getItem("account")).user.email;
  var token = JSON.parse(localStorage.getItem("account")).token;
  axios
    .post(
      baseurl() + "/logs",
      {
        date: date,
        user: user,
        message: msg,
        logType: logType,
      },
      { headers: { authorization: token } }
    )
    .then((response) => {})
    .catch(function (error) {
      console.log(error);
    });
}

export function baseurl() {
  // return "http://localhost:8999";
  return "https://api.fabgearshop.com";
}

export function RandKey() {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 12; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function renderWeekDay(date) {
  var day = moment(date).weekday();
  if (day == 1) {
    return "Monday";
  } else if (day == 2) {
    return "Tuesday";
  } else if (day == 3) {
    return "Wednesday";
  } else if (day == 4) {
    return "Thursday";
  } else if (day == 5) {
    return "Friday";
  } else if (day == 6) {
    return "Saturday";
  } else if (day == 0) {
    return "Sunday";
  }
}

export function GetStatusClr(status) {
  if (status == "Bag Ready" || status == "Parcelled") {
    return "btn-success";
  } else if (status == "Incomplete" || status == "incomplete") {
    return "btn-warning";
  } else if (
    status == "Do not send today" ||
    status == "Waiting for Customer" ||
    status == "Cancelled"
  ) {
    return "btn-danger";
  } else if (status == "Printed") {
    return "btn-secondary";
  } else if (status == "Sublimated") {
    return "btn-default";
  }
}

export function AddBlackList(AppStore, token, number) {
  axios
    .post(
      baseurl() + "/blacklist",
      {
        name: "",
        phone: number,
        reason: "",
      },
      { headers: { authorization: token } }
    )
    .then((response) => {
      console.log(response);
      if (response.data.success === true) {
        AppStore.blacklist = response.data.result;
        notify("Blacklist Added", "#28a745");
        LoadBlackList(AppStore, token);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function LoadBlackList(AppStore, token) {
  axios
    .get(baseurl() + "/blacklist", { headers: { authorization: token } })
    .then((response) => {
      if (response.data.success === true) {
        AppStore.blacklist = response.data.result;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function LoadEntryCat(AppStore, token) {
  axios
    .get(baseurl() + "/entrycategories", { headers: { authorization: token } })
    .then((response) => {
      console.log(response);
      if (response.data.success === true) {
        AppStore.EntryCategories = response.data.result;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

export function GetImagePath(image) {
  image = image.replace("CC-", "");
  image = image.replace("RC-", "");
  image = image.replace("PS-", "");
  image = image.replace("GC-", "");
  return (
    AppStore.url +
    "/uploads/productimages/row/covers/" +
    image.replace(/^0+/, "")
  );
}

export function GetImagePathNew(productData) {
  if (productData.FileUrl && productData.FileUrl?.length > 0) {
    return productData.FileUrl;
  }

  return (
    baseurl() +
    "/uploads/productimages/row/covers/" +
    productData.code
      .toLowerCase()
      .replace("matching", "")
      .replace("pop", "")
      .replace("grip", "")
      .trim() +
    ".jpg"
  );
}

export function GetRowImage(productData) {
  if (productData.productType == "customizer") {
    if (productData.images && productData.images.length > 1) {
      return customizerImageBaseUrl + productData.images[1];
    }

    return null;
  }

  return null;
}

export function NewGuid() {
  var sGuid = "";
  for (var i = 0; i < 32; i++) {
    sGuid += Math.floor(Math.random() * 0xf).toString(0xf);
  }
  return sGuid;
}

export function randomString(length) {
  var chars = "asdfjasdhashdkahs12387562";
  var result = "";
  for (var i = length; i > 0; --i)
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
}

export function replaceAll(str) {
  str = str.toLowerCase();

  str = str.replace("(matte)", "");
  str = str.replace("(glossy)", "");

  str = str.trim();

  for (var i = 0; i < str.length; i++) {
    str = str.replace(" ", "-");
  }

  return str;
}

export function replaceAllSpaceToDash(str) {
  str = str.trim();

  for (var i = 0; i < str.length; i++) {
    str = str.replace(" ", "-");
  }

  return str;
}

export function AddFontLink(AppStore, font) {
  AppStore.loadedFonts.push(font);
  var head = document.getElementsByTagName("head")[0];
  var link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = font.url;
  link.media = "all";
  head.appendChild(link);
}

export function calculate(data, field) {
  var sumtotal = 0;
  var sumdue = 0;
  var sumconveyance = 0;
  var paid = 0;
  var sumbkash = 0;
  var sumCash = 0;
  for (var i = 0; i < data.length; i++) {
    if (data[i].courierCompany == "" || data[i].courierCompany == undefined) {
      sumtotal = sumtotal + data[i].total;
      sumdue = sumdue + data[i].due;
    }

    sumconveyance = sumconveyance + parseFloat(data[i].conveyance);

    for (var n = 0; data[i].payments.length > n; n++) {
      paid = paid + parseInt(data[i].payments[n].paymentAmount);
      if (
        data[i].payments[n].paymenttype == "Cash" &&
        (data[i].courierCompany == "" || data[i].courierCompany == undefined)
      ) {
        sumCash = sumCash + parseInt(data[i].payments[n].paymentAmount);
      }
      if (
        data[i].payments[n].paymenttype == "bKash" &&
        (data[i].courierCompany == "" || data[i].courierCompany == undefined)
      ) {
        sumbkash = sumbkash + parseInt(data[i].payments[n].paymentAmount);
      }
    }
  }
  if (field === "total") {
    return sumtotal;
  } else if (field === "due") {
    return sumdue;
  } else if (field === "conveyance") {
    return sumconveyance;
  } else if (field === "receivable") {
    // it was before
    // return sumdue-sumconveyance;
    //return sumtotal-paid-sumconveyance;
    return sumtotal - sumconveyance - sumbkash;
  } else if (field === "bkash") {
    return sumbkash;
  } else if (field === "Cash") {
    return sumCash;
  }
}

export function numberOfproduct(products) {
  var totalProduct = 0;
  for (var i = 0; i < products.length; i++) {
    totalProduct = totalProduct + parseFloat(products[i].quantity);
  }
  return totalProduct;
}

export function ObjToURLString(obj) {
  var str = "";
  for (var key in obj) {
    if (str != "") {
      str += "&";
    }
    str += key + "=" + obj[key];
  }
  return str;
}

export function GetURLParams(url) {
  var params = {};
  var parser = document.createElement("a");
  parser.href = url;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

export function numberOfproductByphone(products) {
  var totalProductByphone = 0;
  for (var i = 0; i < products.length; i++) {
    totalProductByphone =
      totalProductByphone + parseFloat(products[i].quantity);
  }
  return totalProductByphone;
}

export function BandageToStatus2(orders) {
  for (let index = 0; index < orders.length; index++) {
    if (!orders[index].status2) {
      orders[index].status2 = "Case Not Collected";
    }
  }
  return orders;
}

export function ApprovalFields() {
  return {
    Orders: ["orderDate"],
  };
}
