import React, {Component} from 'react';
import Header from './header.js';
import {ToastContainer} from 'react-toastify';
import {notify} from './utility/utility.js';
import {Button} from 'react-bootstrap';
import Axios from 'axios';
import Sidebar from './sidebar.js';
import {Input} from 'antd';


class wishlist extends Component {
	constructor(props){
		super(props);
		this.state = {
			msg:'',
			loading:false
		};

	}

	SendNotifications = () => {

		if(!this.state.msg){

			return null;
		}

		var data =  {
			"app_id": "c2010ea4-5416-4bfd-9374-0962b57c072d",
			"included_segments": ["All"],
			"data": {"foo": "bar"},
			"contents": {"en": this.state.msg}
		  }

		// Axios({
		// 	method: 'post',
		// 	url: 'https://onesignal.com/api/v1/notifications',
		// 	data: bodyFormData,
		// 	headers: {
		// 		"Content-Type": "application/json;charset=utf-8",
		// 		"Authorization": "Basic MGEwZWQwYzQtOTNiYi00Mjg2LTk3YjEtNGY0Y2ZiODk2MTUy"
		// 	},
		// })
		// .then(function (response) {
		// 	//handle success
		// 	console.log(response);
		// })
		// .catch(function (response) {
		// 	//handle error
		// 	console.log(response);
		// });

		Axios.post(
			'https://onesignal.com/api/v1/notifications',
			data,
			{
				headers:{
					"Content-Type": "application/json;charset=utf-8",
					"Authorization": "Basic MGEwZWQwYzQtOTNiYi00Mjg2LTk3YjEtNGY0Y2ZiODk2MTUy"
				}
			}
		)
		.then((res) => {
			console.log(res)
			this.setState({loading: false})
			notify("Sent", "#28a745");
		})
		.catch((err) => {
			console.log(err)
			this.setState({loading: false})
			notify("Could Not Sent", "#28a745");
		})

	}

	render() {

	    return (
	        <div >
				<Sidebar offers = "active" productshow="show" />
				<div className="page">
					<Header  />
					<div className="container ProductTypes">
					<div className="row">
						<div className="col-12 my-4">
							<h1>Send Notification to customer</h1>
						</div>
						<div className="col-12">
							<Input.TextArea
								rows={5}
								placeholder='Message'
								onChange={(e) => this.setState({msg:e.target.value})}
								value={this.state.msg}

							/>

							<Button disabled={this.state.loading} onClick={this.SendNotifications} className="my-3">{this.state.loading ? 'Sending...' : 'Send'}</Button>
						</div>
					</div>
					</div>
					<ToastContainer />
				</div>
			</div>
	    );
	}
}

export default wishlist;
