import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import moment from 'moment';

import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';

const CompleteTasks = observer(class CompleteTasks extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			update:false,
			id: '',
            name:'',
            complete: false,
			tasks:[],
		};
		//this.Data.bind(this)
	}

	confirmDone = (data) => {
		confirmAlert({
		  title: 'Confirm Incompete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.IncompleteTask(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/tasks" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/tasks', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({tasks : response.data.result});
				AppStore.tasks = response.data.result;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
    }

     editData(){
		axios.put(baseurl()+'/tasks/'+this.state._id, {
            description: this.state.description,
            complete: this.state.complete,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
				this.setState({tasks : response.data.result});
				AppStore.tasks = response.data.result;
				notify("Model Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

    IncompleteTask(data){
        this.setState({_id : data._id, description:data.description, complete: false}, ()=> this.editData())
    }

  render() {
  	const jsonitems=this.state.tasks.map(function(data){
          if(data.complete){
			return(
				<tr key={data._id}>
						<td>{moment(data.createdAt).format("DD/MM/YYYY")}</td>
			      <td>{data.description}</td>
			      <td>
			      	<button className="btn btn-danger" onClick={this.confirmDone.bind(this, data)} data-toggle="modal" data-target="#portfolioModal">Mark As Incomplete</button>
			      </td>
			    </tr>
            );
        }
	}, this);
    return (
        <div >
        	<Sidebar CompleteTasks = "active" taskshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container tasks">
					<div className="row">
						<div className="col-12">
                            <h2 className="m-5 text-center">Completed Tasks</h2>
                        </div>
                    </div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Date</th>
							      <th>Name</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default CompleteTasks;
