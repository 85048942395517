import React, { Component } from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import moment from 'moment';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { sendToLoginIfNoAccess, loggeduser} from './utility/security';
import { ToastContainer } from 'react-toastify';
import {notify, setlog, baseurl} from './utility/utility.js';


class addProducts extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggeduser:loggeduser(),
			redirect: false,
			mode:'Add',
			title:'',
			models:[],
			price:'',
			originalPrice:'',
			quantity:'',
			description:'',
			_id:'',
			addProducts:[],
			allmodels:[],
			selectedModel:'Select Model',
			modelquantity:1,
			allselectedModel:[],
			images:[],
			allselectedcategory:[],
			allcategories:[],
			selectedcategory:'Select Category',
			allselectedmaterial:[],
			allmaterials:[],
			enabled:true,
			log:[],
			imageFolder:'cc/',
			logComment:'',
			selectedmaterial:'Select Material',
		};
	}

	onChangeFile(e) {
		var images = this.state.images;
		const url = baseurl()+'/images';
		const data = new FormData();
		for(var i=0;i<e.target.files.length;i++){
			//console.log();
			images.push(e.target.files[i].name);
			data.append('file',e.target.files[i]);
		}
		data.append('folder' , this.state.imageFolder);
		axios.post(url,data,  { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
			console.log(response);
			this.setState({images:images})
			console.log(images);
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	setenabled(){
		if(this.state.enabled === true){
			this.setState({enabled:false})
		}else{
			this.setState({enabled:true})
		}
	}

	componentWillMount(){
		sendToLoginIfNoAccess("products","add", this, "/addProducts");
		var productId = this.props.match.params.id;
		if(productId !== undefined){
				this.loadproductData(productId);
		}

		axios.get(baseurl()+'/models', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({allmodels : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	    axios.get(baseurl()+'/materials')
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({allmaterials : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	    axios.get(baseurl()+'/categories')
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({allcategories : response.data.result});
			}
	    })
	}

	loadproductData(id){
		console.log(id);
		axios.get(baseurl()+'/products/'+id, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				//this.setState({alldeliveryperson : response.data.result});
				console.log(response.data.result);
				console.log(response.data.result.orderDate);
				this.setState({mode : "Update"});
				this.setState({title : response.data.result.title});
				this.setState({price : response.data.result.price});
				this.setState({originalPrice : response.data.result.originalPrice});
				this.setState({allselectedModel : response.data.result.models});
				this.setState({allselectedcategory : response.data.result.categories});
				this.setState({allselectedmaterial : response.data.result.materials});
				this.setState({quantity : response.data.result.quantity});
				this.setState({description : response.data.result.description});
				this.setState({enabled : response.data.result.enabled});
				this.setState({imageFolder : response.data.result.imageFolder});
				this.setState({images : response.data.result.images});
				this.setState({log : response.data.result.log});
				this.setState({allselectedcategory : response.data.result.categories});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "title"){
			this.setState({title : input.value});
		}
		else if(input.name === "models"){
			this.setState({models : input.value});
		}
		else if(input.name === "price"){
			this.setState({price : input.value});
		}
		else if(input.name === "originalPrice"){
			this.setState({originalPrice : input.value});
		}
		else if(input.name === "quantity"){
			this.setState({quantity : input.value});
		}
		else if(input.name === "description"){
			this.setState({description : input.value});
		}
		else if(input.name === "modelquantity"){
			this.setState({modelquantity : input.value});
		}
		else if(input.name === "logComment"){
			this.setState({logComment : input.value});
		}
	}

	saveData(){
		if(this.state.mode === 'Add' ){
			 axios.post(baseurl()+'/products', {
			  title:this.state.title,
				price:this.state.price,
				originalPrice:this.state.originalPrice,
				quantity:this.state.quantity,
				description:this.state.description,
				imageupload:this.state.imageupload,
				imageFolder:this.state.imageFolder,
				models: [],
				images: this.state.images,
				enabled: this.state.enabled,
				log: [],
				categories: this.state.allselectedcategory,
				materials: this.state.allselectedmaterial,
			  } , { headers: { authorization: this.state.loggeduser.token } })
			  .then((response)=> {
				    if(response.data.success === true){
			      	this.setState({addProducts : response.data.result});
			      	console.log(response.data.result);
			      	this.setState({ redirect: true });
			      }
			  })
			  .catch(function (error) {
			    console.log(error);
			  });
		}else if(this.state.mode === 'Update'){
			console.log(this.state.log);
			var productId = this.props.match.params.id;
			axios.put(baseurl()+'/products/'+productId, {
			  title:this.state.title,
				price:this.state.price,
				originalPrice:this.state.originalPrice,
				quantity:this.state.quantity,
				description:this.state.description,
				log:this.state.log,
				models: this.state.allselectedModel,
				enabled: this.state.enabled,
				images: this.state.images,
				categories: this.state.allselectedcategory,
				materials: this.state.allselectedmaterial,
			  } , { headers: { authorization: this.state.loggeduser.token } })
			  .then((response)=> {
				    if(response.data.success === true){
			      	this.setState({addProducts : response.data.result});
			      	this.setState({ redirect: true });
			      	setlog("Product Updated id: "+productId ,"product update");
			      }
			  })
			  .catch(function (error) {
			    console.log(error);
			  });
		}
	}

	selectModel(name){
		this.setState({selectedModel : name});
	}

	addModel(){
		var models = {};
		models.model = this.state.selectedModel;
		models.quantity = this.state.modelquantity;
		var allselectedModel = JSON.parse(JSON.stringify(this.state.allselectedModel));
		if(this.state.logComment === '' && this.state.modelquantity < 1 ){
				notify("Comment Please", "#dc3545");
		}if(this.state.selectedModel === 'Select Model' || this.state.modelquantity === 0){
				notify("Select Model & Quantity", "#dc3545");
		}else if(allselectedModel.map(function(e) { return e.model; }).indexOf(this.state.selectedModel) === -1){
			allselectedModel.push(models);
			this.addlog();
		}else{
			for(var i=0; i<allselectedModel.length; i++){
				if(allselectedModel[i].model === this.state.selectedModel){
					var modelQTy =  parseFloat(allselectedModel[i].quantity);
					modelQTy += parseFloat(this.state.modelquantity);
					allselectedModel[i].quantity = modelQTy;
					this.addlog();
				}
			}
		}
		this.setState({allselectedModel : allselectedModel});
		this.calculateQuantity(allselectedModel);


	}

	addlog(){
		// add to log start
		var log = this.state.log;
		var addOrRemove = 'added';
		log.date = new Date(); //moment().format("YYYY/MM/DD");
		if(this.state.modelquantity < 0 ){
			addOrRemove = 'removed';
		}
		log.message = "Qty: "+this.state.modelquantity+" Model: "+this.state.selectedModel+" "+addOrRemove+" on "+moment().format("YYYY/MM/DD")+" by "+this.state.loggeduser.user.email+" Comment: "+this.state.logComment;
		this.setState({log : log});

		console.log(log);
	}



	calculateQuantity(allselectedModel){
		var totalQuantiy = 0;
		for(var i =0; i < allselectedModel.length;i++){
			var quantity = parseFloat(allselectedModel[i].quantity);
			totalQuantiy += quantity;
		}
		this.setState({ quantity: totalQuantiy });
	}

	removeImage(index){
		var images = this.state.images;
		images.splice(index, 1);
		this.setState({ images: images });
	}
	selectcategory(name){
		this.setState({selectedcategory : name});
	}
	addcategory(){
		var category = this.state.selectedcategory;
		console.log(category);
		var allselectedcategory = JSON.parse(JSON.stringify(this.state.allselectedcategory));
		allselectedcategory.push(category);
		//console.log(allselectedcategory);
		this.setState({allselectedcategory : allselectedcategory});
	}
	removecategory(index){
		var allselectedcategory = this.state.allselectedcategory;
		allselectedcategory.splice(index, 1);
		this.setState({ allselectedcategory: allselectedcategory });
	}
	selectmaterial(name){
		this.setState({selectedmaterial : name});
	}

	addmaterial(){
		var material = this.state.selectedmaterial;
		console.log(material);
		var allselectedmaterial = JSON.parse(JSON.stringify(this.state.allselectedmaterial));
		allselectedmaterial.push(material);
		//console.log(allselectedmaterial);
		this.setState({allselectedmaterial : allselectedmaterial});
	}

	removematerial(index){
		var allselectedmaterial = this.state.allselectedmaterial;
		allselectedmaterial.splice(index, 1);
		this.setState({ allselectedmaterial: allselectedmaterial });
	}

	delete(data, index){
		console.log(data);
		axios.delete(baseurl()+'/images/', {
			data:{
				images: [data],
			}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response.data);
		    this.removeImage(index);
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	selectimageFolder(type){
		this.setState({imageFolder: type});
	}

  render() {
  	const { redirect } = this.state;
     if (redirect) {
       return <Redirect to='/products'/>
     }

  	const allmodels = this.state.allmodels.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectModel.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

	const uploadedImage = this.state.images.map(function(data, index){
		var url = "https://api.fabgearshop.com/uploads/productimages/"+this.state.imageFolder+data;
		return(
			<div key={index} className="col-2">
				<div class="profile-author">
                     <img src={url.toLowerCase()} alt="product"/>
                     <div class="profile-icon">
                             <i class="fa fa-times" onClick={this.delete.bind(this, data, index)}></i>
                     </div>
                 </div>
	        </div>
	    )
	}, this);

	const addedModel = this.state.allselectedModel.map(function(data, index){
		return(
			<tr key={index} className="selected-model">
				<td >
					{data.model}
				</td>
				<td >
					Qty: {data.quantity}
				</td>
			</tr>
		)
	}, this);

	const addedcategory = this.state.allselectedcategory.map(function(data, index){
		console.log(data);
		return(
			<tr key={index} className="selected-model">
				<td >
					{data}
				</td>
				<td >
					<span onClick={this.removecategory.bind(this, index)}><i className="fa fa-times"></i></span>
				</td>
			</tr>
		)
	}, this);

	const allcategories = this.state.allcategories.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectcategory.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

	const addedmaterial = this.state.allselectedmaterial.map(function(data, index){
		console.log(data);
		return(
			<tr key={index} className="selected-model">
				<td >
					{data}
				</td>
				<td >
					<span onClick={this.removematerial.bind(this, index)}><i className="fa fa-times"></i></span>
				</td>
			</tr>
		)
	}, this);

	const allmaterials = this.state.allmaterials.map(function(data){
		console.log(data);
		return(
			<span key={data._id}  onClick={this.selectmaterial.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		)
	}, this);

    return (
        <div className="addProducts">
        	<Sidebar addProducts="active" productshow="show" />
	        <div className="page">
	        	<Header />
	        	<div className="container">
					<div className="row">
						<div className="col-12 add-product">
							<label htmlFor="title">Select Image Folder:</label>
	        				<div className="dropdown">
								<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						    		{this.state.imageFolder}
						 		</button>
					  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
					    			<span onClick={this.selectimageFolder.bind(this, "cc/")} className="dropdown-item btn btn-default" href="#">cc</span>
					    			<span onClick={this.selectimageFolder.bind(this, "rc/")} className="dropdown-item btn btn-default" href="#">rc</span>
					    			<span onClick={this.selectimageFolder.bind(this, "uc/")} className="dropdown-item btn btn-default" href="#">uc</span>
					  			</div>
							</div>
							<form className="spd-mt-20">
								<div className="form-group">
									<label htmlFor="title">Title</label>
									<input tabIndex="1" autoFocus="1" type="text" className="form-control" id="title" name="title" value={this.state.title} onChange={this.changeInput.bind(this)}/>
								</div>
								{(this.state.mode === 'Update') &&
								<div className="select-model">
				        			<div className="dropdown">
										<button tabIndex="2" className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								    		{this.state.selectedModel}
								 		</button>
							  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							    			{allmodels}
							  			</div>
									</div>

									<input tabIndex="3" placeholder="Quantity" type="number" name="modelquantity" id="modelquantity" value={this.state.modelquantity} onChange={this.changeInput.bind(this)}/>
									<input className="log-comment" tabIndex="4" placeholder="Comment" type="text" name="logComment" id="logComment" value={this.state.logComment} onChange={this.changeInput.bind(this)}/>
									<span onClick={this.addModel.bind(this)} className="btn btn-primary">Add</span>
					        	</div>
					        	}
					        	<div  className="row added-Model">
					        		<div className="col-12">
					        		<table className="table table-responsive">
					        		 {addedModel}
					        		</table>
					        		</div>
					        	</div>
					        	<div className="select-model">
				        			<div className="dropdown">
										<button tabIndex="4" className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								    		{this.state.selectedcategory}
								 		</button>
							  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							    			{allcategories}
							  			</div>
									</div>

									<span onClick={this.addcategory.bind(this)} className="btn btn-primary">Add</span>
					        	</div>
					        	<div  className="row added-Model">
					        		<div className="col-12">
					        		<table className="table table-responsive">
					        		 {addedcategory}
					        		</table>
					        		</div>
					        	</div>
					        	<div className="select-model">
				        			<div className="dropdown">
										<button tabIndex="5" className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								    		{this.state.selectedmaterial}
								 		</button>
							  			<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
							    			{allmaterials}
							  			</div>
									</div>

									<span onClick={this.addmaterial.bind(this)} className="btn btn-primary">Add</span>
					        	</div>
					        	<div  className="row added-Model">
					        		<div className="col-12">
					        		<table className="table table-responsive">
					        		 {addedmaterial}
					        		</table>
					        		</div>
					        	</div>
								<div className="form-group">
									<label htmlFor="price">Sale Price</label>
									<input tabIndex="6" type="number" name="price" id="price"  className="form-control" value={this.state.price} onChange={this.changeInput.bind(this)}/>
								</div>
								<div className="form-group">
									<label htmlFor="originalPrice">Orginal Price</label>
									<input tabIndex="6" type="number" name="originalPrice" id="originalPrice"  className="form-control" value={this.state.originalPrice} onChange={this.changeInput.bind(this)}/>
								</div>
								<div className="form-group">
									<label htmlFor="quantity">Quantity</label>
									<input readOnly="1" type="number" name="quantity" id="quantity"  className="form-control" value={this.state.quantity} onChange={this.changeInput.bind(this)}/>
								</div>
								<div className="form-group">
									<label htmlFor="description">Description</label>
									<textarea tabIndex="6" rows="3" type="text" name="description" id="description"  className="form-control" value={this.state.description} onChange={this.changeInput.bind(this)}> </textarea>
								</div>
								<div className="form-group">
									<label className="space-lbl" htmlFor="checkBox-enabled">Enabled</label>
									<input checked={this.state.enabled != undefined ? this.state.enabled : true } onClick={this.setenabled.bind(this)} id="checkBox-enabled" type="checkbox"/>
								</div>
								<div className="form-group">
							        <label htmlFor="">Images</label>
							        <input tabIndex="8" className="form-control" type="file" onChange={this.onChangeFile.bind(this)} multiple />
							    </div>
					        </form>
					        <div className="row">
						        {uploadedImage}
					        </div>
						      <div className="modal-footer">
						        <button tabIndex="9" type="button" className="btn btn-primary" onClick={this.saveData.bind(this)}>{this.state.mode} Product</button>
						      </div>
						</div>
			        </div>
	        	</div>
	        </div><ToastContainer />
        </div>
    );
  }
}

export default addProducts;
