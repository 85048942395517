import React, {Component} from 'react';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify, setLocalItem} from './utility/utility.js';
import {hasAccess} from './utility/security';

class Login extends Component {
	constructor(props){
		super(props);
		this.state={
			email:'',
			password:'',
			registeremail:'',
			registerpass:'',
			confirmpass:'',
			redirect: false,
			returnurl:'/',
		};
	}

	componentWillMount(){
		//localStorage.clear();
		if(this.getUrlVars()["return"] !== undefined){
			this.setState({returnurl : this.getUrlVars()["return"]});
			console.log(this.getUrlVars()["return"]);
		}
	}

	getUrlVars() {
		var vars = {};
		// var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
		// vars[key] = value;
		// });
		return vars;
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "email"){
			this.setState({email : input.value});
		}
		else if(input.name === "password"){
			this.setState({password : input.value});
		}
		else if(input.name === "registeremail"){
			this.setState({registeremail : input.value});
		}
		else if(input.name === "registerpass"){
			this.setState({registerpass : input.value});
		}
		else if(input.name === "confirmpass"){
			this.setState({confirmpass : input.value});
		}
	}

	register(e){
		 axios.post(baseurl()+'/account/signup', {
		    email:this.state.registeremail,
		    password:this.state.registerpass,
			confirmPassword:this.state.confirmpass,
		  })
		  .then((response)=> {
		  	console.log(response);
			    if(response.data.success === true){
			  	var account = {
			  		token:response.data.token,
			  		user:response.data.user,
			  		access:response.data.access,
			  	}
		      	setLocalItem( "account" , account);
		      	notify("Welcome To Fabgear", "#0275d8");
		      	this.setState({ redirect: true });
		      	}else{
		      		for(var i = 0; i<response.data.msg.length; i++){
		      			notify(response.data.msg[i].msg, "#F44336");
		      		}
		      	}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  e.preventDefault();
	}

	login(e){
		axios.post(baseurl()+'/account/login', {
		    email:this.state.email,
		    password:this.state.password,
		  })
		  .then((response)=> {
		  	console.log(response);
			  if(response.data.success === true){
			  	var account = {
			  		token:response.data.token,
			  		user:response.data.user,
			  		access:response.data.access,
			  	}
		      	setLocalItem( "account" , account);
		      	notify("Welcome To Fabgear", "#0275d8");
		      	if(!hasAccess('products', 'view')){
					  this.setState({ returnurl: "/" });
					  this.setState({ redirect: true });
		      	}else if(this.getUrlVars()["return"] === undefined){
					  this.setState({ returnurl: "/orders" });
					  this.setState({ redirect: true });
		      	}

		      }else{
		      	for(var i = 0; i<response.data.msg.length; i++){
		      		notify(response.data.msg[i].msg, "#F44336");
		      	}
		      }
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		  e.preventDefault();
	}

	render() {
		const { redirect } = this.state;
	     if (redirect) {
	       return <Redirect to={this.state.returnurl} />;
	     }

		return (
		    <div className="page login-page">
		      <div className="container">
		        <div className="text-center">
		          <div className="row">
		          	<div className="form-inner col-sm-6 col-12">
		            <div className="logo text-uppercase"><strong className="text-primary">Login</strong></div>
			            <form id="login-form" >
			              <div className="form-group">
			                <input id="email" type="email" placeholder="Email" name="email" required="" value={this.state.email}  onChange={this.changeInput.bind(this)} />
			              </div>
			              <div className="form-group">
			                <input id="password" type="password" placeholder="Password" name="password" required="" value={this.state.password} onChange={this.changeInput.bind(this)} />
			              </div>
			              <input type="submit" id="login" className="btn btn-primary" onClick={this.login.bind(this)} />
			            </form>
		            <Link to="/recoverpassword" className="forgot-pass">Forgot Password?</Link>
		            <small>Do not have an account? </small>
		          </div>
		          <div className="form-inner col-sm-6 col-12">
		            <div className="logo text-uppercase"><strong className="text-primary">Register</strong></div>
			            <form id="register-form" >
			              <div className="form-group">
			                <input id="registeremail" type="email" placeholder="Email" name="registeremail" required="" value={this.state.registeremail}  onChange={this.changeInput.bind(this)} />
			              </div>
			              <div className="form-group">
			                <input id="registerpass" type="password" placeholder="Password" name="registerpass" required="" value={this.state.registerpass} onChange={this.changeInput.bind(this)} />
			              </div>
			              <div className="form-group">
			                <input id="confirmpass" type="password" placeholder="Confirm Password" name="confirmpass" required="" value={this.state.confirmpass} onChange={this.changeInput.bind(this)} />
			              </div>
			              <input type="submit" className="btn btn-primary" onClick={this.register.bind(this)}/>
			            </form>
		          </div>
		          </div>
		          <div className="copyrights text-center">
		            <p>Design by Server Oasis</p>
		          </div>
		        </div><ToastContainer />
		      </div>
		    </div>
		);
	}
}

export default Login;
