import React, {Component} from 'react';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {loggeduser} from './utility/security';
import {baseurl, notify} from './utility/utility.js';


class myorders extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			orderdata:[],
			sortOrder:"asc",
			start:0,
			perpage:5,
			totalpage:1,
			activePage:1,
		};
	}

	componentWillMount(){
		if(localStorage.getItem('account') === 'null' || localStorage.getItem('account') === null){
			notify('Please Login!','#F44336');
			// this.setState({redirectTologin : true});
			this.props.history.push('/login?return=/myorders');
		}
	 	this.orderByCustomer();
	}

	sortBy(col,e){
		  var orderdata = this.state.orderdata;
			orderdata = _.orderBy(orderdata,[col],[this.state.sortOrder]);
			this.setState({orderdata: orderdata});

			if(this.state.sortOrder === 'asc'){
				this.setState({sortOrder : 'desc'})
			}else{
				this.setState({sortOrder : 'asc'})
			}
	 }

	numberOfproduct(products){
		var totalProduct = 0;
		for(var i = 0; i < products.length; i++){
			totalProduct = totalProduct+parseFloat(products[i].quantity);
		}
		return(
			totalProduct
		)
	}


	orderByCustomer(){
		axios.post(baseurl()+'/orders/myorders',{}, { headers: { authorization: this.state.loggeduser.token }})
	    .then((response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({orderdata  : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

  render() {

  	const orderitems=this.state.orderdata.map(function(data, index){
		return(
			<tr key={data._id}>
		      <td>{moment(data.orderDate).format("DD/MM/YYYY")}</td>
		      <td>{data.customerName}</td>
		      <td>{data.customerPhone}</td>
		      <td>{data.customerAddress}</td>
		      <td>{this.numberOfproduct(data.products)}</td>
		      <td>{data.total}</td>
		      <td>{data.due}</td>
		      <td>
		      	<Link className="btn btn-primary" to={ "myorderDetails/" + data._id} >Details</Link>
		      </td>
		    </tr>
		);
	},this);

    return (
        <div className="">
	        <div className="">
	        	<Header />
	        	<div className="container spd-min-height">
					<div className="row spd-mt-30">
						<div className="col-12">
							<table className="table table-bordered" id="orderTable">
							  <thead>
							    <tr>
							      <th onClick={this.sortBy.bind(this,'orderDate')}>Order Date</th>
							      <th onClick={this.sortBy.bind(this,'customerName')}>Name</th>
							      <th onClick={this.sortBy.bind(this,'customerPhone')}>Phone Number</th>
							      <th onClick={this.sortBy.bind(this,'customerAddress')}>Address</th>
							      <th>Product Count</th>
							      <th onClick={this.sortBy.bind(this,'total')}>Total</th>
							      <th onClick={this.sortBy.bind(this,'due')}>Due</th>
							      <th></th>
							    </tr>
							  </thead>
							  <tbody>
							    {orderitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div>
	        </div>
	        <Footer />
        </div>
    );
  }
}

export default myorders;
