import React, {Component} from 'react';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {loggeduser} from './utility/security';
import {baseurl, notify} from './utility/utility.js';

class myorderDetails extends Component {

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			orderDate: moment(),
			orderNumber:'',
			customerName:'',
			customerEmail:'',
			customerAddress:'',
			allcustomerArea:[],
			customerArea:'Select Customer Area',
			customerPhone:'',
			alldeliveryperson:[],
			deliveryPerson:'Select Delivery Person',
			notes:'',
			total:0,
			payments:[],
			products : [],
			discountTk:0,
			discountPct:0,
			status: 'incomplete',
			paymenttype:'Select Type',
			paymentAmount:'',
			due:0,
		};
	}

	changeDate = orderDate => this.setState({ orderDate })

	loadOrderData(id){
		console.log(id);
		axios.get(baseurl()+'/orders/'+id , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				//this.setState({alldeliveryperson : response.data.result});
				console.log(response.data.result);
				console.log(response.data.result.orderDate);
				this.setState({mode : "Update"});
				this.setState({orderDate : response.data.result.orderDate});
				this.setState({orderNumber : response.data.result.orderNumber});
				this.setState({customerId : response.data.result.customerId});
				this.setState({customerName : response.data.result.customerName});
				this.setState({customerEmail : response.data.result.customerEmail});
				this.setState({customerAddress : response.data.result.customerAddress});
				this.setState({customerPhone : response.data.result.customerPhone});
				this.setState({customerArea : response.data.result.customerArea});
				this.setState({deliveryPerson : response.data.result.deliveryPerson});
				this.setState({notes : response.data.result.notes});
				this.setState({payments : response.data.result.payments});
				this.setState({products : response.data.result.products});
				this.setState({discountTk : response.data.result.discountTk});
				this.setState({discountPct : response.data.result.discountPct});
				this.setState({status : response.data.result.status});
				this.setState({total : response.data.result.total});
				this.setState({due : response.data.result.due});
				this.setState({conveyance : response.data.result.conveyance});

				axios.get(baseurl()+'/orders?customerPhone='+response.data.result.customerPhone)
			    .then( (response)=> {
			     console.log(response);
					if(response.data.success === true){
						this.setState({orderByphone  : response.data.result});
					}
			    })
			    .catch(function (error) {
			      console.log(error);
			    });
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}


	componentWillMount(){
		if(localStorage.getItem('account') === 'null' || localStorage.getItem('account') === null){
			notify('Please Login!','#F44336');
			// this.setState({redirectTologin : true});
			this.props.history.push('/login?return=/myorders');
		}else{
			//axios call to get profile details
		}
		var orderId = this.props.match.params.id;
		if(orderId !== undefined){
				this.loadOrderData(orderId);
		}
	}

  render(){

  	const jsonitems = this.state.products.map(function(data,index){
		return(
			<tr key={index}>
				<td>{data.title}</td>
				<td>
				    {this.state.products[index].selectedModel}
				</td>
				<td>{this.state.products[index].note}</td>
				<td>{this.state.products[index].price}</td>
				<td>{this.state.products[index].quantity}</td>
				<td>{this.state.products[index].discountTk}</td>
				<td>{this.state.products[index].discountPct}</td>
				<td>{this.state.products[index].total}</td>
			</tr>
		)
	},this);

	const payments = this.state.payments.map(function(data, index){
		return(
			<tr key={index}>
				<td>{data.paymentAmount}</td>
				<td>{data.paymenttype}</td>
			</tr>
		)
	}, this);


    return (
        <div className="myorderDetails">
	        <div className="">
	        	<Header  />
	        	<div className="container">
	        		<div className="row">
	        			<div className="col-12 myorders-info-header">Billing Information</div>
						<div className="col-12">
							<div className="row customer-section">
								<div className="col-6">
									<div className="form-group">
										<label htmlFor="orderdate">Order Date</label>
										 <strong className="spd-ml-10">{moment(this.state.orderDate).format("DD/MM/YYYY")}</strong>
									</div>
									<div className="form-group">
										<label htmlFor="orderNumber">Order Number</label>
										<p>{this.state.orderNumber}</p>
									</div>
									<div className="form-group">
										<label htmlFor="customerName">Customer Name</label>
										<p>{this.state.customerName}</p>
									</div>
									<div className="form-group">
										<label htmlFor="customerPhone" >Phone number</label>
										<p>{this.state.customerPhone}</p>
									</div>
								</div>
								<div className="col-6  cus-address">
									<div className="form-group">
										<label htmlFor="customerAddress" >Customer Address</label>
										<p>{this.state.customerAddress}</p>
									</div>
									<div className="form-group">
										<label htmlFor="customerEmail" >Customer Email</label>
										<p>{this.state.customerEmail}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="col-12 myorders-info-header">Order Information</div>
						<div className="col-12 addproduct  table-responsive">
							<div className=" row">
								<table className="table table-bordered">
								  <thead>
								    <tr>
								      <th>Product Title</th>
								      <th>Model</th>
								      <th>Note</th>
								      <th className="sm-th">Price</th>
								      <th className="sm-th">Qty (TK)</th>
								      <th className="sm-th">Discount (Tk)</th>
								      <th className="sm-th">Discount (%)</th>
								      <th>Total</th>
								    </tr>
								  </thead>
								  <tbody>
								    {jsonitems}
								  </tbody>
								</table>
							</div>
						</div>
						<div className="col-12 myorders-info-header">Payments Information</div>
						<div className="col-12">
							<div className="row payment-section">
								<div className="col-sm-6 col-12">
									<div className="form-group">
										<label htmlFor="notes" >Notes</label>
										<p>{this.state.notes}</p>
									</div>
									<div className="row">
										<div className="col-12 payment-table">
											<h4>Payment Details</h4>
											<table className="table table-bordered">
											  <thead>
											    <tr>
											      <th>Amount</th>
											      <th>Amount Type</th>
											    </tr>
											  </thead>
											  <tbody>
											    {payments}
											  </tbody>
											</table>
										</div>
									</div>
								</div>
								<div className="col-sm-6 col-12">
									<div className="form-group">
										<label htmlFor="discountTk" >Discount Tk</label>
										<p>{this.state.discountTk} </p>
									</div>
									<div className="form-group">
										<label htmlFor="discountPct" >Discount Percent</label>
										<p>{this.state.discountPct} </p>
									</div>
									<div className="form-group">
										<label htmlFor="total" >Totals</label>
										<p id="grand-total">{this.state.total} </p>
									</div>
									<div className="form-group">
										<label htmlFor="due" >Due</label>
										<p id="due">{this.state.due} </p>
									</div>
								</div>
							</div>
						</div>
			        </div>
	        	</div>
	        </div>
	        <Footer />
        </div>
    );
  }
}

export default myorderDetails;
