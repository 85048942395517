import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import _ from 'lodash';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

class deliveryPerson extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			id: '',
			name:'',
			email:'',
			phone:'',
			notes:'',
			persons:[],
			sortOrder:"asc",
		};
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};


	componentWillMount(){
		sendToLoginIfNoAccess("orders","edit", this, "/deliveryPersons");

	  axios.get(baseurl()+'/deliverypersons', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({persons : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}
		if(input.name === "email"){
			this.setState({email : input.value});
		}
		else if(input.name === "phone"){
			this.setState({phone : input.value});
		}
		else if(input.name === "notes"){
			this.setState({notes : input.value});
		}
	}

	loadaddData(){
		this.setState({name : ''});
		this.setState({email : ''});
		this.setState({phone : ''});
		this.setState({notes : ''});
	}

	loadeditData(data){
		this.setState({_id : data._id});
		this.setState({name : data.name});
		this.setState({email : data.email});
		this.setState({phone : data.phone});
		this.setState({notes : data.notes});
	}

	addData(){
		axios.post(baseurl()+'/deliverypersons', {
			email:this.state.email,
		    name: this.state.name,
		    phone: this.state.phone,
		    notes: this.state.notes
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({persons : response.data.result});
		    	notify("Person Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/deliverypersons/'+this.state._id, {
			email:this.state.email,
		    name: this.state.name,
		    phone: this.state.phone,
		    notes: this.state.notes
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({persons : response.data.result});
				notify("Person Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/deliverypersons/'+data._id , {
		   data: {}
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({persons : response.data.result});
		    	notify('Person Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	sortBy(col,e){
		  var persons = this.state.persons;
			persons = _.orderBy(persons,[col],[this.state.sortOrder]);
			this.setState({persons: persons});

			if(this.state.sortOrder === 'asc'){
				this.setState({sortOrder : 'desc'})
			}else{
				this.setState({sortOrder : 'asc'})
			}
	 }


  render() {
  	const jsonitems=this.state.persons.map(function(data){
			return(
				<tr key={data._id}>
				  <td>{data.email}</td>
			      <td>{data.name}</td>
			      <td>{data.phone}</td>
			      <td>{data.notes}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#editModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("orders", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
	}, this);
    return (
        <div >
        	<Sidebar deliveryPerson = "active" ordershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container deliveryPerson">
	        	<div className="row">
	        		<div className="col-12">
	        					<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Person</button>

								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">Add Delivery Person</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
								        <form>

											<div className="form-group">
												<label htmlFor="email">Email</label>
												<input type="text" className="form-control" id="email" name="email" value={this.state.email} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="Name">Name</label>
												<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="phone">Contact phone</label>
												<input type="text" name="phone" id="phone"  className="form-control" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="notes">notes</label>
												<input type="text" name="notes" id="notes"  className="form-control" value={this.state.notes} onChange={this.changeInput.bind(this)}/>
											</div>

								        </form>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addData.bind(this)} >Add Person</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
							{/* Popup Modal  Code */}
								<div className="modal fade" id="editModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel"> Delivery Person Information</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
								       <form>
								       		<div className="form-group">
												<label htmlFor="email">email</label>
												<input type="text" className="form-control" id="email" name="email" value={this.state.email} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="name">Name</label>
												<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="phone">Contact phone</label>
												<input type="text" className="form-control" id="phone" name="phone" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label htmlFor="notes" >notes</label>
												<input type="text" className="form-control" name="notes" id="notes" value={this.state.notes} onChange={this.changeInput.bind(this)}/>
											</div>
								        </form>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.editData.bind(this)} >Update</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th onClick={this.sortBy.bind(this,'email')}>Email</th>
							      <th onClick={this.sortBy.bind(this,'name')}>Name</th>
							      <th onClick={this.sortBy.bind(this,'phone')}>Contact phone</th>
							      <th>notes</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default deliveryPerson;
