import React, { Component, Fragment } from "react";
import { Button, Table, Modal, Form, Badge, Checkbox, Input,Select, Row, Col, notification, message, Popover, Spin, Empty  } from "antd";
import moment from "moment";
import axios from "axios";
import DatePicker from 'react-datepicker';
import { RightCircleOutlined, SendOutlined, PlusCircleFilled, MinusCircleFilled, PictureOutlined, LinkOutlined, MessageOutlined  } from '@ant-design/icons';
import AppStore from "../store/AppStore";
import { loggeduser } from "../utility/security";
import { baseurl } from "../utility/utility";
import { observer } from 'mobx-react';

const ConversationsLabels = observer(class ConversationsLabels extends Component {

  constructor(props) {
    super(props);

    this.state = {

      loggeduser:loggeduser(),
      chatsLabel:{
        _id:'',
        fbChatId:'',
        labels: [],
        notes: '',
        order:false,
        sample:false,
        urgent:false,
        Replacement:false,
        OutDhaka:false,
        SameDay:false,
        followup:false,
        covers:'',
        popGrips:'',
        newModelRequest:'',
      },

      saving:false,

    };
  }

  handleChange = (value) => {
    console.log(`selected ${value}`);
  }

  UpdateChatLabels = (field, value) => {
    // console.log(value);
    // var chatsLabel = this.state.chatsLabel;
    // chatsLabel[field] = value;
    // this.setState({chatsLabel : chatsLabel})
    AppStore.selectedChatLabels[field] = value;
  }

  // componentWillReceiveProps(props){
  //   console.log(props.selectedChatLabels)
  //   if(props.selectedChatLabels && props.selectedChatLabels._id){
  //     this.setState({chatsLabel:props.selectedChatLabels})
  //   }
  // }

  Save = () => {
    var chatsLabel = AppStore.selectedChatLabels;
    chatsLabel.fbChatId = this.props.activeUserInfo.id;
    chatsLabel.fbChatLink = this.props.activeUserInfo.link;
    chatsLabel.name = this.props.activeUserInfo.participants.data[0].name;
    this.setState({saving:true})

    var extraUrl = chatsLabel._id ? '/'+chatsLabel._id : '';
    axios[chatsLabel._id ? 'put' : 'post'](baseurl()+'/fbchats' + extraUrl, chatsLabel ,{ headers: { authorization: this.state.loggeduser.token } })
	    .then((response)=> {
        console.log(response)
        this.setState({saving:false});

        if(response.data.lastAdded){
          AppStore.selectedChatLabels = response.data.lastAdded;
        }

        this.props.InjectLabelIntoConversation(this.props.activeUserInfo.id, AppStore.selectedChatLabels)

	    })
	    .catch((error) => {
        this.setState({saving:false})
	      console.log(error);
	    });
  }

  render() {

    const {Option} = Select;

    let {saving} = this.state;

    let {labels, order, sample, newModelRequest, urgent, followup, notes, covers, popGrips, screenProtectors} = AppStore.selectedChatLabels;

    const children = labels.map((data, index) => <Option key={index}>{data}</Option> )

    return (
      <>
        <Select value={labels} mode="tags" style={{ width: '100%' }} placeholder="Type Labels" onChange={(value) => this.UpdateChatLabels('labels', value)}>
          {children}
        </Select>
        <div className='my-2' style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
          <Checkbox checked={order} onChange={(e) => this.UpdateChatLabels('order', e.target.checked)}>Order</Checkbox>
          <Checkbox checked={sample} onChange={(e) => this.UpdateChatLabels('sample', e.target.checked)}>Sample</Checkbox>
          <Checkbox checked={urgent} onChange={(e) => this.UpdateChatLabels('urgent', e.target.checked)}>Urgent</Checkbox>
          <Checkbox checked={followup} onChange={(e) => this.UpdateChatLabels('followup', e.target.checked)}>Follow Up</Checkbox>
        </div>
        <Input
          value={covers ? covers.toString() : ''}
          className='my-2'
          onChange={(e) => this.UpdateChatLabels('covers', e.target.value)}
          placeholder="Total Covers"
        />
        <Input
          value={popGrips ? popGrips.toString() : ''}
          className='my-2'
          onChange={(e) => this.UpdateChatLabels('popGrips', e.target.value)}
          placeholder="Total Pop Grips"
        />
        <Input
          value={screenProtectors ? screenProtectors.toString() : ''}
          className='my-2'
          onChange={(e) => this.UpdateChatLabels('screenProtectors', e.target.value)}
          placeholder="Screen Protector"
        />
        <Input
          value={newModelRequest}
          className='my-2'
          onChange={(e) => this.UpdateChatLabels('newModelRequest', e.target.value)}
          placeholder="Model Request"
        />
        <Input.TextArea
          value={notes}
          className='my-2'
          onChange={(e) => this.UpdateChatLabels('notes', e.target.value)}
          placeholder="Notes"
        />
        <Button type='primary' loading={saving} onClick={this.Save}>Save</Button>
      </>
    );
  }
})

export default ConversationsLabels;
