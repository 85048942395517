import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import {ToastContainer} from 'react-toastify'
import {baseurl, notify} from './utility/utility.js';

class recoverpassword extends Component {
	constructor(props){
		super(props);
		this.state={
			email:'',
		};
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "email"){
			this.setState({email : input.value});
		}
	}

	recoverpassword(e){
		axios.post(baseurl()+'/account/forgot', {
		    email:this.state.email,
		  })
		  .then((response)=> {
		      	console.log(response);
		      if(response.data.success === true){
				notify( response.data.msg , "#28a745");
		      }else{
		      	for(var i = 0; i<response.data.msg.length; i++){
		      		notify(response.data.msg[i].msg, "#F44336");
		      	}
		      }
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
		 e.preventDefault();
	}

	render() {
		return (
		    <div className="page login-page">
		      <div className="container">
		        <div className="text-center">
		          	<div className="row">
			          	<div className="form-inner col-md-6 col-md-offset-3 recover-password">
				            <div className="logo text-uppercase"><strong className="text-primary">Recover password</strong></div>
				            <form id="recoverpassword-form" >
				              <div className="form-group">
				                <input id="email" type="email" placeholder="Email" name="email" required="" value={this.state.email}  onChange={this.changeInput.bind(this)} />
				              </div>
				              <input onClick={this.recoverpassword.bind(this)} type="submit" id="recoverpassword" className="btn btn-primary"  />

				            </form>
			            	<Link to="/login" className="forgot-pass">Login or Signup ..?</Link>
			          	</div>
		          	</div>
		          	<div className="copyrights text-center">
		            	<p>Design by Server Oasis</p>
		          	</div>
		        </div>
		      </div>
		      <ToastContainer />
		    </div>
		);
	}
}

export default recoverpassword;
