import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';

import {observer} from "mobx-react";
import AppStore from './store/AppStore.js';

const ProductTypes = observer(class ProductTypes extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			_id: '',
			name:'',
			price:'',
			title:"",
			mobileApp:true,
			website:true,
			ProductTypes:[],
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/ProductTypes" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/ProductTypes', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({ProductTypes : response.data.result});
				AppStore.productType = response.data.result;
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }



	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}else if(input.name === "price"){
			this.setState({price : input.value});
		}
	}

	loadaddData(){
		this.setState({
			_id: '',
			name:'',
			price:'',
			title:"",
			mobileApp:true,
			website:true,
		});
		this.setState({price : ''});
	}

	loadeditData(data){

		this.setState({
			_id: data._id,
			name:data.name,
			price:data.price,
			title:data.title,
			mobileApp:data.mobileApp,
			website:data.website,
		});

	}

	addData(){
		console.log(this.state)
		axios.post(baseurl()+'/ProductTypes', this.state , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({ProductTypes : response.data.result});
					AppStore.productType = response.data.result;
					notify("Type Added", "#28a745");
				}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}


	editData(){
		axios.put(baseurl()+'/ProductTypes/'+this.state._id, this.state, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({ProductTypes : response.data.result});
					AppStore.productType = response.data.result;
					notify("Type Updated", "#0275d8");
				}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/ProductTypes/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    //console.log(response);
		    console.log(response.data);
		    if(response.data.success === true){
		    	this.setState({ProductTypes : response.data.result});
					AppStore.productType = response.data.result;
					notify('Type Deleted!','#F44336');
				}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

  render() {
  	const jsonitems=this.state.ProductTypes.map(function(data){
			return(
				<tr key={data._id}>
			      <td>{data.name}</td>
			      <td>{data.title}</td>
			      <td>{data.price}</td>
			      <td>{data.website ? "yes" : 'no'}</td>
			      <td>{data.mobileApp ? "yes" : 'no'}</td>
			      <td>
			      	<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#editModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>
			      	<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
			      </td>
			    </tr>
			);
	}, this);
    return (
        <div >
        	<Sidebar ProductTypes = "active" productshow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container ProductTypes">
	        	<div className="row">
	        		<div className="col-12">
	        					<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Product Types</button>

								{/* Popup Modal Code */}
								<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel">Add Product Types</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
											<div className="form-group">
												<label >Name</label>
												<input type="text" className="form-control"  name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label >Title</label>
												<input type="text" className="form-control"  name="name" value={this.state.title} onChange={e => this.setState({title:e.target.value})}/>
											</div>
											<div className="form-group">
												<label >Price</label>
												<input type="text" className="form-control"  name="price" value={this.state.price} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group my-4" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
												<label className='m-0'>Show In Mobile App</label>
												<input type="checkbox" style={{width:100}} className="form-control" checked={this.state.mobileApp} onChange={e => this.setState({mobileApp:!this.state.mobileApp})}/>
											</div>
											<div className="form-group my-4" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
												<label className='m-0'>Show In Mobile App</label>
												<input type="checkbox" style={{width:100}} className="form-control" checked={this.state.website} onChange={e => this.setState({website:!this.state.website})}/>
											</div>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addData.bind(this)} >Add</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
							{/* Popup Modal  Code */}
								<div className="modal fade" id="editModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
								  <div className="modal-dialog" role="document">
								    <div className="modal-content">
								      <div className="modal-header">
								        <h5 className="modal-title" id="portfolioModalLabel"> Delivery ProductTypes Information</h5>
								        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
								          <span aria-hidden="true">&times;</span>
								        </button>
								      </div>
								      <div className="modal-body">
											<div className="form-group">
												<label htmlFor="name">Name</label>
												<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group">
												<label >Title</label>
												<input type="text" className="form-control"  name="name" value={this.state.title} onChange={e => this.setState({title:e.target.value})}/>
											</div>
											<div className="form-group">
												<label >Price</label>
												<input type="text" className="form-control"  name="price" value={this.state.price} onChange={this.changeInput.bind(this)}/>
											</div>
											<div className="form-group my-4" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
												<label className='m-0'>Show In Mobile App</label>
												<input type="checkbox" style={{width:100}} className="form-control" checked={this.state.mobileApp} onClick={e => this.setState({mobileApp:!this.state.mobileApp})}/>
											</div>
											<div className="form-group my-4" style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
												<label className='m-0'>Show In Mobile App</label>
												<input type="checkbox" style={{width:100}} className="form-control" checked={this.state.website} onClick={e => this.setState({website:!this.state.website})}/>
											</div>
								      </div>
								      <div className="modal-footer">
								        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								        <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.editData.bind(this)} >Update</button>
								      </div>
								    </div>
								  </div>
								</div>
							{/* Popup Modal Code End */}
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Name</th>
							      <th>Title</th>
							      <th>Price</th>
							      <th>Website</th>
							      <th>Mobile App</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default ProductTypes;
