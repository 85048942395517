import React, {Component} from 'react';
import Header from './header.js';
import Footer from './footer.js';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import {ToastContainer} from 'react-toastify';
import {baseurl, getLocalItem, notify, setLocalItem} from './utility/utility.js';
import Cart from './cart.js';


class wishlist extends Component {
	constructor(props){
		super(props);
		this.state = {
			allwishlistProduct:[],
			allcartProduct:[],
			start:0,
			perpage:5,
			totalpage:0,
			activepage:1,
		};
	}

	componentWillMount(){
		this.loadwishlist();
	}

	loadwishlist(){
		this.setState({allwishlistProduct : getLocalItem('wishlist')});
		this.setState({allcartProduct : getLocalItem('cart') });
		this.setState({cartQty : getLocalItem('cart').length});
	}

	pagination(pageNo){
			var start = pageNo*this.state.perpage;
			this.searchedProduct(this.state.searchword,  this.state.selectedModel, this.state.selectedcategory, this.state.selectedmaterials, start, this.state.perpage);
			this.setState({activepage  : pageNo+1});
			console.log(pageNo);
	}

	activepage(index){
		if(this.state.activepage === index+1){
			return "page-active";
		}
		console.log(index);
	}

	addCart(product){
		var allcartProduct = getLocalItem('cart');
		//if(allcartProduct.map(function(e) { return e._id; }).indexOf(product._id) === -1){
			product.quantity = 1;
			product.discountTk = 0;
			product.discountPct = 0;
			product.selectedModel = 'Select Model'
			product.total = product.price
			allcartProduct.push(product);
			setLocalItem( "cart" , allcartProduct);
			this.setState({allcartProduct  : allcartProduct});
			notify("Added To Cart", "#28a745");
		// }else{
		// 	notify("Already Added", "#0275d8");
		// }
	}

	removewishlist(index){
		var arrayvar = getLocalItem('wishlist');
		arrayvar.splice(index, 1);
		//this.setState({ products: arrayvar });
		setLocalItem( "wishlist" , arrayvar);
		this.loadwishlist();
		notify("Removed Form Wishlist", "#dc3545");
	}

	render() {

		const jsonitems=this.state.allwishlistProduct.map(function(data, index){
			var imgUrl = baseurl()+'/uploads/productimages/'+data.imageFolder+data.images[0];
			return(
				<div key={data._id} className="col-lg-3 col-md-4 col-sm-4 col-12">
    				<div className="product spd-mt-30">
    					<img src={imgUrl.toLowerCase()} alt="wishlist Product" />
    					<div className="product-content">
							<p>{data.title}</p>
							<p className="money">৳ {data.price}</p>
							<Link className="btn btn-default"  to={ "viewproduct/" + data._id} >Quick View</Link>
							<button title="Add To Cart" onClick={this.addCart.bind(this,  data)} className="btn btn-success"><i className="fa fa-cart-plus" aria-hidden="true"></i></button>
							<button title="Remove From Wishlist" onClick={this.removewishlist.bind(this,  index)} className="btn btn-danger"><i className="fa fa-times" aria-hidden="true"></i></button>
    					</div>
    				</div>
    			</div>
			);
		},this);

		const pageItem = _.times(this.state.totalpage, i =>
				<span className="page-no" id={this.activepage(i)} onClick={this.pagination.bind(this, i)} key={i}>{i + 1}</span>
		);

	    return (
	        <div className="productlist wishlist">
		        <div className="">
		        	<Cart cartProduct={this.state.allcartProduct}  />
		        	<Header cartQty={this.state.cartQty} />
		        	<div className="container spd-min-height">
		        		<div className="row spd-mt-30">
		       				{jsonitems}
		        		</div>
		        		<div className="row">
			        		<div className="col-12">
			        			{pageItem}
			        		</div>
		        		</div>
		        	</div>
		        	<ToastContainer />
		        </div>
		        <Footer />
		    </div>
	    );
	}
}

export default wishlist;
