import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {Link} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-datepicker/dist/react-datepicker.css';
import {Tooltip} from 'react-lightweight-tooltip';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {baseurl, GetStatusClr, RandKey, renderWeekDay} from './utility/utility.js';


import {observer} from "mobx-react";
import AppStore from './store/AppStore';

const courierDistribution = observer(class courierDistribution extends Component {

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			courierDistributionData:[],
			sortOrder:"asc",
			startDate: new Date(),
			endDate: new Date(),
			alldeliveryperson:[],
			start:0,
			perpage:50,

			TotalPayment:0,
			TotalPaymentReceived:0,

			datequery:'Select Date',
			selectedPerson:''
		};
	}

	componentWillMount(){
		sendToLoginIfNoAccess("reports","courierDistribution", this, "/reports/courierDistribution");

		this.filterByDate(AppStore.activeCourier, AppStore.orderStartDate, AppStore.orderEndDate);

	    axios.get(baseurl()+'/deliverypersons' , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
			if(response.data.success === true){
				this.setState({alldeliveryperson : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	changeStartDate =  startDate => AppStore.orderStartDate = startDate;

	changeEndDate = endDate => AppStore.orderEndDate = endDate;


	sortBy(col,e){
		//   var courierDistributionData = this.state.courierDistributionData;
		// 	courierDistributionData = _.orderBy(courierDistributionData,[col],[this.state.sortOrder]);
		// 	this.setState({courierDistributionData: courierDistributionData});

		// 	if(this.state.sortOrder === 'asc'){
		// 		this.setState({sortOrder : 'desc'})
		// 	}else{
		// 		this.setState({sortOrder : 'asc'})
		// 	}
	 }



	numberOfproduct(products){
		var totalProduct = 0;
		for(var i = 0; i < products.length; i++){
			totalProduct = totalProduct+parseFloat(products[i].quantity);
		}
		return(
			totalProduct
		)
	}

	filterByDate(activeCourier, startedDate, endedDate){
		var startDate = moment(startedDate).format("YYYY/MM/DD");
		var  endDate = moment(endedDate).format("YYYY/MM/DD");

		var url = '';

		if(activeCourier == ''){
			url = baseurl()+'/reports/distributioncourier?v='+RandKey()+'&startDate='+startDate+'&endDate='+endDate;
		}else{
			url = baseurl()+'/reports/distributioncourier?v='+RandKey()+'&startDate='+startDate+'&endDate='+endDate+'&couriercompany='+activeCourier;
		}

		axios.get(url, {
			start: 0,
		numRecords: 5
		} , { headers: { authorization: this.state.loggeduser.token } })
		.then( (response)=> {
		if(response.data.success === true){
			this.setState({courierDistributionData : response.data.result});
			this.calculateTotalPayment(response.data.result);
		}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	calculateTotalPayment(data){
		var keys = Object.keys(data);
		var total = 0;
		var payments = 0;
		var conveyance = 0;
		var Cash = 0;
		for(var i = 0; keys.length > i; i++){
			for(var x = 0; data[keys[i]].length > x; x++){
				total += data[keys[i]][x].total;
				for(var n = 0; data[keys[i]][x].payments.length > n; n++){
					if(data[keys[i]][x].payments[n].paymenttype !== "Cash"){
						payments = payments + parseInt(data[keys[i]][x].payments[n].paymentAmount);
					}else{
						Cash = Cash + parseInt(data[keys[i]][x].payments[n].paymentAmount);
					}
				}
			}
		}

		this.setState({TotalPayment: total-payments-Cash});
		this.setState({TotalPaymentReceived: Cash});
	}

	calculateSingleOrderPayment(payments){
		var total = 0;
		for(var n = 0; payments.length > n; n++){
			total = total + parseInt(payments[n].paymentAmount);
		}
		return total;
	}

	calculateSingleOrderBkask(payments){
		var total = 0;
		for(var n = 0; payments.length > n; n++){
			if(payments[n].paymenttype == "bKash"){
				total = total + parseInt(payments[n].paymentAmount);
			}
		}
		return total;
	}


	calculate(data, field){

		var sumtotal = 0;
		var sumdue = 0;
		var sumconveyance = 0;
		var paid = 0;
		var sumbkash = 0;
		var sumCash = 0;
		for (var i = 0; i < data.length; i++) {
			sumtotal = sumtotal+data[i].total;
			sumdue = sumdue+data[i].due;
			sumconveyance = sumconveyance+parseFloat(data[i].conveyance);

			for(var n = 0; data[i].payments.length > n; n++){
				paid = paid + parseInt(data[i].payments[n].paymentAmount);
				if(data[i].payments[n].paymenttype == "Cash"){
					sumCash = sumCash + parseInt(data[i].payments[n].paymentAmount);
				}
				if(data[i].payments[n].paymenttype == "bKash"){
					sumbkash = sumbkash + parseInt(data[i].payments[n].paymentAmount);
				}
			}
		}
		if(field === "total"){
			return sumtotal;
		}else if(field === "due"){
			return sumdue;
		}else if(field === "conveyance"){
			return sumconveyance;
		}
		else if(field === "receivable"){
			// it was before
			// return sumdue-sumconveyance;
			//return sumtotal-paid-sumconveyance;
			return sumtotal - sumbkash;
		}else if(field === "bkash"){
			return sumbkash;
		}else if(field === "Cash"){
			return sumCash;
		}
	}

	tooltipdata(products){
		const tooltiplist = products.map(function(data, index){
			return(
				<div key={index} className="product-tooltip">
					<p>{data.title}</p>
					<p>{data.selectedModel}
					<span className="spd-ml-10">Qty: {data.quantity}</span></p>
				</div>
			)
		}, this);
		return tooltiplist;
	}


	courierDistributionList(courierDistributionList){
		const list = courierDistributionList.map(function(data){
			var  editurl = "/editorder/"+data._id;
			return(
				<tr key={data._id}>
					<td>{moment(data.orderDate).format("DD/MM/YYYY")}</td>
					<td>{renderWeekDay(data.orderDate)}</td>
					<td><button type='button' className={GetStatusClr(data.status)+' btn  btn-sm'}>{data.status}</button></td>
					<td>{data.customerName}</td>
					<td>{data.customerPhone}</td>
					<td>{data.customerArea}</td>
					<td>{data.total}</td>
					<td>{this.calculateSingleOrderBkask(data.payments)}</td>
					<td>{data.due}</td>
					<td>{data.conveyance}</td>
					<td>
						<Tooltip content={this.tooltipdata(data.products)}>{this.numberOfproduct(data.products)}</Tooltip>
					</td>
					<td><Link to={editurl} onClick={()=> AppStore.fromcourierDistribution = true} >Details</Link></td>

				</tr>
			)
		}, this);
		return list;
	}

	loadActiveDate(){
		if(AppStore.activeOrderDate == 'Today' || AppStore.activeOrderDate == ''){
			this.selecttoday();
		}else if(AppStore.activeOrderDate == 'Yesterday'){
			this.selectyesterady();
		}else if(AppStore.activeOrderDate == 'This Week'){
			this.selectweek();
		}else if(AppStore.activeOrderDate == 'This Month'){
			this.selectmonth();
		}else if(AppStore.activeOrderDate == 'Tomorrow'){
			this.selectTomorrowady();
		}else if(AppStore.activeOrderDate == '2 Day Ago'){
			this.selectTwoDaysAge();
		}else if(AppStore.activeOrderDate == '2 Day Later'){
			this.selectTwoDayslater();
		}
	}

	selecttoday(){
		var today = moment();
		this.filterByDate(AppStore.activeCourier, today, today);
		AppStore.selecttoday();
	}

	selectyesterady(){
		var yesterday = moment().subtract(1, 'days');
		this.filterByDate(AppStore.activeCourier, yesterday, yesterday);
		AppStore.selectyesterady();
	}

	selectTwoDaysAge(){
		var TwoDayAgo = moment().subtract(2, 'days');
		this.filterByDate(AppStore.activeCourier, TwoDayAgo, TwoDayAgo);
		AppStore.selectTwoDaysAge();
	}

	selectweek(){
		var end = moment();
		var start = moment().subtract(1, 'week');
		this.filterByDate(AppStore.activeCourier, start, end);
		AppStore.selectweek();

	}

	selectmonth(){
		var end = moment();
		var start  = new moment().startOf('month');
		this.filterByDate(AppStore.activeCourier, start, end);
		AppStore.selectmonth();
	}

	selectTomorrowady(){
		var Tomorrow = moment().add(1, 'days');
		this.filterByDate(AppStore.activeCourier, Tomorrow, Tomorrow);
		AppStore.selectTomorrowady();
	}

	selectTwoDayslater(){
		var date = moment().add(2, 'days');
		this.filterByDate(AppStore.activeCourier, date, date);
		AppStore.selectTwoDayslater();
	}


	selecteCourier(e){
		AppStore.activeCourier = e.target.value;
		this.filterByDate(AppStore.activeCourier, AppStore.orderStartDate, AppStore.orderEndDate);
	}

	// confirmCashInAccount = (data) => {
	// 	confirmAlert({
	// 	  title: 'Confirm Add '+this.state.TotalPayment+'TK In Account',
	// 	  message: 'Are you sure to do this.',
	// 	  confirmLabel: 'Confirm',
	// 	  cancelLabel: 'Cancel',
	// 	  onConfirm: () => this.addCashInAccount(data)
	// 	})
	// };

	// addCashInAccount(){
	// 	if(AppStore.orderEndDate == AppStore.orderStartDate){
	// 		axios.post(baseurl()+'/accounts', {
	// 			description: "Cash from Courier delivery between"+moment(AppStore.orderEndDate).format('DD/MM/YYYY')+" to "+moment(AppStore.orderEndDate).format('DD/MM/YYYY'),
	// 			entryType: "Credit",
	// 			amount: this.state.TotalPayment,
	// 		} , { headers: { authorization: this.state.loggeduser.token } })
	// 		.then((response) => {
	// 			console.log(response);
	// 			if(response.data.success === true){
	// 				notify("Added", "#28a745");
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			console.log(error);
	// 		});
	// 	}else{
	// 		notify('Multiple Days Selected!','#F44336');
	// 	}
	// }

	// confirmColldone = (data) => {
	// 	confirmAlert({
	// 	  title: 'Confirm Collect',
	// 	  message: 'Are you sure to do this.',
	// 	  confirmLabel: 'Confirm',
	// 	  cancelLabel: 'Cancel',
	// 	  onConfirm: () => this.collectionDone(data),
	// 	})
	// };

	// collectionDone(data){
	// 	var orders = this.state.distributionData[data];
	// 	var last = false;
	// 	for(var i = 0; orders.length > i; i++){
	// 		if(parseInt(orders[i].due) > 0){
	// 			orders[i].payments.push({
	// 				date: moment(new Date()).format("DD/MM/YYYY"),
	// 				paymentAmount : orders[i].due,
	// 				paymenttype: "Cash"
	// 			});
	// 			orders[i].due = 0;
	// 		}
	// 		//console.log(orders[i])
	// 		if(i == orders.length - 1){
	// 			last = true;
	// 		}
	// 		this.updateOrder(orders[i], last);
	// 	}
	// }

  render() {

		const AllCourier = AppStore.courier.map((data, index)=>{
			return (
				<option key={index} value={data.name}>{data.name}</option>
			);
		})

  	const orderitems = Object.keys(this.state.courierDistributionData).map(function(data,  index){
		return(
			<div className="col-12 distribution-list" key={index} >
					<h3>{data}</h3>
					<table className="table table-bordered" id="orderTable">
					<thead>
						<tr>
							<th onClick={this.sortBy.bind(this,'orderDate')}>Order Date</th>
							<th>Order Day</th>
							<th>Status</th>
							<th onClick={this.sortBy.bind(this,'customerName')}>Customer Name</th>
							<th onClick={this.sortBy.bind(this,'customerPhone')}>Phone Number</th>
							<th onClick={this.sortBy.bind(this,'customerArea')}>Area</th>
							<th onClick={this.sortBy.bind(this,'total')}>Total</th>
							<th>Bkash</th>
							<th onClick={this.sortBy.bind(this,'due')}>Due</th>
							<th onClick={this.sortBy.bind(this,'conveyance')}>Conveyance</th>
							<th>Product Count</th>
							<th>Details</th>
						</tr>
					</thead>
					<tbody>
						{this.courierDistributionList(this.state.courierDistributionData[data])}
						<tr>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th>Grand Total</th>
							<th>{this.calculate(this.state.courierDistributionData[data],"total")}</th>
							<th>{this.calculate(this.state.courierDistributionData[data],"bkash")}</th>
							<th>{this.calculate(this.state.courierDistributionData[data],"due")}</th>
							<th>{this.calculate(this.state.courierDistributionData[data],"conveyance")}</th>
							<th>Net Receivable</th>
							<th>{this.calculate(this.state.courierDistributionData[data],"receivable")}</th>
						</tr>
						<tr>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th></th>
							<th>Total Cash Received</th>
							<th>{this.calculate(this.state.courierDistributionData[data],"Cash")}</th>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}, this);

    return (
        <div className="products">
        	<Sidebar courierDistribution="active" reportshow="show" />
	        <div className="page">
	        	<Header />
	        	<div className="container-fluid">
	        		<div className="row spd-mt-30">

	        			<div className="col-7">
	        				<div className="row">
	        					<div className="col-5">
											<DatePicker
												selected={moment(AppStore.orderStartDate)}
												onChange={this.changeStartDate.bind(this)}
												dateFormat="DD/MM/YYYY"
											/>
										</div>
										<div className="col-5">
											<DatePicker
											selected={moment(AppStore.orderEndDate)}
											onChange={this.changeEndDate.bind(this)}
											dateFormat="DD/MM/YYYY"
											/>
										</div>
										<div className="col-2">
											<button className="btn btn-success" onClick={() => this.filterByDate(AppStore.activeCourier,  AppStore.orderStartDate, AppStore.orderEndDate)}><i className="fa fa-search"></i></button>
										</div>
									</div>
	        			</div>
								<div className="col-3">
									<div className="form-group">
										<select value={AppStore.activeCourier} className='form-control' onChange={this.selecteCourier.bind(this)}>
											<option value="">All Courier</option>
											{AllCourier}
										</select>
									</div>
								</div>
								<div className="col-2">
									<div className="dropdown">
										<button className="btn btn-default dropdown-toggle pull-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												{AppStore.activeOrderDate}
										</button>
											<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
												<option onClick={this.selecttoday.bind(this)} className="dropdown-item btn btn-default" href="#">Today
												</option>
												<option onClick={this.selectyesterady.bind(this)} className="dropdown-item btn btn-default" href="#">Yesterday</option>
												<option onClick={this.selectTwoDaysAge.bind(this)} className="dropdown-item btn btn-default" href="#">2 Day Ago</option>
												<option onClick={this.selectTomorrowady.bind(this)} className="dropdown-item btn btn-default" href="#">Tomorrow</option>
												<option onClick={this.selectTwoDayslater.bind(this)} className="dropdown-item btn btn-default" href="#">2 Day Later</option>
												<option onClick={this.selectweek.bind(this)} className="dropdown-item btn btn-default" href="#">This Week</option>
												<option onClick={this.selectmonth.bind(this)} className="dropdown-item btn btn-default" href="#">This month</option>
											</div>
									</div>
								</div>

							</div>
	        		<div className="row spd-mt-30">
						<div className="col-12">
							<h3>Total Cash Payment Receivable: {this.state.TotalPayment}</h3>
							<h3>Total Cash Payment Received: {this.state.TotalPaymentReceived}</h3>
						</div>
						{/* <div className="col-6">
							<button onClick={this.confirmCashInAccount.bind(this)} className='btn btn-success btn-lg'>Add Cash In Account (If not done yet)</button>
						</div> */}
					</div>
					<div className="row spd-mt-30">
						<div className="col-12">
							<div className="row">
								{orderitems}
							</div>
						</div>
			        </div>
	        	</div>
	        </div>
        </div>
    );
  }
})

export default courierDistribution;
