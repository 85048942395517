import React, {Component} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import {baseurl} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {observer} from "mobx-react";

const OrderImage = observer(class OrderImage extends Component {


    render(){
        const OrderImages = this.props.images.map(function(data, index){
            return(
                <div className="col-3 mt-2" key={index}>
                    <img key={index} className='full-width'  src={(baseurl()+'/uploads/productimages/'+data.productType.trim()+'/'+data.productType.trim()+'-'+data.code.trim()+'.jpg').toLowerCase()}  />
                    <h4 className="mt-3 text-center">{data.productType.trim()}-{data.code.trim()} {data.model}</h4>
                </div>
            )
        }, this)

        return(
            <div className="row">
                <div className="modal fade " id="OrderImages" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="row">
                                {OrderImages}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
})
export default OrderImage;
