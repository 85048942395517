import React, { Component, Fragment } from "react";
import { Button, Table, Modal, Form, Badge } from "react-bootstrap";
import moment from "moment";
import DatePicker from 'react-datepicker';

class OrderTask extends Component {

  emptyEle = {
    date:moment(),
    title:'',
    agents:['Nur Uddin'],
    description:'',
    complete:false,
  }

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      taskData: JSON.parse(JSON.stringify(this.emptyEle)),
      updatingIndex:'',
      mode:'add'
    };
  }

  componentWillReceiveProps(props){
    console.log(props)
  }

  LoadAddData = () => {
    this.setState({taskData:this.emptyEle, mode:'add', updatingIndex:''})
    this.handleOpen()
  }

  LoadUpdateData = (data, index) => {
    this.setState({taskData:data, updatingIndex: index, mode: 'update'})
    this.handleOpen();
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleOpen = () => {
    this.setState({show: true});
  }

  Save = () => {
    var tasks = this.props.tasks;
    let {taskData} = this.state;
    if(this.state.mode == 'add'){
      tasks.push(this.state.taskData)
    }else{
      tasks[this.state.updatingIndex] = this.state.taskData
    }
    this.props.UpdateTaskData(tasks)

    this.handleClose();
  }

  Delete = (index) => {
    var tasks = this.props.tasks;
    
    tasks.splice(index, 1);

    this.props.UpdateTaskData(tasks);
  }

  UpdateTaskData = (field, value) => {
    var taskData = this.state.taskData;
    taskData[field] = value;
    this.setState({taskData:taskData});
  }

  ToggleAggent = (agent) => {
    var taskData = this.state.taskData;
    var index = this.state.taskData.agents.indexOf(agent);
    if(index == -1){
      taskData.agents.push(agent);
    }else{
      taskData.agents.splice(index, 1);
    }
    this.setState({taskData:taskData});
  }

  MarkDoneToggle = (index) => {
    var tasks = this.props.tasks;
    
    tasks[index].complete = !tasks[index].complete;

    this.props.UpdateTaskData(tasks)

  }



  render() {

    let {show, taskData} = this.state;
    

    return (
      <>
        <div className="row mt-2">
          <div className='my-2 col-12'>
            Total Incomplete Task {(this.props.tasks.filter((ele) => {return ele.complete == false})).length} 
            <Button  size='sm' className='pull-right' variant='dark' onClick={() => this.LoadAddData()}>Add</Button>
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>Title</th>
                <th>Agents</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.tasks.map((data, index) => 
                <tr key={index} style={{opacity: data.complete ? 0.5 : 1}}>
                  <td>{moment(data.date).format('DD/MM/YYYY')}</td>
                  <td>{data.title}</td>
                  <td>
                    <div className='a-c-r'>
                      {data.agents.map((agentData, aganetIndex) => 
                        <Badge variant="secondary" className='m-1'>{agentData}</Badge>
                      )}
                    </div>
                  </td>
                  <td>
                    <div className='a-c-r'>
                      <Button size='sm' onClick={() => this.MarkDoneToggle(index)} ><i className={data.complete ? 'fa fa-repeat' : 'fa fa-check'}></i></Button>
                      <Button size='sm' onClick={() => this.LoadUpdateData(data, index)} variant='danger'><i className='fa fa-eye'></i></Button>
                      <Button size='sm' onClick={() => this.Delete(index)} ><i className='fa fa-trash'></i></Button>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <Modal show={show} onHide={() => this.setState({show:!this.state.show})}>
            <Modal.Header closeButton>
              <Modal.Title>{taskData.mode == 'add' ? "Add Task" : "Task Details" }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                  <div className="form-group">
										<label htmlFor="orderdate">Mock Up Date</label>
											<DatePicker
											selected={moment(taskData.date)}
											onChange={(date) => this.UpdateTaskData('date', date)}
											dateFormat="DD/MM/YYYY"
										/>
									</div>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Task Title</Form.Label>
                  <Form.Control type="email" placeholder="name@example.com" value={taskData.title} onChange={(e) => this.UpdateTaskData('title', e.target.value)} />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label>Agent</Form.Label>
                  <div className='a-c-r'>
                    <Button className='m-1' onClick={() => this.ToggleAggent('Nur Uddin')} variant={ taskData.agents.indexOf('Nur Uddin') > -1 ? 'success' : 'light'}>Nur Uddin</Button>
                    <Button className='m-1' onClick={() => this.ToggleAggent('Saif')} variant={ taskData.agents.indexOf('Saif') > -1 ? 'success' : 'light'}>Saif</Button>
                    <Button className='m-1' onClick={() => this.ToggleAggent('Rezaul Karim')} variant={ taskData.agents.indexOf('Rezaul Karim') > -1 ? 'success' : 'light'}>Rezaul Karim</Button>
                    <Button className='m-1' onClick={() => this.ToggleAggent('Huyaman Kabir')} variant={ taskData.agents.indexOf('Huyaman Kabir') > -1 ? 'success' : 'light'}>Huyaman Kabir</Button>
                    <Button className='m-1' onClick={() => this.ToggleAggent('Delowar Hossain')} variant={ taskData.agents.indexOf('Delowar Hossain') > -1 ? 'success' : 'light'}>Delowar Hossain</Button>
                  </div>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Task Details</Form.Label>
                  <Form.Control as="textarea" rows="3" value={taskData.description} onChange={(e) => this.UpdateTaskData('description', e.target.value)} />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={() => this.Save()}>
                {taskData.mode == 'add' ? "Add" : "Update" }
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

export default OrderTask;