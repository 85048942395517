import React, { Component } from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import {notify, baseurl} from './utility/utility.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, sendToLoginIfNoAccess, loggeduser} from './utility/security';

class CustomizerFonts extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			id: '',
			name:'',
			url:'',
			previewImage:'',
			CustomizerFonts:[],
		};
		//this.Data.bind(this)
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',                        
		  message: 'Are you sure to do this.',               
		  confirmLabel: 'Confirm',                           
		  cancelLabel: 'Cancel',                             
		  onConfirm: () => this.delete(data),      
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/CustomizerFonts");
	  // Make a request for a user with a given ID
	  axios.get(baseurl()+'/customizerfonts',{ headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({CustomizerFonts : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	 }

	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}else if(input.name === "url"){
			this.setState({url : input.value});
		}
	}

	onChangeFile(e) {

		var imageData = e.target.files[0]
		const url = baseurl()+'/images';
		const data = new FormData();
		data.append('file',imageData);
		data.append('folder',"customizer" );
		axios.post(url,data, { headers: { authorization: this.state.loggeduser.token } })
		.then( (response)=> {
			if(response.data.success){
				this.setState({previewImage:imageData.name});
			}else{
				notify("File Didn't Uploaded!",'#F44336');
			}
		})
		.catch((error)=> {
		    console.log(error);
		    notify("File Didn't Uploaded!",'#F44336');
		});
	}

	loadaddData(){
		this.setState({
			name : '',
			url : '',
			previewImage : ''
		});
	}

	loadeditData(data){
		this.setState({
			_id : data._id,
			name : data.name,
			url : data.url,
			previewImage : data.previewImage
		});
	}

	addData(){
		axios.post(baseurl()+'/customizerfonts', {
		    name: this.state.name,
		    url: this.state.url,
		    previewImage: this.state.previewImage,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({CustomizerFonts : response.data.result});
		    	notify("Font Added", "#28a745");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	
	editData(){
		axios.put(baseurl()+'/customizerfonts/'+this.state._id, {
		    name: this.state.name,
		    url: this.state.url,
		    previewImage: this.state.previewImage,
		  }, { headers: { authorization: this.state.loggeduser.token } })
		  .then( (response) => {
		    console.log(response);
		    if(response.data.success === true){
		    	this.setState({CustomizerFonts : response.data.result});
				notify("Font Updated", "#0275d8");
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	delete(data){
		axios.delete(baseurl()+'/customizerfonts/'+data._id , {
		   data: {}
		},{ headers: { authorization: this.state.loggeduser.token } })
		.then((response) => {
			//console.log(response);
			console.log(response.data);
			if(response.data.success === true){
				this.setState({CustomizerFonts : response.data.result});
				notify('Material Deleted!','#F44336');
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

  render() {

  	const jsonitems = this.state.CustomizerFonts.map(function(data){
		return(
			<tr key={data._id}>
				<td>
					{(data.previewImage) ?
						<img style={{width:100}} src={(baseurl()+'/uploads/productimages/customizer/'+data.previewImage).toLowerCase()} />
						:
						<h5>Preview Not Avaliable</h5>
					}
				</td>
				<td>{data.name}</td>
				<td>{data.url}</td>
				<td>
				<button className="btn btn-primary" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#editModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>  
				<button hidden={!hasAccess("products", "delete")}  className="btn btn-danger" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button>
				</td>
			</tr>
		);
	}, this);

    return (
        <div>
        	<Sidebar CustomizerFonts = "active"  Customizershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container CustomizerFonts">
	        	<div className="row">
	        		<div className="col-12">
						<button type="button" className="btn btn-primary spd-mt-30 spd-mb-10" data-toggle="modal" data-target="#portfolioModal" data-whatever="@getbootstrap" onClick={this.loadaddData.bind(this)} >Add Customizer Fonts</button>

						{/* Popup Modal Code */}
						<div className="modal fade" id="portfolioModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
							<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
								<h5 className="modal-title" id="portfolioModalLabel">Add Customizer Fonts</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								</div>
								<div className="modal-body">
								<form>
									<div className="form-group">
										<label htmlFor="Name">Font Name</label>
										<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
									</div>
									<div className="form-group">
										<label htmlFor="Name">Font URL</label>
										<input type="text" className="form-control" id="url" name="url" value={this.state.url} onChange={this.changeInput.bind(this)}/>
									</div>
									<div className="form-group">
										<label htmlFor="imageupload">Preview Image</label>
										<input className="form-control" type="file" onChange={this.onChangeFile.bind(this)} />
									</div>
									{this.state.previewImage &&
										<img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/customizer/'+this.state.previewImage).toLowerCase()} />
									}
								</form>
								</div>
								<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.addData.bind(this)} >Add CustomizerFonts</button>
								</div>
							</div>
							</div>
						</div>
					{/* Popup Modal Code End */}
					{/* Popup Modal  Code */}
						<div className="modal fade" id="editModal" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
							<div className="modal-dialog" role="document">
							<div className="modal-content">
								<div className="modal-header">
								<h5 className="modal-title" id="portfolioModalLabel"> Update Customizer Fonts</h5>
								<button type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
								</div>
								<div className="modal-body">
									<form>
										<div className="form-group">
											<label htmlFor="Name">Font Name</label>
											<input type="text" className="form-control" id="name" name="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
										</div>
										<div className="form-group">
											<label htmlFor="Name">Font URL</label>
											<input type="text" className="form-control" id="url" name="url" value={this.state.url} onChange={this.changeInput.bind(this)}/>
										</div>
										<div className="form-group">
											<label htmlFor="imageupload">Preview Image</label>
											<input className="form-control" type="file" onChange={this.onChangeFile.bind(this)} />
										</div>
										{this.state.previewImage &&
											<img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/customizer/'+this.state.previewImage).toLowerCase()} />
										}
									</form>
								</div>
								<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
								<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.editData.bind(this)} >Update</button>
								</div>
							</div>
							</div>
						</div>
					{/* Popup Modal Code End */} 
	        		</div>
	        	</div>
					<div className="row">
						<div className="col-12">
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th>Name</th>
							      <th>URL</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default CustomizerFonts;
