import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import moment from 'moment';
import _ from 'lodash';
import {ToastContainer} from 'react-toastify';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {baseurl, notify} from './utility/utility.js';
import {Link} from 'react-router-dom';

import {Button, Form, Modal} from 'react-bootstrap'
import {Pagination} from 'antd';
import {observer} from 'mobx-react';

const { compose, withProps, withState, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");

const OrderEntries = observer(class OrderEntries extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			locationData:[],
			entries:[],
			entriesBeforeFilter:[],

			activeLabelData:{
				_id:'',
				labels:[],
				orders:[],
			},
			addingLabel:'',
			addingOrder:'',

			loadedAll:false,

			query:'order',

			count:0,
			perpage:100

		};
	}



	componentDidMount(){
		sendToLoginIfNoAccess("orders","edit", this, "/OrderEntries");
		this.loadOrderEntries()
	}

	UpdateLabelData = (data) => {

		var updatingData = JSON.parse(JSON.stringify(this.state.activeLabelData))
		updatingData = {...updatingData, ...data}
		this.setState({activeLabelData: updatingData})

	}

	LoadAllEntries = (start = 0, end = this.state.perpage) => {

		this.setState({loadedAll:true})
		this.setState({query:'all'})

		axios.get(baseurl()+'/fbchats?start='+start+'&numRecords='+end, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (res) => {
			 console.log(res);
			if(res.data.success){
				this.setState({entries:res.data.result, count:res.data.count})
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });

	}

	Search = (searchWord) => {
		this.setState({loadedAll:false})
		this.setState({query:'all'})

		axios.get(baseurl()+'/fbchats?customerName='+searchWord, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (res) => {
			 console.log(res);
			if(res.data.success){
				this.setState({entries:res.data.result, count:res.data.count})
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	loadOrderEntries(){
		this.setState({loadedAll:false})

		axios.post(baseurl()+'/fbchats/latest',
		{
			query:[
				{order:true},
				{urgent:true},
				{sample:true},
				{followup:true},
				{OutDhaka:true},
				{SameDay:true},
				{Replacement:true}
			]
		}
		, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (res) => {
			console.log(res)

			if(res.data.success){

				if(this.state.query == 'all'){
					this.setState({entries:res.data.result})
				}else{

					this.setState({entries: res.data.result.filter((ele) => {
						return ele[this.state.query] == true
					})})
				}

				this.setState({entriesBeforeFilter:res.data.result})
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	Save = () => {
		// console.log(JSON.parse(JSON.stringify(activeChatData)))
		// return null;

		var savingLabelData = JSON.parse(JSON.stringify(this.state.activeLabelData))

		if(!savingLabelData.orders){
			savingLabelData.orders = [];
		}

		var extraUrl = savingLabelData._id ? '/'+savingLabelData._id : '';

		axios.put(baseurl() + '/fbchats' + extraUrl, savingLabelData, {
			headers: { authorization: this.state.loggeduser.token }
		} )
		  .then((res)=> {

		  console.log(res);
		  if(res.data.success){
			this.RemoveActiveLabels()
			if(this.state.loadedAll){
				this.LoadAllEntries()
			}else{
				this.loadOrderEntries()
			}
			notify('Updated!','green');
		  }

		})
		.catch((error) => {
		  this.setState({saving:false})
		  console.log(error);
		});
	  }

	loadeditData = (data) => {
		this.setState({activeLabelData:JSON.parse(JSON.stringify(data))})
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	delete(data){
		axios.delete(baseurl()+'/fbchats/'+data._id , {
		   data: {}
		  } , { headers: { authorization: this.state.loggeduser.token } })
		  .then((response) => {
		    if(response.data.success === true){
				if(this.state.loadedAll){
					this.LoadAllEntries()
				}else{
					this.loadOrderEntries()
				}
				notify('Entry Deleted!','#F44336');
			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });
	}

	RemoveActiveLabels = () => {
		this.setState({activeLabelData:{
			_id:'',
			labels:[],
			orders:[]
		}})
	}

	AddLabels = () => {
		if(this.state.addingLabel){
			var activeLabelData = this.state.activeLabelData
			activeLabelData.labels.push(this.state.addingLabel)
			this.UpdateLabelData(activeLabelData)
		  	this.setState({addingLabel:''})
		}
	}

	RemoveLabels = (index) => {
		var activeLabelData = this.state.activeLabelData
		activeLabelData.labels.splice(index, 1)
		this.UpdateLabelData(activeLabelData)
	}

	Addorders = () => {
		if(this.state.addingOrder){
			var activeLabelData = this.state.activeLabelData
			activeLabelData.orders.push({_id:this.state.addingOrder})
			this.UpdateLabelData(activeLabelData)
		  	this.setState({addingOrder:''})
		}
	}

	Removeorders = (index) => {
		var activeLabelData = this.state.activeLabelData
		activeLabelData.orders.splice(index, 1)
		this.UpdateLabelData(activeLabelData)
	}

	UpdateQuery = (field) => {
		// var query = this.state.query;
		// if(field == 'order'){
		// 	query.query[0].order = !query.query[0].order
		// }else if(field == 'urgent'){
		// 	query.query[1].urgent = !query.query[1].urgent
		// }else if(field == 'sample'){
		// 	query.query[2].sample = !query.query[2].sample
		// }else if(field == 'followup'){
		// 	query.query[3].followup = !query.query[3].followup
		// }

		this.setState({query:field}, () => { this.loadOrderEntries() })
	}

	calTotalItem = (entries) => {
		let popGrips = 0;
		let covers = 0;
		let screenProtectors = 0;
		for (let index = 0; index < entries.length; index++) {
			covers = covers + parseInt(entries[index].covers ? entries[index].covers : 0);
			popGrips = popGrips + parseInt(entries[index].popGrips ? entries[index].popGrips : 0);
			screenProtectors = screenProtectors + parseInt(entries[index].screenProtectors ? entries[index].screenProtectors : 0);

		}
		return {popGrips:popGrips, covers:covers, screenProtectors:screenProtectors}
	}


  render() {

	var totalInfo = this.calTotalItem(this.state.entries)

	const jsonitems=(this.state.entries).map(function(data){
		return(
			<tr key={data._id}>
			  <td>{moment(data.updatedAt).format('DD/MM/YYYY')}</td>
			  <td style={{maxWidth:200}}>
					{data.name}<br/>
					Order Checked: {data.orderChecked ? 'yes' : 'no'} <br/>
					{data.orderUncheckedDate ? `Order Unchecked date: ${moment(data.orderUncheckedDate).format('Do MMM YYYY - hh:mm a')}`: null } <br/>
					{/* <h5>Order</h5> */}
				  	{_.orderBy(data.orders, (o) => {
					return moment(o.orderDate).format('YYYYMMDD')
					}, ['desc']).map((item) =>
						<Link to={"/editOrder/"+item._id} className='btn btn-primary btn-sm m-2' target="_blank">{moment(item.orderDate).format('DD/MM/YYYY')}</Link>
					)}

					{data.cusPhone ?
						<>
							<br/>
							Search Phone: <b style={{color:'red'}} > {data.cusPhone} </b>
							<br/>
						</>
					: null}

					{data.searchText ?
						<>
							<br/>
							Search String: <b style={{color:'red'}} > {data.searchText} </b>
							<br/>
						</>
					: null}
			  </td>
			  <td>
			  	<input type='checkbox' readOnly={true} checked={data.order ? data.order : false} /> Order <br/>
			  	<input type='checkbox' readOnly={true} checked={data.urgent ? data.urgent : false} /> Urgent <br/>
			  	<input type='checkbox' readOnly={true} checked={data.sample ? data.sample : false} /> Sample <br/>
			  	<input type='checkbox' readOnly={true} checked={data.followup ? data.followup : false} /> Follow Up <br/>
				<input type='checkbox' readOnly={true} checked={data.Replacement ? data.Replacement : false} /> Replacement <br/>
				<input type='checkbox' readOnly={true} checked={data.OutDhaka ? data.OutDhaka : false} /> Out Dhaka <br/>
				<input type='checkbox' readOnly={true} checked={data.SameDay ? data.SameDay : false} /> Same Day <br/>
			  </td>
			  <td>
				  <ul style={{paddingLeft:30}}>
					{data.labels.map((label) =>
						<li key={label}>{label}</li>
					)}
				  </ul>
			  </td>
			  <td>
				  <p>Total Covers: <b>{data.covers}</b></p>
				  <p>Total Pop Grips: <b>{data.popGrips}</b></p>
				  <p>Total Screen Protectors: <b>{data.screenProtectors}</b></p>
				  <p>New Model Request: <b>{data.newModelRequest}</b></p>
				  <p style={{maxWidth:200}}>Notes: <b className='text-danger'>{data.notes} {data.urgent ? 'urgent' : null }</b></p>
			  </td>
			  <td>
				  <button className="btn btn-primary btn-sm" onClick={this.loadeditData.bind(this, data)} data-toggle="modal" data-target="#portfolioModal"><i className="fa fa-pencil" aria-hidden="true"></i></button>

				  <Link title="Copy Order"
					to={{
						pathname: "/addOrder",
						state: { isCoping:true, orderEntryData : data, copiedOrderData: {
							customerName:data.name,
							facebookLink:data.fbChatLink,
							fbSearchText:data.searchText,
							internalNotes:data.notes
						}}
					}}
				  >
				  	<button className="btn btn-secondary btn-sm"  ><i className="fa fa-copy" aria-hidden="true"></i></button>
				  </Link>

				  {/*<a target="_blank" href={AppStore.fbbusinessHost + data.fbChatLink}>*/}
				  {/*	<button className="btn btn-default btn-sm" ><i className="fa fa-comment" aria-hidden="true"></i></button>*/}
				  {/*</a>*/}
				  {/* <button hidden={!hasAccess("orders", "delete")} className="btn btn-danger btn-sm" onClick={this.confirmDelete.bind(this, data)} ><i className="fa fa-trash-o" aria-hidden="true"></i></button> */}
			  </td>
			</tr>
		);
	}, this);

	let {activeLabelData, query} = this.state

    return (
        <div >
        	<Sidebar OrderEntries = "active" ordershow="show" />
	        <div className="page">
	        	<Header  />
	        	<div className="container-fluid Log spd-mt-30">
					<div className="row">
						<div className="col-12 text-center" >
							<h1>Pending Order Entries </h1>
							<Form.Group controlId="chkBoxall">
								<Form.Control
									placeholder="Search"
									onChange={e => this.Search(e.target.value)}
								/>
							</Form.Group>
							<h2 className='my-3'>
								Orders:  {this.state.entriesBeforeFilter.filter((ele) => {return ele.order == true}).length} Urgents:  {this.state.entriesBeforeFilter.filter((ele) => {return ele.urgent == true}).length} Samples:  {this.state.entriesBeforeFilter.filter((ele) => {return ele.sample == true}).length} Followups:  {this.state.entriesBeforeFilter.filter((ele) => {return ele.followup == true}).length}
							</h2>

							<h2 className='my-3'>Total: {this.state.entries.length} Cover: {totalInfo.covers} Pop Grip: {totalInfo.popGrips} Screen P. {totalInfo.screenProtectors}</h2>
						</div>
						<div className="col-2 my" >
							<Button variant='success' onClick={() => this.LoadAllEntries()} >All</Button>
							<Button variant='secondary' onClick={() => this.loadOrderEntries()} >Latest</Button>
						</div>
						<div className="col-1">
							<Form.Group controlId="chkbox0">
								<Form.Check
									type="checkbox"
									label="All"
									checked={query == 'all'}
									onChange={e => this.UpdateQuery('all')}
								/>
							</Form.Group>
						</div>
						<div className="col-1">
							<Form.Group controlId="chkBox">
								<Form.Check
									type="checkbox"
									label="Order"
									checked={query == 'order'}
									onChange={e => this.UpdateQuery('order')}
								/>
							</Form.Group>
						</div>
						<div className="col-1">
							<Form.Group controlId="chkBox2">
								<Form.Check
									type="checkbox"
									label="Urgent"
									checked={query == "urgent"}
									onChange={e => this.UpdateQuery('urgent')}
								/>
							</Form.Group>
						</div>
						<div className="col-1">
							<Form.Group controlId="chkBox3">
								<Form.Check
									type="checkbox"
									label="Sample"
									checked={query == "sample"}
									onChange={e => this.UpdateQuery('sample')}
								/>
							</Form.Group>
						</div>
						<div className="col-1">
							<Form.Group controlId="chkBox4">
								<Form.Check
									type="checkbox"
									label="Followup"
									checked={query == "followup"}
									onChange={e => this.UpdateQuery('followup')}
								/>
							</Form.Group>
						</div>

						<div className="col-1">
							<Form.Group controlId="chkBox5">
								<Form.Check
									type="checkbox"
									label="Replacement"
									checked={query == "Replacement"}
									onChange={e => this.UpdateQuery('Replacement')}
								/>
							</Form.Group>
						</div>

						<div className="col-1">
							<Form.Group controlId="chkBox6">
								<Form.Check
									type="checkbox"
									label="Out Dhaka"
									checked={query == "OutDhaka"}
									onChange={e => this.UpdateQuery('OutDhaka')}
								/>
							</Form.Group>
						</div>

						<div className="col-1">
							<Form.Group controlId="chkBox7">
								<Form.Check
									type="checkbox"
									label="Same Day"
									checked={query == "SameDay"}
									onChange={e => this.UpdateQuery('SameDay')}
								/>
							</Form.Group>
						</div>

						<div className="col-12">
							<table className="table table-bordered">
								<thead>
									<tr>
										<th>Last Updated</th>
										<th>Name</th>
										<th>Basic</th>
										<th>Labels</th>
										<th>Other Info</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{jsonitems}
								</tbody>
							</table>
							{this.state.loadedAll &&
								<Pagination
									defaultCurrent={1}
									total={this.state.count}
									pageSize={this.state.perpage}
									onChange={(page) => {this.LoadAllEntries((page -1) * this.state.perpage , this.state.perpage)}}
									showSizeChanger={false}
								/>
							}
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
			<Modal show={activeLabelData._id ? true : false} onHide={this.RemoveActiveLabels}>
				<Modal.Header closeButton>
					<Modal.Title>Update Labels</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>Labels</Form.Label>
						<div>
							{activeLabelData.labels.map((data, index) =>

								<span onClick={() => this.RemoveLabels(index)} key={index} style={{borderRadius:30, padding:"5px 15px", border:"1px solid #000", margin:5, cursor:'pointer'}}>{data} <i className='fa fa-times'></i></span>
							)}
						</div>
						<Form.Control type="text" placeholder="Label" value={this.state.addingLabel}
						onChange={e => this.setState({addingLabel:e.target.value})} />
					</Form.Group>
					<Button size="sm" onClick={() => this.AddLabels()}>Add Label</Button>

					<div className='my-3 pl-3'>
						<Form.Group controlId="formBasicCheckbox">
							<Form.Check
								type="checkbox"
								label="Order"
								checked={activeLabelData.order ? activeLabelData.order : false}
								onChange={e => this.UpdateLabelData({order: !activeLabelData.order})}
							/>
							</Form.Group>
						<Form.Group controlId="formBasicCheckbox2">
							<Form.Check
								type="checkbox"
								label="urgent"
								checked={activeLabelData.urgent ? activeLabelData.urgent : false}
								onChange={e => this.UpdateLabelData({urgent: !activeLabelData.urgent})}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicCheckbox3">
							<Form.Check
								type="checkbox"
								label="sample"
								checked={activeLabelData.sample ? activeLabelData.sample : false}
								onChange={e => this.UpdateLabelData({sample: !activeLabelData.sample})}
							/>
						</Form.Group>
						<Form.Group controlId="formBasicCheckbox4">
							<Form.Check
								type="checkbox"
								label="followup"
								checked={activeLabelData.followup ? activeLabelData.followup : false}
								onChange={e => this.UpdateLabelData({followup: !activeLabelData.followup})}
							/>
						</Form.Group>

						<Form.Group controlId="formBasicCheckbox5">
							<Form.Check
								type="checkbox"
								label="Replacement"
								checked={activeLabelData.Replacement ? activeLabelData.Replacement : false}
								onChange={e => this.UpdateLabelData({Replacement: !activeLabelData.Replacement})}
							/>
						</Form.Group>

						<Form.Group controlId="formBasicCheckbox6">
							<Form.Check
								type="checkbox"
								label="Out Dhaka"
								checked={activeLabelData.OutDhaka ? activeLabelData.OutDhaka : false}
								onChange={e => this.UpdateLabelData({OutDhaka: !activeLabelData.OutDhaka})}
							/>
						</Form.Group>

						<Form.Group controlId="formBasicCheckbox7">
							<Form.Check
								type="checkbox"
								label="Same Day"
								checked={activeLabelData.SameDay ? activeLabelData.SameDay : false}
								onChange={e => this.UpdateLabelData({SameDay: !activeLabelData.SameDay})}
							/>
						</Form.Group>
					</div>

					<Form.Group>
						<Form.Label>Total Cover</Form.Label>
						<Form.Control type="text" placeholder="Label" value={activeLabelData.covers ? activeLabelData.covers.toString() : '' }
						onChange={e => this.UpdateLabelData({covers:e.target.value})} />
					</Form.Group>

					<Form.Group>
						<Form.Label>Total Pop Grip</Form.Label>
						<Form.Control type="text" placeholder="Label" value={activeLabelData.popGrips ? activeLabelData.popGrips.toString() : '' }
						onChange={e => this.UpdateLabelData({popGrips:e.target.value})} />
					</Form.Group>

					<Form.Group>
						<Form.Label>Total Screen Protechtor</Form.Label>
						<Form.Control type="text" placeholder="Label" value={activeLabelData.screenProtectors ? activeLabelData.screenProtectors.toString() : '' }
						onChange={e => this.UpdateLabelData({screenProtectors:e.target.value})} />
					</Form.Group>

					<Form.Group>
						<Form.Label>Model Request</Form.Label>
						<Form.Control type="text" placeholder="Label" value={activeLabelData.newModelRequest ? activeLabelData.newModelRequest.toString() : '' }
						onChange={e => this.UpdateLabelData({newModelRequest:e.target.value})} />
					</Form.Group>

					<Form.Group>
						<Form.Label>Notes</Form.Label>
						<Form.Control type="text" as="textarea" rows="3" placeholder="Label" value={activeLabelData.notes ? activeLabelData.notes.toString() : '' }
						onChange={e => this.UpdateLabelData({notes:e.target.value})} />
					</Form.Group>

					<Form.Group>
						<Form.Label>Orders</Form.Label>
						<div>
							{activeLabelData.orders.map((data, index) =>

								<span onClick={() => this.Removeorders(index)} key={index} style={{borderRadius:30, padding:"5px 15px", border:"1px solid #000", margin:5, cursor:'pointer'}}>{data._id} <i className='fa fa-times'></i></span>
							)}
						</div>
						<Form.Control type="text" placeholder="Order" value={this.state.addingOrder}
						onChange={e => this.setState({addingOrder:e.target.value})} />
					</Form.Group>
					<Button size="sm" onClick={() => this.Addorders()}>Add Order</Button>

				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={this.RemoveActiveorders}>
					Close
					</Button>
					<Button variant="primary" onClick={this.Save}>
					Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
        </div>
    );
  }
})

export default OrderEntries;
