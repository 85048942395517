import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {observer} from "mobx-react";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {CSVLink} from "react-csv";

const emptyEle = {
	name: "",
    title: "",
    imageLink:"",
    productTypeToPurchase:"",
    numberRequired:"",
    minimumPriceOfEachProductPurchase:"",
    productTypeForFree:"",
    numberFree:"",
    deliveryChargeInsideDhaka:"",
    deliveryChargeOutsideDhaka:"",
    minimumCartValue:"",
	storeCredit:"",
	discountTk:"",
	discountPercent:""
}

const PopularModel = observer(class PopularModel extends Component {

	CSVData = [["Model", "Quantity"]];

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			model:[],
			count:1,
			perpage:50,
			startDate:moment().subtract(30, 'days'),

			loading:false,

		};
		//this.Data.bind(this)
	}

	componentWillMount(){
		sendToLoginIfNoAccess("products","edit", this, "/PopularModel" , { headers: { authorization: this.state.loggeduser.token } });
	  // Make a request for a user with a given ID

		this.LoadEle();
	 }

	 LoadEle = (start = 0, end = this.state.perpage) => {

		this.setState({loading:true})

		var startDate = moment(this.state.startDate).format('YYYY-MM-DD')

		// var fullUrl = baseurl()+'/reports/mostpopular?startDate=' + startDate + '&start='+start+'&numRecords='+end;

		var fullUrl = baseurl()+'/reports/mostpopular?startDate=' + startDate;
		 console.log(fullUrl)
		axios.get(fullUrl, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response) => {
	     console.log(response);
			if(response.data && response.data.products){
				this.setState({model : response.data.models});
			}

			this.setState({loading:false})
	    })
	    .catch(function (error) {
	      console.log(error);

			this.setState({loading:false})

	    });
	 }

	 changeStartDate =  (startDate) => {
		 this.setState({startDate:startDate}, () => {
			this.LoadEle();
		 })
	}





  render() {

	this.orderCaseCSVData = [['Model', 'Quantity']];

	// _.orderBy(this.state.PopularModel, [PopularModel => PopularModel.name.toLowerCase()], ['asc']);
  	const jsonitems=this.state.model.map(function(data){

		this.orderCaseCSVData.push([data.name, data.count]);

		return(
			<tr key={data._id}>
				<td>
				{data.name}
				</td>
				<td>{data.count}</td>
			</tr>
		);
	}, this);

    return (
        <div >
        	<Sidebar saleOffer = "active" />
	        <div className="page">
	        	<Header  />
	        	<div className="container PopularModel">
					<div className="row">
						<div className="col-6 my-4">
							<DatePicker
								selected={moment(this.state.startDate)}
								onChange={this.changeStartDate.bind(this)}
								dateFormat="DD/MM/YYYY"
							/>
						</div>
						<div className="col-6 my-4">
							<button className='btn btn-default pull-right mx-1'>
								<CSVLink data={this.orderCaseCSVData}>Download CSV</CSVLink>
							</button>
						</div>
						{this.state.model.length == 0  ?
							<div className="col-12">
								<h3 style={{textAlign:'center', padding:100}}>
									{this.state.loading ? "Loading... " : "NO DATA FOUND"}
								</h3>
							</div>
						:
							<div className="col-12">
								<table className="table table-bordered">
									<thead>
										<tr>
											<th>Name</th>
											<th>Counts</th>
										</tr>
									</thead>
									<tbody>
										{jsonitems}
									</tbody>
								</table>
							</div>
  						}
					</div>

	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default PopularModel;
