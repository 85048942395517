import axios from 'axios';
import { observable, computed, action, decorate } from "mobx";
import {baseurl, BandageToStatus2} from '../utility/utility';
import moment from 'moment';
import _ from 'lodash';
import {loggeduser} from '../utility/security';

class Store {
  url = baseurl();
  productTypes = [];
  products = [];
  models = [];
  categories = [];
  tasks = [];
  modelgroups = [];
  customerareas = [];
  blacklist = [];
  courier = [];
  activeOrderDate = "Today";
  activeDeliveryPerson = "";
  fromDistribution = false;
  activeCourier = "";
  lastAddedOrderDate = "";
  EntryCategories = [];

  orderStart = 0;
  orderNumRecordes = 100;
  orderStartDate = moment();
  orderEndDate = moment();
  searchWord = "";
  orders = [];
  totalOrder = 0;
  totalpage = 0;

  loggeduser = loggeduser();
  FBapiToken =
    "EAADwJK0rbXABOxdHJK9E89WKj6ZCSYDf2tC289vzeY9znLSWnyHA4XtZALZCwSG7KUZB1bP5LLDyaFH5lov9S0V8ZBC9f2DFDxI1tsTKAR8GFGuVaOGE4ZC4KU7v1TQS8swHogfZAnOvHeiECLzfPQHdpJcK86fVhPAVYaFUtMAMWLFcXZB1beg4ppA2TUDsRonmFM5RZCWls7gZDZD";
  fbBaseUrl = "https://graph.facebook.com/v7.0";
  fbbusinessHost = "https://business.facebook.com";
  pageId = "1263722627074235";
  selectedChatLabels = {
    _id: "",
    fbChatId: "",
    labels: [],
    notes: "",
    order: false,
    sample: false,
    urgent: false,
    newModelRequest: "",
  };

  approvals = [];
  CustomizerFonts = [];
  loadedFonts = [
    {
      name: "Ibarra Real Nova",
      url: "https://fonts.googleapis.com/css?family=Ibarra+Real+Nova&display=swap",
    },
  ];

  constructor() {
    this.OrderLoop();
    this.LoadProducts();
    this.LoadCustomizerFonts();
  }

  LoadProducts() {
    axios
      .get(this.url + "/products")
      .then((response) => {
        if (response.data.success === true) {
          this.products = _.shuffle(response.data.result);
          // this.products = response.data.result;
          this.models = response.data.models;
          this.categories = response.data.categories;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  LoadCustomizerFonts() {
    axios
      .get(this.url + "/customizerfonts")
      .then((response) => {
        if (response.data.success === true) {
          this.CustomizerFonts = response.data.result;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  OrderLoop() {
    setInterval(() => {
      if (this.lastAddedOrderDate) {
        this.filterByDate(
          0,
          this.orderNumRecordes,
          this.lastAddedOrderDate,
          this.lastAddedOrderDate
        );
      } else {
        this.filterByDate(
          this.orderStart,
          this.orderNumRecordes,
          this.orderStartDate,
          this.orderEndDate
        );
      }
    }, 5000);
  }

  filterByDate(start, numRecords, startedDate, endedDate) {
    this.orderStart = start;
    this.orderNumRecordes = numRecords;
    this.orderStartDate = startedDate;
    this.orderEndDate = endedDate;

    var startDate = moment(startedDate).format("YYYY/MM/DD");
    var endDate = moment(endedDate).format("YYYY/MM/DD");

    axios
      .get(
        baseurl() +
          "/orders?startDate=" +
          startDate +
          "&endDate=" +
          endDate +
          "&start=" +
          start +
          "&numRecords=" +
          numRecords,
        { headers: { authorization: this.loggeduser.token } }
      )
      .then((response) => {
        if (response.data.success === true) {
          var bandageOrder = BandageToStatus2(response.data.result);
          this.orders = _.orderBy(bandageOrder, ["customerName"], ["asc"]);
          this.totalpage = Math.ceil(response.data.count / numRecords);
          this.totalOrder = response.data.count;
        }

        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  loadActiveDate() {
    if (this.activeOrderDate == "Today" || this.activeOrderDate == "") {
      this.selecttoday();
    } else if (this.activeOrderDate == "Yesterday") {
      this.selectyesterady();
    } else if (this.activeOrderDate == "This Week") {
      this.selectweek();
    } else if (this.activeOrderDate == "This Month") {
      this.selectmonth();
    } else if (this.activeOrderDate == "Tomorrow") {
      this.selectTomorrowady();
    } else if (this.activeOrderDate == "2 Day Ago") {
      this.selectTwoDaysAge();
    } else if (this.activeOrderDate == "2 Day Later") {
      this.selectTwoDayslater();
    }
  }

  selecttoday() {
    var today = moment();
    this.filterByDate(0, this.orderNumRecordes, today, today);
    this.activeOrderDate = "Today";
  }

  selectyesterady() {
    var yesterday = moment().subtract(1, "days");
    this.filterByDate(0, this.orderNumRecordes, yesterday, yesterday);
    this.activeOrderDate = "Yesterday";
  }

  selectTwoDaysAge() {
    var TwoDayAgo = moment().subtract(2, "days");
    this.filterByDate(0, this.orderNumRecordes, TwoDayAgo, TwoDayAgo);
    this.activeOrderDate = "2 Day Ago";
  }

  selectweek() {
    var end = moment();
    var start = moment().subtract(1, "week");
    this.filterByDate(0, this.orderNumRecordes, start, end);
    this.activeOrderDate = "This Week";
  }

  selectmonth() {
    var end = moment();
    var start = new moment().startOf("month");
    this.filterByDate(0, this.orderNumRecordes, start, end);
    this.activeOrderDate = "This Month";
  }

  selectTomorrowady() {
    var Tomorrow = moment().add(1, "days");
    this.filterByDate(0, this.orderNumRecordes, Tomorrow, Tomorrow);
    this.activeOrderDate = "Tomorrow";
  }

  selectTwoDayslater() {
    var date = moment().add(2, "days");
    this.filterByDate(0, this.orderNumRecordes, date, date);
    this.activeOrderDate = "2 Day Later";
  }
}

decorate(Store, {
  productType: observable,
  products: observable,
  models: observable,
  categories: observable,
  tasks: observable,
  modelgroups: observable,
  customerareas: observable,
  blacklist: observable,
  courier: observable,
  activeOrderDate: observable,
  activeDeliveryPerson: observable,
  fromDistribution: observable,
  activeCourier: observable,
  lastAddedOrderDate: observable,
  EntryCategories: observable,

  orderStart: observable,
  orderNumRecordes: observable,
  orderStartDate: observable,
  orderEndDate: observable,
  searchWord: observable,
  orders: observable,
  totalOrder: observable,
  totalpage: observable,

  loggeduser: observable,
  FBapiToken: observable,
  pageId: observable,
  fbBaseUrl: observable,
  selectedChatLabels: observable,

  CustomizerFonts: observable,
  loadedFonts: observable,
  approvals: observable
});

var AppStore = new Store();

export default AppStore;
