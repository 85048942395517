import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import history from './history';
import {ToastContainer} from 'react-toastify';
import {baseurl, notify, setlog} from './utility/utility.js';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {hasAccess, loggeduser, sendToLoginIfNoAccess} from './utility/security';
import {Badge} from 'react-bootstrap';

class Products extends Component {

	constructor(props){
		super(props);
		this.state = {
			loggeduser:loggeduser(),
			products:[],
			sortOrder:"asc",
			searchproduct:'',
			start:0,
			perpage:50,
			activePage:1,
			totalpage:0,

			categories:[],
			parent:'',
			categoryName:'',
			catLoopStarted:false,
		};
	}

	confirmDelete = (data) => {
		confirmAlert({
		  title: 'Confirm Delete',
		  message: 'Are you sure to do this.',
		  confirmLabel: 'Confirm',
		  cancelLabel: 'Cancel',
		  onConfirm: () => this.delete(data),
		})
	};

	componentWillMount(){
		sendToLoginIfNoAccess("products","view", this, "/products");
	  // Make a request for a user with a given ID
	  	var searchproduct = "";
		if(history.location.state !== undefined){
			//this.setState({searchproduct: history.location.state.searchproduct})
			searchproduct = history.location.state.searchproducts;
		}
	  	this.filterProduct(searchproduct, this.state.start, this.state.perpage);
		  //this.setState({searchproduct : searchproduct});

		this.LoadCategories();
		this.CatInterval();
	 }

	CatInterval = () => {
		setInterval(() => {
			this.LoadCategories()
		}, 5000)
	}

	LoadCategories(){
		axios.get(baseurl()+'/categories' , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({categories : response.data.result});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "searchproduct"){
			this.setState({searchproduct : input.value});
			this.filterProduct(input.value, this.state.start, this.state.perpage);
		}
	}

	delete(data){
	  axios.delete(baseurl()+'/products/'+data._id , {
	     data: {}
	    }, { headers: { authorization: this.state.loggeduser.token } })
	    .then((response)=> {
	      if(response.data.success === true){
	      	this.filterProduct(this.state.searchproduct , this.state.start , this.state.perpage);
	      	notify('Product Deleted!','#F44336');
	      	setlog("Product Deleted id: "+data._id+" Title:  "+data.title ,"product delete");
	      }
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	 }

	 sortBy(col,e){
		var products = this.state.products;
		products = _.orderBy(products,[col],[this.state.sortOrder]);
		this.setState({products: products});
		if(this.state.sortOrder === 'asc'){
			this.setState({sortOrder : 'desc'})
		}else{
			this.setState({sortOrder : 'asc'})
		}
	 }

	filterProduct(searchword, start, numRecords){
		var  url = baseurl()+'/products/search';
		console.log(url);
	    axios.post(url,{
	    	title:searchword,
	    	model:[],
	    	category:[],
	    	material:[],
	    	start:start,
	    	numRecords: numRecords,
	    }, { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
	     console.log(response);
			if(response.data.success === true){
				this.setState({products : response.data.result});
				console.log(Math.ceil(response.data.count/this.state.perpage));
				this.setState({totalpage : Math.ceil(response.data.count/this.state.perpage)});
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	activePage(index){
		if(this.state.activePage === index+1){
			return "page-active";
		}
	}

	pagination(pageNo){
		var start = pageNo*this.state.perpage;
		this.filterProduct(this.state.searchproduct, start, this.state.perpage);
		this.setState({activePage  : pageNo+1});
		this.setState({start  : start});
	}

	UpdateCategories(productData, catName){

		var index = productData.categories.indexOf(catName);
		if(index == -1){
			productData.categories.push(catName);
		}else{
			productData.categories.splice(index, 1);
		}
		axios.put(baseurl()+'/products/'+productData._id, productData , { headers: { authorization: this.state.loggeduser.token } })
		.then((response)=> {
			if(response.data.success === true){
				this.filterProduct(this.state.searchproduct, this.state.start, this.state.perpage);
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	addCat(){
		var categoryName = document.getElementById('categoryName').value;

		var index = this.state.categories.findIndex((ele) => {return ele.name.toLowerCase() == categoryName.toLowerCase()} );
		if(index == -1){
			axios.post(baseurl()+'/categories', {
				parent: this.state.parent,
				name: categoryName,
			} , { headers: { authorization: this.state.loggeduser.token } })
			.then((response) => {
			console.log(response);
			if(response.data.success === true){
				this.setState({categories : response.data.result});
				notify("Category Added", "#28a745");
			}
			})
			.catch(function (error) {
			console.log(error);
			});
		}else{
			notify('Category Exist!','#F44336');
		}

	}

	selectParentCat(data){
		console.log(data);
		this.setState({parent : data});
	}

  render() {

  	const jsonitems=this.state.products.map(function(data){
  		// var imgUrl = baseurl()+'/uploads/productimages/'+data.imageFolder+data.images[0];
  		var imgUrl = baseurl()+'/uploads/'+data.imageFolder+data.images[0];
		return(
			<tr key={data._id}>
				<td>
					<a href={imgUrl} target="_black">
						<img style={{width:'100%'}} className="img-productspage" src={imgUrl.toLowerCase()} alt='product Missing' />
					</a>
					<h3 className="text-center mt-2">{data.title}</h3>
				</td>
				<td>{data.price}</td>
				<td>
					<div className="categorySelectWrapeer">
						{this.state.categories.map((catData, index) =>
							<Badge onClick={() => this.UpdateCategories(data, catData.name)} key={index} variant={data.categories.indexOf(catData.name) == -1 ? "secondary" : "primary"}>{catData.name}</Badge>
						)}
					</div>
				</td>
				<td>
					<Link hidden={!hasAccess("products", "edit")} className="btn btn-primary" to={ "editProducts/" + data._id} > <i className="fa fa-pencil" aria-hidden="true"></i></Link>
					<button hidden={!hasAccess("products", "delete")} className="btn btn-danger" onClick={this.confirmDelete.bind(this,data)}><i className="fa fa-trash"></i></button>
				</td>
		    </tr>
		);
	},this);

	const pageItem = _.times(this.state.totalpage, i =>
			<span className="page-no" id={this.activePage(i)} onClick={this.pagination.bind(this, i)} key={i}>{i + 1}</span>
	);

	const catDropdown=this.state.categories.map(function(data){
		return(
			<span key={data._id}  onClick={this.selectParentCat.bind(this, data.name)} className="dropdown-item btn btn-default" href="#">{data.name}</span>
		);
	}, this);

    return (
        <div className="products">
        	<Sidebar products="active" productshow="show" />
	        <div className="page">
	        	<Header />
	        	<div className="container-fluid">
					<div className="row">
						<div className="col-4 spd-mt-30">
							<div className="dropdown pull-right">
								Parent Category
								<button className="btn btn-default dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									{this.state.parent}
								</button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									{catDropdown}
								</div>
							</div>
						</div>
						<div className="col-5 spd-mt-30">
							<div className="form-group">
								<input type="text" className="form-control" name="categoryName" id='categoryName' />
							</div>
						</div>
						<div className="col-2 spd-mt-30">
							<button className="btn btn-primary" onClick={this.addCat.bind(this)} >Add</button>
						</div>
					</div>
					<div className="row products" >
						<div className="col-12">
							<input type="text" value={this.state.searchproduct} name="searchproduct" className="form-control searchtext" placeholder="Search By Title" onChange={this.changeInput.bind(this)}  />
							<table className="table table-bordered">
							  <thead>
							    <tr>
							      <th style={{width:300}}>Product</th>
							      <th onClick={this.sortBy.bind(this,'price')}>Price</th>
							      <th style={{maxWidth:400}}>Categories</th>
							      <th>Action</th>
							    </tr>
							  </thead>
							  <tbody>
							    {jsonitems}
							  </tbody>
							</table>
						</div>
			        </div>
			        <div className="row">
						<div className="col-12">
							{pageItem}
						</div>
					</div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
}

export default Products;
