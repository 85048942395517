import React, {Component} from 'react';
import Header from './header.js';
import './App.css';
import axios from 'axios';
import {ToastContainer} from 'react-toastify';
import {baseurl, getLocalItem, notify, setLocalItem} from './utility/utility.js';
import {loggeduser} from './utility/security';
import Sidebar from './sidebar.js';


class Profile extends Component {
	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			customersId:'',
			name:'',
			email:'',
			phone:'',
			phone2:'',
			address:'',
			oldPass:'',
			password:'',
			confirmpass:'',
		};
	}

	componentWillMount(){
		if(localStorage.getItem('account') !== null && localStorage.getItem('account') !== 'null'){
			this.loadProfile();
		}else{
			notify('Please Login!','#F44336');
			this.props.history.push('/login?return=/profile');
		}
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "name"){
			this.setState({name : input.value});
		}
		else if(input.name === "email"){
			this.setState({email : input.value});
		}
		else if(input.name === "phone"){
			this.setState({phone : input.value});
		}
		else if(input.name === "phone2"){
			this.setState({phone2 : input.value});
		}
		else if(input.name === "address"){
			this.setState({address : input.value});
		}
		else if(input.name === "password"){
			this.setState({password : input.value});
		}
		else if(input.name === "confirmpass"){
			this.setState({confirmpass : input.value});
		}
		else if(input.name === "registerpass"){
			this.setState({registerpass : input.value});
		}
		else if(input.name === "confirmpass"){
			this.setState({confirmpass : input.value});
		}
	}

	loadProfile(){
		axios.get(baseurl()+"/account/profile/",  { headers: { authorization: this.state.loggeduser.token } })
		    .then( (response)=> {
		     console.log(response);
				if(response.data.success === true){
					this.setState({customersId : response.data.profile._id});
					this.setState({name : response.data.profile.name});
					this.setState({phone : response.data.profile.phone});
					this.setState({phone2 : response.data.profile.phone2});
					this.setState({address : response.data.profile.address});
					//this.setState({totalpage : Math.ceil(response.data.count/this.state.perpage)});
				}
		    })
		    .catch(function (error){
		      console.log(error);
		});
	}

	updateProfile(){
		axios.post(baseurl()+"/account/profile/",{
				name:this.state.name,
				phone:this.state.phone,
				phone2:this.state.phone2,
				address:this.state.address,
		},  { headers: { authorization: this.state.loggeduser.token } })
		    .then( (response)=> {
		     console.log(response);
				if(response.data.success === true){
					this.loadProfile();
					var account = getLocalItem("account");
					account.user.customer.name = this.state.name;
					account.user.customer.phone = this.state.phone;
					account.user.customer.phone2 = this.state.phone2;
					account.user.customer.address = this.state.address;
					setLocalItem("account", account);
					notify("Profile Updated!", "#28a745");
				}
		    })
		    .catch(function (error){
		      console.log(error);
			});
	}

	updatePass(){
		axios.post(baseurl()+"/account/updatepassword",{
				password:this.state.password,
				confirmPassword: this.state.confirmpass,

		},  { headers: { authorization: this.state.loggeduser.token } })
		    .then( (response)=> {
		     console.log(response);
				if(response.data.success === true){
					notify("Password Updated!", "#28a745");
				}
		    })
		    .catch(function (error){
		      console.log(error);
			});
	}

  render() {

    return (
      	<div>
			<Sidebar profile = "active" />
	        <div className="page">
	        	<Header />
	        	<div className="container profile spd-min-height">
        			<div className="row spd-mt-30">
        				<div className="col-2 col-sm-2 col-md-2">
        					<img className='img img-responsive' src="/img/user.png" alt='profile' />
        				</div>
        				<div className='profile-form col-10 col-sm-10 col-md-10'>
        					<div className="form-group">
								<div className="input-group">
									<div className="input-group-addon"><i className="fa fa-pencil-square-o fa-lg"></i></div>
									<input placeholder="Name" className="form-control" type="text" name="name" id="name" value={this.state.name} onChange={this.changeInput.bind(this)}/>
								</div>
							</div>
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-addon"><i className="fa fa-phone fa-lg"></i></div>
									<input placeholder="Phone" className="form-control" type="phone" name="phone" id="phone" value={this.state.phone} onChange={this.changeInput.bind(this)}/>
								</div>
							</div>
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-addon"><i className="fa fa-phone fa-lg"></i></div>
									<input placeholder="Phone 2" className="form-control" type="phone2" name="phone2" id="phone2" value={this.state.phone2} onChange={this.changeInput.bind(this)}/>
								</div>
							</div>
							<div className="form-group">
								<div className="input-group">
									<div className="input-group-addon"><i className="fa fa-home fa-lg"></i></div>
									<textarea rows="3" placeholder="Address" className="form-control" type="address" name="address" id="address" value={this.state.address} onChange={this.changeInput.bind(this)}></textarea>
								</div>
							</div>
        				</div>
        			</div>
        			<div  className="row">
        				<div className="col-12">
        					<span className="pull-right">
	        				<button className="btn btn-success" data-toggle="modal" data-target="#updatePass">Update Password</button>
							<button onClick={this.updateProfile.bind(this)} className="btn btn-success">Save</button>
							</span>
							<div className="modal fade" id="updatePass" role="dialog" aria-labelledby="portfolioModalLabel" aria-hidden="true">
							  <div className="modal-dialog" role="document">
							    <div className="modal-content">
							      <div className="modal-header">
							        <h5 className="modal-title" id="portfolioModalLabel">Update Password</h5>
							        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
							          <span aria-hidden="true">&times;</span>
							        </button>
							      </div>
							      <div className="modal-body">
							       <form>
										<div className="form-group">
											<label htmlFor="password">New Password</label>
											<input type="password" className="form-control" id="password" name="password" value={this.state.password} onChange={this.changeInput.bind(this)}/>
										</div>
										<div className="form-group">
											<label htmlFor="confirmpass">Confirm Password</label>
											<input type="password" className="form-control" id="confirmpass" name="confirmpass" value={this.state.confirmpass} onChange={this.changeInput.bind(this)}/>
										</div>
							        </form>
							      </div>
							      <div className="modal-footer">
							        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
							        <button type="button" onClick={this.updatePass.bind(this)} data-dismiss="modal" className="btn btn-primary">Save</button>
							      </div>
							    </div>
							  </div>
							</div>
						</div>
					</div>
	        	</div><ToastContainer />
	        </div>
	        {/* <Footer /> */}
	  	</div>
    );
  }
}

export default Profile;
