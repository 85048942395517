import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee ,faCarAlt,faSearch, faUser ,faHeart, faCartPlus, faArrowsAlt, faTimes, faEdit, faArrowsAltV, faBold, faItalic, faUnderline, faTextHeight, faAlignLeft, faAlignRight, faAlignCenter, faUndo, faRedo, faCropAlt, faChevronUp,faArrowDown, faTrashAlt, faArrowUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {Row, Container, Col, Form, Button, Modal,Dropdown,ButtonGroup, InputGroup, FormControl} from 'react-bootstrap';
import { observer } from 'mobx-react';
import AppStore from '../store/AppStore';
import ColorPicker from './ColorPicker';
import ReactDOM , {render} from 'react-dom';
import Draggable from 'react-draggable';
import { Resizable } from "re-resizable";
import { AddFontLink } from '../utility/utility';

const resizeStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding:10
};


const DraggableComponent = observer(class DraggableComponent extends React.Component{

  constructor(props){
		super(props);
		this.state={
      data:this.props.data,

      showTextModel:false,
      openFontSelect:false,
		};
  }

  componentDidMount(){
    // if(this.state.data.type == 'text' && this.state.data.content == ''){
    //   this.setState({showTextModel:true});
    // }
  }

  componentWillReceiveProps(props){
    this.setState({data:props.data});
  }

  eventLogger = (e, data) => {
    console.log('Event: ', e);
    console.log('Data: ', data);
  };

  SelectFont = (name) => {
    this.setState({openFontSelect:false});
    var index = AppStore.loadedFonts.findIndex((ele) => {return ele.name == name});
    var font = AppStore.CustomizerFonts.find((ele) => {return ele.name == name});

    if(index == -1){
      AddFontLink(AppStore, font);
    }
    this.CngData('fontFamily', font.name);
  }

  CngData = (field, value) => {
    var data = this.state.data;
    data[field] = value;
    this.setState({data:data});
    this.props.Update(this.props.index, data);
  }

  SaveData(){
    this.props.Update(this.props.index, this.props.data);
    this.setState({showTextModel: false});
  }


  render(){

    let {content, position, color, bold, italic, underline, size, textAlign, backgroundColor, rotate, dimension, fontFamily, type, src} = this.state.data
    let {index} = this.props;


    return (
      <>
        <Draggable
          handle=".handle"
          defaultPosition={position}
          position={null}
          grid={[1, 1]}
          scale={1}
          onStart={() => {}}
          onDrag={() => {}}
          onStop={(e, position) => {this.CngData('position', {x:position.x, y:position.y})}}>
            <div style={{position:'absolute'}}>
              <Resizable
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding:10,
                  backgroundColor: backgroundColor,
                  transform: 'rotate('+ rotate +'deg)'
                }}

                onResizeStop={(e, direction, ref, d) => {
                  this.CngData('dimension', {
                    width: dimension.width + d.width,
                    height: dimension.height + d.height,
                  });
                }}

                defaultSize={{
                  height:dimension.height,
                  width:dimension.width
                }}
                >
                  <div className="handle"   style={{cursor:'pointer', width:'100%', height:'100%', color:color, fontWeight: bold ? 'bold' : 'inherit', textDecoration: underline ? 'underline' : 'inherit', fontStyle: italic ? 'italic' : 'inherit', fontSize:size, display:'flex', alignItems:'center', justifyContent:'center' , fontFamily: fontFamily }} >
                    {type == 'text' &&
                      <span style={{display:'block', width:'100%', textAlign:textAlign}}>{content}</span>
                    }

                    {type == 'image' &&
                      <img style={{width:'100%', height:'100%'}} draggable={false} src={src.toLowerCase()} />
                    }

                    <div className="draggableContentControllerSm">

                      {(this.props.draggableData.length > 1 && index < this.props.draggableData.length-1) &&
                      <button title="Rotate Left" className='roundBtn' onClick={() => this.props.Sort(index, 'up')}>
                        <FontAwesomeIcon icon={faArrowUp} />
                      </button>
                      }
                      {(index > 0 && this.props.draggableData.length > 1) &&
                        <button title="Rotate Left" className='roundBtn' onClick={() => this.props.Sort(index, 'down')}>
                          <FontAwesomeIcon icon={faArrowDown} />
                        </button>
                      }

                      <button title="Rotate Left" className='roundBtn' onClick={() => this.CngData('rotate', rotate == 0 ? 337.5 :  rotate - 22.5)}>
                        <FontAwesomeIcon icon={faUndo} />
                      </button>
                      <button title="Rotate Right" className='roundBtn' onClick={() => this.CngData('rotate', rotate == 360 ? 22.5 : rotate + 22.5)}>
                        <FontAwesomeIcon icon={faRedo} />
                      </button>
                      <button title="Add Text" className='roundBtn' onClick={() => type == 'image' ? this.props.UpdateImage(index) : this.setState({showTextModel:true})}>
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      <button title="Remove" className='roundBtn' onClick={() => this.props.Delete(index)} >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>
                  </div>
                  <button style={{zIndex:-1, position:'absolute', right:-15, bottom:-15, transform: 'rotate(135deg)'}} className='roundBtn'><FontAwesomeIcon icon={faArrowsAltV} /></button>
              </Resizable>
            </div>
        </Draggable>
        <Modal show={this.state.showTextModel} onHide={() => this.setState({showTextModel:false})}>
          <Modal.Header closeButton>
            <Modal.Title>ADD TEXT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='mb-2'>
              <Col sm={12}>
                <div style={{alignItems:'center', display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                  <Button className='m-1' variant={bold ? "primary" : "default"} size="sm" onClick={() => this.CngData('bold', !bold)}>
                    <FontAwesomeIcon icon={faBold} />
                  </Button>
                  <Button className='m-1' variant={italic ? "primary" : "default"} size="sm" onClick={() => this.CngData('italic', !italic)}>
                    <FontAwesomeIcon icon={faItalic} />
                  </Button>
                  <Button className='m-1' variant={underline ? "primary" : "default"} size="sm" onClick={() => this.CngData('underline', !underline)}>
                    <FontAwesomeIcon icon={faUnderline} />
                  </Button>
                  {/* <FontList /> */}

                  <Dropdown size="sm" as={ButtonGroup}>
                    {/* <Button variant="success">Split Button</Button> */}
                    <Form.Control value={size} readOnly onChange={(e) => this.CngData('size', e.target.value)} style={{maxWidth:50}} size="sm" type="text" />
                    <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => this.CngData('size', 8)}>8</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 10)}>10</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 12)}>12</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 14)}>14</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 16)}>16</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 20)}>20</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 24)}>24</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 28)}>28</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 36)}>36</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 48)}>48</Dropdown.Item>
                      <Dropdown.Item onClick={() => this.CngData('size', 72)}>72</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Button className='m-1' variant={textAlign == 'left' ? "primary" : "default"} size="sm" onClick={() => this.CngData('textAlign', 'left')}>
                    <FontAwesomeIcon icon={faAlignLeft} />
                  </Button>
                  <Button className='m-1' variant={textAlign == 'center' ? "primary" : "default"} size="sm" onClick={() => this.CngData('textAlign', 'center')}>
                    <FontAwesomeIcon icon={faAlignCenter} />
                  </Button>
                  <Button className='m-1' variant={textAlign == 'right' ? "primary" : "default"} size="sm" onClick={() => this.CngData('textAlign', 'right')}>
                    <FontAwesomeIcon icon={faAlignRight} />
                  </Button>

                </div>
              </Col>
              <Col sm={12} className='mt-2'>
                <div style={{alignItems:'center',display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                  <span>COLOR:</span>
                  <div style={{display:'inline-block', marginRight:5, marginLeft:5}}>
                    <ColorPicker  fieldName='color' color={color} cngColor={this.CngData} />
                  </div>
                  {/* <Form.Group controlId="formGridState" className='m-0 mr-1'>
                    <Form.Control size='sm' as="select" value={fontFamily} onChange={(e) => this.SelectFont(e.target.value) }>
                      {AppStore.CustomizerFonts.map((fontData, index) =>
                        <option value={fontData.name} style={{fontFamily:fontData.name}}>{fontData.name}</option>
                      )}
                    </Form.Control>
                  </Form.Group> */}
                  <div class="customDropdown">
                    <Button variant='light' onClick={() => this.setState({openFontSelect: !this.state.openFontSelect})} size='sm'>{fontFamily} <FontAwesomeIcon icon={faChevronDown} size='8' /></Button>
                    {this.state.openFontSelect &&
                      <div className="fontImageSelect">
                        {AppStore.CustomizerFonts.map((fontData, fontIndex) =>
                          <img onClick={() => this.SelectFont(fontData.name)} key={fontIndex} src={(AppStore.url+"/uploads/productimages/customizer/"+fontData.previewImage).toLowerCase()} alt={fontData.name} />
                        )}
                      </div>
                    }
                  </div>
                </div>
              </Col>
              <Col sm={12} className='mt-2'>
                <div style={{alignItems:'center',display:'flex', flexDirection:'row', flexWrap:'wrap'}}>

                  <span>BG:</span>
                  <div style={{display:'inline-block', marginRight:10, marginLeft:5}}>
                    <ColorPicker fieldName='backgroundColor' color={backgroundColor} cngColor={this.CngData} />
                  </div>
                  <Form.Check
                    custom
                    type='checkbox'
                    id={`custom-checkbox`}
                    label="Transparent"
                    onChange={() => this.CngData('backgroundColor', 'transparent' )}
                    checked={backgroundColor == 'transparent' ?  true : false}
                  />

                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control as="textarea" placeholder="Text Here..." rows="3" value={content} onChange={(e) => this.CngData('content', e.target.value)}  style={{color:color, fontWeight: bold ? 'bold' : 'inherit', textDecoration: underline ? 'underline' : 'inherit', fontStyle: italic ? 'italic' : 'inherit', fontSize:size, backgroundColor:backgroundColor, textAlign:textAlign, fontFamily: fontFamily }}/>
                </Form.Group>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={() => this.setState({showTextModel:false})}>
              Close
            </Button> */}
            <Button variant="primary" onClick={() => this.SaveData()}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

})

export default DraggableComponent;


// <Draggable
//   handle=".handle"
//   defaultPosition={{x: 0, y: 0}}
//   position={null}
//   grid={[1, 1]}
//   scale={1}
//   onStart={() => {}}
//   onDrag={() => {}}
//   onStop={() => {}}>
//     <div style={{position:'absolute'}} className="handle" >
//       <Resizable
//           style={style}
//           defaultSize={{
//             width: 200,
//             height: 200
//           }}
//         >
//           <div className="handle" style={{position:'absolute',top:5, right:-40}}><FontAwesomeIcon size='2x' icon={faArrowsAlt} /></div>
//           asdfdsfdsfsdf
//       </Resizable>
//     </div>
// </Draggable>
