import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee ,faCarAlt,faSearch, faUser ,faHeart, faCartPlus} from '@fortawesome/free-solid-svg-icons';
import {Row, Container, Col,Pagination,Spinner, Form, Button, Modal,FormControl,InputGroup, } from 'react-bootstrap';
import { observer } from 'mobx-react';
import AppStore from '../store/AppStore';
import Axios from 'axios';
import _ from 'lodash';
import { GetImagePath } from '../utility/utility';
import { SearchProductByKey } from '../utility/productSearch';

import InfiniteScroll from 'react-infinite-scroller';

const SearchDesignModal = observer(function SearchDesignModal(props) {

  const [loading, Setloading] =  useState(false);
  const [hasMore, SethasMore] =  useState(true);
  const [searchword, Setsearchword] =  useState('');
  const [products, Setproducts] =  useState([]);
  const [filteredProduct, SetfilteredProduct] =  useState([]);
  const [totalpage, Settotalpage] =  useState(0);
  const [start, Setstart] =  useState(0);
  const [perpage, Setperpage] =  useState(48);
  const [activepage, Setactivepage] =  useState(0);

  useEffect(() => {
    FilteredProduct();
  }, [AppStore.products.length])

  const FilteredProduct = () => {

    var pro = JSON.parse(JSON.stringify(AppStore.products));
    Setactivepage(0);
    SethasMore(true);
    Setproducts([]);
    SetfilteredProduct(pro);
    loadFunc(pro);

  }

  const loadFunc = (filPro) => {
    var filterJSon = JSON.parse(JSON.stringify(filPro))
    if(filterJSon.length > 0 && (activepage*perpage) + 1 < filterJSon.length){
      var NewProducts = JSON.parse(JSON.stringify(products));
      var shouldLoad = true;
      for(var i = 0; i < perpage; i++){
        if((activepage*perpage) + i < filterJSon.length){
          var singlePro = filterJSon[(activepage*perpage) + i];
          NewProducts.push(singlePro);
        }else{
          shouldLoad = false;
        }
      }
      Setactivepage(activepage+1);
      Setproducts(NewProducts);
      SethasMore(shouldLoad)
    }
  }

  const Search = (searchWord) => {

    SearchProductByKey(searchWord, AppStore, (pro) => {
      Setactivepage(0);
      SethasMore(true);
      Setproducts([]);
      SetfilteredProduct(pro);
      loadFunc(pro);
    });

  }

  return (
    <Modal.Body>
      <Row>
        <Col>
          <InputGroup size="sm" className="mb-3">
            <FormControl
              placeholder="Search Design"
              aria-describedby="basic-addon2"
              onChange={(e) => Setsearchword(e.target.value)}
            />
            <InputGroup.Append>
              <Button onClick={() => Search(searchword)} variant="outline-secondary"><FontAwesomeIcon icon={faSearch} /></Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
      {loading ?
        <Row>
          <Col>
            <div style={{alignItems:'center', display:'flex', justifyContent:'center', minHeight:100}}>
              <Spinner animation="grow" variant="primary" />
            </div>
          </Col>
        </Row>
        :
          <InfiniteScroll
            pageStart={0}
            loadMore={() => loadFunc(filteredProduct)}
            hasMore={hasMore}
            loader={
              <Row>
                <Col>
                  {/* <div style={{alignItems:'center', display:'flex', justifyContent:'center', minHeight:100}}>
                    <Spinner animation="grow" variant="primary" />
                  </div> */}
                </Col>
              </Row>
            }
          >
            <Row>
              {products.map((data, index) =>
                <Col lg={2} md={3} sm={4} xs={4}  key={index} onClick={() => props.SelectDesign(data.images[0])} style={{cursor:'pointer'}}>
                  <img  className='res-img' style={{borderRadius:10}} src={GetImagePath(data.images[0]).toLowerCase()} />
                  <h6 className='m-0 text-center'>{data.title}</h6>
                </Col>
              )}
              {(products.length == 0) &&
                <Col>
                  <h4 className="text-center mt-3" >NO DESIGN FOUND</h4>
                </Col>
              }
            </Row>

          </InfiniteScroll>

      }
    </Modal.Body>
  );
})

export default SearchDesignModal;
