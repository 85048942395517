import React, { Component, Fragment } from "react";
import { Button, Table, Modal, Form, Badge, Input, Row, Col, notification, message, Popover, Spin, Empty  } from "antd";
import moment from "moment";
import axios from "axios";
import DatePicker from 'react-datepicker';
import { RightCircleOutlined, SendOutlined, PlusCircleFilled, MinusCircleFilled, PictureOutlined, LinkOutlined, MessageOutlined  } from '@ant-design/icons';
import AppStore from "../store/AppStore";
import { loggeduser } from "../utility/security";
import { baseurl } from "../utility/utility";

class OrderTask extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loggeduser:loggeduser(),
      msg:'',
      loading:false,

      showImageMenu:false,
      showLinks:false,
      loadingLinks:false,
      albumLinks:[],

      predifinedMsgList:[
        'Avaliable',
        'Sorry! Not Avaliable',
        'Please provide phone number & address for order confirmation'
      ]
    };
  }

  SendMsg = (type = 'msg', attachmentId = null) => {

    let {msg} = this.state;

    if(type == 'msg' && !msg ){
      notification.error({message:"Message Field Empty"});
      return null;
    }

    console.log(this.props)
    // return null;

    var id = (this.props.activeUserInfo.participants.data.find((ele) => {return ele.id !== AppStore.pageId})).id

    let postData = {
      "recipient":{
        "id": id
      },
      "message":{
        "text":msg
      }
    }

    if(type == 'attachment'){
      postData.message = {
        "attachment":{
          "type":"image",
          "payload":{
            "attachment_id": attachmentId
          }
        }
      }
    }

    this.setState({loading: true})

    axios.post(AppStore.fbBaseUrl+"/"+AppStore.pageId+"/messages?access_token="+AppStore.FBapiToken, postData)
    .then((res) => {
      console.log(res)
      this.props.UpdateChat(this.props.activeUserInfo, false);
      this.setState({loading: false, msg: ''})
    })
    .catch((err) => {
      console.log(err)
      this.setState({loading: false})
    })
  }

  UploadImage = (e) => {

    console.log(e.target.files)
    // return null;

    var data = new FormData();
    data.append('filedata', e.target.files[0]);
    data.set('message', JSON.stringify({"attachment":{"type":"image", "payload":{"is_reusable":true}}}))

    axios({
      method: 'post',
      url: AppStore.fbBaseUrl+"/"+AppStore.pageId+"/message_attachments?access_token="+AppStore.FBapiToken,
      data: data,
      headers: {'Content-Type': 'multipart/form-data' }
    })
    .then((res) => {
        //handle success
        console.log(res);
        this.SendMsg('attachment', res.data.attachment_id)
    })
    .catch((res) => {
        //handle error
        console.log(res);
    });
  }

  LoadLinks = () => {
    this.setState({loadingLinks:true})
    axios.get(baseurl()+'/albumLinks', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
        console.log(response);
        if(response.data.success === true){
          this.setState({albumLinks : response.data.result, loadingLinks: false});
          AppStore.albumLinks = response.data.result;
        }
	    })
	    .catch(function (error) {
        this.setState({loadingLinks:false})
	      console.log(error);
	    });
  }

  FocusToMsg = () => {
    var mainMsgField  = document.getElementById('mainMsgField');
    if(mainMsgField){
      mainMsgField.focus();
    }
  }



  render() {

    const {TextArea} = Input;

    let {loading, msg, showImageMenu, loadingLinks, albumLinks, predifinedMsgList} = this.state;

    const content = (
      <div>
        {loadingLinks ?
          <div className='text-center'><Spin></Spin></div>
          :
          <>
            {albumLinks.length == 0 ?
              <Empty />
              :
              <>
                {albumLinks.map((data, index) =>
                  <p style={{cursor:'pointer'}} onClick={() => this.setState({msg:data.link}, () => this.SendMsg())}> {data.name} </p>
                )}
              </>
            }
          </>
        }
      </div>
    );

    const PredefinedMsg = (
      <div>
        {predifinedMsgList.map((data, index) =>
          <p key={index} style={{cursor:'pointer', padding:5, border:'0.5px solid #ddd' , margin:0}} onClick={() => this.setState({msg:data}, () => this.FocusToMsg())}> {data} </p>
        )}
      </div>
    );

    return (
      <>
        <Row>
          <Col flex={showImageMenu ? "160px" : "40px"} >
            <Button
              type="primary"
              icon={showImageMenu ? <MinusCircleFilled /> : <PlusCircleFilled /> }
              onClick={() => this.setState({showImageMenu:!showImageMenu})}
              style={{marginRight:5}}
            />
            {showImageMenu &&
              <>
                <Button
                  type="primary"
                  icon={<PictureOutlined />}
                  onClick={() => document.getElementById('imageUploader').click()}
                  style={{marginRight:5}}
                />

                <input multiple={true} onChange={(e) => this.UploadImage(e)} style={{display:'none'}} type='file' id="imageUploader" />

                <Popover content={content} title="Album Links" trigger="click"   >
                  <Button
                    type="primary"
                    icon={<LinkOutlined />}
                    onClick={() => this.LoadLinks()}
                    style={{marginRight:5}}
                  />
                </Popover>

                <Popover content={PredefinedMsg} title="Predefined Message" trigger="click"   >
                  <Button
                    type="primary"
                    icon={<MessageOutlined />}
                    style={{marginRight:5}}
                  />
                </Popover>
              </>
            }
          </Col>
          <Col flex="auto" id='mainMsgFieldWrapper'>
            <TextArea
              onFocus={() => this.setState({showImageMenu:false})}
              onChange={(e) => this.setState({msg: e.target.value})}
              value={msg}
              placeholder="Aa"
              // style={{maxWidth: showImageMenu ? '105px' : '100%'}}
              autoSize
              id="mainMsgField"
            />
          </Col>
          <Col flex="40px" className='text-center' >
            <Button
              type="primary"
              icon={<SendOutlined />}
              loading={loading}
              onClick={() => this.SendMsg()}
            />
          </Col>
        </Row>



      </>
    );
  }
}

export default OrderTask;
