import React, {Component} from 'react';
import Sidebar from './sidebar.js';
import Header from './header.js';
import axios from 'axios';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {ToastContainer} from 'react-toastify';
import {BandageToStatus2, baseurl, notify} from './utility/utility.js';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {loggeduser, sendToLoginIfNoAccess} from './utility/security';
import MultiSelect from './components/MultiSelect';
import {CSVLink} from "react-csv";

import {observer} from "mobx-react";
import AppStore from './store/AppStore';


const BlankCovers = observer(class BlankCovers extends Component {

	orderCaseCSVData = [["Model", "Quantity"]];

	constructor(props){
		super(props);
		this.state={
			loggeduser:loggeduser(),
			orders:[],
			orderdata:[],
			sortOrder:"asc",
			defaultSort:"customerName",
			startDate: new Date(),
			endDate: new Date(),
			alldeliveryperson:[],
			start:0,
			perpage:5,
			totalpage:0,
			datequery:'Select Date',

			activeOrderImage:[],
			searchWord:'',

			modelList:[],
			totalProduct:{},

			filterStatus:'',
			StatusList:['Incomplete', 'Printed', 'Case Collected', 'Case & Print Done', 'Sublimated', 'Bag Ready', 'Waiting for Customer', 'Do not send today', 'Parcelled', 'Cancelled'],
			SelectedStatusList:['Incomplete', 'Printed', 'Case Collected', 'Case & Print Done', 'Sublimated', 'Bag Ready', 'Waiting for Customer', 'Do not send today', 'Parcelled', 'Cancelled'],

			filterStatus2:'',
			StatusList2:['Case Collected', 'Case Not Collected'],
			SelectedStatusList2:['Case Collected', 'Case Not Collected'],

			SelectedCourierList:[],

			filterCourier:''
		};
	}

	componentDidMount(){
		this.LoadCourierList();
	}

	LoadCourierList(){
		setTimeout(()=>{
			if(AppStore.courier.length == 0){
				this.LoadCourierList()
			}else{
				var CourierList = this.GetCourierList(AppStore.courier);
				CourierList.push('Non Courier');
				this.setState({SelectedCourierList:CourierList}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, CourierList);
				});
			}
		},1000)
	}

	componentWillMount(){
		sendToLoginIfNoAccess("orders","view", this, "/reports/distribution");

		//  this.filterByDate(this.state.start, this.state.perpage , AppStore.orderStartDate, AppStore.orderEndDate);

		this.filterByDate(AppStore.orderStartDate, AppStore.orderEndDate);

	    axios.get(baseurl()+'/deliverypersons', { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
				if(response.data.success === true){
					this.setState({alldeliveryperson : response.data.result});
				}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	changeStartDate =  startDate => AppStore.orderStartDate = startDate;

	changeEndDate = endDate => AppStore.orderEndDate = endDate;

	sortBy(col,allOrder){
		var newOrder = this.state.sortOrder === 'asc' ? 'desc' : 'asc';
		if(this.state.sortOrder === 'asc'){
			this.setState({sortOrder : newOrder})
		}else{
			this.setState({sortOrder : newOrder})
		}

		this.setState({defaultSort:col});
		var orderdata = _.orderBy(allOrder,[col],[newOrder]);
		this.setState({orderdata: orderdata});
	 }

	 StaticSortBy(col,allOrder){
	 	this.setState({defaultSort:col});
		var orderdata = _.orderBy(allOrder,[col],[this.state.sortOrder]);
		this.setState({orderdata: orderdata});
	 }

	searchOrder(){
		if(this.state.searchWord){
			if(isNaN(this.state.searchWord)){
				var url = baseurl()+'/orders?customerName='+this.state.searchWord;
			}else{
				var url = baseurl()+'/orders?customerPhone='+this.state.searchWord;
			}


			axios.get(url , { headers: { authorization: this.state.loggeduser.token } })
			.then( (response)=> {
				if(response.data.success === true){
					var bandageOrder = BandageToStatus2(response.data.result)
					this.setState({orderdata  : bandageOrder});
					this.setState({totalpage : Math.ceil(response.data.count/this.state.perpage)});
				}
			})
			.catch(function (error) {
			console.log(error);
			});
		}else{
			notify('Enter Customer Name or Phone Number!','#F44336');
		}

	}

	filterByDate(startedDate, endedDate){

		var startDate = moment(startedDate).format("YYYY/MM/DD");
		var endDate = moment(endedDate).format("YYYY/MM/DD");

	    axios.get(baseurl()+'/orders?startDate='+startDate+'&endDate='+endDate , { headers: { authorization: this.state.loggeduser.token } })
	    .then( (response)=> {
			console.log(response)
			if(response.data.success === true){
				var bandageOrder = BandageToStatus2(response.data.result);
				console.log(bandageOrder, "bangladesotrderer")
				this.setState({orders  : bandageOrder});
				this.GetModelList(bandageOrder, this.state.filterStatus, this.state.filterCourier)
			}
	    })
	    .catch(function (error) {
	      console.log(error);
	    });
	}

	ChkOrderRenderByStatus(status){
		if( this.state.SelectedStatusList.indexOf(status) !== -1){
			return true;
		}
	}

	ChkOrderRenderByStatus2(status2){

		if( this.state.SelectedStatusList2.indexOf(status2) !== -1){
			return true;
		}
	}

	GetModelList(orders, status, filterCourier, status2 = this.state.filterStatus2 ){
		this.setState({filterStatus: status});
		this.setState({filterStatus2: status2});
		this.setState({filterCourier: filterCourier});

		var modelList = [];
		var totalProduct = {};

		for(var i = 0; i < orders.length; i++){
			if(this.ChkOrderRenderByStatus(orders[i].status) && this.ChkOrderRenderByStatus2(orders[i].status2) && this.ChkOrderRenderByCourier(orders[i].courierCompany)){
				for(var x = 0; x < orders[i].products.length; x++){

					var index = _.findIndex(modelList, function(o) { return o.name == orders[i].products[x].model && o.productType == orders[i].products[x].productType; });

					if(index === -1){
						modelList.push({
							name : orders[i].products[x].model,
							quantity : 1,
							code : [orders[i].products[x].code],
							productType : orders[i].products[x].productType
						});

						if(totalProduct[orders[i].products[x].productType]){
							totalProduct[orders[i].products[x].productType] += 1
						}else{
							totalProduct[orders[i].products[x].productType] = 1
						}

					}else{
						modelList[index].quantity += 1;
						modelList[index].code.push(orders[i].products[x].code);
						totalProduct[modelList[index].productType] += 1;
					}
				}
			}
		}

		this.setState({modelList:modelList});
		this.setState({totalProduct: totalProduct});
	}

	loadActiveDate(){
		if(AppStore.activeOrderDate == 'Today' || AppStore.activeOrderDate == ''){
			this.selecttoday();
		}else if(AppStore.activeOrderDate == 'Yesterday'){
			this.selectyesterady();
		}else if(AppStore.activeOrderDate == 'This Week'){
			this.selectweek();
		}else if(AppStore.activeOrderDate == 'This Month'){
			this.selectmonth();
		}else if(AppStore.activeOrderDate == 'Tomorrow'){
			this.selectTomorrowady();
		}else if(AppStore.activeOrderDate == '2 Day Ago'){
			this.selectTwoDaysAge();
		}else if(AppStore.activeOrderDate == '2 Day Later'){
			this.selectTwoDayslater();
		}
	}

	selecttoday(){
		var today = moment();
		this.filterByDate(today, today);
		AppStore.selecttoday();
	}

	selectyesterady(){
		var yesterday = moment().subtract(1, 'days');
		this.filterByDate(yesterday, yesterday);
		AppStore.selectyesterady();
	}

	selectTwoDaysAge(){
		var TwoDayAgo = moment().subtract(2, 'days');
		this.filterByDate(TwoDayAgo, TwoDayAgo);
		AppStore.selectTwoDaysAge();
	}

	selectweek(){
		var end = moment();
		var start = moment().subtract(1, 'week');
		this.filterByDate(start, end);
		AppStore.selectweek();

	}

	selectmonth(){
		var end = moment();
		var start  = new moment().startOf('month');
		this.filterByDate(start, end);
		AppStore.selectmonth();
	}

	selectTomorrowady(){
		var Tomorrow = moment().add(1, 'days');
		this.filterByDate(Tomorrow, Tomorrow);
		AppStore.selectTomorrowady();
	}

	selectTwoDayslater(){
		var date = moment().add(2, 'days');
		this.filterByDate(date, date);
		AppStore.selectTwoDayslater();
	}

	setActiveOrderImage(data){
		this.setState({activeOrderImage: data});
	}

	changeDeliveryPerson(e){
		AppStore.activeDeliveryPerson = e.target.value;
	}

	changeInput(e){
		var input=e.target;
		if(input.name === "searchWord"){
			this.setState({searchWord : input.value});
		}
	}

	GetModelByName(name){

		var modelDetails = AppStore.models.find(function(ele){
			return ele.name == name;
		});

		return modelDetails;
	}

	HandleSelect = (value) =>{
		console.log(value);
		var SelectedStatusList = this.state.SelectedStatusList;
		if(value == 'all'){

			var StatusList = JSON.parse(JSON.stringify(this.state.StatusList));
			var yesOrNo = true;
			for(var i =0; i < StatusList.length; i++){
				if(this.state.SelectedStatusList.indexOf(StatusList[i]) == -1){
					yesOrNo = false;
					break;
				}
			}

			if(yesOrNo){
				this.setState({SelectedStatusList: []}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}else{
				this.setState({SelectedStatusList: StatusList}, ()=>{
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}

		}else{
			if(this.state.SelectedStatusList.indexOf(value) == -1){
				SelectedStatusList.push(value);
				this.setState({SelectedStatusList: SelectedStatusList}, ()=>{
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}else{
				var index = this.state.SelectedStatusList.indexOf(value);
				SelectedStatusList.splice(index, 1);
				this.setState({SelectedStatusList: SelectedStatusList}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}
		}
	}

	HandleSelect2 = (value) =>{
		console.log(value);
		var SelectedStatusList2 = this.state.SelectedStatusList2;
		if(value == 'all'){

			var StatusList2 = JSON.parse(JSON.stringify(this.state.StatusList2));
			var yesOrNo = true;
			for(var i =0; i < StatusList2.length; i++){
				if(this.state.SelectedStatusList2.indexOf(StatusList2[i]) == -1){
					yesOrNo = false;
					break;
				}
			}

			if(yesOrNo){
				this.setState({SelectedStatusList2: []}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}else{
				this.setState({SelectedStatusList2: StatusList2}, ()=>{
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}

		}else{
			if(this.state.SelectedStatusList2.indexOf(value) == -1){
				SelectedStatusList2.push(value);
				this.setState({SelectedStatusList2: SelectedStatusList2}, ()=>{
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}else{
				var index = this.state.SelectedStatusList2.indexOf(value);
				SelectedStatusList2.splice(index, 1);
				this.setState({SelectedStatusList2: SelectedStatusList2}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.filterCourier)
				});
			}
		}
	}

	HandleCourierSelect = (value) =>{

		var SelectedCourierList = this.state.SelectedCourierList;
		if(value == 'all'){

			var CourierList = JSON.parse(JSON.stringify(this.GetCourierList(AppStore.courier)));
			var yesOrNo = true;
			for(var i =0; i < CourierList.length; i++){
				if(this.state.SelectedCourierList.indexOf(CourierList[i]) == -1){
					yesOrNo = false;
					break;
				}
			}

			if(yesOrNo){
				this.setState({SelectedCourierList: []}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.SelectedCourierList)
				});

			}else{
				this.setState({SelectedCourierList: CourierList}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.SelectedCourierList)
				});
			}

		}else{
			if(this.state.SelectedCourierList.indexOf(value) == -1){
				SelectedCourierList.push(value);
				this.setState({SelectedCourierList: SelectedCourierList}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.SelectedCourierList)
				});
			}else{
				var index = this.state.SelectedCourierList.indexOf(value);
				SelectedCourierList.splice(index, 1);
				this.setState({SelectedCourierList: SelectedCourierList}, () => {
					this.GetModelList(this.state.orders, this.state.filterStatus, this.state.SelectedCourierList)
				});
			}
		}
	}

	ChkOrderRenderByCourier(courier){
		// if( this.state.SelectedStatusList.indexOf(status) !== -1){
		// 	return true;
		// }
		if(courier == '' && this.state.SelectedCourierList.indexOf('Non Courier') !== -1){
			return true;
		}
		var exist = false;
		for(var i = 0; i < this.state.SelectedCourierList.length; i++){
			if(this.state.SelectedCourierList[i].toLowerCase() == courier.toLowerCase()){
				exist = true;
				break;
			}
		}
		return exist;
	}

	GetCourierList(courier){

		var list = ['Non Courier'];
		for(var i = 0; i < courier.length; i++){
			list.push(courier[i].name)
		}

		return list
	}


  render() {

	this.orderCaseCSVData = [['Model', 'Quantity']];

  	const modelList=_.orderBy(this.state.modelList, [modelList => modelList.name], ['Asc']).map((data, index) => {
		var modelDetails = this.GetModelByName(data.name);

		this.orderCaseCSVData.push([data.name, data.quantity]);

		return(
			<tr key={data._id}>
				<td>
					{modelDetails && modelDetails.coverImage ?
						<a target="_blank" href={baseurl()+'/uploads/productimages/models/'+modelDetails.coverImage}><img style={{width:'100%'}} src={(baseurl()+'/uploads/productimages/models/'+modelDetails.coverImage).toLowerCase()} /></a>
						:
						<span>Cover Image Not Avaliavle</span>
					}
				</td>
				<td>
					{data.productType}
				</td>
				<td>
					{data.name}
				</td>
				<td>
					{data.quantity}
				</td>
				<td>
					<div className="row">
						{data.code.map((codeData, index)=> (
							<div className='col-3'>
								 { (data.productType == undefined)?console.log(data,index):'' }
							<img key={index} className='full-width'  src={(baseurl()+'/uploads/productimages/row/covers/'+codeData.trim()+'.jpg').toLowerCase()}  />
							<p className="mt-3 text-center">{data.productType.trim()}-{codeData.trim()} {data.name}</p>
							</div>
						))}
					</div>
				</td>

			</tr>
		);
	},this);

    return (
        <div className="products">
        	<Sidebar BlankCovers="active" />
	        <div className="page">
	        	<Header />
	        	<div className="container-fluid">
	        		<div className="row spd-mt-30">
	        			<div className="col-4">
							<div className="row">
								<div className="col-8">
									<input type="text" name="searchWord" value={this.state.searchWord} className="form-control" placeholder="Search" onChange={this.changeInput.bind(this)}  />
								</div>
								<div className="col-4">
									<button onClick={this.searchOrder.bind(this)} className="btn btn-default"><i className="fa fa-search"></i></button>
								</div>
							</div>
	        			</div>
	        			<div className="col-6">
	        				<div className="row">
	        					<div className="col-4">
			        				<DatePicker
										selected={moment(AppStore.orderStartDate)}
										onChange={this.changeStartDate.bind(this)}
										dateFormat="DD/MM/YYYY"
									/>
								</div>
								<div className="col-2 text-right">
									<label className="mt-1">To</label>
								</div>
								<div className="col-4">
									<DatePicker
										selected={moment(AppStore.orderEndDate)}
										onChange={this.changeEndDate.bind(this)}
										dateFormat="DD/MM/YYYY"
									/>
								</div>
								<div className="col-2">
									<button className="btn btn-success" onClick={() => this.filterByDate(AppStore.orderStartDate, AppStore.orderEndDate)}><i className="fa fa-search"></i></button>
								</div>
							</div>
	        			</div>
	        			<div className="col-2">
							<div className="dropdown">
								<button className="btn btn-default dropdown-toggle pull-right" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										{AppStore.activeOrderDate}
								</button>
								<div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<option onClick={this.selecttoday.bind(this)} className="dropdown-item btn btn-default" href="#">Today
									</option>
									<option onClick={this.selectyesterady.bind(this)} className="dropdown-item btn btn-default" href="#">Yesterday</option>
									<option onClick={this.selectTwoDaysAge.bind(this)} className="dropdown-item btn btn-default" href="#">2 Day Ago</option>
									<option onClick={this.selectTomorrowady.bind(this)} className="dropdown-item btn btn-default" href="#">Tomorrow</option>
									<option onClick={this.selectTwoDayslater.bind(this)} className="dropdown-item btn btn-default" href="#">2 Day Later</option>
									<option onClick={this.selectweek.bind(this)} className="dropdown-item btn btn-default" href="#">This Week</option>
									<option onClick={this.selectmonth.bind(this)} className="dropdown-item btn btn-default" href="#">This month</option>
								</div>
							</div>
	        			</div>
	        		</div>
					<div className="row spd-mt-30">
						<div className='col-12'>
							<h3>
								<span className="mr-4">Case Total - </span>
								{AppStore.productTypes.map((type, index)=>
									<span className="mr-4" key={index}>{type.name} : {this.state.totalProduct[type.name] ? this.state.totalProduct[type.name] : 0 }</span>
								)}
								<Link
									className="btn btn-primary pull-right"
									to={{
										pathname: "/printblankcase",
										state: { modelList: this.state.modelList }
									}}
								>
									<i className='fa fa-print mr-2'></i>Print
								</Link>
								<button className='btn btn-default pull-right mx-1'>
									<CSVLink data={this.orderCaseCSVData}>CSV for Order</CSVLink>
								</button>
								<div className="pull-right mr-1 ml-1">
									<MultiSelect title="Courier" data={this.GetCourierList(AppStore.courier)} selected={this.state.SelectedCourierList} HandleSelect={this.HandleCourierSelect} />
									{/* <div className="dropdown">
										<button className="btn btn-default dropdown-toggle" type="button" id="orderStatus" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												{this.state.filterCourier ? this.state.filterCourier : "All Courier"}
										</button>
										<div className="dropdown-menu" aria-labelledby="orderStatus">
											<span  onClick={() => this.GetModelList(this.state.orders, this.state.filterStatus, '')} className="dropdown-item btn btn-default" href="#">Any Courier</span>
											<span  onClick={() => this.GetModelList(this.state.orders, this.state.filterStatus, 'Non Courier')} className="dropdown-item btn btn-default" href="#">Non Courier</span>
											{AppStore.courier.map((Courier, Courierindex) =>
												<span key={Courierindex}  onClick={() => this.GetModelList(this.state.orders, this.state.filterStatus, Courier.name)} className="dropdown-item btn btn-default" href="#">{Courier.name}</span>
											)}
										</div>
									</div> */}
								</div>

								<div className="pull-right mr-1 ml-1">
									<MultiSelect title="Status" data={this.state.StatusList} selected={this.state.SelectedStatusList} HandleSelect={this.HandleSelect} />
								</div>

								<div className="pull-right mr-1 ml-1">
									<MultiSelect title="Status2" data={this.state.StatusList2} selected={this.state.SelectedStatusList2} HandleSelect={this.HandleSelect2} />
								</div>



							</h3>
						</div>
					</div>
					<div className="row spd-mt-30">
						<div className="col-12 table-responsive">
							<table className="table table-bordered" id="orderTable">
							  <thead>
							    <tr>
									<td style={{width:250}}>Image</td>
									<td>Type</td>
									<td>Model</td>
									<td>Quantity</td>
									<td style={{width:800}}>All Design</td>
							    </tr>
							  </thead>
							  <tbody>
							    {modelList}
							  </tbody>
							</table>
						</div>
			        </div>
	        	</div><ToastContainer />
	        </div>
        </div>
    );
  }
})

export default BlankCovers;
